import React, { useCallback, useEffect, useState } from "react";
import { Alert, Badge, Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Input,
  MyPagination,
  Select,
  Select2,
} from "../../../components/Components";
import { debounce } from "lodash";
import {
  useGetLaporanBarbuk,
  useSatkerTahtiList,
  useGetPoldaPolres,
} from "../../../utils/apis/query";
import { months, triwulan, weeks, years } from "../../../utils";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import logo from "../../../assets/img/logo_kopsurat.png";
import moment from "moment";
import State from "../../../libraries/State";

function List() {
  const state = State.getState();
  const profileData = state.getItem("profileData", true);
  const satker = profileData?.satker?.nama_satker ?? "";
  const isBareskrim = /bareskrim/gi.test(satker);
  const [namaSatkerPoldaFilter, setNamaSatkerPoldaFilter] = useState("");
  const [namaSatkerPolresFilter, setNamaSatkerPolresFilter] = useState("");
  const [namaSatkerPoldaPayloadFilter, setNamaSatkerPoldaPayloadFilter] = useState("");
  const [namaSatkerPolresPayloadFilter, setNamaSatkerPolresPayloadFilter] = useState("");
  const [filters, setFilters] = useState({
    tipe_laporan: "",
    kategori_laporan: "",
    minggu: "",
    triwulan: "",
    bulan: "",
    tahun: "",
    semester: "",
  });
  const [payload, setPayload] = useState({
    tipe_laporan: "",
    kategori_laporan: "",
    minggu: "",
    triwulan: "",
    bulan: "",
    tahun: "",
    semester: "",
  });
  const { data: dataSatker } = useGetPoldaPolres(
    {
      polda: namaSatkerPoldaFilter,
      polres:
        namaSatkerPolresFilter === "satker_sendiri"
          ? ""
          : namaSatkerPolresFilter,
    },
    {
      enabled: !!namaSatkerPoldaFilter || !!namaSatkerPolresFilter,
    }
  );

  // const optionPolda = [
  //   { label: "SEMUA POLDA", value: "" },
  //   ...dataSatker.polda.map((o) => ({ label: o.value, value: o.value })),
  // ];
  // const optionPolres = [
  //   { label: "SEMUA POLRES", value: "" },
  //   { label: "SATKER SENDIRI", value: "satker_sendiri" },
  //   ...dataSatker.polres.map((o) => ({ label: o.value, value: o.value })),
  // ];

  const optionPolda = [
    { label: "SEMUA POLDA", value: "semua_polda" },
    ...dataSatker.polda.map((o) => ({ label: o.value, value: o.value })),
  ];
  const optionPolres = [
    { label: "SEMUA", value: "semua" },
    { label: "SATKER SENDIRI", value: "satker_sendiri" },
    ...dataSatker.polres.map((o) => ({ label: o.value, value: o.value })),
  ];

  const {
    data: dataList,
    pagination: dataListPagination,
    isFetching: isDataListFetching,
    isError: isDataListError,
    error: dataListError,
    refetch: refetchDataList,
  } = useGetLaporanBarbuk(
    {
      tipe_laporan: filters.tipe_laporan,
      kategori_laporan: filters.kategori_laporan,
      minggu: filters.minggu,
      triwulan: filters.triwulan,
      bulan: filters.bulan,
      tahun: filters.tahun,
      semester: filters.semester,
      nama_satker_polda: namaSatkerPoldaPayloadFilter,
      nama_satker_polres: namaSatkerPolresPayloadFilter,
    },
    {
      enabled: false
    }
  );

  const handleDebounce = useCallback(
    debounce(({ field, val }) => {
      setPayload({ ...payload, [field]: val });
    }, 500),
    []
  );

  useEffect(() => {
      if (profileData) {
        moment.locale();
        const satker = profileData?.satker?.nama_satker ?? "";
        const username = profileData?.user?.username ?? "";
        const isPolres = /polres/gi.test(satker);
        const isBareskrim = /bareskrim/gi.test(satker);
        if (isBareskrim) {
          if (['kabareskrim', 'kapusiknas'].includes(username)) {
            setNamaSatkerPoldaFilter('semua_polda');
            setNamaSatkerPoldaPayloadFilter('semua_polda');
          } else {
            setNamaSatkerPoldaFilter(satker);
            setNamaSatkerPoldaPayloadFilter(satker);
          }
          setNamaSatkerPolresFilter("satker_sendiri");
  
        } else {
          if (isPolres) {
            let namaPolda = profileData?.profile?.nama_satker_parent_polres ?? "";
            setNamaSatkerPoldaFilter(namaPolda);
            setNamaSatkerPoldaPayloadFilter(namaPolda);
          } else {
            setNamaSatkerPoldaFilter(satker);
            setNamaSatkerPoldaPayloadFilter(satker);
            setNamaSatkerPolresFilter("satker_sendiri");
          }
        }
      }
    }, [profileData]);
  
    useEffect(() => {
      if (profileData) {
        const satker = profileData?.satker?.nama_satker ?? "";
        const isPolres = /polres/gi.test(satker);
        const isBareskrim = /bareskrim/gi.test(satker);
        const isfilterPoldaAll = namaSatkerPoldaFilter === 'semua_polda'
  
        if (isBareskrim || isfilterPoldaAll) {
          const filterBareskrim = /bareskrim/gi.test(namaSatkerPoldaFilter);
          if (!namaSatkerPolresFilter) setNamaSatkerPolresPayloadFilter('satker_sendiri')
          if (isfilterPoldaAll && namaSatkerPolresPayloadFilter === 'satker_sendiri') {
            setNamaSatkerPoldaPayloadFilter(dataSatker.polda.map((o) => o.value).join(","))
          }
          if (isfilterPoldaAll && namaSatkerPolresPayloadFilter === 'semua') {
            setNamaSatkerPoldaPayloadFilter('semua_polda')
          } 
          if (namaSatkerPolresFilter === 'semua') {
            let payload = filterBareskrim  ? '' : dataSatker.polres.map((o) => o.value).join(",");
            setNamaSatkerPolresPayloadFilter(payload);
          }
          
        } else {
          if (isPolres) {
            let namaPolres = profileData?.profile?.nama_satker_tahti ?? "";
            setNamaSatkerPolresFilter(namaPolres);
            setNamaSatkerPolresPayloadFilter(namaPolres);
          } else {
            if (!namaSatkerPolresFilter) setNamaSatkerPolresPayloadFilter('satker_sendiri')
            if (namaSatkerPolresFilter === 'semua') {
              setNamaSatkerPolresPayloadFilter(
                dataSatker.polres.map((o) => o.value).join(",")
              )
            }
          }
        }
      }
    }, [profileData, dataSatker, namaSatkerPolresFilter, namaSatkerPoldaFilter])

  // useEffect(() => {
  //   if (profileData) {
  //     moment.locale();
  //     const satker = profileData?.satker?.nama_satker ?? "";
  //     const isPolres = /polres/gi.test(satker);
  //     const isBareskrim = /bareskrim/gi.test(satker);
  //     if (isBareskrim) {
  //       setNamaSatkerPoldaFilter(satker);
  //       setNamaSatkerPolresFilter("semua_polres");
  //     } else {
  //       setNamaSatkerPoldaFilter(!isPolres ? satker : "");
  //       setNamaSatkerPolresFilter(isPolres ? satker : "");
  //     }
  //   }
  // }, [profileData]);
  // useEffect(() => {
  //   if (profileData) {
  //     moment.locale();
  //     const satker = profileData?.satker?.nama_satker ?? "";
  //     const isPolres = /polres/gi.test(satker);
  //     const isBareskrim = /bareskrim/gi.test(satker);

  //     if (isBareskrim) {
  //       if (namaSatkerPolresPayloadFilter === "") {
  //         setNamaSatkerPolresPayloadFilter(
  //           dataSatker.polres.map((o) => o.value).join(",")
  //         );
  //       } else {
  //         setNamaSatkerPolresPayloadFilter(namaSatkerPolresPayloadFilter);
  //       }
  //     } else {
  //       if (!isPolres && namaSatkerPolresPayloadFilter === "") {
  //         setNamaSatkerPolresPayloadFilter(
  //           dataSatker.polres.map((o) => o.value).join(",")
  //         );
  //       }
  //       if (isPolres && namaSatkerPolresPayloadFilter === "") {
  //         setNamaSatkerPolresPayloadFilter(satker);
  //       }
  //     }
  //   }
  // }, [profileData, dataSatker, namaSatkerPolresPayloadFilter, refetchDataList]);

  const generate = () => {
    // setPayload((prev) => {
    //   return {
    //     ...prev,
    //     tipe_laporan: filters.tipe_laporan,
    //     kategori_laporan: filters.kategori_laporan,
    //     minggu: filters.minggu,
    //     bulan: filters.bulan,
    //     triwulan: filters.triwulan,
    //     tahun: filters.tahun,
    //     semester: filters.semester,
    //     nama_satker_polda: namaSatkerPoldaFilter,
    //     nama_satker_polres:
    //       namaSatkerPolresPayloadFilter === "satker_sendiri"
    //         ? ""
    //         : namaSatkerPolresPayloadFilter,
    //   }
    // });

    refetchDataList()
  };
  const exportToExcel = async (tipe) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");
    let tipeLaporan =
      tipe === "pengelolaan"
        ? "Pengelolaan Barang Bukti"
        : tipe === "daftar"
        ? "Daftar Barang Bukti"
        : "";

    const title = tipeLaporan;
    const today = new Date().toLocaleDateString();

    worksheet.mergeCells("A1:I1");
    worksheet.getCell("A1").value = title;
    worksheet.getCell("A1").alignment = { horizontal: "center" };
    worksheet.getCell("A1").font = { bold: true, size: 16 };

    worksheet.getCell("A2").value = `Tanggal: ${today}`;
    worksheet.getCell("A2").alignment = { horizontal: "left" };
    worksheet.getCell("A2").font = { size: 12 };

    worksheet.getCell("A3").value =
      "Tipe Laporan : " + (payload.tipe_laporan ? payload.tipe_laporan : "-");
    worksheet.getCell("A3").alignment = { horizontal: "left" };
    worksheet.getCell("A3").font = { size: 12 };

    worksheet.getCell("A4").value =
      "Satker : " + (payload.satker ? payload.satker : "Semua Satker");
    worksheet.getCell("A4").alignment = { horizontal: "left" };
    worksheet.getCell("A4").font = { size: 12 };

    const tableColumn = [];
    const tableRows = [];

    if (tipe === "pengelolaan") {
      tableColumn.push(
        "No",
        "Bulan",
        "Jumlah Barang Bukti",
        "Kondisi Barang Bukti",
        "Jumlah Penerimaan Barang Bukti",
        "Jumlah Pengeluaran Barang Bukti",
        "Giat Kontrol BB",
        "Jumlah Supervisi",
        "Keterangan"
      );

      Object.entries(dataList).forEach(([month, data], index) => {
        if (typeof data === "object") {
          tableRows.push([
            index + 1,
            month.charAt(0).toUpperCase() + month.slice(1),
            data.jumlah_barbuk || "-",
            data.kondisi_barbuk || "-",
            data.jumlah_penerimaan || "-",
            data.jumlah_pengeluaran || "-",
            data.giat_kontrol_bb || "-",
            data.jumlah_supervisi || "-",
            data.keterangan || "-",
          ]);
        }
      });
    } else if (tipe === "daftar") {
      tableColumn.push(
        "No",
        "No. Register Barang Bukti",
        "Jenis Barang Bukti",
        "Nama Barang Bukti",
        "Jumlah",
        "Satuan",
        "Tindak Pidana",
        "Pemilikan Diakui Oleh",
        "Disita Tanggal",
        "Dari",
        "Oleh",
        "Keterangan"
      );

      dataList.forEach((data, index) => {
        tableRows.push([
          index + 1,
          data.no_registrasi_barbuk || "-",
          data.jenis_barbuk || "-",
          data.nama_barbuk || "-",
          data.jumlah || "-",
          data.satuan || "-",
          data.tindak_pidana || "-",
          data.pemilikan_diakui_oleh || "-",
          data.disita_tanggal || "-",
          data.dari || "-",
          data.oleh || "-",
          data.keterangan || "-",
        ]);
      });
    }

    worksheet.addRow(tableColumn);

    tableRows.forEach((row) => {
      worksheet.addRow(row);
    });

    // Styling header
    const headerRow = worksheet.getRow(5);
    headerRow.eachCell((cell) => {
      cell.alignment = { horizontal: "center" };
      cell.font = { bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFA500" },
      };
    });

    // Mengatur lebar kolom otomatis
    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const cellValue = cell.value ? cell.value.toString() : "";
        maxLength = Math.max(maxLength, cellValue.length);
      });
      column.width = maxLength + 2;
    });

    const lastRow = worksheet.lastRow.number;
    for (let rowIndex = 5; rowIndex <= lastRow; rowIndex++) {
      const row = worksheet.getRow(rowIndex);
      row.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    }

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "table-data.xlsx");
  };

  const exportToExcelRekap = async () => {
    const workbook = new ExcelJS.Workbook();
    const tableColumn = [];

    tableColumn.push(
      "No",
      "Satuan Kerja",
      "Jenis Barang Bukti",
      "Nama Barang Bukti",
      "Tindak Pidana",
      "Jumlah",
      "Satuan",
      "Keterangan"
    );

    for (const [satker, items] of Object.entries(dataList)) {
      if (items && Array.isArray(items)) {
        const titleLaporan = "Rekapitulasi Barang Bukti";
        const today = new Date().toLocaleDateString();

        const worksheet = workbook.addWorksheet(satker);

        worksheet.mergeCells("A1:H1");
        worksheet.getCell("A1").value = titleLaporan;
        worksheet.getCell("A1").alignment = { horizontal: "center" };
        worksheet.getCell("A1").font = { bold: true, size: 16 };

        worksheet.getCell("A2").value = `Tanggal: ${today}`;
        worksheet.getCell("A2").alignment = { horizontal: "left" };
        worksheet.getCell("A2").font = { size: 12 };

        worksheet.getCell("A3").value =
          "Tipe Laporan: Rekapitulasi Barang Bukti";
        worksheet.getCell("A3").alignment = { horizontal: "left" };
        worksheet.getCell("A3").font = { size: 12 };

        worksheet.addRow(tableColumn);

        items.forEach((item, index) => {
          const row = [
            index + 1,
            satker,
            item.jenis || "-",
            item.nama_barbuk || "-",
            item.tindak_pidana || "-",
            item.jumlah_penerimaan || "-",
            item.satuan || "-",
            item.keterangan || "-",
          ];
          worksheet.addRow(row);
        });

        // Styling header
        const headerRow = worksheet.getRow(4);
        headerRow.eachCell((cell) => {
          cell.alignment = { horizontal: "center" };
          cell.font = { bold: true };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFA500" },
          };
        });

        // Mengatur lebar kolom otomatis
        worksheet.columns.forEach((column) => {
          let maxLength = 0;
          column.eachCell({ includeEmpty: true }, (cell) => {
            const cellValue = cell.value ? cell.value.toString() : "";
            maxLength = Math.max(maxLength, cellValue.length);
          });
          column.width = maxLength + 2;
        });

        const lastRow = worksheet.lastRow.number;
        for (let rowIndex = 4; rowIndex <= lastRow; rowIndex++) {
          const row = worksheet.getRow(rowIndex);
          row.eachCell((cell) => {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          });
        }
      }
    }

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "laporan_barang_bukti_rekap.xlsx");
  };

  const exportToPDF = (tipe) => {
    const doc = new jsPDF("landscape");
    const pageWidth = doc.internal.pageSize.getWidth();
    const text = payload.tipe_laporan;

    // logo
    const imgData = logo;
    const imgWidth = 30;
    const imgHeight = 30;
    doc.addImage(
      imgData,
      "PNG",
      (pageWidth - imgWidth) / 2,
      10,
      imgWidth,
      imgHeight
    );

    let titleLaporan =
      tipe === "pengelolaan"
        ? "Pengelolaan Barang Bukti"
        : tipe === "daftar"
        ? "Daftar Barang Bukti"
        : "";

    doc.text(titleLaporan, pageWidth / 2, 45, { align: "center" });
    const titleWidth = doc.getTextWidth(text);
    doc.line(
      (pageWidth - titleWidth) / 2,
      47,
      (pageWidth + titleWidth) / 2,
      47
    );

    const today = new Date();
    const formattedDate = today.toLocaleDateString();

    doc.setFontSize(10);
    doc.text(`Tanggal: ${formattedDate}`, 15, 55);
    doc.text(`Tipe Laporan: ${text}`, 15, 60);

    const satkerText = `Satker: ${payload.satker || "Semua Satker"}`;
    doc.text(satkerText, 15, 65);

    // kolom tabel sesuai tipe laporan
    const tableColumn = [];
    const tableRows = [];
    let header = "";
    if (tipe === "pengelolaan") {
      header = "Pengelolaan Barang Bukti";
      tableColumn.push(
        "No",
        "Bulan",
        "Jumlah Barang Bukti",
        "Kondisi Barang Bukti",
        "Jumlah Penerimaan Barang Bukti",
        "Jumlah Pengeluaran Barang Bukti",
        "Giat Kontrol BB",
        "Jumlah Supervisi",
        "Keterangan"
      );

      Object.entries(dataList).forEach(([month, data], index) => {
        if (typeof data === "object") {
          tableRows.push([
            index + 1,
            month.charAt(0).toUpperCase() + month.slice(1),
            data.jumlah_barbuk || "-",
            data.kondisi_barbuk || "-",
            data.jumlah_penerimaan || "-",
            data.jumlah_pengeluaran || "-",
            data.giat_kontrol_bb || "-",
            data.jumlah_supervisi || "-",
            data.keterangan || "-",
          ]);
        }
      });
    } else if (tipe === "daftar") {
      header = "Daftar Barang Bukti";
      tableColumn.push(
        "No",
        "No. Register Barang Bukti",
        "Jenis Barang Bukti",
        "Nama Barang Bukti",
        "Jumlah",
        "Satuan",
        "Tindak Pidana",
        "Pemilikan Diakui Oleh",
        "Disita Tanggal",
        "Dari",
        "Oleh",
        "Keterangan"
      );

      dataList.map((data, index) =>
        tableRows.push([
          index + 1,
          data.no_registrasi_barbuk || "-",
          data.jenis_barbuk || "-",
          data.nama_barbuk || "-",
          data.jumla || "-",
          data.satuan || "-",
          data.tindak_pidana || "-",
          data.pemilikan_diakui_oleh || "-",
          data.disita_tanggal || "-",
          data.dari || "-",
          data.oleh || "-",
          data.keterangan || "-",
        ])
      );
    }

    // Render tabel
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 70,
      headStyles: { fillColor: [255, 165, 0] },
      styles: { fontSize: 10 },
    });

    doc.save(`${payload.tipe_laporan}.pdf`);
  };

  const exportToPDFRekap = () => {
    const doc = new jsPDF("landscape");
    const pageWidth = doc.internal.pageSize.getWidth();

    const imgData = logo;
    const imgWidth = 30;
    const imgHeight = 30;

    for (const [satker, items] of Object.entries(dataList)) {
      if (items && Array.isArray(items)) {
        doc.addImage(
          imgData,
          "PNG",
          (pageWidth - imgWidth) / 2,
          10,
          imgWidth,
          imgHeight
        );

        // judul laporan
        doc.setFontSize(14);
        const titleLaporan = "Rekapitulasi Barang Bukti";
        doc.text(titleLaporan, pageWidth / 2, 45, { align: "center" });

        // Garis di bawah judul
        const titleWidth = doc.getTextWidth(titleLaporan);
        doc.line(
          (pageWidth - titleWidth) / 2,
          47,
          (pageWidth + titleWidth) / 2,
          47
        );

        const today = new Date();
        const formattedDate = today.toLocaleDateString();

        doc.setFontSize(10);
        doc.text(`Tanggal: ${formattedDate}`, 15, 55);
        doc.text(`Satuan Kerja: ${satker}`, 15, 60);

        const tableColumn = [
          "No",
          "Satuan Kerja",
          "Jenis Barang Bukti",
          "Nama Barang Bukti",
          "Tindak Pidana",
          "Jumlah",
          "Satuan",
          "Keterangan",
        ];

        const tableRows = items.map((item, index) => [
          index + 1,
          item.satker || "-",
          item.jenis || "-",
          item.nama_barbuk || "-",
          item.tindak_pidana || "-",
          item.jumlah_penerimaan || "-",
          item.satuan || "-",
          item.keterangan || "-",
        ]);

        // Render tabel
        doc.autoTable({
          head: [tableColumn],
          body: tableRows,
          startY: 65,
          styles: { fontSize: 10 },
          headStyles: { fillColor: [255, 165, 0] },
        });

        // Tambah halaman
        if (
          Object.entries(dataList).indexOf([satker, items]) <
          Object.entries(dataList).length - 1
        ) {
          doc.addPage();
        }
      }
    }
    doc.save("laporan_barang_bukti_rekap.pdf");
  };

  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item">
              <Link to="/laporan/barang-bukti">Laporan</Link>
            </li>
            <li className="breadcrumb-item active fw-bold" aria-current="page">
              Laporan Barang Bukti
            </li>
          </ol>
        </div>
      </div>
      <Row>
        <Col xl="12">
          <Card className="card bg-white">
            <Card.Body>
              <Row className="g-1 mb-2">
                {/* Filter and button */}
                {
                  <Col sm="12">
                    {isDataListError && (
                      <Alert variant="danger">
                        <ul>
                          {dataListError?.response?.data?.detail?.map(
                            (errEdit, idx) => (
                              <li key={idx}>
                                <b>{errEdit.loc[1]} </b>
                                {errEdit.msg}
                              </li>
                            )
                          ) ?? (
                            <li>
                              <b>Error </b>{" "}
                              {dataListError?.response?.data?.message ??
                                " Proses Submit Gagal"}
                            </li>
                          )}
                        </ul>
                      </Alert>
                    )}
                  </Col>
                }
                <Col>
                  <Card className="card bg-white">
                    <Card.Body className="bg-white d-flex flex-column gap-2">
                      <Row className="g-2">
                        <Col sm="12">
                          <div className="fs-6 fw-bold">Pilih Tipe</div>
                        </Col>
                        <Col sm="3">
                          <Select
                            name="tipe_laporan"
                            default="Pilih Tipe Laporan"
                            options={[
                              {
                                value: "Laporan Mingguan",
                                text: "Laporan Mingguan",
                              },
                              {
                                value: "Laporan Bulanan",
                                text: "Laporan Bulanan",
                              },
                              {
                                value: "Laporan Triwulan",
                                text: "Laporan Triwulan",
                              },
                              {
                                value: "Laporan Semester",
                                text: "Laporan Semester",
                              },
                              {
                                value: "Laporan Tahunan",
                                text: "Laporan Tahunan",
                              },
                            ]}
                            onChange={(e) => {
                              setFilters({
                                ...filters,
                                tipe_laporan: e.target.value,
                              });
                            }}
                          />
                        </Col>
                        <Col sm="3">
                          <Select
                            name="Kategori Laporan"
                            default="Pilih Kategori Laporan"
                            options={[
                              {
                                value: "Daftar Barang Bukti",
                                text: "Daftar Barang Bukti",
                              },
                              {
                                value: "Pengelolaan Barang Bukti",
                                text: "Pengelolaan Barang Bukti",
                              },
                              {
                                value: "Rekap Mutasi Barang Bukti",
                                text: "Rekap Mutasi Barang Bukti",
                              },
                              {
                                value: "Rekap Daftar Barang Bukti",
                                text: "Rekap Daftar Barang Bukti",
                              },
                            ]}
                            onChange={(e) => {
                              setFilters({
                                ...filters,
                                kategori_laporan: e.target.value,
                              });
                            }}
                          />
                        </Col>
                        <Col sm="3">
                          <Select2
                            defaultValue={"Semua Kesatuan"}
                            options={optionPolda}
                            value={namaSatkerPoldaFilter}
                            name="nama_satker_polda"
                            placeholder="Semua Kesatuan"
                            required
                            isClearable
                            isSearchable
                            disabled={!isBareskrim}
                            onChange={(e) => {
                              setNamaSatkerPoldaFilter(e.value);
                            }}
                          />
                        </Col>
                        <Col sm="3">
                          <Select2
                            defaultValue={"Semua Kesatuan"}
                            options={optionPolres}
                            value={namaSatkerPolresFilter}
                            name="nama_satker_polres"
                            placeholder="Semua Polres"
                            required
                            isClearable
                            isSearchable
                            onChange={(e) => {
                              setNamaSatkerPolresFilter(e.value);
                              setNamaSatkerPolresPayloadFilter(e.value);
                            }}
                          />
                        </Col>
                        <Col>
                          <Select
                            name="minggu"
                            default="Pilih Minggu"
                            options={weeks}
                            onChange={(e) => {
                              setFilters({
                                ...filters,
                                minggu: e.target.value,
                              });
                            }}
                            disabled={
                              filters.tipe_laporan !== "Laporan Mingguan"
                            }
                          />
                        </Col>
                        <Col>
                          <Select
                            name="bulan"
                            default="Pilih Bulan"
                            options={months}
                            onChange={(e) => {
                              setFilters({
                                ...filters,
                                bulan: e.target.value,
                              });
                            }}
                            disabled={
                              filters.tipe_laporan === "Laporan Semester" ||
                              filters.tipe_laporan === "Laporan Triwulan" ||
                              filters.tipe_laporan === "Laporan Tahunan"
                            }
                          />
                        </Col>
                        <Col>
                          <Select
                            name="semester"
                            default="Pilih Semester"
                            options={[
                              {
                                value: "Semester 1",
                                text: "Semester 1",
                              },
                              {
                                value: "Semester 2",
                                text: "Semester 2",
                              },
                            ]}
                            onChange={(e) => {
                              setFilters({
                                ...filters,
                                semester: e.target.value,
                              });
                            }}
                            disabled={
                              filters.tipe_laporan !== "Laporan Semester" ||
                              filters.tipe_laporan !== "Laporan Triwulan"
                            }
                          />
                        </Col>
                        <Col>
                          <Select
                            name="triwulan"
                            default="Pilih Triwulan"
                            options={triwulan}
                            onChange={(e) => {
                              setFilters({
                                ...filters,
                                triwulan: e.target.value,
                              });
                            }}
                            disabled={
                              filters.tipe_laporan !== "Laporan Triwulan"
                            }
                          />
                        </Col>
                        <Col>
                          <Select
                            name="tahun"
                            default="Pilih Tahun"
                            options={years}
                            onChange={(e) => {
                              setFilters({
                                ...filters,
                                tahun: e.target.value,
                              });
                            }}
                          />
                        </Col>
                        {
                          !isDataListFetching ? 
                          (
                            <Button className="btn btn-primary" onClick={generate}>
                              Generate
                            </Button>
                          ) :
                          (
                            <Button className="btn btn-primary" disabled>
                              <Spinner
                                animation="border"
                                variant="light"
                              />
                            </Button>
                          )
                        }
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="g-2">
                {/* Table Untuk Tipe Pengelolaan Barang Bukti */}
                {payload.kategori_laporan === "Pengelolaan Barang Bukti" && (
                  <Col sm="12">
                    <Card className="card bg-white">
                      <Card.Body className="bg-white flex-column d-flex gap-2 overflow-scroll">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex flex-column gap-2">
                            <div className="fs-4 fw-bold">
                              Laporan Tahunan Pengelolaan Barang Bukti
                            </div>
                            <div className="fs-sm d-flex gap-2">
                              <b>Minggu :</b> {filters.minggu || "-"}
                              <b>Bulan :</b> {filters.bulan || "-"}
                              <b>Triwulan :</b> {filters.triwulan || "-"}
                              <b>Semester :</b> {filters.semester || "-"}
                              <b>Tahun :</b> {filters.tahun || "-"}
                            </div>
                          </div>
                          <div className="d-flex align-items-end gap-2">
                            <Button
                              className="btn-danger btn"
                              onClick={() => exportToPDF("pengelolaan")}
                            >
                              Export PDF
                            </Button>
                            <Button
                              className="btn-success btn"
                              onClick={() => exportToExcel("pengelolaan")}
                            >
                              Export XLSX
                            </Button>
                          </div>
                        </div>
                        <Table className="table dems-table">
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>Bulan</th>
                              <th>Jumlah Barang Bukti</th>
                              <th>Kondisi Barang Bukti</th>
                              <th>Jumlah Penerimaan Barang Bukti</th>
                              <th>Jumlah Pengeluaran Barang Bukti</th>
                              <th>Giat Kontrol BB</th>
                              <th>Jumlah Supervisi</th>
                              <th>Keterangan</th>
                            </tr>
                          </thead>
                          <tbody>
                            {isDataListFetching && (
                              <tr>
                                <td
                                  colSpan="10"
                                  style={{ textAlign: "center" }}
                                >
                                  <Spinner
                                    animation="border"
                                    variant="primary"
                                  />
                                </td>
                              </tr>
                            )}
                            {isDataListError &&
                              (dataListError?.response?.data?.detail?.map(
                                (err, idx) => (
                                  <tr key={idx}>
                                    <td
                                      colSpan="4"
                                      style={{ textAlign: "center" }}
                                      className="text-danger"
                                    >
                                      <span>
                                        <b>{err.loc[1]} </b>
                                        {err.msg}
                                      </span>
                                    </td>
                                  </tr>
                                )
                              ) ?? (
                                <tr>
                                  <td
                                    colSpan="4"
                                    style={{ textAlign: "center" }}
                                    className="text-danger"
                                  >
                                    <span>
                                      <b>Opps Error </b>Reload page or please
                                      wait until we fix this issue
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            {dataList &&
                            !isDataListFetching &&
                            !isDataListError &&
                            Object.keys(dataList).length > 0
                              ? Object.entries(dataList).map(
                                  ([month, data], index) => {
                                    if (typeof data === "object") {
                                      return (
                                        <tr key={index}>
                                          <td>{index + 1}</td>{" "}
                                          <td>
                                            {month.charAt(0).toUpperCase() +
                                              month.slice(1)}
                                          </td>{" "}
                                          <td>{data.jumlah_barbuk || "-"}</td>{" "}
                                          <td>{data.kondisi_barbuk || "-"}</td>{" "}
                                          <td>
                                            {data.jumlah_penerimaan || "-"}
                                          </td>{" "}
                                          <td>
                                            {data.jumlah_pengeluaran || "-"}
                                          </td>{" "}
                                          <td>{data.giat_kontrol_bb || "-"}</td>{" "}
                                          <td>
                                            {data.jumlah_supervisi || "-"}
                                          </td>{" "}
                                          <td>{data.keterangan || "-"}</td>{" "}
                                        </tr>
                                      );
                                    }
                                  }
                                )
                              : Object.entries(dataList).length === 0 && (
                                  <tr>
                                    <td
                                      colSpan="10"
                                      style={{ textAlign: "center" }}
                                    >
                                      <span>Tidak Ada Data!</span>
                                    </td>
                                  </tr>
                                )}
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  </Col>
                )}
                {/* Table Untuk Tipe Daftar Barang Bukti */}
                {payload.kategori_laporan === "Daftar Barang Bukti" && (
                  <Col sm="12">
                    <Card className="card bg-white">
                      <Card.Body className="bg-white flex-column d-flex gap-2 overflow-scroll">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex flex-column gap-2">
                            <div className="fs-4 fw-bold">
                              Daftar Barang Bukti
                            </div>
                            <div className="fs-sm d-flex gap-2">
                              <b>Tahun :</b> {payload.tahun}
                              <b>Bulan :</b> {payload.bulan}
                              <b>Semester :</b> {payload.semester}
                            </div>
                          </div>
                          <div className="d-flex align-items-end gap-2">
                            <Button
                              className="btn-danger btn"
                              onClick={() => exportToPDF("daftar")}
                            >
                              Export PDF
                            </Button>
                            <Button
                              className="btn-success btn"
                              onClick={() => exportToExcel("daftar")}
                            >
                              Export XLSX
                            </Button>
                          </div>
                        </div>
                        <Table className="table dems-table">
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>No. Register Barang Bukti</th>
                              <th>Jenis Barang Bukti</th>
                              <th>Nama Barang Bukti</th>
                              <th>Jumlah</th>
                              <th>Satuan</th>
                              <th>Tindak Pidanan</th>
                              <th>Pemilikan Diakui Oleh</th>
                              <th>Disita Tanggal</th>
                              <th>Dari</th>
                              <th>Oleh</th>
                              <th>Keterangan</th>
                            </tr>
                          </thead>
                          <tbody>
                            {isDataListFetching && (
                              <tr>
                                <td
                                  colSpan="12"
                                  style={{ textAlign: "center" }}
                                >
                                  <Spinner
                                    animation="border"
                                    variant="primary"
                                  />
                                </td>
                              </tr>
                            )}
                            {isDataListError &&
                              (dataListError?.response?.data?.detail?.map(
                                (err, idx) => (
                                  <tr key={idx}>
                                    <td
                                      colSpan="4"
                                      style={{ textAlign: "center" }}
                                      className="text-danger"
                                    >
                                      <span>
                                        <b>{err.loc[1]} </b>
                                        {err.msg}
                                      </span>
                                    </td>
                                  </tr>
                                )
                              ) ?? (
                                <tr>
                                  <td
                                    colSpan="4"
                                    style={{ textAlign: "center" }}
                                    className="text-danger"
                                  >
                                    <span>
                                      <b>Opps Error </b>Reload page or please
                                      wait until we fix this issue
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            {dataList.length &&
                            !isDataListFetching &&
                            !isDataListError
                              ? dataList.map((data, index) => (
                                  <tr key={index}>
                                    <td>{index + 1 || "-"}</td>
                                    <td>{data.no_registrasi_barbuk || "-"}</td>
                                    <td>{data.jenis_barbuk || "-"}</td>
                                    <td>{data.nama_barbuk || "-"}</td>
                                    <td>{data.jumlah || "-"}</td>
                                    <td>{data.satuan || "-"}</td>
                                    <td>{data.tindak_pidana || "-"}</td>
                                    <td>{data.pemilikan_diakui_oleh || "-"}</td>
                                    <td>{data.disita_tanggal || "-"}</td>
                                    <td>{data.dari || "-"}</td>
                                    <td>{data.oleh || "-"}</td>
                                    <td>{data.keterangan || "-"}</td>
                                  </tr>
                                ))
                              : !isDataListFetching &&
                                !dataList.length &&
                                !isDataListError && (
                                  <tr>
                                    <td
                                      colSpan="10"
                                      style={{ textAlign: "center" }}
                                    >
                                      <span>Tidak Ada Data !</span>
                                    </td>
                                  </tr>
                                )}
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  </Col>
                )}
                {/* Table Untuk Tipe Rekap Daftar Barang Bukti */}
                {payload.kategori_laporan === "Rekap Daftar Barang Bukti" && (
                  <Col sm="12">
                    <Card className="card bg-white">
                      <Card.Body className="bg-white flex-column d-flex gap-2 overflow-scroll">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex flex-column gap-2">
                            <div className="fs-4 fw-bold">
                              Laporan Semester Rekap Daftar Barang Bukti
                            </div>
                            {/* <div className="fs-sm d-flex gap-2">
                              <b>Tahun :</b> 20204
                              <b>Bulan :</b> Januari 
                              <b>Semester :</b> I
                            </div> */}
                          </div>
                          <div className="d-flex align-items-end gap-2">
                            <Button
                              className="btn-danger btn"
                              onClick={exportToPDFRekap}
                            >
                              Export PDF
                            </Button>
                            <Button
                              className="btn-success btn"
                              onClick={exportToExcelRekap}
                            >
                              Export XLSX
                            </Button>
                          </div>
                        </div>
                        <div className="d-flex flex-column gap-1">
                          {Object.entries(dataList).map(
                            ([satker, items], satkerIndex) => (
                              <>
                                <Card
                                  className="card bg-white"
                                  key={satkerIndex}
                                >
                                  <Card.Body className="bg-white d-flex flex-column">
                                    <div className="fs-6 fw-bold">
                                      Satuan Kerja : Bag. {satker}
                                    </div>
                                    <Table className="table dems-table">
                                      <thead>
                                        <tr>
                                          <th>No</th>
                                          <th>Satuan Kerja</th>
                                          <th>Jenis Barang Bukti</th>
                                          <th>Nama Barang Bukti</th>
                                          <th>Tindak Pidana</th>
                                          <th>Jumlah</th>
                                          <th>Satuan</th>
                                          <th>Keterangan</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {isDataListFetching && (
                                          <tr>
                                            <td
                                              colSpan="8"
                                              style={{ textAlign: "center" }}
                                            >
                                              <Spinner
                                                animation="border"
                                                variant="primary"
                                              />
                                            </td>
                                          </tr>
                                        )}
                                        {isDataListError &&
                                          (dataListError?.response?.data?.detail?.map(
                                            (err, idx) => (
                                              <tr key={idx}>
                                                <td
                                                  colSpan="4"
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                  className="text-danger"
                                                >
                                                  <span>
                                                    <b>{err.loc[1]} </b>
                                                    {err.msg}
                                                  </span>
                                                </td>
                                              </tr>
                                            )
                                          ) ?? (
                                            <tr>
                                              <td
                                                colSpan="8"
                                                style={{ textAlign: "center" }}
                                                className="text-danger"
                                              >
                                                <span>
                                                  <b>Opps Error </b>Reload page
                                                  or please wait until we fix
                                                  this issue
                                                </span>
                                              </td>
                                            </tr>
                                          ))}
                                        {dataList &&
                                        !isDataListFetching &&
                                        !isDataListError
                                          ? items.map((item, itemIndex) => (
                                              <tr key={item.id}>
                                                <td>{itemIndex + 1}</td>
                                                <td>{item.satker}</td>
                                                <td>{item.jenis}</td>
                                                <td>{item.nama_barbuk}</td>
                                                <td>{item.tindak_pidana}</td>
                                                <td>
                                                  {item.jumlah_penerimaan}
                                                </td>
                                                <td>{item.satuan}</td>
                                                <td>
                                                  {item.tanggal_penerimaan}
                                                </td>
                                              </tr>
                                            ))
                                          : !isDataListFetching &&
                                            !dataList &&
                                            !isDataListError && (
                                              <tr>
                                                <td
                                                  colSpan="8"
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  <span>Tidak Ada Data !</span>
                                                </td>
                                              </tr>
                                            )}
                                      </tbody>
                                    </Table>
                                  </Card.Body>
                                </Card>
                              </>
                            )
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default List;
