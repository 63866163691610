import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as Components from "../../../components/Components";
import State from "../../../libraries/State";

function UserRoleForm() {
  const params = useParams();
  const state = State.getState();
  const navigate = useNavigate();

  const id = params?.id !== undefined ? params.id : null;
  const FormType = id ? "ubah" : "tambah";

  const handleSubmit = () => {
    withReactContent(Swal)
      .fire({
        title: "Apa anda yakin?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
      })
      .then((result) => {
        if (result.isConfirmed) {
          /**
           * Preparing data
           */
          const data = {
            username: state.getItem("username"),
            nama_lengkap: state.getItem("nama_lengkap"),
            role: state.getItem("role"),
          };
          state.flush(); // clean state

          /**
           * Send to APi
           */
          //   

          /**
           * Send notification
           */
          Swal.fire({
            title: "Berhasil",
            icon: "success",
            text: "Data berhasil tersimpan",
          });
          navigate(`/user_role`);
        }
      });
  };

  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/user_role">Role User</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/user_role">List Role User</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Form {FormType}
                </li>
              </ol>
            </div>
          </div>
        </Card.Body>
      </Card>

      <Row className="g-3">
        <Col xl="12">
          <Card className="card">
            <Card.Body className="bg-white">
              <Row>
                <Col sm="4">
                  <Components.Input
                    type="text"
                    name="Role"
                    label="Role"
                    placeholder="Role"
                  />
                </Col>
                <Col sm="4">
                  <Components.Input
                    type="text"
                    name="User"
                    label="User"
                    placeholder="User"
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="bg-white">
              <div className="d-flex justify-content-end align-items-center gap-3">
                <Button as={Link} to="/user_role" variant="warning">
                  Batal
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                  Simpan
                </Button>
              </div>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default UserRoleForm;
