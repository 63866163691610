import { createBrowserRouter, RouterProvider } from "react-router-dom";

/**
 * Import templates
 */
// import "./assets/css/remixicon.css";
import "remixicon/fonts/remixicon.css";
import "./scss/style.scss";

/**
 * Import middlewares
 */
import * as Middleware from "./middlewares/Core";
/**
 * Import layouts
 */
import Layout from "./layouts/base/Layout";
/**
 * Import error handlers
 */
// import NotFound from "./handlers/NotFound";
import NotFound from "./pages/NotFound";
// import NotFound from "./handlers/NotFound";
import Debug from "./pages/debug";

/**
 * Import all pages right here
 */
import Login from "./pages/auths/Login";
import SessionExpired from "./pages/auths/SessionExpired";
import Dashboard from "./pages/dashboard/index";
import DashTahanan from "./pages/dashboard/Tahanan/index";
import DashAdmindik from "./pages/dashboard/arsipMindik/index";
import DashBarbuk from "./pages/dashboard/BarangBukti/index";
import DashTindakPidana from "./pages/dashboard/tindakPidana/index";
import ProfileUser from "./pages/masters/profile";
import ListUsers from "./pages/masters/users/List";
import UsersForm from "./pages/masters/users/Form";
import UserProfile from "./pages/masters/user_profile/List";
import UserProfileForm from "./pages/masters/user_profile/Form";
import UserRole from "./pages/masters/user_role/List";
import UserRoleForm from "./pages/masters/user_role/Form";
import Role from "./pages/masters/settings/role/List";
import RoleForm from "./pages/masters/settings/role/Form";
import RoleMenu from "./pages/masters/settings/role_menu/List";
import RoleMenuForm from "./pages/masters/settings/role_menu/Form";
import SettingMenuList from "./pages/masters/settings/menus/List";
import SettingMenuForm from "./pages/masters/settings/menus/Form";
import SatkerList from "./pages/masters/satker/List";
import SatkerForm from "./pages/masters/satker/Form";
// import FileManager from "./pages/FileManager";
import FileManager from "./pages/masters/file_manager/index";
import FMViewer from "./pages/masters/file_manager/viewer";
// Tahanan Barang Bukti
import ListBarangBukti from "./pages/masters/tahanan/barang_bukti/List";
import ListBarangBuktiDetail from "./pages/masters/tahanan/barang_bukti/detail";
import ListBarangBuktiFile from "./pages/masters/tahanan/barang_bukti/file";
// Tahanan Daftar Tahanan
import ListTahanan from "./pages/masters/tahanan/list_tahanan/List";
import ListTahananTitipan from "./pages/masters/tahanan/registrasi_tahanan/List";
import TahananForm from "./pages/masters/tahanan/registrasi_tahanan/Form";
import PrintTahanan from "./pages/masters/tahanan/registrasi_tahanan/Print";
import ListInputBonTahanan from "./pages/masters/tahanan/bon_tahanan/BonCreateList";
import ListBonTahanan from "./pages/masters/tahanan/bon_tahanan/List";
import BonTahananForm from "./pages/masters/tahanan/bon_tahanan/Form";
import PengembalianTahanan from "./pages/masters/tahanan/bon_tahanan/PengembalianTahanan";
import ListPenangguhanTahanan from "./pages/masters/tahanan/penangguhan/List";
import PenangguhanTahananForm from "./pages/masters/tahanan/penangguhan/Form";
import ListPembantaranTahanan from "./pages/masters/tahanan/pembantaran/List";
import PembantaranTahananForm from "./pages/masters/tahanan/pembantaran/Form";
import ListPerpanjanganTahanan from "./pages/masters/tahanan/perpanjangan/List";
import PerpanjanganTahananForm from "./pages/masters/tahanan/perpanjangan/Form";
import PerpanjanganTahananEdit from "./pages/masters/tahanan/perpanjangan/Edit";
import ListPengalihanTahanan from "./pages/masters/tahanan/pengalihan_jenis/List";
import PengalihanTahananForm from "./pages/masters/tahanan/pengalihan_jenis/Form";
import ListPengeluaranTahanan from "./pages/masters/tahanan/pengeluaran/List";
import PengeluaranTahananForm from "./pages/masters/tahanan/pengeluaran/Form";
import PengeluaranTahananEdit from "./pages/masters/tahanan/pengeluaran/Edit";
// Barang Bukti
import EvidencesList from "./pages/masters/evidences/evidences/List";
import EvidencesForm from "./pages/masters/evidences/evidences/Form";
import EvidencesAdd from "./pages/masters/evidences/evidences/Add";
import EvidencesImport from "./pages/masters/evidences/evidences/Import";
import EvidenceCategoryList from "./pages/masters/evidences/categories/List";
import EvidenceCategoryForm from "./pages/masters/evidences/categories/Form";
import EvidenceTypeList from "./pages/masters/evidences/types/List";
import EvidenceTypeForm from "./pages/masters/evidences/types/Form";
import EvidenceUnitList from "./pages/masters/evidences/units/List";
import EvidenceUnitForm from "./pages/masters/evidences/units/Form";
// Barang Bukti Menu Tahti
import TahananBarbukList from "./pages/tahti/barbuk/barang_bukti/List";
import TahananBarbukView from "./pages/tahti/barbuk/barang_bukti/View";
import TahananBarbukPenyerahanList from "./pages/tahti/barbuk/penyerahan/ListPenyerahan";
import TahananBarbukPengembalianList from "./pages/tahti/barbuk/penyerahan/ListPengembalian";
import TahananBarbukPemusnahanList from "./pages/tahti/barbuk/penyerahan/ListPemusnahan";
import TahananBarbukPenyerahanForm from "./pages/tahti/barbuk/penyerahan/Form";
import PengembalianPinjamPakaiForm from "./pages/tahti/barbuk/penyerahan/Pengembalian";
import TahananBarbukPenyerahanEdit from "./pages/tahti/barbuk/penyerahan/Edit";
import TahananBarbukPengembalianForm from "./pages/tahti/barbuk/pengembalian/Form";
import ListPenerimaanBarangBukti from "./pages/tahti/barbuk/penerimaan/List";
import FormPenerimaanBarangBukti from "./pages/tahti/barbuk/penerimaan/Form";
import PenyimpananBarbukList from "./pages/tahti/barbuk/penyimpanan/List";
import PenyimpananBarbukView from "./pages/tahti/barbuk/penyimpanan/View";
// Arsip
import ListArsip from "./pages/masters/arsip/index";
// Shared File
import ListShared from "./pages/masters/shared_file/index";
// Pengaturan Scope
import PengaturanRoleList from "./pages/pengaturan/role/List";
import PengaturanRoleForm from "./pages/pengaturan/role/Form";
import PengaturanRoleEdit from "./pages/pengaturan/role/Edit";
import PengaturanPenggunaList from "./pages/pengaturan/pengguna/List";
import PengaturanRoleAksesForm from "./pages/pengaturan/role_akses/Form";
import PengaturanPenggunaForm from "./pages/pengaturan/pengguna/Form";
import PengaturanPenggunaEdit from "./pages/pengaturan/pengguna/Edit";
import RiwayatLogList from "./pages/pengaturan/riwayat/List";
import RiwayatLogDetail from "./pages/pengaturan/riwayat/Detail";
// Laporan Scope
import LaporanBarangBukti from "./pages/laporan/barbuk/List";
import LaporanTahanan from "./pages/laporan/tahanan/List";
// Lokasi Barang Bukti
import LokasiBarbukList from "./pages/masters/lokasi_barbuk/List";
import LokasiBarbukForm from "./pages/masters/lokasi_barbuk/Form";
// Jenis Tindak Pidana
import JenisTindakPidanaList from "./pages/masters/jenis_tindak_pidana/List";
import JenisTindakPidanaForm from "./pages/masters/jenis_tindak_pidana/Form";
// Perkara
import PerkaraList from "./pages/masters/perkara/List";
import PerkaraForm from "./pages/masters/perkara/Form";
// Rutan
import RutanList from "./pages/masters/rutan/List";
import RutanForm from "./pages/masters/rutan/Form";

// Master Barang Bukti
import MasterBarbukJenisList from "./pages/masters/barang_bukti/Jenis/List";
import MasterBarbukJenisForm from "./pages/masters/barang_bukti/Jenis/FormLayout";
import MasterBarbukJenisEdit from "./pages/masters/barang_bukti/Jenis/EditLayout";

import MasterBarbukSubJenisList from "./pages/masters/barang_bukti/SubJenis/List";
import MasterBarbukSubJenisForm from "./pages/masters/barang_bukti/SubJenis/FormLayout";
import MasterBarbukSubJenisEdit from "./pages/masters/barang_bukti/SubJenis/EditLayout";

import MasterBarbukTipeList from "./pages/masters/barang_bukti/Tipe/List";
import MasterBarbukTipeForm from "./pages/masters/barang_bukti/Tipe/FormLayout";
import MasterBarbukTipeEdit from "./pages/masters/barang_bukti/Tipe/EditLayout";

import MasterBarbukUnitList from "./pages/masters/barang_bukti/Unit/List";
import MasterBarbukUnitForm from "./pages/masters/barang_bukti/Unit/FormLayout";
import MasterBarbukUnitEdit from "./pages/masters/barang_bukti/Unit/EditLayout";

import NotificationList from "./pages/notification/List";

import { QueryClient, QueryClientProvider } from "react-query";
// Search
import SearchPage from "./pages/masters/search";
import { useEffect } from "react";
import { jenisTindakPidana } from "./utils";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
    },
  },
});
/**
 * Initiate auth routes
 */
const authRoutes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/session-expired",
    element: <SessionExpired />,
  },
];

/**
 * Intitate dashboard routes
 */
const dashboardRoutes = [
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Middleware.Authenticated group={null} page={null}  render={<Dashboard />} />,
      },
      {
        path: "/dashboard",
        element: <Middleware.Authenticated group={null} page={null}  render={<Dashboard />} />,
      },
      {
        path: "/dashboard/tahanan",
        element: <Middleware.Authenticated group={null} page={null}  render={<DashTahanan />} />,
      },
      {
        path: "/dashboard/admindik",
        element: <Middleware.Authenticated group={null} page={null}  render={<DashAdmindik />} />,
      },
      {
        path: "/dashboard/barangbukti",
        element: <Middleware.Authenticated group={null} page={null}  render={<DashBarbuk />} />,
      },
      {
        path: "/dashboard/tindak-pidana",
        element: <Middleware.Authenticated group={null} page={null}  render={<DashTindakPidana />} />,
      },
    ],
  },
];

const userRoutes = [
  {
    element: <Layout />,
    children: [
      {
        path: "/profile",
        element: <ProfileUser />,
      },
      {
        path: "/users",
        element: <Middleware.Authenticated group={null} page={null}  render={<ListUsers />} />,
      },
      {
        path: "/users/form/:id?",
        element: <Middleware.Authenticated group={null} page={null}  render={<UsersForm />} />,
      },
      {
        path: "/user_profile",
        element: <Middleware.Authenticated group={null} page={null}  render={<UserProfile />} />,
      },
      {
        path: "/user_profile/form/:id?",
        element: <Middleware.Authenticated group={null} page={null}  render={<UserProfileForm />} />,
      },
      {
        path: "/user_role",
        element: <Middleware.Authenticated group={null} page={null}  render={<UserRole />} />,
      },
      {
        path: "/user_role/form/:id?",
        element: <Middleware.Authenticated group={null} page={null}  render={<UserRoleForm />} />,
      },
    ],
  },
];

/**
 * Initiate evidence routes
 */
const evidenceRoutes = [
  {
    element: <Layout />,
    children: [
      {
        path: "/master/barang-bukti/list",
        element: <Middleware.Authenticated group={null} page={null}  render={<EvidencesList />} />,
      },
      {
        path: "/evidences/add",
        element: <Middleware.Authenticated group={null} page={null}  render={<EvidencesAdd />} />,
      },
      {
        path: "/evidences/form/:id?",
        element: <Middleware.Authenticated group={null} page={null}  render={<EvidencesForm />} />,
      },
      {
        path: "/evidences/Import",
        element: <Middleware.Authenticated group={null} page={null}  render={<EvidencesImport />} />,
      },
      {
        path: "/evidence_categories",
        element: <Middleware.Authenticated group={null} page={null}  render={<EvidenceCategoryList />} />,
      },
      {
        path: "/evidence_categories/form/:id?",
        element: <Middleware.Authenticated group={null} page={null}  render={<EvidenceCategoryForm />} />,
      },
      {
        path: "/evidence_types",
        element: <Middleware.Authenticated group={null} page={null}  render={<EvidenceTypeList />} />,
      },
      {
        path: "/evidence_types/form/:id?",
        element: <Middleware.Authenticated group={null} page={null}  render={<EvidenceTypeForm />} />,
      },
      {
        path: "/evidence_units",
        element: <Middleware.Authenticated group={null} page={null}  render={<EvidenceUnitList />} />,
      },
      {
        path: "/evidence_units/form/:id?",
        element: <Middleware.Authenticated group={null} page={null}  render={<EvidenceUnitForm />} />,
      },
    ],
  },
];

// Tahanan Dan Barang Bukti

const tahtiRoutes = [
  {
    element: <Layout />,
    children: [
      {
        path: "/barbuk/list",
        element: <Middleware.Authenticated group={"TAHTI"} page={"Daftar Barang Bukti"}  render={<TahananBarbukList />} />,
      },
      {
        path: "/barbuk/view/:id?",
        element: <Middleware.Authenticated group={"TAHTI"} page={"Daftar Barang Bukti"}  render={<TahananBarbukView />} />,
      },
      {
        path: "/barbuk/pengeluaran/list",
        element: (
          <Middleware.Authenticated group={"TAHTI"} page={"Penyerahan"}  render={<TahananBarbukPenyerahanList />} />
        ),
      },
      {
        path: "/barbuk/pengeluaran/form",
        element: (
          <Middleware.Authenticated group={"TAHTI"} page={"Penyerahan"}  render={<TahananBarbukPenyerahanForm />} />
        ),
      },
      {
        path: "/barbuk/pengeluaran/pengembalian_pinjampakai/:id?",
        element: (
          <Middleware.Authenticated group={"TAHTI"} page={"Penyerahan"}  render={<PengembalianPinjamPakaiForm />} />
        ),
      },
      {
        path: "/barbuk/pengeluaran/edit/:id?",
        element: (
          <Middleware.Authenticated group={"TAHTI"} page={"Penyerahan"}  render={<TahananBarbukPenyerahanEdit />} />
        ),
      },
      {
        path: "/barbuk/pemusnahan/list",
        element: (
          <Middleware.Authenticated group={"TAHTI"} page={"Pemusnahan"}  render={<TahananBarbukPemusnahanList />} />
        ),
      },
      {
        path: "/barbuk/pemusnahan/form",
        element: (
          <Middleware.Authenticated group={"TAHTI"} page={"Pemusnahan"}  render={<TahananBarbukPenyerahanForm />} />
        ),
      },
      {
        path: "/barbuk/pemusnahan/edit/:id?",
        element: (
          <Middleware.Authenticated group={"TAHTI"} page={"Pemusnahan"}  render={<TahananBarbukPenyerahanEdit />} />
        ),
      },
      {
        path: "/barbuk/pengembalian/list",
        element: (
          <Middleware.Authenticated group={"TAHTI"} page={null} 
            render={<TahananBarbukPengembalianList />}
          />
        ),
      },
      {
        path: "/barbuk/pengembalian/form",
        element: (
          <Middleware.Authenticated group={"TAHTI"} page={null}  render={<TahananBarbukPenyerahanForm />} />
        ),
      },
      {
        path: "/barbuk/pengembalian/edit/:id?",
        element: (
          <Middleware.Authenticated group={"TAHTI"} page={null}  render={<TahananBarbukPenyerahanEdit />} />
        ),
      },
      {
        path: "/barbuk/pengembalian/:id?",
        element: (
          <Middleware.Authenticated group={"TAHTI"} page={null} 
            render={<TahananBarbukPengembalianForm />}
          />
        ),
      },
      {
        path: "/barbuk/penerimaan/list",
        element: (
          <Middleware.Authenticated group={"TAHTI"} page={"Penerimaan"}  render={<ListPenerimaanBarangBukti />} />
        ),
      },
      {
        path: "/barbuk/penerimaan/form",
        element: (
          <Middleware.Authenticated group={"TAHTI"} page={"Penerimaan"}  render={<FormPenerimaanBarangBukti />} />
        ),
      },
      {
        path: "/barbuk/penerimaan/form/:id?",
        element: (
          <Middleware.Authenticated group={"TAHTI"} page={"Penerimaan"}  render={<FormPenerimaanBarangBukti />} />
        ),
      },
      {
        path: "/barbuk/penyimpanan/list",
        element: (
          <Middleware.Authenticated group={"TAHTI"} page={"Penyimpanan"}  render={<PenyimpananBarbukList />} />
        ),
      },
      {
        path: "/barbuk/penyimpanan/view/:id?",
        element: (
          <Middleware.Authenticated group={"TAHTI"} page={"Penyimpanan"}  render={<PenyimpananBarbukView />} />
        ),
      },
    ],
  },
];

/**
 * Initiate setting routes
 */
const settingRoutes = [
  {
    element: <Layout />,
    children: [
      {
        path: "/menus",
        element: <Middleware.Authenticated group={null} page={null}  render={<SettingMenuList />} />,
      },
      {
        path: "/menus/form/:id?",
        element: <Middleware.Authenticated group={null} page={null}  render={<SettingMenuForm />} />,
      },
      {
        path: "/role",
        element: <Middleware.Authenticated group={null} page={null}  render={<Role />} />,
      },
      {
        path: "/role/form/:id?",
        element: <Middleware.Authenticated group={null} page={null}  render={<RoleForm />} />,
      },
      {
        path: "/role_menu",
        element: <Middleware.Authenticated group={null} page={null}  render={<RoleMenu />} />,
      },
      {
        path: "/role_menu/form/:id?",
        element: <Middleware.Authenticated group={null} page={null}  render={<RoleMenuForm />} />,
      },
      {
        path: "/role/akses/:role/:id?",
        element: (
          <Middleware.Authenticated group={null} page={null}  render={<PengaturanRoleAksesForm />} />
        ),
      },
    ],
  },
];

const SatkerRoutes = [
  {
    element: <Layout />,
    children: [
      {
        path: "/satker",
        element: <Middleware.Authenticated group={null} page={null}  render={<SatkerList />} />,
      },
      {
        path: "/satker/form/:id?",
        element: <Middleware.Authenticated group={null} page={null}  render={<SatkerForm />} />,
      },
    ],
  },
];

const File = [
  {
    element: <Layout />,
    children: [
      {
        path: "/arsip",
        element: <Middleware.Authenticated group={null} page={null}  render={<FileManager />} />,
      },
      {
        path: "/arsip/view/:file",
        element: <Middleware.Authenticated group={null} page={null}  render={<FMViewer />} />,
      },
    ],
  },
];

const Tahanan = [
  {
    element: <Layout />,
    children: [
      {
        path: "/tahanan/barang_bukti",
        element: <Middleware.Authenticated group={null} page={null}  render={<ListBarangBukti />} />,
      },
      {
        path: "/tahanan/barang_bukti/detail",
        element: (
          <Middleware.Authenticated group={null} page={null}  render={<ListBarangBuktiDetail />} />
        ),
      },
      {
        path: "/tahanan/barang_bukti/detail/file",
        element: <Middleware.Authenticated group={null} page={null}  render={<ListBarangBuktiFile />} />,
      },
      {
        path: "/tahanan/list",
        element: <Middleware.Authenticated group={null} page={null}  render={<ListTahanan />} />,
      },
      {
        path: "/tahanan/form/:id?",
        element: <Middleware.Authenticated group={null} page={null}  render={<TahananForm />} />,
      },
      {
        path: "/tahanan/lapas/form/:id?",
        element: <Middleware.Authenticated group={null} page={null}  render={<TahananForm />} />,
      },
      {
        path: "/tahanan/registrasi/list",
        element: <Middleware.Authenticated group={null} page={null}  render={<ListTahananTitipan />} />,
      },
      {
        path: "/tahanan/registrasi/form/:id?",
        element: <Middleware.Authenticated group={null} page={null}  render={<TahananForm />} />,
      },
      {
        path: "/tahanan/lapas/registrasi/form/:id?",
        element: <Middleware.Authenticated group={null} page={null}  render={<TahananForm />} />,
      },
      {
        path: "/tahanan/print/:id?",
        element: <Middleware.Authenticated render={<PrintTahanan />} />,
      },
      {
        path: "/tahanan/bon/list",
        element: <Middleware.Authenticated group={null} page={null}  render={<ListBonTahanan />} />,
      },
      {
        path: "/tahanan/input/list",
        element: <Middleware.Authenticated group={null} page={null}  render={<ListInputBonTahanan />} />,
      },
      {
        path: "/tahanan/bon/form/:id?",
        element: <Middleware.Authenticated group={null} page={null}  render={<BonTahananForm />} />,
      },
      {
        path: "/tahanan/bon/pengembalian_tahanan/:id?",
        element: <Middleware.Authenticated group={null} page={null}  render={<PengembalianTahanan />} />,
      },
      {
        path: "/tahanan/penangguhan/list",
        element: (
          <Middleware.Authenticated group={null} page={null}  render={<ListPenangguhanTahanan />} />
        ),
      },
      {
        path: "/tahanan/penangguhan/form/:id?",
        element: (
          <Middleware.Authenticated group={null} page={null}  render={<PenangguhanTahananForm />} />
        ),
      },
      {
        path: "/tahanan/pembantaran/list",
        element: (
          <Middleware.Authenticated group={null} page={null}  render={<ListPembantaranTahanan />} />
        ),
      },
      {
        path: "/tahanan/pembantaran/form/:id?",
        element: (
          <Middleware.Authenticated group={null} page={null}  render={<PembantaranTahananForm />} />
        ),
      },
      {
        path: "/tahanan/perpanjangan/list",
        element: (
          <Middleware.Authenticated group={null} page={null}  render={<ListPerpanjanganTahanan />} />
        ),
      },
      {
        path: "/tahanan/perpanjangan/form/:id?",
        element: (
          <Middleware.Authenticated group={null} page={null}  render={<PerpanjanganTahananForm />} />
        ),
      },
      {
        path: "/tahanan/perpanjangan/edit/:id?",
        element: (
          <Middleware.Authenticated group={null} page={null}  render={<PerpanjanganTahananEdit />} />
        ),
      },
      {
        path: "/tahanan/pengalihan/list",
        element: (
          <Middleware.Authenticated group={null} page={null}  render={<ListPengalihanTahanan />} />
        ),
      },
      {
        path: "/tahanan/pengalihan/list",
        element: (
          <Middleware.Authenticated group={null} page={null}  render={<ListPengalihanTahanan />} />
        ),
      },
      {
        path: "/tahanan/pengalihan/form/:id?",
        element: (
          <Middleware.Authenticated group={null} page={null}  render={<PengalihanTahananForm />} />
        ),
      },
      {
        path: "/tahanan/pengeluaran/list",
        element: (
          <Middleware.Authenticated group={null} page={null}  render={<ListPengeluaranTahanan />} />
        ),
      },
      {
        path: "/tahanan/pengeluaran/form",
        element: (
          <Middleware.Authenticated group={null} page={null}  render={<PengeluaranTahananForm />} />
        ),
      },
      {
        path: "/tahanan/pengeluaran/edit/:id?",
        element: (
          <Middleware.Authenticated group={null} page={null}  render={<PengeluaranTahananEdit />} />
        ),
      },
    ],
  },
];

const Arsip = [
  {
    element: <Layout />,
    children: [
      {
        path: "/arsip/list",
        element: <Middleware.Authenticated group={null} page={null}  render={<ListArsip />} />,
      },
    ],
  },
];

const Search = [
  {
    element: <Layout />,
    children: [
      {
        path: "/search",
        element: <Middleware.Authenticated group={null} page={null}  render={<SearchPage />} />,
      },
    ],
  },
];

const SharedFile = [
  {
    element: <Layout />,
    children: [
      {
        path: "/shared-file",
        element: <Middleware.Authenticated group={null} page={null}  render={<ListShared />} />,
      },
    ],
  },
];

const Pengaturan = [
  {
    element: <Layout />,
    children: [
      {
        path: "/pengaturan/role/list",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<PengaturanRoleList />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/pengaturan/role/form",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<PengaturanRoleForm />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/pengaturan/role/edit",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<PengaturanRoleEdit />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/pengaturan/pengguna/list",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<PengaturanPenggunaList />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/pengaturan/pengguna/form",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<PengaturanPenggunaForm />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/pengaturan/pengguna/edit/:id?",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<PengaturanPenggunaEdit />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/pengaturan/riwayat/list",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<RiwayatLogList />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/pengaturan/riwayat/detail/:id?",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<RiwayatLogDetail />}
          ></Middleware.Authenticated>
        ),
      },
    ],
  },
];

const Laporan = [
  {
    element: <Layout />,
    children: [
      {
        path: "/laporan/tahanan/rekap-tahanan-polri",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<LaporanTahanan />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/laporan/tahanan/rekap-tahanan-rutan-yang-lebih-dari-60-hari",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<LaporanTahanan />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/laporan/tahanan/rekap-tahanan-rutan-yang-kurang-dari-60-hari",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<LaporanTahanan />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/laporan/tahanan/daftar-tahanan-direktorat-narkoba-yang-lebih-dari-60-hari",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<LaporanTahanan />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/laporan/tahanan/daftar-tahanan-direktorat-narkoba-yang-kurang-dari-60-hari",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<LaporanTahanan />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/laporan/tahanan/daftar-tahanan-titipan-kejaksaan-pada-rutan",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<LaporanTahanan />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/laporan/tahanan/daftar-tahanan-atensi-menonjol",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<LaporanTahanan />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/laporan/tahanan/daftar-tahanan-yang-menderita-sakit",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<LaporanTahanan />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/laporan/tahanan/daftar-tahanan-perempuan",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<LaporanTahanan />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/laporan/tahanan/daftar-tahanan-kriminal-umum",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<LaporanTahanan />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/laporan/tahanan/daftar-tahanan-ditangguhkan",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<LaporanTahanan />}
          ></Middleware.Authenticated>
        ),
      },
    ],
  },
  {
    element: <Layout />,
    children: [
      {
        path: "/laporan/barang-bukti",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<LaporanBarangBukti />}
          ></Middleware.Authenticated>
        ),
      },
    ],
  },
];

const LokasiBarbuk = [
  {
    element: <Layout />,
    children: [
      {
        path: "/penyimpanan-barbuk",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<LokasiBarbukList />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/penyimpanan-barbuk/form/:id?",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<LokasiBarbukForm />}
          ></Middleware.Authenticated>
        ),
      },
    ],
  },
];

const JenisTindakPidana = [
  {
    element: <Layout />,
    children: [
      {
        path: "/master/jenis-tindak-pidana",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<JenisTindakPidanaList />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/master/jenis-tindak-pidana/form/:id?",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<JenisTindakPidanaForm />}
          ></Middleware.Authenticated>
        ),
      },
    ],
  },
];

const Perkara = [
  {
    element: <Layout />,
    children: [
      {
        path: "/master/perkara",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<PerkaraList />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/master/perkara/form/:id?",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<PerkaraForm />}
          ></Middleware.Authenticated>
        ),
      },
    ],
  },
];
const Rutan = [
  {
    element: <Layout />,
    children: [
      {
        path: "/master/rutan",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<RutanList />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/master/rutan/form/:id?",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<RutanForm />}
          ></Middleware.Authenticated>
        ),
      },
    ],
  },
];

const MasterBarbuk = [
  {
    element: <Layout />,
    children: [
      // Jenis
      {
        path: "/master/barang-bukti/jenis",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<MasterBarbukJenisList />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/master/barang-bukti/jenis/form",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<MasterBarbukJenisForm />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/master/barang-bukti/jenis/edit/:id?",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<MasterBarbukJenisEdit />}
          ></Middleware.Authenticated>
        ),
      },
      // Sub Jenis
      {
        path: "/master/barang-bukti/sub-jenis",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<MasterBarbukSubJenisList />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/master/barang-bukti/sub-jenis/form",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<MasterBarbukSubJenisForm />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/master/barang-bukti/sub-jenis/edit/:id?",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<MasterBarbukSubJenisEdit />}
          ></Middleware.Authenticated>
        ),
      },
      // Tipe
      {
        path: "/master/barang-bukti/tipe",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<MasterBarbukTipeList />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/master/barang-bukti/tipe/form",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<MasterBarbukTipeForm />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/master/barang-bukti/tipe/edit/:id?",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<MasterBarbukTipeEdit />}
          ></Middleware.Authenticated>
        ),
      },
      // Unit
      {
        path: "/master/barang-bukti/unit",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<MasterBarbukUnitList />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/master/barang-bukti/unit/form",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<MasterBarbukUnitForm />}
          ></Middleware.Authenticated>
        ),
      },
      {
        path: "/master/barang-bukti/unit/edit/:id?",
        element: (
          <Middleware.Authenticated group={null} page={null} 
            render={<MasterBarbukUnitEdit />}
          ></Middleware.Authenticated>
        ),
      },
    ],
  },
];

const Notification = [
  {
    element: <Layout />,
    children: [
      {
        path: "/notification/all",
        element: <Middleware.Authenticated group={null} page={null}  render={<NotificationList />} />,
      },
    ],
  },
];

/**
 * Load all defined routes into main router
 * note: make sure route you made have same properties
 */
const routers = createBrowserRouter([
  ...authRoutes,
  ...dashboardRoutes,
  ...userRoutes,
  ...evidenceRoutes,
  ...settingRoutes,
  ...SatkerRoutes,
  ...File,
  ...Tahanan,
  ...Arsip,
  ...Search,
  ...SharedFile,
  ...tahtiRoutes,
  ...Pengaturan,
  ...Laporan,
  ...LokasiBarbuk,
  ...MasterBarbuk,
  ...Notification,
  ...JenisTindakPidana,
  ...Perkara,
  ...Rutan,
  /**
   * Page error handler
   */
  {
    path: "*",
    element: <NotFound />,
  },
  {
    element: <Layout />,
    children: [
      {
        path: "/debug",
        element: <Middleware.Authenticated group={null} page={null}  render={<Debug />} />,
      },
    ],
  },
]);

function App() {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "PrintScreen") {
        alert("Screenshot is disabled!");
        e.preventDefault();
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === "PrintScreen") {
        navigator.clipboard.writeText("");
        alert("Screenshot is disabled!");
      }
    };
    let hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }

    // function handleVisibilityChange() {
    //     if (document[hidden]) {
    //         alert('Screenshot is disabled!');
    //     }
    // }

    // document.addEventListener(visibilityChange, handleVisibilityChange, false);
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={routers} />
    </QueryClientProvider>
  );
}

export default App;
