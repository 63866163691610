import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Card, Row, Col, Button, Spinner, Form, Alert } from "react-bootstrap";
import { Input, Select } from "../../../components/Components";
import State from "../../../libraries/State";
import { getProfile } from "../../../utils/apis";
import {
  useSatkerTahtiList,
  useUpdatePasswordProfile,
  usePutUsers,
} from "../../../utils/apis/query";
import { pangkat, roles } from "../../../utils";
import { useNavigate } from "react-router-dom";

function ProfileUser() {
  const navigate = useNavigate();
  const [idUser, setIdUser] = useState("");
  const state = State.getState();
  const queryClient = useQueryClient();
  const [profile, setProfile] = useState({
    username: "",
    role: "",
    nama_lengkap: "",
    nrp: "",
    jabatan: "",
    handphone: "",
    email: "",
    pangkat: "",
    nama_satker_tahti: "",
    tipe_akun: "",
    is_active: "",
  });
  const [password, setPassword] = useState({
    old_password: "",
    new_password: "",
    confirm_new_password: "",
  });
  const {
    data: dataProfile,
    isLoading: isProfileLoading,
    error: profileError,
  } = useQuery(["profile/get"], getProfile);
  // Mutations

  const { data: dataSatkersAll } = useSatkerTahtiList();
  dataSatkersAll.map((e) => {
    e.value = e.nama_satker;
    e.text = e.nama_satker;
    return e;
  });

  const onSuccess = (response) => {
    setTimeout(() => {
      navigate("/profile");
    }, 2500);
  };

  const mutateProfile = usePutUsers(onSuccess);
  const mutateSubmit = useUpdatePasswordProfile(onSuccess);

  const changePassword = () => {
    const payload = password;
    mutateSubmit.mutate(payload, {
      onSuccess: () => {
        setPassword({
          old_password: "",
          new_password: "",
          confirm_new_password: "",
        });

        state.flush();
      },
    });
  };
  const submitProfile = () => {
    const payloadForm = profile;
    const id = idUser;
    mutateProfile.mutate({ id, payloadForm });
    state.flush();
  };

  useEffect(() => {
    if (dataProfile) {
      const data = {
        username: dataProfile.user.username,
        role: dataProfile.role.role,
        nama_lengkap: dataProfile.profile.nama_lengkap,
        nrp: dataProfile.profile.nrp,
        jabatan: dataProfile.profile.jabatan,
        handphone: dataProfile.profile.no_handphone,
        email: dataProfile.profile.email,
        pangkat: dataProfile.profile.pangkat,
        nama_satker: dataProfile.profile.nama_satker || "",
        nama_satker_tahti: dataProfile.profile.nama_satker_tahti,
        // tipe_satker: dataProfile?.satker?.tipe_satker ?? "",
        tipe_akun: dataProfile.profile?.tipe_akun,
        is_active: dataProfile.user.is_active,
        // awal_masa_aktif: dataProfile.profile.awal_masa_aktif,
        // akhir_masa_aktif: dataProfile.profile.akhir_masa_aktif,
      };

      setProfile((prev) => ({ ...prev, ...data }));
      setIdUser(dataProfile.profile.id_user);

      for (const [key, val] of Object.entries(data)) {
        state.setItem(key, val);
      }
    }
  }, [dataProfile]);
  return (
    <React.Fragment>
      <Row>
        {/* <Col sm="12" md="3">
          <Card className="card">
            <Card.Body className="bg-white card-header-profile">
              <div className="avatar-container">
                <div className="avatar">
                  <i className="ri-user-line"></i>
                  <button>
                    <i className="ri-edit-2-line"></i>
                  </button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col> */}
        <Col sm="12">
          <Row className="gap-3">
            <Col sm="12" md="12">
              <Card className="card">
                <Card.Header className="bg-white d-flex gap-2 align-items-center">
                  <i className="ri-information-line fs-15"></i>
                  <Card.Text as="label" className="fs-15 fw-medium">
                    Basic Information
                  </Card.Text>
                </Card.Header>
                <Card.Body className="bg-white py-2">
                  <Col sm="12">
                    {mutateProfile.isError && (
                      <Alert variant="danger">
                        <ul className="m-0">
                          {mutateProfile?.error?.response?.data?.detail?.map(
                            (errEdit, idx) => (
                              <li key={idx}>
                                <b>{errEdit.loc[1]} : </b>
                                {errEdit.msg}
                              </li>
                            )
                          ) ?? (
                            <li>
                              <b>Error : </b>
                              {mutateProfile?.error?.response?.data?.message ??
                                "Bermasalah"}
                            </li>
                          )}
                        </ul>
                      </Alert>
                    )}
                    {mutateProfile.isSuccess && (
                      <Alert variant="success">
                        {mutateProfile.data.data.message}
                      </Alert>
                    )}
                  </Col>
                  {isProfileLoading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ) : !profileError ? (
                    <Row>
                      <Col sm="12" md="6">
                        <Input
                          type="text"
                          name="username"
                          label="Username"
                          placeholder="Username"
                          defaultValue={profile.username}
                          onChange={(e) =>
                            setProfile({
                              ...profile,
                              username: e?.target?.value,
                            })
                          }
                        />
                      </Col>
                      <Col sm="12" md="6">
                        <Input
                          type="text"
                          name="nama_lengkap"
                          defaultValue={profile.nama_lengkap}
                          label="Fullname"
                          placeholder="Fullname"
                          onChange={(e) =>
                            setProfile({
                              ...profile,
                              nama_lengkap: e?.target?.value,
                            })
                          }
                        />
                      </Col>
                      <Col sm="12" md="6">
                        <Input
                          type="number"
                          name="nrp"
                          label="NRP"
                          defaultValue={profile.nrp}
                          placeholder="NRP"
                          onChange={(e) =>
                            setProfile({
                              ...profile,
                              nrp: e?.target?.value,
                            })
                          }
                        />
                      </Col>
                      <Col sm="12" md="6">
                        <Input
                          type="number"
                          name="handphone"
                          label="Handphone"
                          defaultValue={profile.handphone}
                          placeholder="Handphone"
                          onChange={(e) =>
                            setProfile({
                              ...profile,
                              handphone: e?.target?.value,
                            })
                          }
                        />
                      </Col>
                      <Col sm="12" md="6">
                        <Input
                          type="text"
                          name="email"
                          label="Email"
                          defaultValue={profile.email}
                          placeholder="Email"
                          onChange={(e) =>
                            setProfile({
                              ...profile,
                              email: e?.target?.value,
                            })
                          }
                        />
                      </Col>
                      <Col sm="12" md="6">
                        <Input
                          disabled
                          label="Role"
                          name="role"
                          value={profile.role}
                        />
                      </Col>
                      <Col sm="12" md="6">
                        <Select
                          disabled
                          label="Nama Satker"
                          default="Pilih Satker"
                          name="nama_satker_tahti"
                          value={profile.nama_satker_tahti}
                          options={dataSatkersAll}
                        />
                      </Col>
                      <Col sm="12" md="6">
                        <Select
                          label="Pangkat"
                          name="pangkat"
                          value={profile.pangkat}
                          default="Pilih Pangkat"
                          options={pangkat}
                          onChange={(e) =>
                            setProfile({
                              ...profile,
                              pangkat: e?.target?.value,
                            })
                          }
                        />
                      </Col>
                      <Col sm="12" md="6">
                        <Input
                          type="text"
                          name="jabatan"
                          label="Jabatan"
                          defaultValue={profile.jabatan}
                          placeholder="Jabatan"
                          onChange={(e) =>
                            setProfile({
                              ...profile,
                              jabatan: e?.target?.value,
                            })
                          }
                        />
                      </Col>
                      {/* <Col sm="12" md="6">
                        <Select
                          required
                          label="Status"
                          default="Pilih Status"
                          name="is_active"
                          value={profile.is_active}
                          options={[
                            {
                              value: true,
                              text: "Aktif",
                            },
                            {
                              value: false,
                              text: "Tidak Aktif",
                            },
                          ]}
                          onChange={(e) =>
                            setProfile({
                              ...profile,
                              is_active: e?.target?.value,
                            })
                          }
                        />
                      </Col>
                      <Col sm="12" md="6">
                        <Select
                          required
                          label="Role Akses"
                          default="Pilih Role"
                          name="role"
                          value={profile.role}
                          options={roles}
                          onChange={(e) =>
                            setProfile({
                              ...profile,
                              role: e?.target?.value,
                            })
                          }
                        />
                      </Col> */}
                      {/* 
                        <Col sm="12" md="12">
                          <Input
                            type="text"
                            name="deskripsi"
                            label="Deskripsi"
                            defaultValue={profile.deskripsi}
                            placeholder="Deskripsi"
                          />
                        </Col> */}
                    </Row>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center">
                      <Card.Text className="fw-bold fs-5">
                        There something error
                      </Card.Text>
                    </div>
                  )}
                </Card.Body>
                <Card.Footer className="bg-white">
                  <div className="d-flex justify-content-end align-items-center">
                    {mutateProfile.isLoading ? (
                      <Button
                        type="submit"
                        className="btn btn-success"
                        disabled
                      >
                        <Spinner animation="grow" size="sm" variant="light" />
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        className="btn btn-primary"
                        onClick={submitProfile}
                      >
                        Update Profile
                      </Button>
                    )}
                  </div>
                </Card.Footer>
              </Card>
            </Col>
            <Col sm="12" md="12">
              <Card className="card">
                <Card.Header className="bg-white d-flex gap-2 align-items-center">
                  <i className="ri-lock-line fs-15"></i>
                  <Card.Text as="label" className="fs-15 fw-medium">
                    Change Password
                  </Card.Text>
                </Card.Header>
                <Card.Body className="bg-white py-2">
                  <Col sm="12">
                    {mutateSubmit.isError && (
                      <Alert variant="danger">
                        <ul className="m-0">
                          {mutateSubmit?.error?.response?.data?.detail?.map(
                            (errEdit, idx) => (
                              <li key={idx}>
                                <b>{errEdit.loc[1]} : </b>
                                {errEdit.msg}
                              </li>
                            )
                          ) ?? (
                            <li>
                              <b>Error : </b>
                              {mutateSubmit?.error?.response?.data?.message ??
                                "Bermasalah"}
                            </li>
                          )}
                        </ul>
                      </Alert>
                    )}
                    {mutateSubmit.isSuccess && (
                      <Alert variant="success">
                        {mutateSubmit.data.data.message}
                      </Alert>
                    )}
                  </Col>
                  <Row>
                    <Col sm="12">
                      <Input
                        type="password"
                        name="old_password"
                        label="Old Password"
                        placeholder="Old Password"
                        value={password.old_password}
                        onChange={(e) =>
                          setPassword({
                            ...password,
                            old_password: e?.target?.value,
                          })
                        }
                      />
                    </Col>
                    <Col sm="12" md="6">
                      <Input
                        type="password"
                        name="new_password"
                        label="New Password"
                        placeholder="New Password"
                        value={password.new_password}
                        onChange={(e) =>
                          setPassword({
                            ...password,
                            new_password: e?.target?.value,
                          })
                        }
                      />
                    </Col>
                    <Col sm="12" md="6">
                      <Input
                        type="password"
                        name="confirm_new_password"
                        label="Confirm New Password"
                        placeholder="Confirm New Password"
                        value={password.confirm_new_password}
                        onChange={(e) =>
                          setPassword({
                            ...password,
                            confirm_new_password: e?.target?.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="bg-white">
                  <div className="d-flex justify-content-end align-items-center">
                    {mutateSubmit.isLoading ? (
                      <Button
                        type="submit"
                        className="btn btn-success"
                        disabled
                      >
                        <Spinner animation="grow" size="sm" variant="light" />
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        className="btn btn-primary"
                        onClick={changePassword}
                      >
                        Change Password
                      </Button>
                    )}
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default ProfileUser;
