import React, { useCallback, useEffect, useState } from "react";
import { Badge, Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Input,
  MyPagination,
  Select,
  Select2,
} from "../../../components/Components";
import { debounce, isBoolean } from "lodash";
import {
  useDeletePengeluaran,
  useDeleteUser,
  useGetUserList,
  useSatkerTahtiList,
  useGetPoldaPolres,
} from "../../../utils/apis/query";
import Alert from "../../../libraries/Alert";
import { roles } from "../../../utils/dummy/variable";
import State from "../../../libraries/State";
import moment from "moment";

function List() {
  const state = State.getState();
  const profileData = state.getItem("profileData", true);
  const satker = profileData?.satker?.nama_satker ?? "";
  const isBareskrim = /bareskrim/gi.test(satker);
  const isPolres = /polres/gi.test(satker);
  const [namaSatkerPoldaFilter, setNamaSatkerPoldaFilter] = useState("");
  const [namaSatkerPolresFilter, setNamaSatkerPolresFilter] = useState("");
  const [namaSatkerPoldaPayloadFilter, setNamaSatkerPoldaPayloadFilter] = useState("");
  const [namaSatkerPolresPayloadFilter, setNamaSatkerPolresPayloadFilter] = useState("");
  const [isActive, setIsActive] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [role, setRole] = useState("");
  const [search, setSearch] = useState("");
  const [fetchedOnce, setFetchedOnce] = useState(true);

  const { data: dataSatker } = useGetPoldaPolres(
    {
      polda: namaSatkerPoldaFilter,
      polres:
        namaSatkerPolresFilter === "satker_sendiri"
          ? ""
          : namaSatkerPolresFilter,
    },
    {
      enabled: !!namaSatkerPoldaPayloadFilter || !!namaSatkerPolresFilter,
    }
  );

  const optionPolda = [
    { label: "SEMUA POLDA", value: "semua_polda" },
    ...dataSatker.polda.map((o) => ({ label: o.value, value: o.value })),
  ];
  const optionPolres = [
    { label: "SEMUA", value: "semua" },
    { label: "SATKER SENDIRI", value: "satker_sendiri" },
    ...dataSatker.polres.map((o) => ({ label: o.value, value: o.value })),
  ];

  const {
    data: dataList,
    pagination: dataListPagination,
    isFetching: isDataListFetching,
    isError: isDataListError,
    error: dataListError,
    refetch: refetchDataList,
  } = useGetUserList(
    {
      nama_satker_polda: namaSatkerPoldaPayloadFilter,
      nama_satker_polres: namaSatkerPolresPayloadFilter,
      username: search,
      is_active: isActive,
      role: role,
      page: page,
      size: size,
    },
    {
      enabled: (!!namaSatkerPoldaPayloadFilter || !!namaSatkerPolresPayloadFilter) && fetchedOnce
    }
  );

  const mutateDeleteList = useDeleteUser();

  useEffect(() => {
    if (profileData) {
      moment.locale();
      const satker = profileData?.satker?.nama_satker ?? "";
      const username = profileData?.user?.username ?? "";
      const isPolres = /polres/gi.test(satker);
      const isBareskrim = /bareskrim/gi.test(satker);
      if (isBareskrim) {
        if (['kabareskrim', 'kapusiknas'].includes(username)) {
          setNamaSatkerPoldaFilter('semua_polda');
          setNamaSatkerPoldaPayloadFilter('semua_polda');
        } else {
          setNamaSatkerPoldaFilter(satker);
          setNamaSatkerPoldaPayloadFilter(satker);
        }
        setNamaSatkerPolresFilter("satker_sendiri");

      } else {
        if (isPolres) {
          let namaPolda = profileData?.profile?.nama_satker_parent_polres ?? "";
          setNamaSatkerPoldaFilter(namaPolda);
          setNamaSatkerPoldaPayloadFilter(namaPolda);
        } else {
          setNamaSatkerPoldaFilter(satker);
          setNamaSatkerPoldaPayloadFilter(satker);
          setNamaSatkerPolresFilter("satker_sendiri");
        }
      }
    }
  }, [profileData]);

  useEffect(() => {
    if (profileData) {
      const satker = profileData?.satker?.nama_satker ?? "";
      const isPolres = /polres/gi.test(satker);
      const isBareskrim = /bareskrim/gi.test(satker);
      const isfilterPoldaAll = namaSatkerPoldaFilter === 'semua_polda'

      if (isBareskrim || isfilterPoldaAll) {
        const filterBareskrim = /bareskrim/gi.test(namaSatkerPoldaFilter);
        if (!namaSatkerPolresFilter) setNamaSatkerPolresPayloadFilter('satker_sendiri')
        if (isfilterPoldaAll && namaSatkerPolresPayloadFilter === 'satker_sendiri') {
          setNamaSatkerPoldaPayloadFilter(dataSatker.polda.map((o) => o.value).join(","))
        }
        if (isfilterPoldaAll && namaSatkerPolresPayloadFilter === 'semua') {
          setNamaSatkerPoldaPayloadFilter('semua_polda')
        } 
        if (namaSatkerPolresFilter === 'semua') {
          let payload = filterBareskrim  ? '' : dataSatker.polres.map((o) => o.value).join(",");
          setNamaSatkerPolresPayloadFilter(payload);
        }
        
      } else {
        if (isPolres) {
          let namaPolres = profileData?.profile?.nama_satker_tahti ?? "";
          setNamaSatkerPolresFilter(namaPolres);
          setNamaSatkerPolresPayloadFilter(namaPolres);
        } else {
          if (!namaSatkerPolresFilter) setNamaSatkerPolresPayloadFilter('satker_sendiri')
          if (namaSatkerPolresFilter === 'semua') {
            setNamaSatkerPolresPayloadFilter(
              dataSatker.polres.map((o) => o.value).join(",")
            )
          }
        }
      }
    }
  }, [profileData, dataSatker, namaSatkerPolresFilter, namaSatkerPoldaFilter])

  useEffect(() => {
    if (isDataListFetching) {
      
      
      if (fetchedOnce) setFetchedOnce(false);
    }
  }, [fetchedOnce, isDataListFetching]);

  useEffect(() => {
    refetchDataList();
  }, [page]);
  // useEffect(() => {
  //   if (namaSatkerPolresPayloadFilter && fetchedOnce) {
  //     refetchDataList();
  //   }
  // }, [namaSatkerPolresPayloadFilter, refetchDataList]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (namaSatkerPoldaFilter !== null || !!namaSatkerPoldaFilter) {
  //       refetchDataList();
  //       setFetchedOnce(false);
  //     }
  //   }, 2000);
  // }, []);
  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item">
              <Link to="/pengaturan/pengguna/list">Pengaturan</Link>
            </li>
            <li className="breadcrumb-item active fw-bold" aria-current="page">
              List Akun Pengguna
            </li>
          </ol>
        </div>
      </div>
      <Row>
        <Col xl="12">
          <Card className="card bg-white">
            <Card.Body>
              <Row className="g-1 mb-2">
                <Col sm="12" md="6">
                  <Input
                    type="text"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </Col>
                <Col
                  sm="12"
                  md="6"
                  className="d-flex justify-content-end align-items-center gap-2"
                >
                  <Button
                    variant="danger"
                    onClick={() => {
                      refetchDataList()
                    }}
                    className="btn"
                  >
                    Filter
                  </Button>
                  <Button
                    as={Link}
                    to="/pengaturan/pengguna/form"
                    className="btn btn-primary"
                  >
                    TAMBAH
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="12">
                  <Row className="g-2 d-flex justify-content-between align-content-center flex-wrap">
                    <Col>
                    <Select2
                      defaultValue={"Semua Kesatuan"}
                      options={optionPolda}
                      value={namaSatkerPoldaFilter}
                      name="nama_satker_polda"
                      placeholder="Semua Kesatuan"
                      required
                      isClearable
                      isSearchable
                      disabled={!isBareskrim || isPolres}
                      onChange={(e) => {
                        setNamaSatkerPoldaFilter(e.value)
                        setNamaSatkerPoldaPayloadFilter(e.value)
                        setNamaSatkerPolresFilter((prev) => ('satker_sendiri'))
                        setNamaSatkerPolresPayloadFilter((prev) => ('satker_sendiri'))
                      }}
                    />
                    </Col>
                    <Col>
                    <Select2
                      defaultValue={"Semua Kesatuan"}
                      options={optionPolres}
                      value={namaSatkerPolresFilter}
                      name="nama_satker_polres"
                      placeholder="SEMUA"
                      disabled={isPolres}
                      required
                      isClearable
                      isSearchable
                      onChange={(e) => {
                        setNamaSatkerPolresFilter(e.value);
                        setNamaSatkerPolresPayloadFilter(e.value);
                      }}
                    />
                    </Col>
                    <Col>
                      <Select
                        required
                        default="Status"
                        options={[
                          { text: "Aktif", value: true },
                          { text: "Tidak Aktif", value: false },
                        ]}
                        value={isActive}
                        onChange={(e) => {
                          setIsActive(e.target.value);
                        }}
                      />
                    </Col>
                    <Col>
                      <Select
                        required
                        default="Role"
                        // options={roles}
                        options={[
                          {
                            value: "Super Admin",
                            text: "Super Admin",
                            isDisabled:
                              !["Super Admin"].includes(profileData?.role?.role ?? ''),
                          },
                          {
                            value: "Admin TAHTI",
                            text: "Admin TAHTI",
                            isDisabled:
                              !["Super Admin"].includes(profileData?.role?.role ?? ''),
                          },
                          {
                            value: "Eksekutif POLRI",
                            text: "Eksekutif POLRI",
                            isDisabled:
                              !["Admin TAHTI", "Super Admin"].includes(profileData?.role?.role ?? ''),
                          },
                          {
                            value: "Eksternal",
                            text: "Eksternal",
                            isDisabled:
                              !["Super Admin"].includes(profileData?.role?.role ?? ''),
                          },
                          {
                            value: "Petugas Rutan TAHTI",
                            text: "Petugas Rutan TAHTI",
                            isDisabled:
                              !["Admin TAHTI"].includes(profileData?.role?.role ?? ''),
                          },
                          {
                            value: "Petugas BB TAHTI",
                            text: "Petugas BB TAHTI",
                            isDisabled:
                              !["Admin TAHTI"].includes(profileData?.role?.role ?? ''),
                          },
                        ]}
                        value={role}
                        onChange={(e) => {
                          setRole(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-5">
                <div className="d-flex justify-content-end align-items-center flex-row gap-1">
                  <label className="text-muted">Items per page</label>
                  <Select
                    name="size"
                    options={[
                      { text: "10", value: 10 },
                      { text: "20", value: 20 },
                      { text: "50", value: 50 },
                      { text: "100", value: 100 },
                    ]}
                    value={size}
                    onChange={(e) => setSize(e.target.value)}
                    style={{
                      padding: "2px 4px",
                      fontSize: "0.875rem",
                      width: "60px",
                    }}
                  />
                  <div className="text-muted d-flex justify-content-end">
                    {`${(page - 1) * size + 1}–${Math.min(
                      page * size,
                      dataListPagination.total_items
                    )} of ${dataListPagination.total_items}`}
                  </div>
                </div>
              </Row>
              <div className="table-responsive">
                <Table className="table dems-table">
                  <thead>
                    <tr>
                      <th>Aksi</th>
                      <th>No</th>
                      <th>Username</th>
                      <th>Nama Lengkap</th>
                      <th>NRP/IP</th>
                      <th>Pangkat</th>
                      <th>Satker</th>
                      <th>Role</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isDataListFetching && (
                      <tr>
                        <td colSpan="9" style={{ textAlign: "center" }}>
                          <Spinner animation="border" variant="primary" />
                        </td>
                      </tr>
                    )}
                    {isDataListError &&
                      (dataListError?.response?.data?.detail?.map(
                        (err, idx) => (
                          <tr key={idx}>
                            <td
                              colSpan="9"
                              style={{ textAlign: "center" }}
                              className="text-danger"
                            >
                              <span>
                                <b>{err.loc[1]} </b>
                                {err.msg}
                              </span>
                            </td>
                          </tr>
                        )
                      ) ?? (
                        <tr>
                          <td
                            colSpan="9"
                            style={{ textAlign: "center" }}
                            className="text-danger"
                          >
                            <span>
                              <b>Opps Error </b>Reload page or please wait until
                              we fix this issue
                            </span>
                          </td>
                        </tr>
                      ))}
                    {dataList.length && !isDataListFetching && !isDataListError
                      ? dataList.map((data, index) => (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center justify-content-start gap-2">
                                <Link
                                  to={
                                    "/pengaturan/pengguna/edit/" +
                                    data?.user?.id
                                  }
                                >
                                  <Badge className="btn bg-info" as="button">
                                    <i className="ri-eye-line"></i>
                                  </Badge>
                                </Link>
                                <Badge
                                  className="btn bg-danger"
                                  as="button"
                                  onClick={() => {
                                    Alert.showConfirm("warning", "Hapus").then(
                                      ({ isConfirmed }) => {
                                        if (isConfirmed) {
                                          mutateDeleteList.mutate(
                                            data?.user?.id
                                          );
                                        }
                                      }
                                    );
                                  }}
                                >
                                  <i className="ri-delete-bin-line"></i>
                                </Badge>
                              </div>
                            </td>
                            <td>
                              {(page - 1) * size + index + 1}
                            </td>
                            <td>{data?.user?.username ?? "-"}</td>
                            <td>{data?.profile?.nama_lengkap ?? "-"}</td>
                            <td>{data?.profile?.nrp ?? "-"}</td>
                            <td>{data?.profile?.pangkat ?? "-"}</td>
                            <td>{data?.profile?.nama_satker_tahti ?? "-"}</td>
                            <td>{data?.role?.role ?? "-"}</td>
                            <td>
                              <Badge
                                className={`
                                    ${
                                      (data?.user?.is_active ?? false) ===
                                        false && "bg-danger"
                                    }
                                    ${
                                      (data?.user?.is_active ?? false) ===
                                        true && "bg-success"
                                    }
                                `}
                              >
                                {data?.user?.is_active
                                  ? "Aktif"
                                  : "Tidak Aktif"}
                              </Badge>
                            </td>
                          </tr>
                        ))
                      : !isDataListFetching &&
                        !dataList.length &&
                        !isDataListError && (
                          <tr>
                            <td colSpan="10" style={{ textAlign: "center" }}>
                              <span>Tidak Ada Data !</span>
                            </td>
                          </tr>
                        )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
            <Card.Footer className="bg-white">
              <MyPagination
                currentPage={page}
                totalPages={dataListPagination.total_pages}
                onPageChange={(page) => setPage(page)}
              />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default List;
