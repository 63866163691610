/* eslint-disable no-unused-vars */
import { get, post, put, del, axios } from "../../libraries/Axios";
import State from "../../libraries/State";
const state = State.getState();

export const getMasterMeasure = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query).filter(([_, v]) => v !== "")
  );
  const res = await get("ukuran", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const postMasterMeasure = async (payload) => {
  const res = await get("ukuran", payload);
  return res;
};
export const putMasterMeasure = async ({ id, formData }) => {
  const res = await put("ukuran/" + id, formData);
  return res;
};
export const delMasterMeasure = async (id) => {
  const res = await get("ukuran/" + id);
  return res;
};
// master endpoint
export const getUsers = async ({ queryKey }) => {
  const [_, payload] = queryKey;
  const res = await get("users", { params: { ...payload } });
  return res;
};
export const getSatkerList = async () => {
  const res = await get("satkers/list");
  return res;
};
export const getRutanList = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("rutan/list", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
// export const getRutanList = async () => {
//   const res = await get("rutan/list");
//   return res;
// };

export const getPenyidikList = async () => {
  const res = await get("penyidik/list");
  return res;
};
//Auth Endpoint
export const authLogin = async (payload) => {
  const res = await post("users/login", payload);
  return res;
};
// Users Enpoint
export const getProfile = async () => {
  const res = await post("users/profile");
  return res.data.value;
};
export const updateProfile = async ({ payload }) => {
  return await put("users/", { ...payload });
};
export const updateProfilePassword = async (payload) => {
  const res = await post("users/update-password", payload);
  return res;
};
// Berkas Endpoint
export const getBerkas = async ({ queryKey }) => {
  const [_, params] = queryKey;
  const res = await get("berkas", { params: params });
  return res;
};
export const getExtractedFile = async ({ queryKey }) => {
  const [_, query] = queryKey;
  const res = await get("berkas/extracted/file", { params: query });
  return res;
};
export const getExtrackedFileAll = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query).filter(([_, v]) => v !== "")
  );
  const res = await get("berkas/extracted/file/all", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};

export const getExtrackedFile = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query).filter(([_, v]) => v !== "")
  );
  const res = await get("berkas/search/extracted/file", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
// Menus endpoint
export const getMenuDetail = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const res = await get("menus/" + id);
  return res;
};
export const getMenuList = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("menus", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const putMenu = async ({ id, payload }) => {
  const res = await put("menus/" + id, payload);
  return res;
};
export const postMenu = async (payload) => {
  const res = await post("menus", payload);
  return res;
};
export const deleteMenu = async (payload) => {
  const res = await del("menus/" + payload, payload);
  return res;
};
export const getMenuParentList = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("menus/parent-list", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const getMenuRoleAction = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query.payload || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("menu-role-action/" + query.id, {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const postMenuRoleAction = async ({ id, payload }) => {
  const res = await post("menu-role-action/" + id, payload);
  return res;
};

// TAHANAN TITIPAN ENDPOINT
export const getTahtiList = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  if (spring.nama_satker_polda === "semua_polda") spring.nama_satker_polda = "";
  if (!spring.nama_satker_polda.includes("BARESKRIM")) {
    if (spring.nama_satker_polres === "satker_sendiri") {
      spring.nama_satker_polres = "";
    }
  }
  const res = await get("tahanan", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const getRegisterList = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  if (spring.nama_satker_polda === "semua_polda") spring.nama_satker_polda = "";
  if (!spring.nama_satker_polda.includes("BARESKRIM")) {
    if (spring.nama_satker_polres === "satker_sendiri") {
      spring.nama_satker_polres = "";
    }
  }
  const res = await get("tahanan/register-list", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const getTahtiBonList = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("tahanan/search", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const postTahti = async (payload) => {
  const res = await post("tahanan", payload);
  return res;
};
export const postTahtiLapas = async (payload) => {
  const res = await post("tahanan/lapas", payload);
  return res;
};
export const putTahti = async ({ id, tahtiFormData }) => {
  const res = await put("tahanan/" + id, tahtiFormData);
  return res;
};
export const putTahtiLapas = async ({ id, tahtiFormData }) => {
  const res = await put("tahanan/lapas/" + id, tahtiFormData);
  return res;
};
export const getDetailTahti = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const res = await get("tahanan/" + id);
  return res;
};
export const getDetailTahtiLapas = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const res = await get("tahanan/lapas/" + id);
  return res;
};
export const getDetailTahananBarbuk = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const res = await get("tahanan/barang-bukti/" + id);
  return res;
};
export const deleteTahti = async (payload) => {
  const res = await del("tahanan/" + payload, payload);
  return res;
};

// BON TAHANAN
export const getBonTahanan = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  if (spring.nama_satker_polda === "semua_polda") spring.nama_satker_polda = "";
  if (!spring.nama_satker_polda.includes("BARESKRIM")) {
    if (spring.nama_satker_polres === "satker_sendiri") {
      spring.nama_satker_polres = "";
    }
  }
  const res = await get("bon-tahanan", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const postBonTahanan = async (payload) => {
  const res = await post("bon-tahanan", payload);
  return res;
};
export const putBonTahanan = async ({ id, formData }) => {
  const res = await put("bon-tahanan/" + id, formData);
  return res;
};
export const getDetailBonTahanan = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const res = await get("bon-tahanan/" + id);
  return res;
};
export const deleteBonTahanan = async (payload) => {
  const res = await del("bon-tahanan/" + payload, payload);
  return res;
};

// PENANGGUHAN ENDPOINT
export const postPenangguhan = async (payload) => {
  const res = await post("penangguhan", payload);
  return res;
};

// PEMBANTARAN
export const getPembantaran = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("pembantaran", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const postPembantaran = async (payload) => {
  const res = await post("pembantaran", payload);
  return res;
};
export const putPembantaran = async ({ id, formData }) => {
  const res = await put("pembantaran/" + id, formData);
  return res;
};
export const getDetailPembantaran = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const res = await get("pembantaran/" + id);
  return res;
};
export const deletePembantaran = async (payload) => {
  const res = await del("pembantaran/" + payload, payload);
  return res;
};

// PERPANJANGAN ENDPOINT
export const getPerpanjangan = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  if (spring.nama_satker_polda === "semua_polda") spring.nama_satker_polda = "";
  if (!spring.nama_satker_polda.includes("BARESKRIM")) {
    if (spring.nama_satker_polres === "satker_sendiri") {
      spring.nama_satker_polres = "";
    }
  }
  const res = await get("perpanjangan", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const getPerpanjanganDetail = async ({ queryKey }) => {
  const [_, query] = queryKey;
  const res = await get("perpanjangan/" + query);
  return res;
};
export const postPerpanjangan = async (payload) => {
  const res = await post("perpanjangan", payload);
  return res;
};
export const putPerpanjangan = async ({ payload, id }) => {
  const res = await put("perpanjangan/" + id, payload);
  return res;
};
export const delPerpanjangan = async (payload) => {
  const res = await del("perpanjangan/" + payload);
  return res;
};

// PENGELUARAN ENDPOINT
export const getPengeluaran = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  if (spring.nama_satker_polda === "semua_polda") spring.nama_satker_polda = "";
  if (!spring.nama_satker_polda.includes("BARESKRIM")) {
    if (spring.nama_satker_polres === "satker_sendiri") {
      spring.nama_satker_polres = "";
    }
  }
  const res = await get("pengeluaran", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const getPengeluaranDetail = async ({ queryKey }) => {
  const [_, query] = queryKey;
  const res = await get("pengeluaran/" + query);
  return res;
};
export const postPengeluaran = async (payload) => {
  const res = await post("pengeluaran", payload);
  return res;
};
export const putPengeluaran = async ({ payload, id }) => {
  const res = await put("pengeluaran/" + id, payload);
  return res;
};
export const delPengeluaran = async (payload) => {
  const res = await del("pengeluaran/" + payload);
  return res;
};

// PENGELUARAN BARBUK
export const getPenyerahan = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  if (spring.nama_satker_polda === "semua_polda") spring.nama_satker_polda = "";
  if (!spring.nama_satker_polda.includes("BARESKRIM")) {
    if (spring.nama_satker_polres === "satker_sendiri") {
      spring.nama_satker_polres = "";
    }
  }
  const res = await get("penyerahan/barang-bukti/pengeluaran", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const getPemusnahan = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  if (spring.nama_satker_polda === "semua_polda") spring.nama_satker_polda = "";
  if (!spring.nama_satker_polda.includes("BARESKRIM")) {
    if (spring.nama_satker_polres === "satker_sendiri") {
      spring.nama_satker_polres = "";
    }
  }
  const res = await get("penyerahan/barang-bukti/pemusnahan", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const getPengembalian = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  if (spring.nama_satker_polda === "semua_polda") spring.nama_satker_polda = "";
  if (!spring.nama_satker_polda.includes("BARESKRIM")) {
    if (spring.nama_satker_polres === "satker_sendiri") {
      spring.nama_satker_polres = "";
    }
  }
  const res = await get("penyerahan/barang-bukti/pengembalian", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const getPenyerahanDetail = async ({ queryKey }) => {
  const [_, query] = queryKey;
  const res = await get("penyerahan/barang-bukti/" + query);
  return res;
};
export const postPenyerahan = async (payload) => {
  const res = await post("penyerahan/barang-bukti", payload);
  return res;
};
export const putPenyerahan = async ({ id, formData }) => {
  const res = await put("penyerahan/barang-bukti/" + id, formData);
  return res;
};
export const delPenyerahan = async (payload) => {
  const res = await del("penyerahan/barang-bukti/" + payload, payload);
  return res;
};
export const getTahtiPenyerahanList = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("penyerahan/barang-bukti/tahanan", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};

// PENYIDIK
export const getPenyidik = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("penyidik", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const postPenyidik = async (payload) => {
  const res = await post("penyidik", payload);
  return res;
};
export const deletePenyidik = async (payload) => {
  const res = await del("penyidik/" + payload, payload);
  return res;
};

// Shared File
export const getSharedFileAll = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("berkas/shared-file", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const postSharedFile = async (payload) => {
  const res = await post("berkas/shared-file", payload);
  return res;
};
// Dummy JSON
export const jsonJpeg = async () => {
  const res = await axios.get("/bottle.json");
  return res;
};
export const jsonVideo = async () => {
  const res = await axios.get("/cctv.json");
  return res;
};
export const jsonDocs = async () => {
  const res = await axios.get("/doc1.json");
  return res;
};

// MASTER TIPE BARANG BUKTI ENDPOINT
export const getTypeDetail = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const res = await get("barang-bukti/tipe/" + id);
  return res;
};
export const getTypeList = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("barang-bukti/tipe", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const putType = async ({ id, tipe }) => {
  const res = await put("barang-bukti/tipe/" + id, tipe);
  return res;
};
export const postType = async (payload) => {
  const res = await post("barang-bukti/tipe", payload);
  return res;
};
export const deleteType = async (payload) => {
  const res = await del("barang-bukti/tipe/" + payload, payload);
  return res;
};

// MASTER UNIT BARANG BUKTI ENDPOINT
export const getUnitDetail = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const res = await get("barang-bukti/units/" + id);
  return res;
};
export const getUnitList = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("barang-bukti/units", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const putUnit = async ({ id, unit }) => {
  const res = await put("barang-bukti/units/" + id, unit);
  return res;
};
export const postUnit = async (payload) => {
  const res = await post("barang-bukti/units", payload);
  return res;
};
export const deleteUnit = async (payload) => {
  const res = await del("barang-bukti/units/" + payload, payload);
  return res;
};

// MASTER SATKERS TAHTI ENDPOINT
export const getSatkerDetail = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const res = await get("satker-tahti/" + id);
  return res;
};
export const getSatkersList = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("satker-tahti", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const getSatkersTahtiList = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("satker-tahti/list", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const getSatkersTypeList = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("satker-tahti/type/list", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const putSatker = async ({ id, satker }) => {
  const res = await put("satker-tahti/" + id, satker);
  return res;
};
export const postSatker = async (payload) => {
  const res = await post("satker-tahti", payload);
  return res;
};
export const deleteSatker = async (payload) => {
  const res = await del("satker-tahti/" + payload, payload);
  return res;
};
export const getPoldaList = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("satker-tahti/polda/list", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const getPoldaPolres = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  if (spring.polda === "semua_polda") delete spring.polda;
  if (spring.polres === "semua_polres") delete spring.polres;
  if (spring.polres === "semua") delete spring.polres;

  const res = await get("satker-tahti/polda-polres", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};

// MASTER USER ENDPOINT
export const getUserDetail = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const res = await get("users/" + id);
  return res;
};
export const getUserList = async ({ queryKey }) => {
  const [_, query] = queryKey;

  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );

  if (spring.nama_satker_polda === "semua_polda") {
    spring.nama_satker_polda = "";
  }

  if (spring.nama_satker_polda && !spring.nama_satker_polda.includes("BARESKRIM")) {
    if (spring.nama_satker_polres === "satker_sendiri") {
      spring.nama_satker_polres = "";
    }
  }

  const res = await get("users", {
    params: Object.keys(spring).length ? spring : undefined,
  });

  return res;
};
export const putUser = async ({ id, payloadForm }) => {
  const res = await put("users/" + id, payloadForm);
  return res;
};
export const postUser = async (payload) => {
  const res = await post("users/register", payload);
  return res;
};
export const deleteUser = async (payload) => {
  const res = await del("users/" + payload, payload);
  return res;
};
// PENERIMAAN BARBUK ENDPOINT
export const getPenerimaanBarbukList = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  if (spring.nama_satker_polda === "semua_polda") spring.nama_satker_polda = "";
  if (!spring.nama_satker_polda.includes("BARESKRIM")) {
    if (spring.nama_satker_polres === "satker_sendiri") {
      spring.nama_satker_polres = "";
    }
  }
  const response = await get("penerimaan/barang-bukti/", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return response;
};
export const postPenerimaanBarbuk = async (payload) => {
  const res = await post("penerimaan/barang-bukti", payload);
  return res;
};
export const putPenerimaanBarbuk = async ({ id, formData }) => {
  const res = await put("penerimaan/barang-bukti/" + id, formData);
  return res;
};
export const getDetailPenerimaanBarbuk = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const res = await get("penerimaan/barang-bukti/" + id);
  return res;
};
export const deletePenerimaanBarbuk = async (payload) => {
  const res = await del("penerimaan/barang-bukti/" + payload, payload);
  return res;
};
export const getBarbukTahananList = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  if (spring.nama_satker_polda === "semua_polda") spring.nama_satker_polda = "";
  if (!spring.nama_satker_polda.includes("BARESKRIM")) {
    if (spring.nama_satker_polres === "satker_sendiri") {
      spring.nama_satker_polres = "";
    }
  }
  const res = await get("penerimaan/barang-bukti/list", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const getBarbukByNoLP = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("penerimaan/barang-bukti/no-laporan", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const getDetailBarbukTahanan = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const res = await get("transaksi/barang-bukti/" + id);

  return res;
};
export const putTagsBarbukTahanan = async ({ idBarbuk, data }) => {
  const res = await put("transaksi/barang-bukti/foto/tag/" + idBarbuk, data);
  return res;
};
// MASTER BARANG BUKTI ENDPOINT
export const getMasterBarbukList = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("master/barang-bukti", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const getMasterBarbukListNew = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("master/barang-bukti/list", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const getMasterBarbukDetail = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const res = await get("master/barang-bukti/" + id);
  return res;
};
export const putMasterBarbuk = async ({ id, barbuk }) => {
  const res = await put("master/barang-bukti/" + id, barbuk);
  return res;
};
export const uploadMasterBarbuk = async (payload) => {
  const res = await post("master/barang-bukti/upload", payload);
  return res;
};
export const deleteMasterBarbuk = async (payload) => {
  const res = await del("master/barang-bukti/" + payload, payload);
  return res;
};
// MASTER ROLE ENDPOINT
export const getMasterRoleList = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("roles", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const getMasterRoleDetail = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const res = await get("roles/" + id);
  return res;
};
export const getSidebarMenuByRole = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const res = await get("roles/menu-list");
  return res;
};
export const postMasterRole = async (payload) => {
  const res = await post("roles", payload);
  return res;
};
export const putMasterRole = async ({ id, role }) => {
  const res = await put("roles/" + id, role);
  return res;
};
export const deleteMasterRole = async (payload) => {
  const res = await del("roles/" + payload, payload);
  return res;
};

// scope endpoint daerah
export const getMasterDaerah = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("daerah", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};

//DASRBOARD ENDPOINT
export const getDashboardBarbuk = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  if (spring.nama_satker_polda === "semua_polda") spring.nama_satker_polda = "";
  if (!spring.nama_satker_polda.includes("BARESKRIM")) {
    if (spring.nama_satker_polres === "satker_sendiri") {
      spring.nama_satker_polres = "";
    }
  }
  const res = await get("dashboard/barang-bukti", {
    params: Object.keys(spring).length ? spring : undefined,
  });

  return res;
};
export const getDashboardBarbukList = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  if (spring.nama_satker_polda === "semua_polda") spring.nama_satker_polda = "";
  if (!spring.nama_satker_polda.includes("BARESKRIM")) {
    if (spring.nama_satker_polres === "satker_sendiri") {
      spring.nama_satker_polres = "";
    }
  }
  const res = await get("dashboard/barang-bukti/list", {
    params: Object.keys(spring).length ? spring : undefined,
  });

  return res;
};
export const getDashboardBarbukMap = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  if (spring.nama_satker_polda === "semua_polda") spring.nama_satker_polda = "";
  if (!spring.nama_satker_polda.includes("BARESKRIM")) {
    if (spring.nama_satker_polres === "satker_sendiri") {
      spring.nama_satker_polres = "";
    }
  }
  const res = await get("dashboard/barang-bukti/maps", {
    params: Object.keys(spring).length ? spring : undefined,
  });

  return res;
};
export const getDashboardAdmindik = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  if (spring.nama_satker_polda === "semua_polda") spring.nama_satker_polda = "";
  if (!spring.nama_satker_polda.includes("BARESKRIM")) {
    if (spring.nama_satker_polres === "satker_sendiri") {
      spring.nama_satker_polres = "";
    }
  }
  const res = await get("dashboard/admindik", {
    params: Object.keys(spring).length ? spring : undefined,
  });

  return res;
};
export const getDashboardTahanan = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "yow")
  );

  if (spring.nama_satker_polda === "semua_polda") spring.nama_satker_polda = "";
  if (!spring.nama_satker_polda.includes("BARESKRIM")) {
    if (spring.nama_satker_polres === "satker_sendiri") {
      spring.nama_satker_polres = "";
    }
  }
  const res = await get("dashboard/tahanan/", {
    params: Object.keys(spring).length ? spring : undefined,
  });

  return res;
};
export const getDashboardTahananBulanan = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  if (spring.nama_satker_polda === "semua_polda") spring.nama_satker_polda = "";
  if (!spring.nama_satker_polda.includes("BARESKRIM")) {
    if (spring.nama_satker_polres === "satker_sendiri") {
      spring.nama_satker_polres = "";
    }
  }
  const res = await get("dashboard/tahanan/bulanan", {
    params: Object.keys(spring).length ? spring : undefined,
  });

  return res;
};
export const getDashboardTahananMap = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  if (spring.nama_satker_polda === "semua_polda") spring.nama_satker_polda = "";
  if (!spring.nama_satker_polda.includes("BARESKRIM")) {
    if (spring.nama_satker_polres === "satker_sendiri") {
      spring.nama_satker_polres = "";
    }
  }
  const res = await get("dashboard/tahanan/maps", {
    params: Object.keys(spring).length ? spring : undefined,
  });

  return res;
};

//LAPORAN ENDPOINT
export const getLaporanTahanan = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("laporan/rekap-tahanan", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const getLaporanBarbuk = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  if (spring.nama_satker_polda === "semua_polda") spring.nama_satker_polda = "";
  if (!spring.nama_satker_polda.includes("BARESKRIM")) {
    if (spring.nama_satker_polres === "satker_sendiri") {
      spring.nama_satker_polres = "";
    }
  }
  const res = await get("laporan/barang-bukti", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};

export const getTahananBelumPerpanjang = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  if (spring.nama_satker_polda === "semua_polda") spring.nama_satker_polda = "";
  if (!spring.nama_satker_polda.includes("BARESKRIM")) {
    if (spring.nama_satker_polres === "satker_sendiri") {
      spring.nama_satker_polres = "";
    }
  }

  const res = await get("tahanan/belum-perpanjang", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};

//RIWAYAT LOG ENDPOINT
export const getRiwayatLogDetail = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const res = await get("audit/trail/" + id);
  return res;
};
export const getRiwayatLogList = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  if (spring.nama_satker_polda === "semua_polda") spring.nama_satker_polda = "";
  if (!spring.nama_satker_polda.includes("BARESKRIM")) {
    if (spring.nama_satker_polres === "satker_sendiri") {
      spring.nama_satker_polres = "";
    }
  }
  const res = await get("audit/trail", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};

// MASTER LOKASI PENYIMPANAN BARANG BUKTI ENDPOINT
export const getLokasiBarbukDetail = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const res = await get("lokasi/barang-bukti/" + id);
  return res;
};
export const getLokasiBarbukList = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("lokasi/barang-bukti", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const getLokasiBarbukListNew = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("lokasi/barang-bukti/list", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const putLokasiBarbuk = async ({ id, lokasiBarbuk }) => {
  const res = await put("lokasi/barang-bukti/" + id, lokasiBarbuk);
  return res;
};
export const postLokasiBarbuk = async (payload) => {
  const res = await post("lokasi/barang-bukti", payload);
  return res;
};
export const deleteLokasiBarbuk = async (payload) => {
  const res = await del("lokasi/barang-bukti/" + payload, payload);
  return res;
};

//MASTER RUTAN ENDPOINT
export const getMasterRutanList = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("rutan", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const getMasterRutanDetail = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const res = await get("rutan/" + id);
  return res;
};
export const postMasterRutan = async (payload) => {
  const res = await post("rutan", payload);
  return res;
};
export const putMasterRutan = async ({ id, formData }) => {
  const res = await put("rutan/" + id, formData);
  return res;
};
export const deleteMasterRutan = async (payload) => {
  const res = await del("rutan/" + payload, payload);
  return res;
};

//PENYIMPANAN BARANG BUKTI ENDPOINT
export const getPenyimpananBarbukList = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  if (spring.nama_satker_polda === "semua_polda") spring.nama_satker_polda = "";
  if (!spring.nama_satker_polda.includes("BARESKRIM")) {
    if (spring.nama_satker_polres === "satker_sendiri") {
      spring.nama_satker_polres = "";
    }
  }
  const res = await get("penyimpanan/barang-bukti", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};

// Master Barang Bukti
// Jenis
export const getMasterBarangBuktiJenis = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("barang-bukti/jenis", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const getMasterDetailBarangBuktiJenis = async ({ queryKey }) => {
  let [_, id] = queryKey;
  const res = await get("barang-bukti/jenis/" + id);
  return res;
};
export const postMasterBarangBuktiJenis = async (payload) => {
  const res = await post("barang-bukti/jenis", payload);
  return res;
};
export const putMasterBarangBuktiJenis = async ({ id, payload }) => {
  const res = await put("barang-bukti/jenis/" + id, payload);
  return res;
};
export const delMasterBarangBuktiJenis = async (id) => {
  const res = await del("barang-bukti/jenis/" + id);
  return res;
};
// Sub Jenis
export const getMasterBarangBuktiSubJenis = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("barang-bukti/sub-jenis", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const getMasterBarangBuktiSubJenisNew = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("master/barang-bukti/select-barbuk", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const getMasterDetailBarangBuktiSubJenis = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const res = await get("barang-bukti/sub-jenis/" + id);
  return res;
};
export const postMasterBarangBuktiSubJenis = async (payload) => {
  const res = await post("barang-bukti/sub-jenis", payload);
  return res;
};
export const putMasterBarangBuktiSubJenis = async ({ id, payload }) => {
  const res = await put("barang-bukti/sub-jenis/" + id, payload);
  return res;
};
export const delMasterBarangBuktiSubJenis = async (id) => {
  const res = await del("barang-bukti/sub-jenis/" + id);
  return res;
};
// Unit
export const getMasterBarangBuktiUnit = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("barang-bukti/unit", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const getMasterDetailBarangBuktiUnit = async ({ queryKey }) => {
  let [_, id] = queryKey;
  const res = await get("barang-bukti/unit/" + id);
  return res;
};
export const postMasterBarangBuktiUnit = async (payload) => {
  const res = await post("barang-bukti/unit", payload);
  return res;
};
export const putMasterBarangBuktiUnit = async ({ id, payload }) => {
  const res = await put("barang-bukti/unit/" + id, payload);
  return res;
};
export const delMasterBarangBuktiUnit = async (id) => {
  const res = await del("barang-bukti/unit/" + id);
  return res;
};
// Tipe
export const getMasterBarangBuktiTipe = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("barang-bukti/tipe", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const getMasterDetailBarangBuktiTipe = async ({ queryKey }) => {
  let [_, id] = queryKey;
  const res = await get("barang-bukti/tipe/" + id);
  return res;
};
export const postMasterBarangBuktiTipe = async (payload) => {
  const res = await post("barang-bukti/tipe", payload);
  return res;
};
export const putMasterBarangBuktiTipe = async ({ id, payload }) => {
  const res = await put("barang-bukti/tipe/" + id, payload);
  return res;
};
export const delMasterBarangBuktiTipe = async (id) => {
  const res = await del("barang-bukti/tipe/" + id);
  return res;
};
// NOTIFICATION
export const getNotificationList = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("notifications/", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
export const deleteNotification = async (payload) => {
  const res = await del("notifications/" + payload, payload);
  return res;
};
// Role
export const getRoles = async ({ queryKey }) => {
  const [_, query] = queryKey;
  let spring = Object.fromEntries(
    Object.entries(query || {}).filter(([_, v]) => v !== "")
  );
  const res = await get("/roles", {
    params: Object.keys(spring).length ? spring : undefined,
  });
  return res;
};
