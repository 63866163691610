import React, { useCallback, useEffect, useState } from "react";
import { Badge, Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Input, MyPagination, Select } from "../../../components/Components";
import { debounce } from "lodash";
import {
  useDeletePerpanjangann,
  useGetPerpanjangan,
  useSatkerList,
} from "../../../utils/apis/query";
import Alert from "../../../libraries/Alert";

function List() {
  const [search, setSearch] = useState("");
  const [payload, setPayload] = useState({
    search: "",
    perkara: "",
    status: "",
    nama_satker: "",
    page: 1,
    size: 10,
  });
  const { data: masterListSatkers } = useSatkerList();
  const satkersFilter = masterListSatkers.map((e) => ({
    text: e.nama_satker,
    value: e.nama_satker,
  }));

  const {
    data: dataList,
    pagination: dataListPagination,
    isFetching: isDataListFetching,
    isError: isDataListError,
    error: dataListError,
  } = useGetPerpanjangan(payload);

  const mutateDeleteList = useDeletePerpanjangann();

  const handleDebounce = useCallback(
    debounce(({ field, val }) => {
      setPayload({ ...payload, [field]: val });
    }, 500),
    []
  );

  useEffect(() => {
    if (masterListSatkers) {
      
    }
  }, [masterListSatkers]);
  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item">
              <Link to="/pengaturan/role/list">Role</Link>
            </li>
            <li className="breadcrumb-item active fw-bold" aria-current="page">
              Role List
            </li>
          </ol>
        </div>
      </div>
      <Row>
        <Col xl="12">
          <Card className="card bg-white">
            <Card.Body>
              <Row className="g-1 mb-2">
                <Col sm="12" md="5">
                  <Input
                    type="text"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      handleDebounce({ field: "search", val: e.target.value });
                    }}
                  />
                </Col>
                <Col sm="12" md="7">
                  <Row className="g-2">
                    <Col sm="12" className="d-flex justify-content-end">
                      <Button
                        as={Link}
                        to="/pengaturan/role/form"
                        className="btn btn-primary"
                      >
                        TAMBAH
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Table className="table dems-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Nama Role</th>
                    <th>Akses Menu</th>
                    <th>Keteranngan</th>
                    <th style={{ textAlign: "right" }}>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {isDataListFetching && (
                    <tr>
                      <td colSpan="10" style={{ textAlign: "center" }}>
                        <Spinner animation="border" variant="primary" />
                      </td>
                    </tr>
                  )}
                  {isDataListError &&
                    (dataListError?.response?.data?.detail?.map((err, idx) => (
                      <tr key={idx}>
                        <td
                          colSpan="4"
                          style={{ textAlign: "center" }}
                          className="text-danger"
                        >
                          <span>
                            <b>{err.loc[1]} </b>
                            {err.msg}
                          </span>
                        </td>
                      </tr>
                    )) ?? (
                      <tr>
                        <td
                          colSpan="4"
                          style={{ textAlign: "center" }}
                          className="text-danger"
                        >
                          <span>
                            <b>Opps Error </b>Reload page or please wait until
                            we fix this issue
                          </span>
                        </td>
                      </tr>
                    ))}
                  {dataList.length && !isDataListFetching && !isDataListError
                    ? [{}, {}, {}, {}, {}].map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1 || "-"}</td>
                          <td>Nama Role</td>
                          <td>Akses Menu</td>
                          <td>Keterangan</td>
                          <td>
                            <div className="d-flex align-items-center justify-content-end gap-2">
                              <Link to={"/pengaturan/role/edit/" + data.id}>
                                <Badge className="btn bg-info" as="button">
                                  <i className="ri-eye-line"></i>
                                </Badge>
                              </Link>
                              <Badge
                                className="btn bg-danger"
                                as="button"
                                onClick={() => {
                                  Alert.showConfirm("warning", "Hapus").then(
                                    ({ isConfirmed }) => {
                                      if (isConfirmed) {
                                        mutateDeleteList.mutate(data.id);
                                      }
                                    }
                                  );
                                }}
                              >
                                <i className="ri-delete-bin-line"></i>
                              </Badge>
                            </div>
                          </td>
                        </tr>
                      ))
                    : !isDataListFetching &&
                      !dataList.length &&
                      !isDataListError && (
                        <tr>
                          <td colSpan="10" style={{ textAlign: "center" }}>
                            <span>Tidak Ada Data !</span>
                          </td>
                        </tr>
                      )}
                </tbody>
              </Table>
            </Card.Body>
            <Card.Footer className="bg-white">
              <MyPagination
                currentPage={payload.page}
                totalPages={dataListPagination.total_pages}
                onPageChange={(page) => {
                  setPayload({ ...payload, page: page });
                }}
              />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default List;
