import React, { useCallback, useEffect, useState } from "react";
import { Alert, Badge, Button, Card, Col, Modal, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IconPicker, Input, Select } from "../../../components/Components";
import {
  useGetLokasiBarbukDetail,
  usePostLokasiBarbuk,
  usePutLokasiBarbuk,
  useSatkerTahtiList,
} from "../../../utils/apis/query";

function Form() {
  const [isEdit, setIsEdit] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [payload, setPayload] = useState({
    kode: "",
    tindak_pidana: "",
    perkara: "",
  });
  const onSuccess = () => {
    setTimeout(() => {
      navigate("/jenis-tindak-pidana");
    }, 1500);
  };

  const mutateAdd = usePostLokasiBarbuk(onSuccess);
  const mutateEdit = usePutLokasiBarbuk(onSuccess);
  const {
    data: detailData,
    isFetching: detailIsFetching,
    isError: detailIsError,
    error: detailError,
  } = useGetLokasiBarbukDetail(id, { enabled: !!id });

  const handleChange = (e) => {
    let { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };
  const handleSubmit = () => {
    // if (isEdit) {
    //   mutateEdit.mutate({ id, lokasiBarbuk });
    // } else {
    //   mutateAdd.mutate(lokasiBarbuk);
    // }
  };

  useEffect(() => {
    setIsEdit(false);
    if (id) {
      setIsEdit(true);
    }
  }, [id]);

  useEffect(() => {
    if (detailData) {
      setPayload({
        ...payload,
        kode: detailData.kode,
        tindak_pidana: detailData.tindak_pidana,
        perkara: detailData.perkara,
      });
    }
  }, [detailData]);

  useEffect(() => {
    return () => {
      setPayload({
        kode: "",
        tindak_pidana: "",
        perkara: "",
      });
    };
  }, []);
  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between m-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/penyimpanan-barbuk">Perkara</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/penyimpanan-barbuk">List Perkara</Link>
                </li>
                <li
                  className="breadcrumb-item active fw-bold"
                  aria-current="page"
                >
                  Form {isEdit ? "Edit" : "Add"} Perkara
                </li>
              </ol>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="bg-white">
        <Card.Body>
          <Row className="g-2">
            <Col sm="12">
              {mutateAdd.isError && (
                <Alert variant="danger">
                  <ul>
                    {mutateAdd?.error?.response?.data?.detail?.map(
                      (errEdit) => (
                        <li>
                          <b>{errEdit.loc[0]} </b>
                          {errEdit.msg}
                        </li>
                      )
                    ) ?? (
                      <li>
                        <b>Error</b>Sesuatu Bermasalah
                      </li>
                    )}
                  </ul>
                </Alert>
              )}
              {mutateAdd.isSuccess && (
                <Alert variant="success">{mutateAdd.data.data.message}</Alert>
              )}
            </Col>
            <Col sm="12">
              {mutateEdit.isError && (
                <Alert variant="danger">
                  <ul>
                    {mutateEdit?.error?.response?.data?.detail?.map(
                      (errEdit) => (
                        <li>
                          <b>{errEdit.loc[0]} </b>
                          {errEdit.msg}
                        </li>
                      )
                    ) ?? (
                      <li>
                        <b>Error</b>Sesuatu Bermasalah
                      </li>
                    )}
                  </ul>
                </Alert>
              )}
              {mutateEdit.isSuccess && (
                <Alert variant="success">{mutateEdit.data.data.message}</Alert>
              )}
            </Col>
            <Col sm="4">
              <Input
                value={payload.kode}
                name="kode"
                onChange={handleChange}
                label="Kode"
                required
              />
            </Col>
            <Col sm="4">
              <Input
                value={payload.tindak_pidana}
                name="tindak_pidana"
                onChange={handleChange}
                label="Tindak Pidana"
                required
              />
            </Col>
            <Col sm="4">
              <Input
                value={payload.perkara}
                name="perkara"
                onChange={handleChange}
                label="Perkara"
                required
              />
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
          <div className="d-flex justify-content-end align-items-center">
            {!mutateAdd.isLoading || !mutateEdit.isLoading ? (
              <Button variant="primary" onClick={handleSubmit}>
                {isEdit ? "Save" : "Submit"}
              </Button>
            ) : (
              <Button variant="primary" disabled>
                Loading
              </Button>
            )}
          </div>
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
}

export default Form;
