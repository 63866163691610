import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as Components from "./../../../../components/Components";
import State from "./../../../../libraries/State";

function EvidenceCategoryForm() {
  const params = useParams();
  const state = State.getState();

  const id = params?.id !== undefined ? params.id : null;
  const FormType = id ? "ubah" : "tambah";

  /**
   * Call APi to get default value if case is edit
   */
  var defValue = {};
  if (id) {
    defValue = {
      kategori: "default_kategori",
      keterangan: "default_keterangan",
      id_parent: 2,
    };
  }

  /**
   * Init data categories
   */
  const categories = [
    {
      value: 0,
      label: "-- KOSONGKAN --",
    },
    {
      value: 1,
      label: "Kategori 1",
    },
    {
      value: 2,
      label: "Kategori 2",
    },
  ];

  const handleSubmit = () => {
    withReactContent(Swal)
      .fire({
        title: "Apa anda yakin?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
      })
      .then((result) => {
        if (result.isConfirmed) {
          /**
           * Preparing data
           */
          const data = {
            kategori: state.getItem("kategori"),
            keterangan: state.getItem("keterangan"),
            id_parent: state.getItem("id_parent"),
            is_parent: state.getItem("id_parent") > 0 ? true : false,
          };
          state.flush(); // clean state

          /**
           * Send to APi
           */
          

          /**
           * Send notification
           */
          Swal.fire({
            title: "Berhasil",
            icon: "success",
            text: "Data berhasil tersimpan",
          });
        }
      });
  };

  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/evidence_categories">Kategori barang bukti</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/evidence_categories">
                    List kategori barang bukti
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Form {FormType}
                </li>
              </ol>
            </div>
          </div>
        </Card.Body>
      </Card>

      <Row className="g-3">
        <Col xl="12">
          <Card className="card">
            <Card.Body className="bg-white">
              <Row>
                <Col sm="4">
                  <Components.Input
                    type="text"
                    name="kategori"
                    label="Kategori"
                    placeholder="Kategori"
                    defaultValue={defValue?.kategori}
                  />
                </Col>
                <Col sm="4">
                  <Components.Input
                    type="text"
                    name="keterangan"
                    label="Keterangan"
                    placeholder="Keterangan"
                    defaultValue={defValue?.keterangan}
                  />
                </Col>
                <Col sm="4">
                  <Components.Select
                    type="text"
                    name="id_parent"
                    label="Main kategori"
                    options={categories}
                    defaultValue={defValue?.id_parent}
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="bg-white">
              <div className="d-flex justify-content-end align-items-center gap-3">
                <Button as={Link} to="/evidence_categories" variant="warning">
                  Batal
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                  Simpan
                </Button>
              </div>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default EvidenceCategoryForm;
