import React, { useCallback, useEffect, useState } from "react";
import { Badge, Button, Card, Col, Form, Row, Spinner, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Alert from "../../../libraries/Alert";
import {
  Input,
  MyPagination,
  Select,
  Select2,
} from "../../../components/Components";
import { useGetMenuList, useGetMenuRoleAction, usePostMenuRoleAction } from "../../../utils/apis/query";
import { decodeFromHex } from "../../../utils";

function FormCreate() {
    const [size, setSize] = useState(10)
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const navigate = useNavigate();
    const { id, role } = useParams();
    const roleText = decodeFromHex(role)
    const [payload, setPayload] = useState([])
    const [payloadSubmit, setPayloadSubmit] = useState([])

    const mutateSubmit = usePostMenuRoleAction()

    const handleSubmit = () => {
        mutateSubmit.mutate({id, payload: payloadSubmit})
        
        
    }

    const {
        data: dataRoleAkses,
        pagination: dataRoleAksesPagination,
        isFetching: isDataRoleAksesFetching,
        isError: isDataRoleAksesError,
        error: dataRoleAksesError,
        refetch: refetchDataRoleAkses,
    } = useGetMenuRoleAction(
        {
            id: id,
            payload: {
            search: search,
            page: page,
            size: size,
            }
        },
        {
            enabled: true
        }
    );

    const checkBoxToggle = (e, field) => {
        setPayloadSubmit((prev) => {
            return prev.map((item, i) => {
                return {
                    ...item,
                    [field]: e.target.checked, 
                };
            });
        });
    };

    useEffect(() => {
        if (dataRoleAkses) {
            setPayloadSubmit(
                dataRoleAkses
                    .sort((a, b) => {
                    const nameA = a.nama_parent?.toLowerCase() ?? ''; // Menggunakan lowercase untuk case-insensitive sorting
                    const nameB = b.nama_parent?.toLowerCase() ?? '';
                    
                    if (nameA < nameB) return -1; // Sort A sebelum B
                    if (nameA > nameB) return 1;  // Sort B sebelum A
                    return 0; // Jika sama, tidak ada perubahan
                    })
                    .map((e) => {
                    return {
                        id_menu: e.id,
                        nama_menu: e?.nama_menu ?? '-',
                        nama_parent: e?.nama_parent ?? '-',
                        group: e?.group ?? '-',
                        ...e.action_menu
                    };
                })
            );
        }
    }, [dataRoleAkses])
    return (
        <React.Fragment>
        <div className="d-flex align-items-center justify-content-between mb-4">
            <div>
            <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                    <Link to="/role">Role</Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to="#">Role Akses</Link>
                </li>
                <li className="breadcrumb-item active fw-bold" aria-current="page">
                    {roleText}
                </li>
            </ol>
            </div>
        </div>
        <Row>
            <Col xl="12">
            <Card className="card bg-white">
                <Card.Body>
                <Row className="g-1 mb-2">
                    <Col sm="12" md="6">
                        <Input
                            type="text"
                            placeholder="Search"
                            onChange={(e) => setSearch(e.target.value)}
                        />  
                    </Col>
                    <Col sm="12" md="6" className="d-flex justify-content-end align-items-center gap-2">
                        <Button
                            variant="primary"
                            className="btn"
                            onClick={handleSubmit}
                        >
                            Simpan
                        </Button>
                    </Col>
                </Row>
                {/* <Row className="mt-4">
                    <div className="d-flex justify-content-end align-items-center flex-row gap-1">
                    <label className="text-muted">Items per page</label>
                    <Select
                        name="size"
                        options={[
                        { text: "10", value: 10 },
                        { text: "20", value: 20 },
                        { text: "50", value: 50 },
                        { text: "100", value: 100 },
                        ]}
                        value={size}
                        onChange={(e) => setSize(e.target.value)}
                        style={{
                        padding: "2px 4px",
                        fontSize: "0.875rem",
                        width: "60px",
                        }}
                    />
                    <div className="text-muted d-flex justify-content-end">
                        {`${(page - 1) * size + 1}–${Math.min(
                        page * size,
                        dataRoleAksesPagination.total_items
                        )} of ${dataRoleAksesPagination.total_items}`}
                    </div>
                    </div>
                </Row> */}
                <div className="table-responsive">
                    <Table className="table dems-table">
                    <thead>
                        <tr>
                            <th
                                style={{width: '50%'}}
                            >
                                Menu
                            </th>
                            <th
                                style={{width: '30%'}}
                            >
                                Parent
                            </th>
                            <th
                                style={{width: '30%'}}
                            >
                                Group
                            </th>
                            <th
                                style={{width: 10}}
                            >
                                <div className="d-flex align-items-center gap-2">
                                    <Form.Check
                                        type="checkbox"
                                        defaultChecked={false}
                                        onChange={(e) => {
                                            checkBoxToggle(e, 'create')
                                        }}
                                    />
                                    <div className="fs-sm">Create</div>
                                </div>
                            </th>
                            <th
                                style={{width: 10}}
                            >
                                <div className="d-flex align-items-center gap-2">
                                    <Form.Check
                                        type="checkbox"
                                        defaultChecked={false}
                                        onChange={(e) => {
                                            checkBoxToggle(e, 'read')
                                        }}
                                    />
                                    <div className="fs-sm">Read</div>
                                </div>
                            </th>
                            <th
                                style={{width: 10}}
                            >
                                <div className="d-flex align-items-center gap-2">
                                    <Form.Check
                                        type="checkbox"
                                        defaultChecked={false}
                                        onChange={(e) => {
                                            checkBoxToggle(e, 'update')
                                        }}
                                    />
                                    <div className="fs-sm">Update</div>
                                </div>
                            </th>
                            <th
                                style={{width: 10}}
                            >
                                <div className="d-flex align-items-center gap-2">
                                    <Form.Check
                                        type="checkbox"
                                        defaultChecked={false}
                                        onChange={(e) => {
                                            checkBoxToggle(e, 'delete')
                                        }}
                                    />
                                    <div className="fs-sm">Delete</div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {isDataRoleAksesFetching && (
                        <tr>
                            <td colSpan="9" style={{ textAlign: "center" }}>
                            <Spinner animation="border" variant="primary" />
                            </td>
                        </tr>
                        )}
                        {isDataRoleAksesError &&
                        (dataRoleAksesError?.response?.data?.detail?.map(
                            (err, idx) => (
                            <tr key={idx}>
                                <td
                                colSpan="9"
                                style={{ textAlign: "center" }}
                                className="text-danger"
                                >
                                <span>
                                    <b>{err.loc[1]} </b>
                                    {err.msg}
                                </span>
                                </td>
                            </tr>
                            )
                        ) ?? (
                            <tr>
                            <td
                                colSpan="9"
                                style={{ textAlign: "center" }}
                                className="text-danger"
                            >
                                <span>
                                <b>Opps Error </b>Reload page or please wait until
                                we fix this issue
                                </span>
                            </td>
                            </tr>
                        ))}
                        {dataRoleAkses.length && !isDataRoleAksesFetching && !isDataRoleAksesError
                        ? payloadSubmit.map((data, index) => (
                            <tr key={index}>
                                <td>{data?.nama_menu ?? '-'}</td>
                                <td>{data?.nama_parent ?? '-'}</td>
                                <td>{data?.group ?? '-'}</td>
                                <td>
                                    <Form.Check
                                        type="checkbox"
                                        checked={data.create} // Gunakan 'checked'
                                        onChange={(e) => {
                                            setPayloadSubmit(prevState => 
                                                prevState.map((item, i) => 
                                                    i === index
                                                        ? { ...item, create: e.target.checked } // Menggunakan e.target.checked untuk update state
                                                        : item
                                                )
                                            );
                                        }}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        type="checkbox"
                                        checked={data.read} // Gunakan 'checked'
                                        onChange={(e) => {
                                            setPayloadSubmit(prevState => 
                                                prevState.map((item, i) => 
                                                    i === index
                                                        ? { ...item, read: e.target.checked } // Menggunakan e.target.checked untuk update state
                                                        : item
                                                )
                                            );
                                        }}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        type="checkbox"
                                        checked={data.update} // Gunakan 'checked'
                                        onChange={(e) => {
                                            setPayloadSubmit(prevState => 
                                                prevState.map((item, i) => 
                                                    i === index
                                                        ? { ...item, update: e.target.checked } // Menggunakan e.target.checked untuk update state
                                                        : item
                                                )
                                            );
                                        }}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        type="checkbox"
                                        checked={data.delete} // Gunakan 'checked'
                                        onChange={(e) => {
                                            setPayloadSubmit(prevState => 
                                                prevState.map((item, i) => 
                                                    i === index
                                                        ? { ...item, delete: e.target.checked } // Menggunakan e.target.checked untuk update state
                                                        : item
                                                )
                                            );
                                        }}
                                    />
                                </td>
                            </tr>
                            ))
                        : !isDataRoleAksesFetching &&
                            !dataRoleAkses.length &&
                            !isDataRoleAksesError && (
                            <tr>
                                <td colSpan="10" style={{ textAlign: "center" }}>
                                <span>Tidak Ada Data !</span>
                                </td>
                            </tr>
                            )}
                    </tbody>
                    </Table>
                </div>
                </Card.Body>
                {/* <Card.Footer className="bg-white">
                <MyPagination
                    currentPage={page}
                    totalPages={dataRoleAksesPagination.total_pages}
                    onPageChange={(page) => setPage(page)}
                />
                </Card.Footer> */}
            </Card>
            </Col>
        </Row>
        </React.Fragment>
    )
}

export default FormCreate