import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { decodeFromHex, decryptAES, useQueryPage } from '../../../utils'
import { ViewDoc, ViewImage, ViewMedia } from './module'
import { useExtrackedFile } from '../../../utils/apis/query'
import { Card, Spinner } from 'react-bootstrap'

function Viewer() {
  const {file} = useParams()
  const qp = useQueryPage()
  const p = decodeFromHex(qp.get('p'))
  const c = qp.get('kategori')
  const fileName = file

  const {
    data: dataFile,
    isFetching: isDataFetching,
    isError: errorData,
    error

  } = useExtrackedFile({filename: fileName, file_path: p, kategori: c})

  const fileData = dataFile

  if (isDataFetching) {
    return (
      <React.Fragment>
        <Card className='card bg-white'>
          <Card.Body className='d-flex justify-content-center'>
            <Spinner variant='primary' />
          </Card.Body>
        </Card>
      </React.Fragment>
    )
  } 
  if (errorData) {
    return (
      <React.Fragment>
        <Card className='card bg-white'>
          <Card.Body className='d-flex justify-content-center'>
            {error?.response?.data?.message ?? 'File URL Tidak Valid'}
          </Card.Body>
        </Card>
      </React.Fragment>
    )
  } 
  if (!isDataFetching && !errorData) {
    if (fileData.typeFormat === 'image') {
      return (<ViewImage payload={fileData} />)
    }
    if (fileData.typeFormat === 'docs') {
      return (<ViewDoc payload={fileData} />)
    }
    if (fileData.typeFormat === 'media') {
      return (<ViewMedia payload={fileData} />)
    }
  } else {

    return (
      <div>
          Unknown Type Error
      </div>
    )
  }
}

export default Viewer