import React, { useCallback, useEffect, useState } from "react";
import { Badge, Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  useDeleteTahti,
  useGetTahtiList,
  useSatkerTahtiList,
  useGetPoldaPolres,
} from "../../../../utils/apis/query";
import {
  Input,
  MyPagination,
  Select,
  Select2,
  DatePicker,
} from "../../../../components/Components";
import { debounce, filter } from "lodash";
import Alert from "../../../../libraries/Alert";
import {
  jenisTindakPidanaTRX,
  status_tahanan,
} from "../../../../utils/dummy/variable";
import moment from "moment";
import State from "../../../../libraries/State";

function List() {
  const state = State.getState();
  const profileData = state.getItem("profileData", true);
  const satker = profileData?.satker?.nama_satker ?? "";
  const isBareskrim = /bareskrim/gi.test(satker);
  const isPolres = /polres/gi.test(satker);
  const [namaSatkerPoldaFilter, setNamaSatkerPoldaFilter] = useState("");
  const [namaSatkerPolresFilter, setNamaSatkerPolresFilter] = useState("");
  const [namaSatkerPolresPayloadFilter, setNamaSatkerPolresPayloadFilter] =
    useState("");
  const [namaSatkerPoldaPayloadFilter, setNamaSatkerPoldaPayloadFilter] =
    useState("");
  const [fetchedOnce, setFetchedOnce] = useState(true);
  const [search, setSearch] = useState("");
  const [formDate, setFormDate] = useState({
    start_date: "",
    end_date: "",
  });
  const [payloadDebounced, setPayloadDebounced] = useState({
    search: "",
    status_tahanan: "",
    jenis_tindak_pidana: "",
    asal_tahanan: "",
    start_date: "",
    end_date: "",
    umur_range: "",
    nama_fase_penahanan: "",
    tipe_tahanan: "",
    nama_satker_polda: namaSatkerPoldaPayloadFilter,
    nama_satker_polres: namaSatkerPolresPayloadFilter,
    page: 1,
    size: 10,
  });
  const [filters, setFilters] = useState({
    status_tahanan: "",
    jenis_tindak_pidana: "",
    asal_tahanan: "",
    umur_range: "",
    nama_fase_penahanan: "",
    tipe_tahanan: "",
  });

  const { data: dataSatker } = useGetPoldaPolres(
    {
      polda: namaSatkerPoldaFilter,
      polres:
        namaSatkerPolresFilter === "satker_sendiri"
          ? ""
          : namaSatkerPolresFilter,
    },
    {
      enabled: !!namaSatkerPoldaPayloadFilter || !!namaSatkerPolresFilter,
    }
  );

  const optionPolda = [
    { label: "SEMUA POLDA", value: "semua_polda" },
    ...dataSatker.polda.map((o) => ({ label: o.value, value: o.value })),
  ];
  const optionPolres = [
    { label: "SEMUA", value: "semua" },
    { label: "SATKER SENDIRI", value: "satker_sendiri" },
    ...dataSatker.polres.map((o) => ({ label: o.value, value: o.value })),
  ];

  const {
    data: dataList,
    pagination,
    isFetching: dataFetching,
    isError: dataIsError,
    error: dataError,
    refetch: refetchDataList,
  } = useGetTahtiList(payloadDebounced);

  const handleDebounce = useCallback(
    debounce(({ field, val }) => {
      setPayloadDebounced((prev) => ({ ...prev, [field]: val, page: 1 }));
    }, 500),
    []
  );

  const handlePagination = (page) =>
    setPayloadDebounced({ ...payloadDebounced, page: page });

  const handleFilters = () => {
    setPayloadDebounced((prev) => ({
      ...prev,
      nama_satker_polda: namaSatkerPoldaPayloadFilter,
      nama_satker_polres: namaSatkerPolresPayloadFilter,
      status_tahanan: filters.status_tahanan,
      jenis_tindak_pidana: filters.jenis_tindak_pidana,
      asal_tahanan: filters.asal_tahanan,
      start_date: filters.start_date,
      end_date: filters.end_date,
      umur_range: filters.umur_range,
      nama_fase_penahanan: filters.nama_fase_penahanan,
      tipe_tahanan: filters.tipe_tahanan,
      page: 1,
    }));
  };

  useEffect(() => {
    setPayloadDebounced((prev) => ({
      ...prev,
      status_tahanan: "Ditahan",
    }));
    setFilters((prev) => ({
      ...prev,
      status_tahanan: "Ditahan",
    }));
  }, []);

  useEffect(() => {
    if (profileData) {
      moment.locale();
      const satker = profileData?.satker?.nama_satker ?? "";
      const username = profileData?.user?.username ?? "";
      const isPolres = /polres/gi.test(satker);
      const isBareskrim = /bareskrim/gi.test(satker);
      if (isBareskrim) {
        if (isBareskrim) {
          if (["kabareskrim", "kapusiknas"].includes(username)) {
            setNamaSatkerPoldaFilter("semua_polda");
            setNamaSatkerPoldaPayloadFilter("semua_polda");
            setPayloadDebounced((prev) => ({
              ...prev,
              nama_satker_polda: "semua_polda",
              // nama_satker_polres: "semua_polres",
            }));
          } else {
            setNamaSatkerPoldaFilter(satker);
            setNamaSatkerPoldaPayloadFilter(satker);
            setPayloadDebounced((prev) => ({
              ...prev,
              nama_satker_polda: satker,
              // nama_satker_polres: "semua_polres",
            }));
          }
        }
        setNamaSatkerPolresFilter("satker_sendiri");
      } else {
        if (isPolres) {
          let namaPolda = profileData?.profile?.nama_satker_parent_polres ?? "";
          setNamaSatkerPoldaFilter(namaPolda);
          setNamaSatkerPoldaPayloadFilter(namaPolda);
          setPayloadDebounced((prev) => ({
            ...prev,
            nama_satker_polda: namaPolda,
          }));
        } else {
          setNamaSatkerPoldaFilter(satker);
          setNamaSatkerPoldaPayloadFilter(satker);
          setNamaSatkerPolresFilter("satker_sendiri");
          setPayloadDebounced((prev) => ({
            ...prev,
            nama_satker_polda: satker,
            nama_satker_polres: "satker_sendiri",
          }));
        }
        // setNamaSatkerPoldaFilter(!isPolres ? satker : "");
        // setNamaSatkerPolresFilter(isPolres ? satker : "satker_sendiri");
        // setPayloadDebounced((prev) => ({
        //   ...prev,
        //   nama_satker_polda: !isPolres ? satker : "",
        //   nama_satker_polres: isPolres ? satker : "satker_sendiri",
        // }));
      }
    }
  }, [profileData]);
  useEffect(() => {
    if (profileData) {
      const satker = profileData?.satker?.nama_satker ?? "";
      const isPolres = /polres/gi.test(satker);
      const isBareskrim = /bareskrim/gi.test(satker);
      const isfilterPoldaAll = namaSatkerPoldaFilter === "semua_polda";

      if (isBareskrim || isfilterPoldaAll) {
        const filterBareskrim = /bareskrim/gi.test(namaSatkerPoldaFilter);
        if (!namaSatkerPolresFilter)
          setNamaSatkerPolresPayloadFilter("satker_sendiri");
        if (
          isfilterPoldaAll &&
          namaSatkerPolresPayloadFilter === "satker_sendiri"
        ) {
          setNamaSatkerPoldaPayloadFilter(
            dataSatker.polda.map((o) => o.value).join(",")
          );
        }
        if (isfilterPoldaAll && namaSatkerPolresPayloadFilter === "semua") {
          setNamaSatkerPoldaPayloadFilter("semua_polda");
        }
        if (namaSatkerPolresFilter === "semua") {
          let payload = filterBareskrim
            ? ""
            : dataSatker.polres.map((o) => o.value).join(",");
          setNamaSatkerPolresPayloadFilter(payload);
        }
      } else {
        if (isPolres) {
          let namaPolres = profileData?.profile?.nama_satker_tahti ?? "";
          setNamaSatkerPolresFilter(namaPolres);
          setNamaSatkerPolresPayloadFilter(namaPolres);
        } else {
          if (!namaSatkerPolresFilter)
            setNamaSatkerPolresPayloadFilter("satker_sendiri");
          if (namaSatkerPolresFilter === "semua") {
            setNamaSatkerPolresPayloadFilter(
              dataSatker.polres.map((o) => o.value).join(",")
            );
          }
        }
      }
    }
  }, [profileData, dataSatker, namaSatkerPolresFilter, namaSatkerPoldaFilter]);

  useEffect(() => {
    if (dataFetching) {
      if (fetchedOnce) setFetchedOnce(false);
    }
  }, [fetchedOnce, dataFetching]);

  // useEffect(() => {
  //   if (namaSatkerPolresPayloadFilter && fetchedOnce) {
  //     refetchDataList();
  //   }
  // }, [namaSatkerPolresPayloadFilter, refetchDataList]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (namaSatkerPoldaFilter !== null || !!namaSatkerPoldaFilter) {
  //       refetchDataList();
  //       setFetchedOnce(false);
  //     }
  //   }, 2000);
  // }, []);

  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item">
              <Link to="/tahanan/list">Tahanan</Link>
            </li>
            <li className="breadcrumb-item active fw-bold" aria-current="page">
              List Tahanan
            </li>
          </ol>
        </div>
      </div>
      <Row>
        <Col xl="12">
          <Card className="card bg-white">
            <Card.Body>
              <Row className="g-1 mb-2">
                <Col sm="12" md="6">
                  <Input
                    type="text"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      handleDebounce({ field: "search", val: e.target.value });
                    }}
                  />
                </Col>
                <Col sm="12" md="3">
                  <DatePicker
                    placeholderText="Rentang Tanggal Ditahan"
                    selectsRange={true}
                    dateFormat="dd/MM/yyyy"
                    startDate={formDate.start_date}
                    endDate={formDate.end_date}
                    selected={formDate.start_date}
                    onChange={(dates) => {
                      const [start, end] = dates || [];
                      const tglAwal = start
                        ? moment(start).format("YYYY-MM-DD")
                        : "";
                      const tglAkhir = end
                        ? moment(end).format("YYYY-MM-DD")
                        : "";

                      setFilters((prev) => ({
                        ...prev,
                        start_date: tglAwal,
                        end_date: tglAkhir,
                      }));
                      setFormDate((prev) => ({
                        ...prev,
                        start_date: start || "",
                        end_date: end || "",
                      }));
                    }}
                    isClearable={true}
                  />
                </Col>
                <Col
                  sm="12"
                  md="3"
                  className="d-flex justify-content-end align-items-center"
                >
                  <Button
                    variant="danger"
                    onClick={handleFilters}
                    className="btn"
                  >
                    Filter
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="12">
                  <Row className="g-2 d-flex align-items-center justify-content-between flex-wrap">
                    <Col sm="12" md="3">
                      <Select2
                        defaultValue={"Semua Kesatuan"}
                        options={optionPolda}
                        value={namaSatkerPoldaFilter}
                        name="nama_satker_polda"
                        placeholder="Semua Kesatuan"
                        required
                        isClearable
                        isSearchable
                        disabled={!isBareskrim || isPolres}
                        onChange={(e) => {
                          setNamaSatkerPoldaFilter(e.value);
                          setNamaSatkerPoldaPayloadFilter(e.value);
                          setNamaSatkerPolresFilter((prev) => "satker_sendiri");
                          setNamaSatkerPolresPayloadFilter(
                            (prev) => "satker_sendiri"
                          );
                        }}
                      />
                    </Col>
                    <Col sm="3">
                      <Select2
                        defaultValue={"Semua Kesatuan"}
                        options={optionPolres}
                        value={namaSatkerPolresFilter}
                        name="nama_satker_polres"
                        placeholder="SEMUA"
                        required
                        isClearable
                        isSearchable
                        disabled={isPolres}
                        onChange={(e) => {
                          setNamaSatkerPolresFilter(e.value);
                          setNamaSatkerPolresPayloadFilter(e.value);
                        }}
                      />
                    </Col>
                    <Col sm="12" md="3">
                      <Select
                        default="Fase Penahanan"
                        options={[
                          {
                            value: "Penyidik",
                            text: "Penyidik",
                          },
                          {
                            value: "Jaksa",
                            text: "Jaksa",
                          },
                          {
                            value: "PN1",
                            text: "PN1",
                          },
                          {
                            value: "PN2",
                            text: "PN2",
                          },
                        ]}
                        value={filters.nama_fase_penahanan}
                        onChange={(e) => {
                          setFilters({
                            ...filters,
                            nama_fase_penahanan: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col sm="12" md="3">
                      <Select
                        default="Tindak Pidana"
                        options={jenisTindakPidanaTRX}
                        value={filters.jenis_tindak_pidana}
                        onChange={(e) => {
                          setFilters({
                            ...filters,
                            jenis_tindak_pidana: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col sm="12" md="3">
                      <Select
                        default="Semua Status"
                        options={status_tahanan}
                        value={filters.status_tahanan}
                        onChange={(e) => {
                          setFilters({
                            ...filters,
                            status_tahanan: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col sm="12" md="3">
                      <Select
                        default="Instansi Gakkum"
                        value={filters.asal_tahanan}
                        options={[
                          { value: "POLRI", text: "POLRI" },
                          { value: "OJK", text: "OJK" },
                          { value: "Jaksa", text: "Jaksa" },
                          { value: "BNN", text: "BNN" },
                          { value: "PPNS", text: "PPNS" },
                          { value: "KPK", text: "KPK" },
                          { value: "Lapas", text: "Lapas" },
                        ]}
                        onChange={(e) => {
                          setFilters({
                            ...filters,
                            asal_tahanan: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col sm="12" md="3">
                      <Select
                        default="Rentang Usia"
                        options={[
                          { value: "1 - 16", text: "< 17" },
                          { value: "17 - 29", text: "17 - 29" },
                          { value: "30 - 39", text: "30 - 39" },
                          { value: "40 - 49", text: "40 - 49" },
                          { value: "50 - 59", text: "50 - 59" },
                          { value: "60 - 200", text: "> 60" },
                        ]}
                        onChange={(e) => {
                          const value = e.target.value;
                          setFilters({
                            ...filters,
                            umur_range: value,
                          });
                        }}
                      />
                    </Col>
                    <Col sm="12" md="3">
                      <Select
                        onChange={(e) => {
                          setFilters({
                            ...filters,
                            tipe_tahanan: e.target.value,
                          });
                        }}
                        value={filters.tipe_tahanan}
                        default="Tipe Tahanan"
                        options={[
                          {
                            value: "Dewasa",
                            text: "Dewasa",
                          },
                          { value: "Anak", text: "Anak" },
                        ]}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-5">
                <div className="d-flex justify-content-between align-items-center flex-row">
                  <div>
                    <label className="text-muted">
                      Jumlah : {`${pagination.total_items}`}
                    </label>
                  </div>
                  <div className="d-flex justify-content-end align-items-center flex-row gap-1">
                    <label className="text-muted">Items per page</label>
                    <Select
                      name="size"
                      options={[
                        { text: "10", value: 10 },
                        { text: "20", value: 20 },
                        { text: "50", value: 50 },
                        { text: "100", value: 100 },
                      ]}
                      value={payloadDebounced.size}
                      onChange={(e) => {
                        setPayloadDebounced({
                          ...payloadDebounced,
                          size: e.target.value,
                          page: 1,
                        });
                      }}
                      style={{
                        padding: "2px 4px",
                        fontSize: "0.875rem",
                        width: "60px",
                      }}
                    />
                    <div className="text-muted d-flex justify-content-end">
                      {`${
                        (payloadDebounced.page - 1) * payloadDebounced.size + 1
                      }–${Math.min(
                        payloadDebounced.page * payloadDebounced.size,
                        pagination.total_items
                      )} of ${pagination.total_items}`}
                    </div>
                  </div>
                </div>
              </Row>
              <div className="table-responsive">
                <Table
                  className="table dems-table-responsive"
                  style={{ tableLayout: "fixed" }}
                >
                  <thead>
                    <tr>
                      <th style={{ width: 80 }}>Lihat Detail</th>
                      <th style={{ width: 80 }}>#</th>
                      <th style={{ width: 130 }}>No. LP</th>
                      <th style={{ width: 130 }}>No. Sprin Han</th>
                      <th style={{ width: 90 }}>Tgl Sprin Han</th>
                      <th style={{ width: 150 }}>Perkara (TP)</th>
                      <th style={{ width: 150 }}>Nama</th>
                      <th style={{ width: 150 }}>No. KTP</th>
                      <th style={{ width: 100 }}>Jenis Kelamin</th>
                      <th style={{ width: 150 }}>TTL & Usia</th>
                      <th style={{ width: 250 }}>Pasal Dilanggar</th>
                      <th style={{ width: 100 }}>Satker</th>
                      <th style={{ width: 100 }}>Instansi Gakkum</th>
                      <th style={{ width: 90 }}>Masuk Rutan</th>
                      <th style={{ width: 95 }}>Akhir Penahanan</th>
                      <th style={{ width: 90 }}>Keluar Rutan</th>
                      <th style={{ width: 130 }}>Periode Penahanan</th>
                      <th style={{ width: 100 }}>Sisa Penahanan</th>
                      <th style={{ width: 110 }}>Status Tahanan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataFetching && (
                      <tr>
                        <td colSpan="4" style={{ textAlign: "center" }}>
                          <Spinner animation="border" variant="primary" />
                        </td>
                      </tr>
                    )}
                    {dataIsError &&
                      (dataError?.response?.data?.detail?.map((err, idx) => (
                        <tr key={idx}>
                          <td
                            colSpan="4"
                            style={{ textAlign: "center" }}
                            className="text-danger"
                          >
                            <span>
                              <b>{err.loc[1]} </b>
                              {err.msg}
                            </span>
                          </td>
                        </tr>
                      )) ?? (
                        <tr>
                          <td
                            colSpan="4"
                            style={{ textAlign: "center" }}
                            className="text-danger"
                          >
                            <span>
                              <b>Opps Error </b>Reload page or please wait until
                              we fix this issue
                            </span>
                          </td>
                        </tr>
                      ))}
                    {dataList.length && !dataFetching && !dataIsError
                      ? dataList.map((data, index) => {
                          const startPenahanan = new Date(data.awal_penahanan);
                          const endPenahanan = startPenahanan.setDate(
                            startPenahanan.getDate() +
                              parseInt(data.fase_penahanan)
                          );
                          const today = new Date();
                          let masaTahanan =
                            new Date(data.akhir_penahanan) - today;
                          masaTahanan = Math.ceil(
                            masaTahanan / (1000 * 60 * 60 * 24)
                          );
                          const rowNumber =
                            (payloadDebounced.page - 1) *
                              payloadDebounced.size +
                            index +
                            1;
                          const isTahananKeluar =
                            data.status_tahanan === "Dibantarkan" ||
                            data.status_tahanan === "Ditangguhkan" ||
                            data.status_tahanan === "Pengalihan" ||
                            data.status_tahanan === "SP3" ||
                            data.status_tahanan === "Tahap 2" ||
                            data.status_tahanan === "Meninggal Dunia" ||
                            data.status_tahanan === "Bebas Demi Hukum" ||
                            data.status_tahanan === "Rehabilitasi Narkoba";
                          return (
                            <tr key={index}>
                              <td
                                style={{
                                  backgroundColor: isTahananKeluar
                                    ? "pink"
                                    : "",
                                }}
                              >
                                <div className="d-flex align-items-center justify-content-start gap-2">
                                  <Link
                                    to={
                                      data.asal_tahanan === "Lapas"
                                        ? `/tahanan/lapas/form/${data.id}`
                                        : `/tahanan/form/${data.id}`
                                    }
                                  >
                                    <Badge className="btn bg-info" as="button">
                                      <i className="ri-eye-line"></i>
                                    </Badge>
                                  </Link>
                                  {!!data.asal_tahanan !== "Lapas" && (
                                    <Link to={"/tahanan/print/" + data.id}>
                                      <Badge
                                        className="btn bg-secondary"
                                        as="button"
                                      >
                                        <i className="ri-printer-line"></i>
                                      </Badge>
                                    </Link>
                                  )}
                                </div>
                              </td>
                              <td
                                style={{
                                  backgroundColor: isTahananKeluar
                                    ? "pink"
                                    : "",
                                }}
                              >
                                {rowNumber}
                              </td>
                              <td
                                style={{
                                  backgroundColor: isTahananKeluar
                                    ? "pink"
                                    : "",
                                }}
                              >
                                {data.no_laporan || "-"}
                              </td>
                              <td
                                style={{
                                  backgroundColor: isTahananKeluar
                                    ? "pink"
                                    : "",
                                }}
                              >
                                {data.no_sprin_penahanan || "-"}
                              </td>
                              <td
                                style={{
                                  backgroundColor: isTahananKeluar
                                    ? "pink"
                                    : "",
                                }}
                              >
                                {data.tanggal_sprin_penahanan
                                  ? moment(data.tanggal_sprin_penahanan).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "-"}
                              </td>
                              <td
                                style={{
                                  backgroundColor: isTahananKeluar
                                    ? "pink"
                                    : "",
                                }}
                              >
                                {data.perkara && data.perkara.length > 50
                                  ? `${data.perkara.slice(0, 50)} ...`
                                  : data.perkara || "-"}
                              </td>
                              <td
                                style={{
                                  backgroundColor: isTahananKeluar
                                    ? "pink"
                                    : "",
                                }}
                              >
                                {data.nama_tersangka || "-"}
                              </td>
                              <td
                                style={{
                                  backgroundColor: isTahananKeluar
                                    ? "pink"
                                    : "",
                                }}
                              >
                                {data.no_ktp || "-"}
                              </td>
                              <td
                                style={{
                                  backgroundColor: isTahananKeluar
                                    ? "pink"
                                    : "",
                                }}
                              >
                                {data.jenis_kelamin || "-"}
                              </td>
                              <td
                                style={{
                                  backgroundColor: isTahananKeluar
                                    ? "pink"
                                    : "",
                                }}
                              >
                                {data.tempat_lahir},{" "}
                                {data.tanggal_lahir
                                  ? moment(data.tanggal_lahir).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "-"}
                                <br />{" "}
                                <Badge className="me-1">{data.umur} Th</Badge>
                                <Badge>{data.tipe_tahanan}</Badge>
                              </td>
                              <td
                                style={{
                                  backgroundColor: isTahananKeluar
                                    ? "pink"
                                    : "",
                                }}
                              >
                                {data.pasal_pelanggaran || "-"}
                              </td>
                              <td
                                style={{
                                  backgroundColor: isTahananKeluar
                                    ? "pink"
                                    : "",
                                }}
                              >
                                {data.nama_satker || "-"}
                              </td>
                              <td
                                style={{
                                  backgroundColor: isTahananKeluar
                                    ? "pink"
                                    : "",
                                }}
                              >
                                {data.asal_tahanan || "-"}
                              </td>
                              <td
                                style={{
                                  backgroundColor: isTahananKeluar
                                    ? "pink"
                                    : "",
                                }}
                              >
                                {data.awal_penahanan
                                  ? moment(data.awal_penahanan).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "-"}
                              </td>
                              <td
                                style={{
                                  backgroundColor: isTahananKeluar
                                    ? "pink"
                                    : "",
                                }}
                              >
                                {data.riwayat_penahanan.length > 0 ? (
                                  data.riwayat_penahanan.map((x, index) => (
                                    <div key={index}>
                                      {x.akhir_penahanan
                                        ? moment(x.akhir_penahanan).format(
                                            "DD/MM/YYYY"
                                          )
                                        : "-"}
                                      <br />
                                    </div>
                                  ))
                                ) : (
                                  <span>-</span>
                                )}
                              </td>
                              <td
                                style={{
                                  backgroundColor: isTahananKeluar
                                    ? "pink"
                                    : "",
                                }}
                              >
                                {data.tanggal_keluar
                                  ? moment(data.tanggal_keluar).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "-"}
                              </td>
                              <td
                                style={{
                                  backgroundColor: isTahananKeluar
                                    ? "pink"
                                    : "",
                                }}
                              >
                                {data.riwayat_penahanan.length > 0 ? (
                                  data.riwayat_penahanan.map((x, index) => (
                                    <div key={index}>
                                      {x.fase_penahanan === "Penyidik"
                                        ? "20 Hari "
                                        : x.fase_penahanan === "Jaksa"
                                        ? "40 Hari "
                                        : x.fase_penahanan === "PN1"
                                        ? "30 Hari "
                                        : x.fase_penahanan === "PN2"
                                        ? "30 Hari "
                                        : "-"}{" "}
                                      ({x.fase_penahanan})
                                      <br />
                                    </div>
                                  ))
                                ) : (
                                  <span>-</span>
                                )}
                              </td>
                              <td
                                style={{
                                  backgroundColor: isTahananKeluar
                                    ? "pink"
                                    : "",
                                }}
                              >
                                {isTahananKeluar ? (
                                  <span>Selesai</span>
                                ) : masaTahanan <= 10 && masaTahanan > 5 ? (
                                  <Badge bg="warning">{masaTahanan} Hari</Badge>
                                ) : masaTahanan <= 5 && masaTahanan > 0 ? (
                                  <Badge bg="danger">{masaTahanan} Hari</Badge>
                                ) : masaTahanan < 0 ? (
                                  <Badge bg="danger">0 Hari</Badge>
                                ) : !masaTahanan ? (
                                  <span>-</span>
                                ) : (
                                  <span>{masaTahanan} Hari</span>
                                )}
                              </td>
                              <td
                                style={{
                                  backgroundColor: isTahananKeluar
                                    ? "pink"
                                    : "",
                                }}
                              >
                                {data.status_tahanan || "-"}
                              </td>
                            </tr>
                          );
                        })
                      : !dataFetching &&
                        !dataList.length &&
                        !dataIsError && (
                          <tr>
                            <td colSpan="4" style={{ textAlign: "center" }}>
                              <span>Tidak ada data tahanan!</span>
                            </td>
                          </tr>
                        )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
            <Card.Footer className="bg-white">
              <MyPagination
                currentPage={payloadDebounced.page}
                totalPages={pagination.total_pages}
                onPageChange={handlePagination}
              />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default List;
