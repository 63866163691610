import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Tabs,
  Tab,
  Card,
  Table,
  Placeholder,
  Badge,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  ChartDonut,
  ChartKapasitasRutan,
  ChartPie,
  ChartTahti,
  DashboardMap,
} from "./../module";
import * as am5 from "@amcharts/amcharts5";
import Select from "../../../components/inputs/Select";
import { Select2 } from "../../../components/Components";
import moment from "moment/moment";
import "moment/locale/id";
import { getNumbersFromString, kesatuan, months, years } from "../../../utils";
import {
  useGetDashboardTahanan,
  useGetDashboardTahananBulanan,
  useGetDashboardTahananMap,
  useGetPoldaList,
  useGetPoldaPolres,
  useGetSatkerPoldaList,
  useGetTahananBelumPerpanjang,
  useSatkerTahtiList,
} from "../../../utils/apis/query";
import State from "../../../libraries/State";

function Index({ data }) {
  const state = State.getState();
  const profileData = state.getItem("profileData", true);
  const [activeSort, setActiveSort] = useState("nama");
  const [namaSatkerPoldaFilter, setNamaSatkerPoldaFilter] = useState("");
  const [namaSatkerPolresFilter, setNamaSatkerPolresFilter] = useState("");
  const [namaSatkerPoldaPayloadFilter, setNamaSatkerPoldaPayloadFilter] = useState("");
  const [namaSatkerPolresPayloadFilter, setNamaSatkerPolresPayloadFilter] = useState("");
  const [bulanFilter, setBulanFilter] = useState("");
  const [tahunFilter, setTahunFilter] = useState("");

  // Map
  const [markersMap, setMarkerMap] = useState([]);
  const [dataMapMarker, setDataMapMarker] = useState(null);
  const [mapRendered, setMapRendered] = useState(false);

  // Chart Tahti & Donut Chart & Rutan
  const [fetchedOnce, setFetchedOnce] = useState(true);
  const [dataChartTahti, setDataChartTahti] = useState([]);
  const [dataChartRutan, setDataChartRutan] = useState([]);
  const [tahananTitipan, setTahananTitipan] = useState([]);
  const [jtp, setJtp] = useState([]);
  const [fpp, setFpp] = useState([]);
  const [dataKategoriTahanan, setDataKategoriTahanan] = useState([]);
  const [tahananUsiaGender, setTahananUsiaGender] = useState([]);
  const [tahananAnak, setTahananAnak] = useState([]);
  const [rutanLapas, setRutanLapas] = useState([]);
  const [statusTahanan, setStatusTahanan] = useState([]);

  const satker = profileData?.satker?.nama_satker ?? ''
  const isPolres = /polres/gi.test(satker)
  const isPolda = /polda/gi.test(satker)
  const isBareskrim = /bareskrim/gi.test(satker)
  const {
    data: masterSatkerList
  } = useGetSatkerPoldaList()

  const {
    data: dataSatker
  } = useGetPoldaPolres(
    {
      polda: namaSatkerPoldaFilter,
      polres:
        namaSatkerPolresFilter === "satker_sendiri"
          ? ""
          : namaSatkerPolresFilter,
    },
    {
      enabled: !!namaSatkerPoldaPayloadFilter || !!namaSatkerPolresFilter,
    }
  );

  const optionPolda = [{label: 'SEMUA POLDA', value: 'semua_polda'},...dataSatker.polda.map((o) => ({label: o.value, value: o.value}))]
  const optionPolres= [{label: 'SEMUA', value: 'semua'}, {label: 'SATKER SENDIRI', value: 'satker_sendiri'},...dataSatker.polres.map((o) => ({label: o.value, value: o.value}))]

  const {
    data: dataTahanan,
    isFetching: isDataTahananFethcing,
    isLoading: isDataTahananLoading,
    isError: isDataTahananError,
    error: dataTahananError,
    refetch: refetchDataTahanan,
  } = useGetDashboardTahanan(
    {
      nama_satker_polda: namaSatkerPoldaPayloadFilter,
      nama_satker_polres: namaSatkerPolresPayloadFilter,
      bulan: bulanFilter,
      tahun: tahunFilter,
    },
    {
      enabled: (!!namaSatkerPoldaPayloadFilter || !!namaSatkerPolresPayloadFilter) && fetchedOnce
    }
  );

  const {
    data: dataTahananBulanan,
    isFetching: isDataTahananBulananFethcing,
    isLoading: isDataTahananBulananLoading,
    isError: isDataTahananBulananError,
    error: dataTahananBulananError,
    refetch: refetchDataTahananBulanan,
  } = useGetDashboardTahananBulanan(
    {
      nama_satker_polda: namaSatkerPoldaPayloadFilter,
      nama_satker_polres: namaSatkerPolresPayloadFilter,
      bulan: bulanFilter,
      tahun: tahunFilter,
    },
    {
      enabled: (!!namaSatkerPoldaPayloadFilter || !!namaSatkerPolresPayloadFilter) && fetchedOnce
    }
  );

  const {
    data: dataTahananMap,
    isFetching: isDatatahananMapFetching,
    isLoading: isDatatahananMapLoading,
    refetch: refetchDataTahananMap,
  } = useGetDashboardTahananMap(
    {
      nama_satker_polda: namaSatkerPoldaPayloadFilter,
      nama_satker_polres: namaSatkerPolresPayloadFilter,
      bulan: bulanFilter,
      tahun: tahunFilter,
    },
    {
      enabled: (!!namaSatkerPoldaPayloadFilter || !!namaSatkerPolresPayloadFilter) && fetchedOnce
    }
  );
  const {
    data: dataPerpanjanganTahanan,
    isFetching: isDataPerpanjanganTahananFetching,
    isLoading: isDataPerpanjanganTahananLoading,
    isError: isDataPerpanjanganTahananError,
    error: dataPerpanjanganTahananError,
    refetch: refetchDataPerpanjanganTahanan,
  } = useGetTahananBelumPerpanjang(
    {
      nama_satker_polda: namaSatkerPoldaPayloadFilter,
      nama_satker_polres: namaSatkerPolresPayloadFilter,
      bulan: bulanFilter,
      tahun: tahunFilter,
    },
    {
      enabled: (!!namaSatkerPoldaPayloadFilter || !!namaSatkerPolresPayloadFilter) && fetchedOnce
    }
  );

  // CHART DONUT TAHTI USE EFFECT
  useEffect(() => {
    if (dataTahanan && masterSatkerList.length) {
      const generateDataRutanChart = (dataTahanan) => {
        let kapasitas_rutan = (dataTahanan?.kapasitas_rutan ?? []).sort((a, b) => {
          const isPoldaA = a.nama_satker.toUpperCase().includes("POLDA") ? 1 : 0;
          const isPoldaB = b.nama_satker.toUpperCase().includes("POLDA") ? 1 : 0;

          if (isPoldaB !== isPoldaA) {
            return isPoldaB - isPoldaA;
          }

          return a.nama_satker.localeCompare(b.nama_satker);
        });
        let data = [];
  
        for (let i = 0; i < kapasitas_rutan.length; i++) {
          let matchedData = kapasitas_rutan[i]
          let persentase = 0
          if (matchedData.kapasitas_rutan_ideal !== 0) parseFloat(((matchedData.kapasitas_rutan_aktual / matchedData.kapasitas_rutan_ideal) * 100).toFixed(2))
          data.push({
            category: matchedData.nama_satker,
            categoryTooltip: matchedData.nama_satker,
            ideal: matchedData.kapasitas_rutan_ideal,
            jumlah: matchedData.kapasitas_rutan_aktual,
            presentase: persentase,
          });
        }

        return data;
      };

      setDataChartRutan(generateDataRutanChart(dataTahanan));

      const sumTahananAktual =
        dataTahanan?.tahanan_aktual?.reduce((acc, obj, index) => {
          // Untuk object pertama, salin semua field non-number
          if (index === 0) {
            Object.keys(obj).forEach((key) => {
              if (typeof obj[key] !== "number") {
                acc[key] = obj[key];
              }
            });
          }

          // Tambahkan semua field number
          Object.keys(obj).forEach((key) => {
            if (typeof obj[key] === "number") {
              acc[key] = (acc[key] || 0) + obj[key];
            }
          });

          return acc;
        }, {}) ?? [];

      setTahananTitipan([
        {
          category: "Jaksa",
          value: sumTahananAktual?.tahanan_asal_jaksa ?? 0,
          color: am5.color("#63a8b8"),
        },
        {
          category: "KPK",
          value: sumTahananAktual?.tahanan_asal_kpk ?? 0,
          color: am5.color("#01b5b4"),
        },
        {
          category: "BNN",
          value: sumTahananAktual?.tahanan_asal_bnn ?? 0,
          color: am5.color("#b1dede"),
        },
        {
          category: "PPNS",
          value: sumTahananAktual?.tahanan_asal_ppns ?? 0,
          color: am5.color("#a49bca"),
        },
      ]);
      setJtp([
        {
          category: "Umum",
          value: sumTahananAktual?.tahanan_pidana_umum ?? 0,
          color: am5.color("#f16c9b"),
        },
        {
          category: "Korupsi",
          value: sumTahananAktual?.tahanan_pidana_korupsi ?? 0,
          color: am5.color("#b94795"),
        },
        {
          category: "Narkoba",
          value: sumTahananAktual?.tahanan_pidana_narkoba ?? 0,
          color: am5.color("#355798"),
        },
        {
          category: "Tertentu",
          value: sumTahananAktual?.tahanan_pidana_tertentu ?? 0,
          color: am5.color("#1a84c8"),
        },
        {
          category: "Siber",
          value: sumTahananAktual?.tahanan_pidana_siber ?? 0,
          color: am5.color("#a49bca"),
        },
        {
          category: "Eksus",
          value: sumTahananAktual?.tahanan_pidana_eksus ?? 0,
          color: am5.color("#b1dede"),
        },
      ]);
      setFpp([
        {
          category: "Penyidik",
          value: sumTahananAktual?.tahanan_fase_penyidik ?? 0,
          color: am5.color("#355798"),
        },
        {
          category: "Jaksa",
          value: sumTahananAktual?.tahanan_fase_jaksa ?? 0,
          color: am5.color("#1a84c8"),
        },
        {
          category: "PN1",
          value: sumTahananAktual?.tahanan_fase_pn1 ?? 0,
          color: am5.color("#a49bca"),
        },
        {
          category: "PN2",
          value: sumTahananAktual?.tahanan_fase_pn2 ?? 0,
          color: am5.color("#b1dede"),
        },
      ]);
      setTahananUsiaGender([
        {
          category: "Dewasa Laki-Laki",
          value: sumTahananAktual?.tahanan_dewasa_laki ?? 0,
          color: am5.color("#1a84c8"),
        },
        {
          category: "Dewasa Perempuan",
          value: sumTahananAktual?.tahanan_dewasa_perempuan ?? 0,
          color: am5.color("#f16c9b"),
        },
        {
          category: "Anak Laki-Laki",
          value: sumTahananAktual?.tahanan_anak_laki ?? 0,
          color: am5.color("#355798"),
        },
        {
          category: "Anak Perempuan",
          value: sumTahananAktual?.tahanan_anak_perempuan ?? 0,
          color: am5.color("#b94795"),
        },
      ]);
      setTahananAnak([
        {
          category: "Laki-Laki",
          value: sumTahananAktual?.tahanan_anak_laki ?? 0,
          color: am5.color("#fdd294"),
        },
        {
          category: "Perempuan",
          value: sumTahananAktual?.tahanan_anak_perempuan ?? 0,
          color: am5.color("#ee9a42"),
        },
      ]);
      setStatusTahanan([
        {
          category: "Penangguhan",
          value: sumTahananAktual?.tahanan_status_ditangguhkan ?? 0,
          color: am5.color("#01b5b4"),
        },
        {
          category: "Dibantarkan",
          value: sumTahananAktual?.tahanan_status_dibantarkan ?? 0,
          color: am5.color("#63a8b8"),
        },
        {
          category: "Pengalihan Jenis Thnan",
          categoryShort: "PJT",
          value: sumTahananAktual?.tahanan_status_pengalihan ?? 0,
          color: am5.color("#b1dede"),
        },
        {
          category: "Tahanan Titipan",
          categoryShort: "Titipan",
          value: sumTahananAktual?.tahanan_status_titipan ?? 10,
          color: am5.color("#a49bca"),
        },
        {
          category: "Tahanan Baru",
          categoryShort: "Baru",
          value: sumTahananAktual?.tahanan_status_di ?? 10,
          color: am5.color("#1a84c8"),
        },
      ]);
      setDataKategoriTahanan([
        {
          category: "Jaksa",
          value: sumTahananAktual?.tahanan_asal_jaksa ?? 0,
        },
        {
          category: "OJK",
          value: sumTahananAktual?.tahanan_asal_ojk ?? 0,
        },
        {
          category: "BNN",
          value: sumTahananAktual?.tahanan_asal_bnn ?? 0,
        },
        {
          category: "PPNS",
          value: sumTahananAktual?.tahanan_asal_ppns ?? 0,
        },
        {
          category: "KPK",
          value: sumTahananAktual?.tahanan_asal_kpk ?? 0,
        },
        {
          category: "POLRI",
          value: sumTahananAktual?.tahanan_asal_polri ?? 0,
        },
        {
          category: "Lapas",
          value: sumTahananAktual?.tahanan_asal_lapas ?? 0,
        },
      ]);
    }
    
  }, [dataTahanan, masterSatkerList])

  // CHART TAHTI USE EFFECT
  useEffect(() => {
    if (dataTahananBulanan && bulanFilter && tahunFilter) {
      const dataTahanan = dataTahananBulanan;
      const generateDataForChartTahti = (dataTahanan) => {
        const monthsMap = {
          Januari: 1,
          Februari: 2,
          Maret: 3,
          April: 4,
          Mei: 5,
          Juni: 6,
          Juli: 7,
          Agustus: 8,
          September: 9,
          Oktober: 10,
          November: 11,
          Desember: 12,
        };
        const monthMapping = {
          Januari: "January",
          Februari: "February",
          Maret: "March",
          April: "April",
          Mei: "May",
          Juni: "June",
          Juli: "July",
          Agustus: "August",
          September: "September",
          Oktober: "October",
          November: "November",
          Desember: "December",
        };

        const data = [];
        let tahanan_perbulan = dataTahanan || [];
        const tahun = tahunFilter;
        const bulan = bulanFilter;
        const daysInMonth = moment(
          `${tahun}-${monthsMap[bulan]}`
        ).daysInMonth();

        for (let day = 1; day <= daysInMonth; day++) {
          const englishMonth = monthMapping[bulan];
          const currentDate = moment(
            `${tahun}-${englishMonth}-${day}`,
            "YYYY-MMMM-D"
          ).format("YYYY-MM-DD");
          const matchedData = tahanan_perbulan.find(
            (item) => item.tanggal === currentDate
          );
          if (matchedData) {
            data.push({
              date: day + "-" + bulan + "-" + tahun,
              category: day,
              masuk: matchedData.tahanan_masuk,
              keluar: -matchedData.tahanan_keluar,
              tahananKeluarJumlah: matchedData.tahanan_keluar,
              jumlahAktual: matchedData.selisih_jumlah_tahanan_harian,
              jumlah: matchedData.tahanan_aktual,
            });
          } else {
            data.push({
              date: day + "-" + bulan + "-" + tahun,
              category: day,
              masuk: 0,
              keluar: 0,
              tahananKeluarJumlah: 0,
              jumlah: 0,
            });
          }
        }

        return data;
      };

      setDataChartTahti(generateDataForChartTahti(dataTahanan));
    }
  }, [dataTahananBulanan, bulanFilter, tahunFilter]);

  // MAP USE EFFECT
  useEffect(() => {
    if (dataTahananMap.length) {
      setMapRendered(false);
      const markers = dataTahananMap.map((item) => ({
        latLng: item.latLng,
        name: item.nama_satker,
        tanggal_rekap: item.tanggal_rekap,
      }));

      const dataMarkers = dataTahananMap?.reduce((acc, item) => {
        acc[item.nama_satker] = {
          "Jumlah Tahanan": `${item.total_tahanan} Orang`,
          "Tahanan Lapas": `${item.tahanan_asal_lapas} Orang`,
          "Tahanan Laki-Laki": `${item.tahanan_laki} Orang`,
          "Tahanan Perempuan": `${item.tahanan_perempuan} Orang`,
          "Tahanan Anak": `${
            item.tahanan_anak_laki + item.tahanan_anak_perempuan
          } Orang`,
          "Tahanan OJK": `${item.tahanan_asal_ojk} Orang`,
          "Tahanan Jaksa": `${item.tahanan_asal_jaksa} Orang`,
          "Tahanan BNN": `${item.tahanan_asal_bnn} Orang`,
          "Tahanan PPNS": `${item.tahanan_asal_ppns} Orang`,
          "Tahanan KPK": `${item.tahanan_asal_kpk} Orang`,
          "Tahanan POLRI": `${item.tahanan_asal_polri} Orang`,
        };
        return acc;
      }, {}) ?? []
      
      setMarkerMap(markers);
      setDataMapMarker(dataMarkers);
    }
  }, [dataTahananMap]);

  useEffect(() => {
    if (markersMap && dataMapMarker) {
      setMapRendered(true);
    }
  }, [markersMap, dataMapMarker]);

  // BAGIAN KRUSIAL SET FILTER A I U E O
  useEffect(() => {
    if (profileData) {
      moment.locale();
      const satker = profileData?.satker?.nama_satker ?? "";
      const username = profileData?.user?.username ?? "";
      const isPolres = /polres/gi.test(satker);
      const isBareskrim = /bareskrim/gi.test(satker);
      if (isBareskrim) {
        if (['kabareskrim', 'kapusiknas'].includes(username)) {
          setNamaSatkerPoldaFilter('semua_polda');
          setNamaSatkerPoldaPayloadFilter('semua_polda');
        } else {
          setNamaSatkerPoldaFilter(satker);
          setNamaSatkerPoldaPayloadFilter(satker);
        }
        setNamaSatkerPolresFilter("satker_sendiri");
        setBulanFilter(moment().format("MMMM"));
        setTahunFilter(moment().format("YYYY"));
      } else {
        if (isPolres) {
          let namaPolda = profileData?.profile?.nama_satker_parent_polres ?? "";
          setNamaSatkerPoldaFilter(namaPolda);
          setNamaSatkerPoldaPayloadFilter(namaPolda);

          setBulanFilter(moment().format("MMMM"));
          setTahunFilter(moment().format("YYYY"));
        } else {
          setNamaSatkerPoldaFilter(satker);
          setNamaSatkerPoldaPayloadFilter(satker);
          setNamaSatkerPolresFilter("satker_sendiri");
          setBulanFilter(moment().format("MMMM"));
          setTahunFilter(moment().format("YYYY"));
        }
      }
    }
  }, [profileData]);

  useEffect(() => {
    if (profileData) {
      const satker = profileData?.satker?.nama_satker ?? "";
      const isPolres = /polres/gi.test(satker);
      const isBareskrim = /bareskrim/gi.test(satker);
      const isfilterPoldaAll = namaSatkerPoldaFilter === 'semua_polda'

      if (isBareskrim || isfilterPoldaAll) {
        const filterBareskrim = /bareskrim/gi.test(namaSatkerPoldaFilter);
        if (!namaSatkerPolresFilter) setNamaSatkerPolresPayloadFilter('satker_sendiri')
        if (isfilterPoldaAll && namaSatkerPolresPayloadFilter === 'satker_sendiri') {
          setNamaSatkerPoldaPayloadFilter(dataSatker.polda.map((o) => o.value).join(","))
        }
        if (isfilterPoldaAll && namaSatkerPolresPayloadFilter === 'semua') {
          setNamaSatkerPoldaPayloadFilter('semua_polda')
        } 
        if (namaSatkerPolresFilter === 'semua') {
          let payload = filterBareskrim  ? '' : dataSatker.polres.map((o) => o.value).join(",");
          setNamaSatkerPolresPayloadFilter(payload);
        }
        
      } else {
        if (isPolres) {
          let namaPolres = profileData?.profile?.nama_satker_tahti ?? "";
          setNamaSatkerPolresFilter(namaPolres);
          setNamaSatkerPolresPayloadFilter(namaPolres);
        } else {
          if (!namaSatkerPolresFilter) setNamaSatkerPolresPayloadFilter('satker_sendiri')
          if (namaSatkerPolresFilter === 'semua') {
            setNamaSatkerPolresPayloadFilter(
              dataSatker.polres.map((o) => o.value).join(",")
            )
          }
        }
      }
    }
  }, [profileData, dataSatker, namaSatkerPolresFilter, namaSatkerPoldaFilter])

  useEffect(() => {
    if (isDataTahananFethcing && isDatatahananMapFetching && isDataPerpanjanganTahananFetching && isDataTahananBulananFethcing) {
      if (fetchedOnce) setFetchedOnce(false)
    }
    
  }, [
    isDataTahananFethcing,
    isDatatahananMapFetching,
    isDataPerpanjanganTahananFetching,
    isDataTahananBulananFethcing,
    fetchedOnce
  ])
  return (
    <React.Fragment>
      {/* <div className="mb-3">
        <h4 className="main-title mb-0">Dashboard Tahanan</h4>
      </div> */}
      <Card className="card border-0 shadow bg-white mb-2">
        <Card.Body>
          <h4 className="fw-bold mb-0">DASHBOARD TAHANAN</h4>
        </Card.Body>
      </Card>
      <Row>
        <Col sm="12">
          <Card className="card border-0 shadow bg-white">
            <Card.Body>
              <Row className="g-2">
                <Col sm="12">
                  <Row className="mt-2 mb-2 g-2">
                    <Col sm="12">
                      <Card.Text
                        as="label"
                        style={{ paddingLeft: 0 }}
                        className="fs-5 fw-bold mb-2"
                      >
                        Jumlah Tahanan Bareskrim Polri Dan Polda Jajaran Bulan{" "}
                        {bulanFilter} Tahun {tahunFilter}
                      </Card.Text>
                      <Row style={{ paddingLeft: 0 }}>
                        <Col sm="3">
                          <Select2
                            defaultValue={"Semua Kesatuan"}
                            options={optionPolda}
                            value={namaSatkerPoldaFilter}
                            name="nama_satker_polda"
                            placeholder="Semua Kesatuan"
                            required
                            isClearable
                            isSearchable
                            disabled={!isBareskrim || isPolres}
                            onChange={(e) => {
                              setNamaSatkerPoldaFilter(e.value)
                              setNamaSatkerPoldaPayloadFilter(e.value)
                              setNamaSatkerPolresFilter((prev) => ('satker_sendiri'))
                              setNamaSatkerPolresPayloadFilter((prev) => ('satker_sendiri'))
                            }}
                          />
                        </Col>
                        <Col sm="3">
                          <Select2
                            defaultValue={"Semua Kesatuan"}
                            options={optionPolres}
                            value={namaSatkerPolresFilter}
                            name="nama_satker_polres"
                            placeholder="SEMUA"
                            disabled={isPolres}
                            required
                            isClearable
                            isSearchable
                            onChange={(e) => {
                              setNamaSatkerPolresFilter(e.value);
                              setNamaSatkerPolresPayloadFilter(e.value);
                            }}
                          />
                        </Col>
                        <Col sm="2">
                          <Select
                            default="Select Bulan"
                            value={bulanFilter}
                            name="bulan"
                            options={months}
                            onChange={(e) => {
                              const val = e.target.value;
                              setBulanFilter(val);
                            }}
                          />
                        </Col>
                        <Col sm="2">
                          <Select
                            default="Select Tahun"
                            value={tahunFilter}
                            name="tahun"
                            options={years}
                            onChange={(e) => {
                              const val = e.target.value;
                              setTahunFilter(val);
                            }}
                          />
                        </Col>
                        <Col sm="2">
                          <Button
                            variant="danger"
                            size="small"
                            onClick={() => {
                              refetchDataTahanan()
                              refetchDataTahananBulanan()
                              refetchDataTahananMap()
                              refetchDataPerpanjanganTahanan()
                            }}
                          >
                            Filter
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col sm="12">
                  <div className="w-100">
                    <Card className="card bg-white">
                      <Card.Body>
                        <DashboardMap
                          mapRender={mapRendered}
                          markers={markersMap}
                          dataMapMarker={dataMapMarker}
                        />
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
                <Col sm="12">
                  {/* <Tabs
                    className="dems-tabs nav-line py-1"
                    defaultActiveKey="insight"
                  >
                    <Tab eventKey="statistik" title=""></Tab>
                    <Tab eventKey="insight" title="">
                      
                    </Tab>
                  </Tabs> */}
                  <Row className="mb-2 g-2">
                    <Col sm="12">
                      <Row className="g-2">
                        {/* <Col sm="12" md="12">
                          {isDataTahananBulananFethcing && (
                            <div className="d-flex justify-content-center align-items-center">
                              <Placeholder
                                as="p"
                                animation="wave"
                                className="m-0 w-100 d-flex flex-column gap-2"
                              >
                                <Placeholder sm="12" style={{ height: 300 }} />
                              </Placeholder>
                            </div>
                          )}
                          {!isDataTahananBulananFethcing &&
                            isDataTahananBulananError && (
                              <Card className="card-one">
                                <Card.Body className="d-flex flex-col justify-content-center items-center bg-secosndary p-5">
                                  {(
                                    dataTahananBulananError?.response?.data
                                      ?.detail ?? []
                                  ).length ? (
                                    dataTahananBulananError?.response?.data
                                      ?.detail[0]?.loc ?? null ? (
                                      dataTahananBulananError?.response?.data?.detail?.map(
                                        (errorDetail, idx) => (
                                          <span
                                            className="text-danger fw-bold"
                                            key={idx}
                                          >
                                            {errorDetail.loc[1]} -{" "}
                                            {errorDetail.msg}
                                          </span>
                                        )
                                      ) ?? ''
                                    ) : 
                                    (
                                      <span className="fw-bold">
                                        Statistik Tahanan Bulanan - Tidak ada data untuk ditampilkan
                                      </span>
                                    )
                                  ) : (
                                    <span className="text-danger fw-bold">
                                      {dataTahananBulananError?.response?.data
                                        ?.message ??
                                        "Jaringan Bermasalah - Tidak dapat memuat"}
                                    </span>
                                  )}
                                </Card.Body>
                              </Card>
                            )}
                          {!isDataTahananBulananFethcing &&
                            !isDataTahananBulananError && (
                              <ChartTahti dataSeries={dataChartTahti} />
                            )}
                        </Col> */}
                        <Col sm="12">
                          <Card className="card-one bg-white">
                            <Card.Body className="d-flex flex-column gap-2">
                              <Card.Text
                                as="label"
                                style={{ paddingLeft: 0 }}
                                className="fs-5 fw-bold"
                              >
                                Tahanan Belum Diperpanjang
                              </Card.Text>
                              {isDataPerpanjanganTahananFetching && (
                                <div className="d-flex justify-content-center align-items-center">
                                  <Placeholder
                                    as="p"
                                    animation="wave"
                                    className="m-0 w-100 d-flex flex-column gap-2"
                                  >
                                    <Placeholder
                                      sm="12"
                                      style={{ height: 200 }}
                                    />
                                  </Placeholder>
                                </div>
                              )}
                              {isDataPerpanjanganTahananError &&
                                !isDataPerpanjanganTahananFetching && (
                                  <Card className="card-one">
                                    <Card.Body className="d-flex flex-col justify-content-center items-center bg-secosndary p-5">
                                      <span className="text-danger fw-bold">
                                        {dataPerpanjanganTahananError?.response
                                          ?.data?.message ??
                                          "Jaringan Bermasalah - Tidak dapat memuat"}
                                      </span>
                                    </Card.Body>
                                  </Card>
                                )}
                              {!isDataPerpanjanganTahananError &&
                                !isDataPerpanjanganTahananFetching && (
                                  <div
                                    className="d-flex overflow-auto"
                                    style={{ maxHeight: 400 }}
                                  >
                                    <Table className="table dems-table table-xl sticky">
                                      <thead>
                                        <tr>
                                          <th>Aksi</th>
                                          <th
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              setActiveSort("nama_tersangka")
                                            }
                                          >
                                            <i
                                              style={{ marginRight: 5 }}
                                              className={
                                                activeSort === "nama_tersangka"
                                                  ? "ri-sort-alphabet-asc"
                                                  : "ri-sort-alphabet-desc"
                                              }
                                            ></i>
                                            Nama
                                          </th>
                                          <th
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              setActiveSort("nama_satker")
                                            }
                                          >
                                            <i
                                              style={{ marginRight: 5 }}
                                              className={
                                                activeSort === "nama_satker"
                                                  ? "ri-sort-alphabet-asc"
                                                  : "ri-sort-alphabet-desc"
                                              }
                                            ></i>
                                            Satuan Kerja
                                          </th>
                                          <th
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              setActiveSort("periode_penahanan")
                                            }
                                          >
                                            <i
                                              style={{ marginRight: 5 }}
                                              className={
                                                activeSort ===
                                                "periode_penahanan"
                                                  ? "ri-sort-alphabet-asc"
                                                  : "ri-sort-alphabet-desc"
                                              }
                                            ></i>
                                            Periode Penahanan
                                          </th>
                                          <th
                                            style={{ cursor: "pointer", width: 300}}
                                            onClick={() =>
                                              setActiveSort("penyidik")
                                            }
                                          >
                                            <i
                                              style={{ marginRight: 5 }}
                                              className={
                                                activeSort === "penyidik"
                                                  ? "ri-sort-alphabet-asc"
                                                  : "ri-sort-alphabet-desc"
                                              }
                                            ></i>
                                            Nama Penyidik
                                          </th>
                                          <th
                                            style={{ cursor: "pointer", width: 0 }}
                                            onClick={() =>
                                              setActiveSort("akhir_penahanan")
                                            }
                                          >
                                            <i
                                              style={{ marginRight: 5 }}
                                              className={
                                                activeSort === "akhir_penahanan"
                                                  ? "ri-sort-alphabet-asc"
                                                  : "ri-sort-alphabet-desc"
                                              }
                                            ></i>
                                            Akhir Penahanan
                                          </th>
                                          <th
                                            style={{ cursor: "pointer", width: 120 }}
                                            onClick={() =>
                                              setActiveSort("sisa_penahanan")
                                            }
                                          >
                                            <i
                                              style={{ marginRight: 5 }}
                                              className={
                                                activeSort === "sisa_penahanan"
                                                  ? "ri-sort-alphabet-asc"
                                                  : "ri-sort-alphabet-desc"
                                              }
                                            ></i>
                                            Sisa Masa Tahanan
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {dataPerpanjanganTahanan.length &&
                                        !isDataPerpanjanganTahananFetching &&
                                        !isDataPerpanjanganTahananError ? (
                                          dataPerpanjanganTahanan
                                            .sort(
                                              (a, b) =>
                                                a[activeSort]?.localeCompare(
                                                  b[activeSort]
                                                ) ?? ""
                                            )
                                            .map((data, index) => (
                                              <tr key={index}>
                                                <td>
                                                  <div className="d-flex align-items-center justify-content-start gap-2">
                                                    <Link
                                                      to={
                                                        data.asal_tahanan ===
                                                        "Lapas"
                                                          ? `/tahanan/lapas/form/${data.id}`
                                                          : `/tahanan/form/${data.id}`
                                                      }
                                                    >
                                                      <Badge
                                                        className="btn bg-info"
                                                        as="button"
                                                      >
                                                        <i className="ri-eye-line"></i>
                                                      </Badge>
                                                    </Link>
                                                  </div>
                                                </td>
                                                <td>{data.nama_tersangka}</td>
                                                <td>{data.nama_satker}</td>
                                                <td>
                                                  {data.periode_penahanan}
                                                </td>
                                                <td>{data.penyidik}</td>
                                                <td>{data.akhir_penahanan}</td>
                                                <td>
                                                  <Badge
                                                    bg={
                                                      getNumbersFromString(
                                                        data.sisa_penahanan
                                                      ).toString() <= 7
                                                        ? "danger"
                                                        : getNumbersFromString(
                                                            data.sisa_penahanan
                                                          ).toString() >= 7 &&
                                                          getNumbersFromString(
                                                            data.sisa_penahanan
                                                          ).toString() <= 10
                                                        ? "warning"
                                                        : "light"
                                                    }
                                                  >
                                                    {getNumbersFromString(
                                                      data.sisa_penahanan
                                                    ).toString() < 0
                                                      ? "0 Hari"
                                                      : data.sisa_penahanan}
                                                  </Badge>
                                                </td>
                                              </tr>
                                            ))
                                        ) : (
                                          <tr>
                                            <td
                                              colSpan="7"
                                              style={{ textAlign: "center" }}
                                            >
                                              <span>
                                                Tidak ada untuk ditampilkan !
                                              </span>
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                )}
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col sm="6">
                          <ChartDonut
                            title="Instansi Penegak Hukum"
                            data={dataKategoriTahanan}
                          />
                        </Col>
                        <Col sm="6">
                          <ChartPie title="Fase Periode Penahanan" data={fpp} />
                        </Col>
                        <Col sm="6">
                          <ChartPie title="Jenis Tindak Pidana" data={jtp} />
                        </Col>
                        <Col sm="6">
                          <ChartPie
                            title="Kelompok Usia"
                            data={tahananUsiaGender}
                          />
                        </Col>
                        {/* <Col sm="6">
                          <ChartPie title="Kelom" data={tahananAnak} />
                        </Col> */}
                        {/* <Col sm="6">
                          <ChartPie
                            title="Status Tahanan"
                            data={statusTahanan}
                          />
                        </Col> */}

                        <Col sm="12">
                          <ChartKapasitasRutan
                            title="Sebaran Jumlah Tahanan"
                            dataSeries={dataChartRutan}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Index;
