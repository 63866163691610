import React, { useCallback, useEffect, useState } from 'react'
import { Alert, Badge, Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { IconPicker, Input, Select } from '../../../../components/Components'
import { useGetMenuDetail, useGetMenuParentList, usePostMenus, usePutMenus } from '../../../../utils/apis/query'

function FormLayout() {
  const [isEdit, setIsEdit] = useState(false)
  const [modalIcon, setModalIcon] = useState(false)
  const {id} = useParams()
  const [groupList, setGroupList] = useState([
    {
      text: "DASHBOARD",
      value: "DASHBOARD",
    },
    {
      text: "BERKAS",
      value: "BERKAS",
    },
    {
      text: "TAHTI",
      value: "TAHTI",
    },
    {
      text: "LAPORAN",
      value: "LAPORAN",
    },
    {
      text: "PENGGUNA",
      value: "PENGGUNA",
    },
    {
      text: "MASTERS",
      value: "MASTERS",
    },
    {
      text: "KONFIGURASI",
      value: "KONFIGURASI",
    },
  ])
  const navigate = useNavigate()

  const [payload, setPayload] = useState({
    nama_parent_menu: '',
    group: '',
    nama_menu: '',
    nomor_menu: '',
    deskripsi: '',
    path: '',
    icon: '',
    type: 'Parent',
    jenis: 'Label',
  })

  const onSuccess = () => {
    setTimeout(() => {
      navigate('/menus')
    }, 1500)
  }
  const mutateAdd = usePostMenus(onSuccess)
  const mutateEdit = usePutMenus(onSuccess)
  const {
    data: detailData,
    isFetching: detailIsFetching,
    isError: detailIsError,
    error: detailError
  } = useGetMenuDetail(id, {enabled: !!id})

  const {
    data: menuParentList
  } = useGetMenuParentList()
  const menuParentOptions = menuParentList.map(e => ({text: e.nama_menu, value: e.nama_menu}))
  // Fungsi transformasi untuk mengelompokkan opsi
  const transformToSelectOptions = (data) => {
    const groupedOptions = {};

    data.forEach((item) => {
      const groupName = item.group;

      if (groupedOptions[groupName]) {
        groupedOptions[groupName].options.push({
          text: item.nama_menu,
          value: item.group
        });
      } else {
        groupedOptions[groupName] = {
          label: groupName,
          options: [
            {
              text: item.nama_menu,
              value: item.group
            }
          ]
        };
      }
    });
    return Object.values(groupedOptions);
  };

  // let menuParentOptions = transformToSelectOptions(menuParentList)

  const handleChange = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value ?? ""; 
    if (name === "type") {
      setPayload({ ...payload, [name]: value, jenis: "Non Label" });
    } else {
      setPayload({ ...payload, [name]: value });
    }
  };
  const selectIcon = (e) => {
    setPayload({...payload, icon: e})
    setModalIcon(false)
  }

  const handleSubmit = () => {
    if (isEdit) {
      let formData = new FormData();
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });
      mutateEdit.mutate({id, payload: formData})
    } else {
      let formData = new FormData();
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });
      mutateAdd.mutate(formData)
    }
  }

  useEffect(() => {
    if (detailData && payload) {
      setPayload({
        ...payload,
        nama_parent_menu: detailData.nama_parent,
        group: detailData.group,
        nama_menu: detailData.nama_menu,
        nomor_menu: detailData.nomor_menu,
        deskripsi: detailData.deskripsi,
        path: detailData.path,
        icon: detailData.icon,
        type: detailData.type,
        jenis: detailData.jenis,
      })
      setIsEdit(true)
    }
  }, [detailData])

  useEffect(() => {
    return () => {
      setPayload({
        nama_parent_menu: '',
        group: '',
        nama_menu: '',
        nomor_menu: '',
        deskripsi: '',
        path: '',
        icon: '',
        type: 'Parent',
        jenis: 'Label',
      })
    }
  }, [])
  return (
    <React.Fragment>
      <Card className='bg-white mb-2'>
        <Card.Body className='p-2'>
          <div className="d-flex align-items-center justify-content-between m-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/menus">Menu</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/menus">List menu</Link>
                </li>
                <li className="breadcrumb-item active fw-bold" aria-current="page">
                  Form {isEdit ? 'Edit' : 'Add'} Menu
                </li>
              </ol>
            </div>
          </div>
        </Card.Body>
      </Card>

      {/* CONTENT */}
      <Card className='bg-white'>
        <Card.Body>
            <Row className="g-2">
            <Col sm="12">
                {
                  mutateAdd.isError && (
                    <Alert variant="danger">
                      <ul>
                        {mutateAdd?.error?.response?.data?.detail?.map(
                          (errEdit, idx) => (
                            <li key={idx}>
                              <b>{errEdit.loc[1]} </b>
                              {errEdit.msg}
                            </li>
                          )
                        ) ?? (
                          <li>
                            <b>Error </b>{" "}
                            {mutateAdd?.error?.response?.data?.message ??
                              " Proses Submit Gagal"}
                          </li>
                        )}
                      </ul>
                    </Alert>
                  )
                }
                {
                  mutateAdd.isSuccess && (
                    <Alert variant='success'>
                      {mutateAdd.data.data.message}
                    </Alert>
                  )
                }
              </Col>
              <Col sm="12">
                {
                  mutateEdit.isError && (
                    <Alert variant='danger'>
                      <ul>
                        {
                          mutateEdit?.error?.response?.data?.detail
                          ?.map((errEdit) => (
                            <li><b>{errEdit.loc[1]} </b>{errEdit.msg}</li>
                          )) ?? (
                            <li><b>Error</b>Sesuatu Bermasalah</li>
                          )
                        }
                      </ul>
                    </Alert>
                  )
                }
                {
                  mutateEdit.isSuccess && (
                    <Alert variant='success'>
                      {mutateEdit.data.data.message}
                    </Alert>
                  )
                }
              </Col>
              <Col sm="6" className="d-flex flex-column">
                  <Form.Label className="form-label fs-sm">
                    Pilih Type
                  </Form.Label>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex gap-2">
                      <div className="d-flex align-items-center gap-1">
                        <Form.Check
                          type="radio"
                          required
                          id="required"
                          name="type"
                          value="Parent"
                          onChange={handleChange}
                          checked={
                            payload.type === "Parent" ? "checked" : ""
                          }
                        />
                        <span className="fs-medium">Parent</span>
                      </div>
                      <div className="d-flex align-items-center gap-1">
                        <Form.Check
                          type="radio"
                          required
                          id="radioTipe"
                          value="Child"
                          name="type"
                          onChange={handleChange}
                          checked={
                            payload.type === "Child" ? "checked" : ""
                          }
                        />
                        <span className="fs-medium">Child</span>
                      </div>
                    </div>
                  </div>
              </Col>
              <Col sm="6" className="d-flex flex-column">
                  <Form.Label className="form-label fs-sm">
                    Pilih Jenis
                  </Form.Label>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex gap-2">
                      <div className="d-flex align-items-center gap-1">
                        <Form.Check
                          type="radio"
                          disabled={payload.type === "Child"}
                          required
                          id="radioJenis"
                          value="Label"
                          name="jenis"
                          onChange={handleChange}
                          checked={
                            payload.jenis === "Label" ? "checked" : ""
                          }
                        />
                        <span className="fs-medium">Label</span>
                      </div>
                      <div className="d-flex align-items-center gap-1">
                        <Form.Check
                          type="radio"
                          disabled={payload.type === "Child"}
                          required
                          id="radioJenis"
                          name="jenis"
                          value="Non Label"
                          onChange={handleChange}
                          checked={
                            payload.jenis === "Non Label" ? "checked" :  ""
                          }
                        />
                        <span className="fs-medium">Non Label</span>
                      </div>
                    </div>
                  </div>
              </Col>
              <Col sm="6">
                <Select 
                  default="Pilih Group"
                  label="Pilih Group"
                  name="group"
                  options={groupList}
                  onChange={handleChange}
                  value={payload.group}
                  required
                />
              </Col>
              <Col sm="6">
                <Select 
                  disabled={payload.type !== 'Child'}
                  default="Pilih Menu Parrent"
                  label="Pilih Menu Parrent"
                  name="nama_parent_menu"
                  value={payload.nama_parent_menu}
                  options={payload.type !== 'Child' ? []  : menuParentOptions}
                  onChange={handleChange}
                  required={payload.type === 'Child'}
                />
                {/* <Form.Group>
                  <Form.Label className="mb-1">
                   {payload.type === 'Child' && (<span style={{ color: "red" }}>* </span>)}
                    Pilih Menu Parrent
                  </Form.Label>
                  <Form.Select
                     name="nama_parent_menu"
                     value={payload.nama_parent_menu}
                     onChange={handleChange}
                     required={payload.type === 'Child'}
                     disabled={payload.type === 'Child'}
                  >
                    {menuParentOptions.map((group) => (
                      <optgroup key={group.label} label={group.label}>
                        {group.options.map((option) => (
                          <option key={option.text} value={option.value}>
                            {option.text}
                          </option>
                        ))}
                      </optgroup>
                    ))}
                  </Form.Select>
                </Form.Group> */}
              </Col>
              <Col sm="6">
                <Input 
                  placeholder="Nama Menu"
                  label="Nama Menu"
                  name="nama_menu"
                  value={payload.nama_menu}
                  required
                  onChange={handleChange}
                />
              </Col>
              <Col sm="6">
                <Input 
                  placeholder="Path Menu"
                  name="path"
                  value={payload.path}
                  onChange={handleChange}
                  label="Path Menu"
                />
              </Col>
              <Col sm="6">
                <Input 
                  placeholder="Nomor Menu"
                  name="nomor_menu"
                  value={payload.nomor_menu}
                  label="Nomor Menu"
                  onChange={handleChange}
                />
              </Col>
              <Col sm="6">
                <Input 
                  placeholder="Deskripsi Menu"
                  name="deskripsi"
                  value={payload.deskripsi}
                  label="Deskripsi Menu"
                  onChange={handleChange}
                />
              </Col>
              {
                payload.type === 'Parent' &&
                (
                  <Col sm="2">
                    <div className="input-wrapper">
                      <span>Pilih Icon</span>
                      <Button 
                        variant="primary"
                        onClick={() => setModalIcon(true)}
                        size='sm'
                      >
                        <i className={`${payload.icon || 'ri-remixicon-line'} fs-4`}></i>
                      </Button>
                      <input type='hidden' value={payload.icon} />
                    </div>
                  </Col>
                )
              }
            </Row>
        </Card.Body>
        <Card.Footer className='bg-white'>
          <div className="d-flex justify-content-end align-items-center">
            {
              (!mutateAdd.isLoading) || (!mutateEdit.isLoading) ? (
                <Button onClick={handleSubmit} variant='primary'>{isEdit ? 'Save' : 'Submit'}</Button>
              ) : (
                <Button variant='primary' disabled>Loading</Button>
              )
            }
          </div>
        </Card.Footer>
      </Card>
      <Modal
          show={modalIcon}
          onHide={() => setModalIcon(false)}
          backdrop="static"
          keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Icon Picker</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <IconPicker onClickTag={selectIcon}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setModalIcon(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </React.Fragment>
  )
}

export default FormLayout