import { useNavigate } from "react-router-dom";
import State from "../libraries/State";
import { useEffect } from "react";

function Authenticated(props) {
    const navigate = useNavigate();
    const state = State.getState()
    const accessToken = State.getState().getItem('accessToken', true);

    let allowed = false;
    if(accessToken) {
        allowed = true; 
    } else {
        allowed = false
    }

    useEffect(() => {
        if(!allowed) navigate('/login');
    }, []);

    useEffect(() => {
        const group = props?.group ?? null
        const page = props?.page ?? null
        const menuRole = state.getItem("menuRole", true)
        if (menuRole) {
            const menuRoleActive = menuRole.find((e) => e.group === group)
            let defaultAction = {
                "create": false,
                "read": false,
                "update": false,
                "delete": false
            }
            function findMenuAction(data, searchString) {
                
                if ((data?.menu ?? null)) {
                    for (const menu of data.menu) {
                        if (menu.menu_parent.toLowerCase().includes(searchString.toLowerCase())) {
                            return menu.menu_action || defaultAction;
                        }
                    
                        for (const child of menu.menu_child) {
                            if (child.nama_child.toLowerCase().includes(searchString.toLowerCase())) {
                                return child.menu_action;
                            }
                        }
                    }
                
                    return defaultAction;
                }
            }
    
            let menuAction = findMenuAction(menuRoleActive, page)
    
            if (menuAction) {
                state.setItem("permissionPage", menuAction, true)
            } else {
                state.setItem("permissionPage", defaultAction, true)
            }
        }
        
        
        
    }, [props, state]);
    
    return props.render;
}

export default Authenticated;