import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Card, Table, Button, Spinner, Badge } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Input, MyPagination, Select } from "../../../components/Components";
import {
  useDeleteLokasiBarbuk,
  useGetLokasiBarbukList,
} from "../../../utils/apis/query";
import { debounce } from "lodash";
import Alert from "../../../libraries/Alert";

function List() {
  const [payloadDebounced, setPayloadDebounced] = useState({
    lokasi: "",
    alamat: "",
    page: 1,
    size: 10,
  });
  const [search, setSearch] = useState("");
  const {
    data: dataList,
    pagination,
    isFetching: dataFetching,
    isError: dataIsError,
    error: dataError,
  } = useGetLokasiBarbukList(payloadDebounced);

  const mutateDelete = useDeleteLokasiBarbuk();

  const handleDebounce = useCallback(
    debounce(({ field, val }) => {
      setPayloadDebounced({ ...payloadDebounced, [field]: val });
    }, 500),
    []
  );
  const handleDelete = (id) => {
    Alert.showConfirm("warning", "Hapus").then(({ isConfirmed }) => {
      if (isConfirmed) {
        mutateDelete.mutate(id);
      }
    });
  };
  const handlePagination = (page) =>
    setPayloadDebounced({ ...payloadDebounced, page: page });

  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item">
              <Link to="/perkara">Konfigurasi</Link>
            </li>
            <li className="breadcrumb-item active fw-bold" aria-current="page">
              List Perkara
            </li>
          </ol>
        </div>
      </div>
      <Row>
        <Col xl="12">
          <Card className="card bg-white">
            <Card.Body>
              <Row className="g-1 mb-2">
                <Col sm="12" md="5">
                  <Input
                    type="text"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      handleDebounce({ field: "lokasi", val: e.target.value });
                    }}
                  />
                </Col>
                <Col sm="12" md="7" className="d-flex justify-content-end">
                  <Button
                    as={Link}
                    to="/perkara/form"
                    className="btn btn-primary"
                  >
                    Tambah
                  </Button>
                </Col>
              </Row>
              <Row className="mt-5">
                <div className="d-flex justify-content-end align-items-center flex-row gap-1">
                  <label className="text-muted">Items per page</label>
                  <Select
                    name="size"
                    options={[
                      { text: "10", value: 10 },
                      { text: "20", value: 20 },
                      { text: "50", value: 50 },
                      { text: "100", value: 100 },
                    ]}
                    value={payloadDebounced.size}
                    onChange={(e) => {
                      setPayloadDebounced({
                        ...payloadDebounced,
                        size: e.target.value,
                        page: 1,
                      });
                    }}
                    style={{
                      padding: "2px 4px",
                      fontSize: "0.875rem",
                      width: "60px",
                    }}
                  />
                  <div className="text-muted d-flex justify-content-end">
                    {`${
                      (payloadDebounced.page - 1) * payloadDebounced.size + 1
                    }–${Math.min(
                      payloadDebounced.page * payloadDebounced.size,
                      pagination.total_items
                    )} of ${pagination.total_items}`}
                  </div>
                </div>
              </Row>
              <div className="table-responsive">
                <Table className="table dems-table">
                  <thead>
                    <tr>
                      <th>Aksi</th>
                      <th>Kode</th>
                      <th>Perkara</th>
                      <th>Tindak Pidana</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataFetching && (
                      <tr>
                        <td colSpan="4" style={{ textAlign: "center" }}>
                          <Spinner animation="border" variant="primary" />
                        </td>
                      </tr>
                    )}
                    {dataIsError &&
                      (dataError?.response?.data?.detail?.map((err, idx) => (
                        <tr key={idx}>
                          <td
                            colSpan="4"
                            style={{ textAlign: "center" }}
                            className="text-danger"
                          >
                            <span>
                              <b>{err.loc[1]} </b>
                              {err.msg}
                            </span>
                          </td>
                        </tr>
                      )) ?? (
                        <tr>
                          <td
                            colSpan="4"
                            style={{ textAlign: "center" }}
                            className="text-danger"
                          >
                            <span>
                              <b>Opps Error </b>Reload page or please wait until
                              we fix this issue
                            </span>
                          </td>
                        </tr>
                      ))}
                    {dataList.length && !dataFetching && !dataIsError
                      ? dataList.map((data, index) => (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center justify-content-start gap-2">
                                <Link to={"/perkara/form/" + data.id}>
                                  <Badge className="btn bg-info" as="button">
                                    <i className="ri-eye-line"></i>
                                  </Badge>
                                </Link>
                                <Badge
                                  //   onClick={() => handleDelete(data.id)}
                                  className="btn bg-danger"
                                  as="button"
                                >
                                  <i className="ri-delete-bin-line"></i>
                                </Badge>
                              </div>
                            </td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        ))
                      : !dataFetching &&
                        !dataList.length &&
                        !dataIsError && (
                          <tr>
                            <td colSpan="4" style={{ textAlign: "center" }}>
                              <span>Tidak Ada Data !</span>
                            </td>
                          </tr>
                        )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
            <Card.Footer className="bg-white">
              <MyPagination
                currentPage={payloadDebounced.page}
                totalPages={pagination.total_pages}
                onPageChange={handlePagination}
              />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default List;
