import {
  Badge,
  Button,
  Card,
  Col,
  Row,
  Form,
  Table,
  Accordion,
  Spinner,
  Alert as AlertBS,
} from "react-bootstrap";
import {
  DatePicker,
  Input,
  Select,
  Image,
  Select2,
} from "../../../../components/Components";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import {
  useGetDetailTahti,
  usePostTahti,
  usePostTahtiLapas,
  usePutTahti,
  useSatkerTahtiList,
  useGetDetailTahtiLapas,
  usePutTahtiLapas,
  useRutanList,
} from "../../../../utils/apis/query";
import { format, parse, addDays } from "date-fns";
import Alert from "../../../../libraries/Alert";
import State from "../../../../libraries/State";
import Files from "react-files";
import { country, jenisTindakPidanaTRX, pangkat } from "../../../../utils";
import moment from "moment";
import { encodeToHex, trimFileUrl, trimUrlPath } from "../../../../utils";
import { upperCase } from "lodash";

function FormTahti() {
  const onSuccess = () => {
    setIsFormDirty(false);
    setTimeout(() => {
      navigate("/tahanan/registrasi/list");
    }, 1500);
  };
  const [today, setToday] = useState("");
  const location = useLocation();
  const isRegistrasi = location.pathname.includes("/registrasi");
  const isLapas = location.pathname.includes("/lapas");
  const state = State.getState();
  const userData = state.getItem("profileData", true);
  const [isEdit, setIsEdit] = useState(false);
  const [isFormEditable, setIsFormEditable] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const mutateAdd = usePostTahti(onSuccess);
  const mutateAddLapas = usePostTahtiLapas(onSuccess);
  const mutateEdit = usePutTahti(onSuccess);
  const mutateEditLapas = usePutTahtiLapas(onSuccess);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isAddingRow, setIsAddingRow] = useState(false);
  const [penyidikData, setPenyidikData] = useState([]);
  const [imagePreviewTahanan, setImagePreviewTahanan] = useState(null);
  const [imagePreviewSidikJari, setImagePreviewSidikJari] = useState(null);
  const [sisaMasaTahanan, setSisaMasaTahanan] = useState(0);
  const [penyidik, setPenyidik] = useState({
    nrp: "",
    nama_penyidik: "",
    nama_satker: "",
    pangkat: "",
    jabatan: "",
    no_hp: "",
  });
  const [payloadDate, setPayloadDate] = useState({
    tanggal_lahir: "",
    tanggal_penahanan: "",
    tanggal_kejadian: "",
    awal_penahanan: "",
    akhir_penahanan: "",
    tanggal_laporan: "",
    tanggal_sprin_penahanan: "",
    tanggal_dokkes: "",
    tanggal_laporan_lapas: "",
    tanggal_dokumen_ba: "",
    tanggal_ba_penahanan: "",
    tanggal_surat_keterangan_pusdokkes: "",
  });

  const [tahti, setTahti] = useState({
    no_kasus: "",
    no_sprin_penahanan: "",
    nama_satker: "",
    nama_tersangka: "",
    tempat_lahir: "",
    tanggal_lahir: "",
    umur: "",
    jenis_kelamin: "",
    agama: "",
    kebangsaan: "",
    asal_negara: "",
    pekerjaan: "",
    alamat: "",
    perkara: "",
    tanggal_penahanan: "",
    tanggal_kejadian: "",
    alamat_tkp: "",
    nama_rutan: "",
    alamat_rutan: "",
    kota_rutan: "",
    fase: "",
    awal_penahanan: "",
    akhir_penahanan: "",
    tanggal_surat: today,
    no_tahanan: "",
    fase_penahanan: "",
    pasal_pelanggaran: "",
    informasi_tambahan: "",
    no_ktp: "",
    no_passport: "",
    no_sim: "",
    golongan_darah: "",
    no_laporan: "",
    tanggal_laporan: "",
    tanggal_sprin_penahanan: "",
    no_dokkes: "",
    tanggal_dokkes: "",
    rumus_wajah: "",
    rumus_sidikjari: "",
    penyidik: "",
    asal_direktorat: "",
    asal_tahanan: "",
    kondisi_tahanan: "",
    status_dokumen: "",
    no_laporan_lapas: "",
    ba_peminjaman_lapas: "",
    tanggal_laporan_lapas: "",
    tanggal_dokumen_ba: "",
    arsip_laporan_lapas: null,
    arsip_berita_acara_lapas: null,
    lampiran: null,
    no_register_lapas: "",
    no_putusan: "",
    tindak_pidana: "",
    no_ba_penahanan: "",
    no_keterangan_pemeriksaan_tersangka_dari_pusdokkes: "",
    tanggal_ba_penahanan: "",
    tanggal_surat_keterangan_pusdokkes: "",
    arsip_ba_penahanan: null,
    arsip_pemeriksaan_tersangka_dari_pusdokkes: null,
    arsip_no_sprin_penahanan: null,
    arsip_nomor_lp: null,
    nama_fase_penahanan: "",
    tipe_tahanan: "",
  });

  const { data: masterListSatkers } = useSatkerTahtiList();
  const satkersFilter = masterListSatkers.map((e) => ({
    label: e.nama_satker,
    value: e.nama_satker,
  }));
  const { data: masterListRutan } = useRutanList({
    nama_satker: tahti.nama_satker,
  });
  const rutanFilter = masterListRutan.map((e) => ({
    text: e.nama_rutan,
    value: e.nama_rutan,
  }));

  const handleAddRow = () => {
    setIsAddingRow(true);
  };
  const closeRow = () => {
    setIsAddingRow(false);
  };

  const handleSaveRow = () => {
    setPenyidikData([...penyidikData, penyidik]);
    setPenyidik({
      nrp: "",
      nama_penyidik: "",
      nama_satker: "",
      pangkat: "",
      jabatan: "",
      no_hp: "",
    });
    setIsAddingRow(false);
  };

  const handleNewRowChange = (e) => {
    const { name, value } = e.target;
    setPenyidik({ ...penyidik, [name]: value });
  };

  const handleDelete = (index) => {
    const newPenyidikData = penyidikData.filter((_, idx) => idx !== index);
    setPenyidikData(newPenyidikData);
  };
  // ----------------------

  const nonLapas = useGetDetailTahti(id, { enabled: !!id });
  const asalLapas = useGetDetailTahtiLapas(id, { enabled: !!id });
  let hookResult = "";

  if (isLapas) {
    hookResult = asalLapas;
  } else {
    hookResult = nonLapas;
  }
  const {
    data: detailData,
    isFetching: detailIsFetching,
    isError: detailIsError,
    error: detailError,
  } = hookResult;

  const handleChange = (e) => {
    setIsFormDirty(true);
    let { name, value } = e.target;
    if (name === "nama_fase_penahanan") {
      let akhir_penahanan = null;
      let val = "";
      if (value === "Penyidik") {
        val = "20";
      } else if (value === "Jaksa") {
        val = "40";
      } else if (value === "PN1") {
        val = "30";
      } else if (value === "PN2") {
        val = "30";
      } else {
        val = "0";
      }
      if (tahti.tanggal_sprin_penahanan) {
        // const awal = parse(tahti.awal_penahanan, "yyyy-MM-dd", new Date());
        const awal = moment(tahti.tanggal_sprin_penahanan).format("YYYY-MM-DD");
        akhir_penahanan = addDays(awal, parseInt(val - 1));
      }

      setTahti({
        ...tahti,
        fase: val,
        nama_fase_penahanan: value,
        akhir_penahanan: akhir_penahanan
          ? moment(akhir_penahanan).format("YYYY-MM-DD")
          : "",
      });
      setPayloadDate({
        ...payloadDate,
        akhir_penahanan: akhir_penahanan
          ? moment(akhir_penahanan).toDate()
          : null,
      });
    } else if (
      // name === "nama_rutan" ||
      name === "kota_rutan" ||
      name === "alamat_rutan"
    ) {
      setTahti({
        ...tahti,
        [name]: value.toUpperCase(),
      });
    } else {
      setTahti({ ...tahti, [name]: value });
    }
  };

  const handleDatePicker = (date, name) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setTahti({ ...tahti, [name]: formattedDate });

    if (name === "tanggal_sprin_penahanan" && date) {
      setTahti((prevState) => ({
        ...prevState,
        tanggal_penahanan: formattedDate,
      }));
      setPayloadDate((prevState) => ({
        ...prevState,
        tanggal_penahanan: date,
      }));

      if (tahti.fase) {
        const awalDate = formattedDate;
        const akhirDate = addDays(awalDate, parseInt(tahti.fase - 1));
        setTahti((prevState) => ({
          ...prevState,
          akhir_penahanan: moment(akhirDate).format("YYYY-MM-DD"),
        }));
        setPayloadDate((prevState) => ({
          ...prevState,
          akhir_penahanan: akhirDate ? moment(akhirDate).toDate() : null,
        }));
      }
    }

    if (name === "tanggal_lahir") {
      const age = calculateAge(date);
      setTahti((prevState) => ({ ...prevState, umur: age }));
    }

    // if (name === "awal_penahanan" && tahti.fase) {
    //   const awalDate = formattedDate;
    //   const akhirDate = addDays(awalDate, parseInt(tahti.fase - 1));
    //   setTahti((prevState) => ({
    //     ...prevState,
    //     akhir_penahanan: moment(akhirDate).format("YYYY-MM-DD"),
    //   }));
    //   setPayloadDate((prevState) => ({
    //     ...prevState,
    //     akhir_penahanan: akhirDate ? moment(akhirDate).toDate() : null,
    //   }));
    // }
  };
  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDifference = today.getMonth() - birth.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birth.getDate())
    ) {
      age--;
    }

    return age;
  };

  const handleSubmit = (status) => {
    tahti.status_dokumen = status;
    tahti.penyidik = JSON.stringify(penyidikData);

    let tahtiFormData = new FormData();
    Object.keys(tahti).forEach((key) => {
      if (
        typeof tahti[key] === "string" ||
        typeof tahti[key] === "number" ||
        key === "rumus_wajah" ||
        key === "rumus_sidikjari"
      ) {
        tahtiFormData.append(key, tahti[key]);
      }
    });
    tahti.arsip_laporan_lapas?.forEach((file, idx) => {
      tahtiFormData.append(`arsip_laporan_lapas`, file);
    });
    tahti.arsip_berita_acara_lapas?.forEach((file, idx) => {
      tahtiFormData.append(`arsip_berita_acara_lapas`, file);
    });
    tahti.arsip_nomor_lp?.forEach((file, idx) => {
      tahtiFormData.append(`arsip_nomor_lp`, file);
    });
    tahti.arsip_no_sprin_penahanan?.forEach((file, idx) => {
      tahtiFormData.append(`arsip_no_sprin_penahanan`, file);
    });
    tahti.arsip_ba_penahanan?.forEach((file, idx) => {
      tahtiFormData.append(`arsip_ba_penahanan`, file);
    });
    tahti.arsip_pemeriksaan_tersangka_dari_pusdokkes?.forEach((file, idx) => {
      tahtiFormData.append(`arsip_pemeriksaan_tersangka_dari_pusdokkes`, file);
    });
    tahti.lampiran?.forEach((file, idx) => {
      tahtiFormData.append(`lampiran`, file);
    });

    if (isEdit) {
      if (tahti.asal_tahanan === "Lapas") {
        mutateEditLapas.mutate({ id, tahtiFormData });
      } else {
        mutateEdit.mutate({ id, tahtiFormData });
      }
    } else {
      if (tahti.asal_tahanan === "Lapas") {
        mutateAddLapas.mutate(tahtiFormData);
      } else {
        mutateAdd.mutate(tahtiFormData);
      }
    }
  };

  useEffect(() => {
    const getToday = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    setToday(getToday);
  }, []);

  useEffect(() => {
    if (userData) {
      setTahti({
        ...tahti,
        nama_satker: userData?.satker?.nama_satker,
        // nama_rutan: userData?.satker?.nama_rutan,
        // alamat_rutan: userData?.satker?.alamat_rutan,
        // kota_rutan: userData?.satker?.kota,
      });
    }
  }, [userData]);

  useEffect(() => {
    setIsEdit(false);
    setIsFormEditable(true);
    if (id) {
      setIsEdit(true);
      setIsFormEditable(false);
    }
  }, [id]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormDirty]);

  useEffect(() => {
    if (detailData) {
      setTahti({
        ...tahti,
        no_kasus: detailData.no_kasus,
        no_tahanan: detailData.no_tahanan,
        no_sprin_penahanan: detailData.no_sprin_penahanan,
        nama_satker: detailData.nama_satker,
        nama_tersangka: detailData.nama_tersangka,
        tempat_lahir: detailData.tempat_lahir,
        tanggal_lahir: detailData.tanggal_lahir,
        umur: detailData.umur,
        jenis_kelamin: detailData.jenis_kelamin,
        agama: detailData.agama,
        kebangsaan: detailData.kebangsaan,
        asal_negara: detailData.asal_negara,
        pekerjaan: detailData.pekerjaan,
        alamat: detailData.alamat,
        perkara: detailData.perkara,
        tanggal_penahanan: detailData.tanggal_penahanan,
        tanggal_kejadian: detailData.tanggal_kejadian,
        alamat_tkp: detailData.alamat_tkp,
        nama_rutan: detailData.nama_rutan
          ? detailData.nama_rutan
          : userData?.satker?.nama_rutan,
        alamat_rutan: detailData.alamat_rutan
          ? detailData.alamat_rutan
          : userData?.satker?.alamat_rutan,
        kota_rutan: detailData.kota_rutan
          ? detailData.kota_rutan
          : userData?.satker?.kota,
        fase_penahanan: detailData.fase_penahanan,
        fase: detailData.fase_penahanan?.split("/")[0],
        nama_fase_penahanan: detailData.nama_fase_penahanan,
        awal_penahanan: detailData.awal_penahanan,
        akhir_penahanan: detailData.akhir_penahanan,
        tanggal_surat: detailData.tanggal_surat,
        pasal_pelanggaran: detailData.pasal_pelanggaran,
        informasi_tambahan: detailData.informasi_tambahan,
        no_ktp: detailData.no_ktp,
        no_passport: detailData.no_passport,
        no_sim: detailData.no_sim,
        golongan_darah: detailData.golongan_darah,
        no_laporan: detailData.no_laporan,
        tanggal_laporan: detailData.tanggal_laporan,
        tanggal_sprin_penahanan: detailData.tanggal_sprin_penahanan,
        no_dokkes: detailData.no_dokkes,
        tanggal_dokkes: detailData.tanggal_dokkes,
        asal_direktorat: detailData.asal_direktorat,
        asal_tahanan: detailData.asal_tahanan,
        kondisi_tahanan: detailData.kondisi_tahanan,
        status_dokumen: detailData.status_dokumen,
        no_laporan_lapas: detailData.no_laporan_lapas,
        ba_peminjaman_lapas: detailData.ba_peminjaman_lapas,
        tanggal_laporan_lapas: detailData.tanggal_laporan_lapas,
        tanggal_dokumen_ba: detailData.tanggal_dokumen_ba,
        no_register_lapas: detailData.no_register_lapas,
        no_putusan: detailData.no_putusan,
        tindak_pidana: detailData.tindak_pidana,
        tanggal_ba_penahanan: detailData.tanggal_ba_penahanan,
        tanggal_surat_keterangan_pusdokkes:
          detailData.tanggal_surat_keterangan_pusdokkes,
        no_ba_penahanan: detailData.no_ba_penahanan,
        no_keterangan_pemeriksaan_tersangka_dari_pusdokkes:
          detailData.no_keterangan_pemeriksaan_tersangka_dari_pusdokkes,
        tipe_tahanan: detailData.tipe_tahanan,
      });
      setImagePreviewTahanan(detailData.rumus_wajah);
      setImagePreviewSidikJari(detailData.rumus_sidikjari);
      setPayloadDate({
        ...payloadDate,
        tanggal_lahir: detailData?.tanggal_lahir
          ? moment(detailData?.tanggal_lahir).toDate()
          : null,
        tanggal_penahanan: detailData?.tanggal_penahanan
          ? moment(detailData?.tanggal_penahanan).toDate()
          : null,
        tanggal_kejadian: detailData?.tanggal_kejadian
          ? moment(detailData?.tanggal_kejadian).toDate()
          : null,
        awal_penahanan: detailData?.awal_penahanan
          ? moment(detailData?.awal_penahanan).toDate()
          : null,
        akhir_penahanan: detailData?.akhir_penahanan
          ? moment(detailData?.akhir_penahanan).toDate()
          : null,
        tanggal_laporan: detailData?.tanggal_laporan
          ? moment(detailData?.tanggal_laporan).toDate()
          : null,
        tanggal_sprin_penahanan: detailData?.tanggal_sprin_penahanan
          ? moment(detailData?.tanggal_sprin_penahanan).toDate()
          : null,
        tanggal_dokkes: detailData?.tanggal_dokkes
          ? moment(detailData?.tanggal_dokkes).toDate()
          : null,
        tanggal_laporan_lapas: detailData?.tanggal_laporan_lapas
          ? moment(detailData?.tanggal_laporan_lapas).toDate()
          : null,
        tanggal_dokumen_ba: detailData?.tanggal_dokumen_ba
          ? moment(detailData?.tanggal_dokumen_ba).toDate()
          : null,
        tanggal_ba_penahanan: detailData?.tanggal_ba_penahanan
          ? moment(detailData?.tanggal_ba_penahanan).toDate()
          : null,
        tanggal_surat_keterangan_pusdokkes:
          detailData?.tanggal_surat_keterangan_pusdokkes
            ? moment(detailData?.tanggal_surat_keterangan_pusdokkes).toDate()
            : null,
      });
      setPenyidikData(detailData.penyidik ?? []);

      const startPenahanan = new Date(detailData.awal_penahanan);
      const endPenahanan = startPenahanan.setDate(
        startPenahanan.getDate() +
          parseInt(detailData.fase_penahanan?.split("/")[1])
      );
      const today = new Date();
      let masaTahanan = new Date(detailData.akhir_penahanan) - today;
      masaTahanan = Math.ceil(masaTahanan / (1000 * 60 * 60 * 24));
      setSisaMasaTahanan(masaTahanan);
    }
  }, [detailData]);

  useEffect(() => {
    return () => {
      setTahti({
        no_kasus: "",
        no_tahanan: "",
        no_sprin_penahanan: "",
        nama_satker: "",
        nama_tersangka: "",
        tempat_lahir: "",
        tanggal_lahir: "",
        umur: "",
        jenis_kelamin: "",
        agama: "",
        kebangsaan: "",
        asal_negara: "",
        pekerjaan: "",
        alamat: "",
        perkara: "",
        tanggal_penahanan: "",
        tanggal_kejadian: "",
        alamat_tkp: "",
        nama_rutan: "",
        fase_penahanan: "",
        fase: "",
        nama_fase_penahanan: "",
        awal_penahanan: "",
        akhir_penahanan: "",
        tanggal_surat: "",
        alamat_rutan: "",
        pasal_pelanggaran: "",
        informasi_tambahan: "",
        no_ktp: "",
        no_passport: "",
        no_sim: "",
        golongan_darah: "",
        no_laporan: "",
        tanggal_laporan: "",
        tanggal_sprin_penahanan: "",
        no_dokkes: "",
        tanggal_dokkes: "",
        rumus_wajah: "",
        rumus_sidikjari: "",
        penyidik: [],
        asal_direktorat: "",
        asal_tahanan: "",
        kondisi_tahanan: "",
        no_laporan_lapas: "",
        ba_peminjaman_lapas: "",
        tanggal_laporan_lapas: "",
        tanggal_dokumen_ba: "",
        lampiran: "",
        arsip_laporan_lapas: "",
        arsip_berita_acara_lapas: "",
        no_register_lapas: "",
        no_putusan: "",
        tindak_pidana: "",
        tanggal_ba_penahanan: "",
        tanggal_surat_keterangan_pusdokkes: "",
        no_ba_penahanan: "",
        no_keterangan_pemeriksaan_tersangka_dari_pusdokkes: "",
        arsip_ba_penahanan: "",
        arsip_pemeriksaan_tersangka_dari_pusdokkes: "",
        arsip_no_sprin_penahanan: "",
        arsip_nomor_lp: "",
        tipe_tahanan: "",
      });
    };
  }, []);

  const polri = [
    { value: "Dittipidum", text: "Dittipidum (Pidana Umum)" },
    {
      value: "Dittipidkor",
      text: "Dittipidkor (Pidana Korupsi)",
    },
    {
      value: "Dittipidnarkoba",
      text: "Dittipidnarkoba (Pidana Narkoba)",
    },
    {
      value: "Dittipidter",
      text: "Dittipidter (Pidana Tertentu)",
    },
    {
      value: "Dittipidsiber",
      text: "Dittipidsiber (Pidana Siber)",
    },
    {
      value: "Dittipideksus",
      text: "Dittipideksus (Pidana Eksus)",
    },
    {
      value: "Dittipid PPA-PPO",
      text: "Dittipid PPA-PPO",
    },
  ];

  const ojk = [
    {
      value: "OJK",
      text: "OJK",
    },
  ];
  const kpk = [
    {
      value: "KPK",
      text: "KPK",
    },
  ];
  const jaksa = [
    {
      value: "Jaksa",
      text: "Jaksa",
    },
  ];
  const ppns = [
    {
      value: "PPNS",
      text: "PPNS",
    },
  ];
  const bnn = [
    {
      value: "BNN",
      text: "BNN",
    },
  ];
  const lapas = [
    {
      value: "Lapas",
      text: "Lapas",
    },
  ];

  const one =
    tahti.no_laporan &&
    tahti.tanggal_laporan &&
    (detailData?.info_tahanan_files?.arsip_nomor_lp || tahti?.arsip_nomor_lp);
  const two =
    tahti.no_sprin_penahanan &&
    tahti.tanggal_sprin_penahanan &&
    (detailData?.info_tahanan_files?.arsip_no_sprin_penahanan ||
      tahti.arsip_no_sprin_penahanan);
  const three =
    tahti.no_ba_penahanan &&
    tahti.tanggal_ba_penahanan &&
    (detailData?.info_tahanan_files?.arsip_ba_penahanan ||
      tahti.arsip_ba_penahanan);
  const four =
    tahti.no_keterangan_pemeriksaan_tersangka_dari_pusdokkes &&
    tahti.tanggal_surat_keterangan_pusdokkes &&
    (detailData?.info_tahanan_files
      ?.arsip_pemeriksaan_tersangka_dari_pusdokkes ||
      tahti.arsip_pemeriksaan_tersangka_dari_pusdokkes);

  const oneLapas =
    tahti.no_laporan_lapas &&
    tahti.tanggal_laporan_lapas &&
    (detailData?.files?.arsip_laporan || tahti?.arsip_laporan_lapas);
  const twoLapas =
    tahti.ba_peminjaman_lapas &&
    tahti.tanggal_dokumen_ba &&
    (detailData?.files?.arsip_ba_peminjaman_tahanan_lapas ||
      tahti?.arsip_berita_acara_lapas);
  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between m-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/tahanan/registrasi/list">Tahanan</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/tahanan/registrasi/list">Tahanan List</Link>
                </li>
                <li
                  className="breadcrumb-item active fw-bold"
                  aria-current="page"
                >
                  Form {isEdit ? "Ubah" : "Tambah"} Tahanan
                </li>
              </ol>
            </div>
            {isEdit && isRegistrasi && (
              <Button
                className="d-flex align-items-center gap-2"
                variant="primary"
                size="normal"
                onClick={() => setIsFormEditable(!isFormEditable)}
              >
                <i className="ri-edit-2-line"></i>
                {isFormEditable ? "Batalkan Edit" : "Edit Form"}
              </Button>
            )}
          </div>
        </Card.Body>
      </Card>
      <Card className="bg-white">
        <Card.Body>
          <Row className="g-2">
            {
              <Col sm="12">
                {mutateAdd.isError && (
                  <AlertBS variant="danger">
                    <ul>
                      {mutateAdd?.error?.response?.data?.detail?.map(
                        (errEdit) => (
                          <li>
                            <b>{errEdit.loc[1]} </b>
                            {errEdit.msg}
                          </li>
                        )
                      ) ?? (
                        <li>
                          <b>Error </b>{" "}
                          {mutateAdd?.error?.response?.data?.value?.message ??
                            " Proses Submit Gagal"}
                        </li>
                      )}
                    </ul>
                  </AlertBS>
                )}
                {mutateAdd.isSuccess && (
                  <AlertBS variant="success">
                    {mutateAdd.data.data.message}
                  </AlertBS>
                )}
              </Col>
            }
            {
              <Col sm="12">
                {mutateEdit.isError && (
                  <AlertBS variant="danger">
                    <ul>
                      {mutateEdit?.error?.response?.data?.detail?.map(
                        (errEdit) => (
                          <li>
                            <b>{errEdit.loc[1]} </b>
                            {errEdit.msg}
                          </li>
                        )
                      ) ?? (
                        <li>
                          <b>Error </b>{" "}
                          {mutateEdit?.error?.response?.data?.value?.message ??
                            " Proses Submit Gagal"}
                        </li>
                      )}
                    </ul>
                  </AlertBS>
                )}
                {mutateEdit.isSuccess && (
                  <AlertBS variant="success">
                    {mutateEdit.data.data.message}
                  </AlertBS>
                )}
              </Col>
            }
            <Col sm="12">
              <Row className="mb-3">
                <Col sm="4">
                  <Select
                    label="Instansi Gakkum"
                    name="asal_tahanan"
                    value={tahti.asal_tahanan}
                    onChange={handleChange}
                    default="-"
                    options={[
                      { value: "POLRI", text: "POLRI" },
                      { value: "Propam POLRI", text: "Propam POLRI" },
                      { value: "POLAIR", text: "POLAIR" },
                      { value: "OJK", text: "OJK" },
                      { value: "Jaksa", text: "Jaksa" },
                      { value: "BNN", text: "BNN" },
                      { value: "PPNS", text: "PPNS" },
                      { value: "KPK", text: "KPK" },
                      { value: "Lapas", text: "Lapas" },
                    ]}
                    required
                    disabled={!isFormEditable}
                  />
                </Col>
                <Col sm="4">
                  <Select
                    label="Tindak Pidana"
                    name="tindak_pidana"
                    value={tahti.tindak_pidana}
                    onChange={handleChange}
                    required
                    disabled={!isFormEditable}
                    default="-"
                    options={jenisTindakPidanaTRX}
                  />
                </Col>
                {/* <Col sm="4">
                  <Select
                    label="Asal Direktorat"
                    name="asal_direktorat"
                    value={tahti.asal_direktorat}
                    onChange={handleChange}
                    options={
                      tahti.asal_tahanan === "POLRI"
                        ? polri
                        : tahti.asal_tahanan === "OJK"
                        ? ojk
                        : tahti.asal_tahanan === "Jaksa"
                        ? jaksa
                        : tahti.asal_tahanan === "BNN"
                        ? bnn
                        : tahti.asal_tahanan === "PPNS"
                        ? ppns
                        : tahti.asal_tahanan === "KPK"
                        ? kpk
                        : tahti.asal_tahanan === "Lapas"
                        ? lapas
                        : []
                    }
                    required
                    disabled={!isFormEditable}
                  />
                </Col> */}
              </Row>
              <Accordion
                defaultActiveKey={["0", "1", "2", "3", "4"]}
                flush
                alwaysOpen
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="custom-header">
                    Informasi / Profil Tahanan
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row>
                          {tahti.asal_tahanan !== "Lapas" && (
                            <Col sm="4" className="d-flex flex-column gap-3">
                              <div className="d-flex flex-column gap-2">
                                <span className="fs-6 fw-medium">
                                  Foto Tahanan
                                </span>
                                <Image
                                  src={imagePreviewTahanan}
                                  height="250px"
                                  width="100%"
                                />
                              </div>
                              <Input
                                type="file"
                                label="Unggah Foto Wajah"
                                name="rumus_wajah"
                                required
                                accepts={[".jpg", ".jpeg", ".png"]}
                                disabled={!isFormEditable}
                                onChange={(e) => {
                                  const files = e.target.files[0];
                                  if (files) {
                                    setTahti({ ...tahti, rumus_wajah: files });
                                    setImagePreviewTahanan(
                                      URL.createObjectURL(files)
                                    );
                                  }
                                }}
                              />
                              <div className="d-flex flex-column gap-2">
                                <span className="fs-6 fw-medium">
                                  Foto Sidik Jari
                                </span>
                                <Image
                                  src={imagePreviewSidikJari}
                                  height="250px"
                                  width="100%"
                                />
                              </div>
                              <Input
                                type="file"
                                label="Unggah Rumus Sidik Jari"
                                name="rumus_sidikjari"
                                // required
                                accepts={[".jpg", ".jpeg", ".png"]}
                                disabled={!isFormEditable}
                                onChange={(e) => {
                                  const files = e.target.files[0];
                                  if (files) {
                                    setTahti({
                                      ...tahti,
                                      rumus_sidikjari: files,
                                    });
                                    setImagePreviewSidikJari(
                                      URL.createObjectURL(files)
                                    );
                                  }
                                }}
                              />
                            </Col>
                          )}
                          <Col sm={tahti.asal_tahanan === "Lapas" ? "12" : "8"}>
                            <Row className="g-2 mt-2">
                              <Col sm="6">
                                <Input
                                  label="No. Tahanan"
                                  value={tahti.no_tahanan}
                                  onChange={handleChange}
                                  required
                                  disabled
                                  placeholder="Generated by system.."
                                />
                              </Col>
                              <Col sm="6">
                                <Input
                                  label="Nama"
                                  name="nama_tersangka"
                                  value={tahti.nama_tersangka}
                                  onChange={handleChange}
                                  required
                                  disabled={!isFormEditable}
                                />
                              </Col>
                              {tahti.asal_tahanan === "Lapas" ? null : (
                                <>
                                  <Col sm="6">
                                    <Input
                                      label="Tempat Lahir"
                                      name="tempat_lahir"
                                      value={tahti.tempat_lahir}
                                      onChange={handleChange}
                                      // required
                                      disabled={!isFormEditable}
                                    />
                                  </Col>
                                  <Col sm="6">
                                    <DatePicker
                                      label="Tanggal Lahir"
                                      name="tanggal_lahir"
                                      timeFormat="HH:mm:ss"
                                      dateFormat="dd/MM/yyyy"
                                      maxDate={new Date()}
                                      selected={payloadDate.tanggal_lahir}
                                      onChange={(date) => {
                                        setPayloadDate({
                                          ...payloadDate,
                                          tanggal_lahir: date,
                                        });
                                        handleDatePicker(date, "tanggal_lahir");
                                      }}
                                      // required
                                      disabled={!isFormEditable}
                                    />
                                  </Col>
                                </>
                              )}
                              <Col sm="6">
                                <Input
                                  label="Umur Saat Ini"
                                  name="umur"
                                  value={tahti.umur}
                                  onChange={handleChange}
                                  type="number"
                                  required
                                  disabled={tahti.asal_tahanan !== "Lapas"}
                                />
                              </Col>
                              <Col sm="6">
                                <Select
                                  label="Jenis Kelamin"
                                  name="jenis_kelamin"
                                  value={tahti.jenis_kelamin}
                                  onChange={handleChange}
                                  default="Pilih"
                                  options={[
                                    { value: "Laki-Laki", text: "Laki-Laki" },
                                    { value: "Perempuan", text: "Perempuan" },
                                  ]}
                                  required
                                  disabled={!isFormEditable}
                                />
                              </Col>
                              {tahti.asal_tahanan === "Lapas" ? null : (
                                <>
                                  <Col sm="6">
                                    <Select
                                      label="Kewarganegaraan"
                                      name="kebangsaan"
                                      value={tahti.kebangsaan}
                                      onChange={handleChange}
                                      default="Pilih"
                                      options={[
                                        { value: "WNI", text: "WNI" },
                                        { value: "WNA", text: "WNA" },
                                      ]}
                                      // required
                                      disabled={!isFormEditable}
                                    />
                                  </Col>
                                  <Col sm="6">
                                    <Select
                                      label="Asal Negara"
                                      name="asal_negara"
                                      value={tahti.asal_negara}
                                      onChange={handleChange}
                                      default="Pilih"
                                      options={
                                        tahti.kebangsaan === "WNA"
                                          ? country
                                          : [
                                              {
                                                value: "Indonesia",
                                                text: "Indonesia",
                                              },
                                            ]
                                      }
                                      // required
                                      disabled={!isFormEditable}
                                    />
                                  </Col>
                                  <Col sm="6">
                                    <Select
                                      label="Agama"
                                      name="agama"
                                      value={tahti.agama}
                                      onChange={handleChange}
                                      default="Pilih"
                                      options={[
                                        { value: "Islam", text: "Islam" },
                                        { value: "Kristen", text: "Kristen" },
                                        { value: "Katholik", text: "Katholik" },
                                        { value: "Hindu", text: "Hindu" },
                                        { value: "Budha", text: "Budha" },
                                        { value: "N/A", text: "N/A" },
                                      ]}
                                      // required
                                      disabled={!isFormEditable}
                                    />
                                  </Col>
                                  <Col sm="6">
                                    <Input
                                      label="Pekerjaan"
                                      name="pekerjaan"
                                      value={tahti.pekerjaan}
                                      onChange={handleChange}
                                      type="text"
                                      // required
                                      disabled={!isFormEditable}
                                    />
                                  </Col>
                                </>
                              )}
                              <Col sm="6">
                                <Input
                                  as="textarea"
                                  label="Alamat"
                                  name="alamat"
                                  value={tahti.alamat}
                                  onChange={handleChange}
                                  // required
                                  disabled={!isFormEditable}
                                />
                              </Col>
                              {tahti.asal_tahanan === "Lapas" ? null : (
                                <>
                                  <Col sm="6">
                                    <Input
                                      label="No. Dokkes"
                                      name="no_dokkes"
                                      value={tahti.no_dokkes}
                                      onChange={handleChange}
                                      disabled={!isFormEditable}
                                    />
                                  </Col>
                                  <Col sm="6">
                                    <DatePicker
                                      label="Tanggal dokkes"
                                      name="tanggal_dokkes"
                                      timeFormat="HH:mm:ss"
                                      dateFormat="dd/MM/yyyy"
                                      maxDate={new Date()}
                                      selected={payloadDate.tanggal_dokkes}
                                      onChange={(date) => {
                                        setPayloadDate({
                                          ...payloadDate,
                                          tanggal_dokkes: date,
                                        });
                                        handleDatePicker(
                                          date,
                                          "tanggal_dokkes"
                                        );
                                      }}
                                      disabled={!isFormEditable}
                                    />
                                  </Col>
                                  <Col sm="6">
                                    <Select
                                      label="Golongan Darah"
                                      name="golongan_darah"
                                      value={tahti.golongan_darah}
                                      onChange={handleChange}
                                      options={[
                                        { value: "A+", text: "A+" },
                                        { value: "A-", text: "A-" },
                                        { value: "B+", text: "B+" },
                                        { value: "B-", text: "B-" },
                                        { value: "AB+", text: "AB+" },
                                        { value: "AB-", text: "AB-" },
                                        { value: "O+", text: "O+" },
                                        { value: "O-", text: "O-" },
                                      ]}
                                      disabled={!isFormEditable}
                                    />
                                  </Col>
                                  <Col sm="6">
                                    <Input
                                      type="number"
                                      label="No. KTP"
                                      name="no_ktp"
                                      value={tahti.no_ktp}
                                      onChange={handleChange}
                                      disabled={!isFormEditable}
                                    />
                                  </Col>
                                  <Col sm="6">
                                    <Input
                                      label="No. Passport"
                                      name="no_passport"
                                      value={tahti.no_passport}
                                      onChange={handleChange}
                                      disabled={!isFormEditable}
                                    />
                                  </Col>
                                  <Col sm="6">
                                    <Input
                                      type="number"
                                      label="No. SIM"
                                      name="no_sim"
                                      value={tahti.no_sim}
                                      onChange={handleChange}
                                      disabled={!isFormEditable}
                                    />
                                  </Col>
                                  <Col sm="6">
                                    <Input
                                      type="file"
                                      label="Lampiran"
                                      name="lampiran"
                                      // required
                                      accepts={[".pdf"]}
                                      disabled={!isFormEditable}
                                      onChange={(e) => {
                                        const files = e.target.files[0];
                                        setTahti({
                                          ...tahti,
                                          lampiran: [files],
                                        });
                                      }}
                                    />
                                    {detailData?.info_tahanan_files?.lampiran &&
                                      (() => {
                                        const { lampiran } =
                                          detailData.info_tahanan_files;
                                        const { p: path, k: kategori } =
                                          trimUrlPath(lampiran);
                                        const file = trimFileUrl(lampiran);

                                        return (
                                          <Link
                                            to={`/arsip/view/${file}?kategori=${kategori}&p=${encodeToHex(
                                              path
                                            )}`}
                                            className="text-primary fs-6 fw-medium d-flex align-items-center gap-2"
                                          >
                                            <i className="ri-link"></i>
                                            File Yang Sudah Terupload
                                          </Link>
                                        );
                                      })()}
                                  </Col>
                                </>
                              )}
                            </Row>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="custom-header">
                    Informasi Dokumen Penahanan
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row className="g-2">
                          <Col sm="12">
                            <Row className="g-2 mt-1">
                              <Col
                                xs="auto"
                                className="d-flex align-items-center me-3"
                              >
                                1
                              </Col>
                              <Col sm="5">
                                <Input
                                  label="No. LP"
                                  name={
                                    tahti.asal_tahanan === "Lapas"
                                      ? "no_laporan_lapas"
                                      : "no_laporan"
                                  }
                                  value={
                                    tahti.asal_tahanan === "Lapas"
                                      ? tahti.no_laporan_lapas
                                      : tahti.no_laporan
                                  }
                                  onChange={handleChange}
                                  required
                                  disabled={!isFormEditable}
                                />
                              </Col>
                              <Col sm="2">
                                <DatePicker
                                  label="Tanggal Laporan"
                                  name={
                                    tahti.asal_tahanan === "Lapas"
                                      ? "tanggal_laporan_lapas"
                                      : "tanggal_laporan"
                                  }
                                  timeFormat="HH:mm:ss"
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                  selected={
                                    tahti.asal_tahanan === "Lapas"
                                      ? payloadDate.tanggal_laporan_lapas
                                      : payloadDate.tanggal_laporan
                                  }
                                  onChange={(date) => {
                                    const name =
                                      tahti.asal_tahanan === "Lapas"
                                        ? "tanggal_laporan_lapas"
                                        : "tanggal_laporan";
                                    setPayloadDate({
                                      ...payloadDate,
                                      [name]: date,
                                    });
                                    handleDatePicker(date, name);
                                  }}
                                  required
                                  disabled={!isFormEditable}
                                />
                              </Col>
                              {detailData?.asal_tahanan === "Lapas" ||
                              tahti.asal_tahanan === "Lapas" ? (
                                <>
                                  <Col sm="4">
                                    <Input
                                      type="file"
                                      label="Arsip Laporan Lapas"
                                      name="arsip_laporan_lapas"
                                      // required
                                      accepts={[".pdf"]}
                                      disabled={!isFormEditable}
                                      onChange={(e) => {
                                        const files = e.target.files[0];
                                        setTahti({
                                          ...tahti,
                                          arsip_laporan_lapas: [files],
                                        });
                                      }}
                                    />
                                    {detailData?.files?.arsip_laporan ? (
                                      (() => {
                                        const { arsip_laporan } =
                                          detailData.files;
                                        const { p: path, k: kategori } =
                                          trimUrlPath(arsip_laporan);
                                        const file = trimFileUrl(arsip_laporan);

                                        return (
                                          <Link
                                            to={`/arsip/view/${file}?kategori=${kategori}&p=${encodeToHex(
                                              path
                                            )}`}
                                            className="text-primary fs-6 fw-medium d-flex align-items-center gap-2"
                                          >
                                            <i className="ri-link"></i>
                                            File Yang Sudah Terupload
                                          </Link>
                                        );
                                      })()
                                    ) : (
                                      <Col className="mt-4"></Col>
                                    )}
                                  </Col>
                                  <Col
                                    xs="auto"
                                    className="d-flex align-items-center ms-3"
                                  >
                                    {oneLapas ? (
                                      <span
                                        style={{
                                          color: "green",
                                          fontSize: "1.5rem",
                                        }}
                                      >
                                        <i className="ri-check-line"></i>
                                      </span>
                                    ) : (
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "1.5rem",
                                        }}
                                      >
                                        <i className="ri-close-large-line"></i>
                                      </span>
                                    )}
                                  </Col>
                                </>
                              ) : (
                                <>
                                  <Col sm="4">
                                    <Input
                                      type="file"
                                      label="Arsip Laporan"
                                      name="arsip_nomor_lp"
                                      // required
                                      accepts={[".pdf"]}
                                      disabled={!isFormEditable}
                                      onChange={(e) => {
                                        const files = e.target.files[0];
                                        setTahti({
                                          ...tahti,
                                          arsip_nomor_lp: [files],
                                        });
                                      }}
                                    />
                                    {detailData?.info_tahanan_files
                                      ?.arsip_nomor_lp ? (
                                      (() => {
                                        const { arsip_nomor_lp } =
                                          detailData.info_tahanan_files;
                                        const { p: path, k: kategori } =
                                          trimUrlPath(arsip_nomor_lp);
                                        const file =
                                          trimFileUrl(arsip_nomor_lp);

                                        return (
                                          <Link
                                            to={`/arsip/view/${file}?kategori=${kategori}&p=${encodeToHex(
                                              path
                                            )}`}
                                            className="text-primary fs-6 fw-medium d-flex align-items-center gap-2"
                                          >
                                            <i className="ri-link"></i>
                                            File Yang Sudah Terupload
                                          </Link>
                                        );
                                      })()
                                    ) : (
                                      <Col className="mt-4"></Col>
                                    )}
                                  </Col>
                                  <Col
                                    xs="auto"
                                    className="d-flex align-items-center ms-3"
                                  >
                                    {one ? (
                                      <span
                                        style={{
                                          color: "green",
                                          fontSize: "1.5rem",
                                        }}
                                      >
                                        <i className="ri-check-line"></i>
                                      </span>
                                    ) : (
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "1.5rem",
                                        }}
                                      >
                                        <i className="ri-close-large-line"></i>
                                      </span>
                                    )}
                                  </Col>
                                </>
                              )}
                            </Row>

                            <Row className="g-2 mt-1">
                              <Col
                                xs="auto"
                                className="d-flex align-items-center me-3"
                              >
                                2
                              </Col>
                              <Col sm="5">
                                <Input
                                  label={
                                    tahti.asal_tahanan === "Lapas"
                                      ? "BA Peminjaman Tahanan Narapidana (dari Lapas)"
                                      : "No. Sprin Penahanan"
                                  }
                                  name={
                                    tahti.asal_tahanan === "Lapas"
                                      ? "ba_peminjaman_lapas"
                                      : "no_sprin_penahanan"
                                  }
                                  value={
                                    tahti.asal_tahanan === "Lapas"
                                      ? tahti.ba_peminjaman_lapas
                                      : tahti.no_sprin_penahanan
                                  }
                                  onChange={handleChange}
                                  required
                                  disabled={!isFormEditable}
                                />
                              </Col>
                              <Col sm="2">
                                <DatePicker
                                  label={
                                    tahti.asal_tahanan === "Lapas"
                                      ? "Tanggal Dokumen"
                                      : "Tanggal Sprin Penahanan"
                                  }
                                  timeFormat="HH:mm:ss"
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                  name={
                                    tahti.asal_tahanan === "Lapas"
                                      ? "tanggal_dokumen_ba"
                                      : "tanggal_sprin_penahanan"
                                  }
                                  selected={
                                    tahti.asal_tahanan === "Lapas"
                                      ? payloadDate.tanggal_dokumen_ba
                                      : payloadDate.tanggal_sprin_penahanan
                                  }
                                  onChange={(date) => {
                                    const name =
                                      tahti.asal_tahanan === "Lapas"
                                        ? "tanggal_dokumen_ba"
                                        : "tanggal_sprin_penahanan";
                                    setPayloadDate({
                                      ...payloadDate,
                                      [name]: date,
                                    });
                                    handleDatePicker(date, name);
                                  }}
                                  required
                                  disabled={!isFormEditable}
                                />
                              </Col>
                              {detailData?.asal_tahanan === "Lapas" ||
                              tahti.asal_tahanan === "Lapas" ? (
                                <>
                                  <Col sm="4">
                                    <Input
                                      type="file"
                                      label="Arsip BA Lapas"
                                      name="arsip_berita_acara_lapas"
                                      // required
                                      accepts={[".pdf"]}
                                      disabled={!isFormEditable}
                                      onChange={(e) => {
                                        const files = e.target.files[0];
                                        setTahti({
                                          ...tahti,
                                          arsip_berita_acara_lapas: [files],
                                        });
                                      }}
                                    />
                                    {detailData?.files
                                      ?.arsip_ba_peminjaman_tahanan_lapas ? (
                                      (() => {
                                        const {
                                          arsip_ba_peminjaman_tahanan_lapas,
                                        } = detailData.files;
                                        const { p: path, k: kategori } =
                                          trimUrlPath(
                                            arsip_ba_peminjaman_tahanan_lapas
                                          );
                                        const file = trimFileUrl(
                                          arsip_ba_peminjaman_tahanan_lapas
                                        );

                                        return (
                                          <Link
                                            to={`/arsip/view/${file}?kategori=${kategori}&p=${encodeToHex(
                                              path
                                            )}`}
                                            className="text-primary fs-6 fw-medium d-flex align-items-center gap-2"
                                          >
                                            <i className="ri-link"></i>
                                            File Yang Sudah Terupload
                                          </Link>
                                        );
                                      })()
                                    ) : (
                                      <Col className="mt-4"></Col>
                                    )}
                                  </Col>
                                  <Col
                                    xs="auto"
                                    className="d-flex align-items-center ms-3"
                                  >
                                    {twoLapas ? (
                                      <span
                                        style={{
                                          color: "green",
                                          fontSize: "1.5rem",
                                        }}
                                      >
                                        <i className="ri-check-line"></i>
                                      </span>
                                    ) : (
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "1.5rem",
                                        }}
                                      >
                                        <i className="ri-close-large-line"></i>
                                      </span>
                                    )}
                                  </Col>
                                </>
                              ) : (
                                <>
                                  <Col sm="4">
                                    <Input
                                      type="file"
                                      label="Arsip Sprin Penahanan"
                                      name="arsip_no_sprin_penahanan"
                                      // required
                                      accepts={[".pdf"]}
                                      disabled={!isFormEditable}
                                      onChange={(e) => {
                                        const files = e.target.files[0];
                                        setTahti({
                                          ...tahti,
                                          arsip_no_sprin_penahanan: [files],
                                        });
                                      }}
                                    />
                                    {detailData?.info_tahanan_files
                                      ?.arsip_no_sprin_penahanan ? (
                                      (() => {
                                        const { arsip_no_sprin_penahanan } =
                                          detailData.info_tahanan_files;
                                        const { p: path, k: kategori } =
                                          trimUrlPath(arsip_no_sprin_penahanan);
                                        const file = trimFileUrl(
                                          arsip_no_sprin_penahanan
                                        );

                                        return (
                                          <Link
                                            to={`/arsip/view/${file}?kategori=${kategori}&p=${encodeToHex(
                                              path
                                            )}`}
                                            className="text-primary fs-6 fw-medium d-flex align-items-center gap-2"
                                          >
                                            <i className="ri-link"></i>
                                            File Yang Sudah Terupload
                                          </Link>
                                        );
                                      })()
                                    ) : (
                                      <Col className="mt-4"></Col>
                                    )}
                                  </Col>
                                  <Col
                                    xs="auto"
                                    className="d-flex align-items-center ms-3"
                                  >
                                    {two ? (
                                      <span
                                        style={{
                                          color: "green",
                                          fontSize: "1.5rem",
                                        }}
                                      >
                                        <i className="ri-check-line"></i>
                                      </span>
                                    ) : (
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "1.5rem",
                                        }}
                                      >
                                        <i className="ri-close-large-line"></i>
                                      </span>
                                    )}
                                  </Col>
                                </>
                              )}
                            </Row>
                            {tahti.asal_tahanan !== "Lapas" && (
                              <Row className="g-2 mt-1">
                                <Col
                                  xs="auto"
                                  className="d-flex align-items-center me-3"
                                >
                                  3
                                </Col>
                                <Col sm="5">
                                  <Input
                                    label="No. BA Penahanan"
                                    name="no_ba_penahanan"
                                    value={tahti.no_ba_penahanan}
                                    onChange={handleChange}
                                    // required
                                    disabled={!isFormEditable}
                                  />
                                </Col>
                                <Col sm="2">
                                  <DatePicker
                                    label="Tanggal BA Penahanan"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={new Date()}
                                    name="tanggal_ba_penahanan"
                                    selected={payloadDate.tanggal_ba_penahanan}
                                    onChange={(date) => {
                                      setPayloadDate({
                                        ...payloadDate,
                                        tanggal_ba_penahanan: date,
                                      });
                                      handleDatePicker(
                                        date,
                                        "tanggal_ba_penahanan"
                                      );
                                    }}
                                    // required
                                    disabled={!isFormEditable}
                                  />
                                </Col>
                                <Col sm="4">
                                  <Input
                                    type="file"
                                    label="Arsip BA Penahanan"
                                    name="arsip_ba_penahanan"
                                    // required
                                    accepts={[".pdf"]}
                                    disabled={!isFormEditable}
                                    onChange={(e) => {
                                      const files = e.target.files[0];
                                      setTahti({
                                        ...tahti,
                                        arsip_ba_penahanan: [files],
                                      });
                                    }}
                                  />
                                  {detailData?.info_tahanan_files
                                    ?.arsip_ba_penahanan ? (
                                    (() => {
                                      const { arsip_ba_penahanan } =
                                        detailData.info_tahanan_files;
                                      const { p: path, k: kategori } =
                                        trimUrlPath(arsip_ba_penahanan);
                                      const file =
                                        trimFileUrl(arsip_ba_penahanan);

                                      return (
                                        <Link
                                          to={`/arsip/view/${file}?kategori=${kategori}&p=${encodeToHex(
                                            path
                                          )}`}
                                          className="text-primary fs-6 fw-medium d-flex align-items-center gap-2"
                                        >
                                          <i className="ri-link"></i>
                                          File Yang Sudah Terupload
                                        </Link>
                                      );
                                    })()
                                  ) : (
                                    <Col className="mt-4"></Col>
                                  )}
                                </Col>
                                <Col
                                  xs="auto"
                                  className="d-flex align-items-center ms-3"
                                >
                                  {three ? (
                                    <span
                                      style={{
                                        color: "green",
                                        fontSize: "1.5rem",
                                      }}
                                    >
                                      <i className="ri-check-line"></i>
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "1.5rem",
                                      }}
                                    >
                                      <i className="ri-close-large-line"></i>
                                    </span>
                                  )}
                                </Col>
                              </Row>
                            )}

                            {tahti.asal_tahanan !== "Lapas" && (
                              <Row className="g-2 mt-1">
                                <Col
                                  xs="auto"
                                  className="d-flex align-items-center me-3"
                                >
                                  4
                                </Col>
                                <Col sm="5">
                                  <Input
                                    label="No. Keterangan Pemeriksaan Tersangka dari PUSDOKKES"
                                    name="no_keterangan_pemeriksaan_tersangka_dari_pusdokkes"
                                    value={
                                      tahti.no_keterangan_pemeriksaan_tersangka_dari_pusdokkes
                                    }
                                    onChange={handleChange}
                                    // required
                                    disabled={!isFormEditable}
                                  />
                                </Col>
                                <Col sm="2">
                                  <DatePicker
                                    label="Tanggal Surat Keterangan PUSDOKKES"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={new Date()}
                                    name="tanggal_surat_keterangan_pusdokkes"
                                    selected={
                                      payloadDate.tanggal_surat_keterangan_pusdokkes
                                    }
                                    onChange={(date) => {
                                      setPayloadDate({
                                        ...payloadDate,
                                        tanggal_surat_keterangan_pusdokkes:
                                          date,
                                      });
                                      handleDatePicker(
                                        date,
                                        "tanggal_surat_keterangan_pusdokkes"
                                      );
                                    }}
                                    // required
                                    disabled={!isFormEditable}
                                  />
                                </Col>
                                <Col sm="4">
                                  <Input
                                    type="file"
                                    label="Arsip Surat Keterangan PUSDOKKES"
                                    name="arsip_pemeriksaan_tersangka_dari_pusdokkes"
                                    // required
                                    accepts={[".pdf"]}
                                    disabled={!isFormEditable}
                                    onChange={(e) => {
                                      const files = e.target.files[0];
                                      setTahti({
                                        ...tahti,
                                        arsip_pemeriksaan_tersangka_dari_pusdokkes:
                                          [files],
                                      });
                                    }}
                                  />
                                  {detailData?.info_tahanan_files
                                    ?.arsip_pemeriksaan_tersangka_dari_pusdokkes ? (
                                    (() => {
                                      const {
                                        arsip_pemeriksaan_tersangka_dari_pusdokkes,
                                      } = detailData.info_tahanan_files;
                                      const { p: path, k: kategori } =
                                        trimUrlPath(
                                          arsip_pemeriksaan_tersangka_dari_pusdokkes
                                        );
                                      const file = trimFileUrl(
                                        arsip_pemeriksaan_tersangka_dari_pusdokkes
                                      );

                                      return (
                                        <Link
                                          to={`/arsip/view/${file}?kategori=${kategori}&p=${encodeToHex(
                                            path
                                          )}`}
                                          className="text-primary fs-6 fw-medium d-flex align-items-center gap-2"
                                        >
                                          <i className="ri-link"></i>
                                          File Yang Sudah Terupload
                                        </Link>
                                      );
                                    })()
                                  ) : (
                                    <Col className="mt-4"></Col>
                                  )}
                                </Col>
                                <Col
                                  xs="auto"
                                  className="d-flex align-items-center ms-3"
                                >
                                  {four ? (
                                    <span
                                      style={{
                                        color: "green",
                                        fontSize: "1.5rem",
                                      }}
                                    >
                                      <i className="ri-check-line"></i>
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "1.5rem",
                                      }}
                                    >
                                      <i className="ri-close-large-line"></i>
                                    </span>
                                  )}
                                </Col>
                              </Row>
                            )}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
                {tahti.asal_tahanan === "Lapas" ? null : (
                  <Accordion.Item eventKey="2">
                    <Accordion.Header className="custom-header">
                      Informasi Penyidikan
                    </Accordion.Header>
                    <Accordion.Body className="py-2 px-0">
                      <Card className="card bg-white">
                        <Card.Body>
                          <Row className="mt-2">
                            <Col sm="12">
                              <Card className="card bg-white">
                                <Card.Body>
                                  <Row className="g-1 mb-2 d-flex align-items-center">
                                    <Col sm="12" md="5">
                                      <h6>Daftar Penyidik</h6>
                                    </Col>
                                    <Col sm="12" md="7">
                                      <Link className="d-flex align-items-center justify-content-end">
                                        <Button
                                          className="btn btn-primary"
                                          onClick={handleAddRow}
                                          disabled={!isFormEditable}
                                        >
                                          TAMBAH
                                        </Button>
                                      </Link>
                                    </Col>
                                  </Row>
                                  <Table className="table dems-table">
                                    <thead>
                                      <tr>
                                        <th>No</th>
                                        <th>NRP</th>
                                        <th>Nama Penyidik</th>
                                        <th>Asal Satker</th>
                                        <th>Pangkat</th>
                                        <th>Jabatan</th>
                                        <th>No. HP</th>
                                        <th>&nbsp;</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {penyidikData.length
                                        ? penyidikData.map((data, index) => (
                                            <tr key={index}>
                                              <td>{index + 1}</td>
                                              <td>{data.nrp}</td>
                                              <td>{data.nama_penyidik}</td>
                                              <td>{data.nama_satker}</td>
                                              <td>{data.pangkat}</td>
                                              <td>{data.jabatan}</td>
                                              <td>{data.no_hp}</td>
                                              <td>
                                                <div className="d-flex align-items-center justify-content-end gap-2">
                                                  <Badge
                                                    onClick={() =>
                                                      handleDelete(index)
                                                    }
                                                    className="btn bg-danger"
                                                    as="button"
                                                    disabled={!isFormEditable}
                                                  >
                                                    <i className="ri-delete-bin-line"></i>
                                                  </Badge>
                                                </div>
                                              </td>
                                            </tr>
                                          ))
                                        : !penyidikData.length && (
                                            <tr>
                                              <td
                                                colSpan="4"
                                                style={{ textAlign: "center" }}
                                              >
                                                <span>
                                                  Tidak ada data penyidik!
                                                </span>
                                              </td>
                                            </tr>
                                          )}
                                      {isAddingRow && (
                                        <tr>
                                          <td></td>
                                          <td>
                                            <Input
                                              type="text"
                                              name="nrp"
                                              value={penyidik.nrp}
                                              onChange={handleNewRowChange}
                                              placeholder="NRP"
                                            />
                                          </td>
                                          <td>
                                            <Input
                                              type="text"
                                              name="nama_penyidik"
                                              value={penyidik.nama_penyidik}
                                              onChange={handleNewRowChange}
                                              placeholder="Nama Penyidik"
                                            />
                                          </td>
                                          <td style={{ width: "200px" }}>
                                            <Select2
                                              name="nama_satker"
                                              defaultValue={"-"}
                                              placeholder={"Satker"}
                                              options={satkersFilter}
                                              value={penyidik.nama_satker}
                                              onChange={(selectedOption) => {
                                                setPenyidik({
                                                  ...penyidik,
                                                  nama_satker:
                                                    selectedOption.value,
                                                });
                                              }}
                                              required
                                            />
                                            {/* <Select
                                              default="Pilih Satker"
                                              name="nama_satker"
                                              value={penyidik.nama_satker}
                                              onChange={handleNewRowChange}
                                              options={satkersFilter}
                                            /> */}
                                          </td>
                                          <td>
                                            <Select
                                              default="Pilih Pangkat"
                                              name="pangkat"
                                              value={penyidik.pangkat}
                                              onChange={handleNewRowChange}
                                              options={pangkat}
                                            />
                                          </td>
                                          <td>
                                            <Input
                                              type="text"
                                              name="jabatan"
                                              value={penyidik.jabatan}
                                              onChange={handleNewRowChange}
                                              placeholder="Jabatan"
                                            />
                                          </td>
                                          <td>
                                            <Input
                                              type="text"
                                              name="no_hp"
                                              value={penyidik.no_hp}
                                              onChange={handleNewRowChange}
                                              placeholder="NO. HP"
                                            />
                                          </td>
                                          <td className="d-flex gap-1">
                                            <Button
                                              className="btn bg-danger"
                                              onClick={closeRow}
                                            >
                                              <i class="ri-close-large-line"></i>
                                            </Button>
                                            <Button
                                              className="btn bg-success"
                                              onClick={handleSaveRow}
                                            >
                                              <i class="ri-check-line"></i>
                                            </Button>
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </Table>
                                </Card.Body>
                              </Card>
                            </Col>
                          </Row>

                          <Row className="g-2 mt-2">
                            <Col sm="3">
                              <Input
                                label="No. Kasus"
                                name="no_kasus"
                                value={tahti.no_kasus}
                                onChange={handleChange}
                                disabled={!isFormEditable}
                              />
                            </Col>
                            <Col sm="3">
                              <DatePicker
                                label="Tanggal kejadian"
                                name="tanggal_kejadian"
                                timeFormat="HH:mm:ss"
                                dateFormat="dd/MM/yyyy"
                                maxDate={new Date()}
                                selected={payloadDate.tanggal_kejadian}
                                onChange={(date) => {
                                  setPayloadDate({
                                    ...payloadDate,
                                    tanggal_kejadian: date,
                                  });
                                  handleDatePicker(date, "tanggal_kejadian");
                                }}
                                disabled={!isFormEditable}
                                required
                              />
                            </Col>
                            <Col sm="6">
                              <Input
                                label="Perkara"
                                name="perkara"
                                value={tahti.perkara}
                                onChange={handleChange}
                                required
                                disabled={!isFormEditable}
                              />
                            </Col>
                          </Row>
                          <Row className="g-2 mt-2">
                            <Col sm="3">
                              <Input
                                required
                                label="Asal Satuan Kerja"
                                name="nama_satker"
                                value={tahti.nama_satker}
                                disabled
                              />
                            </Col>
                            <Col sm="3">
                              <Input
                                as="textarea"
                                label="Pasal Pelanggaran"
                                name="pasal_pelanggaran"
                                value={tahti.pasal_pelanggaran}
                                onChange={handleChange}
                                disabled={!isFormEditable}
                              />
                            </Col>
                            <Col sm="6">
                              <Input
                                as="textarea"
                                label="Tempat Kejadian Perkara"
                                name="alamat_tkp"
                                value={tahti.alamat_tkp}
                                onChange={handleChange}
                                required
                                disabled={!isFormEditable}
                              />
                            </Col>
                          </Row>

                          <Row className="g-2 mt-2">
                            <Col sm="3">
                              {/* <Input
                                required
                                label="Penempatan Rutan"
                                name="nama_rutan"
                                style={{ textTransform: "uppercase" }}
                                value={tahti.nama_rutan}
                                onChange={handleChange}
                                disabled={!isFormEditable}
                              /> */}
                              <Select
                                label="Penempatan Rutan"
                                name="nama_rutan"
                                value={tahti.nama_rutan}
                                onChange={(e) => {
                                  const selectedId = e.target.value;
                                  const selectedRutan = masterListRutan.find(
                                    (rutan) => rutan.nama_rutan === selectedId
                                  );

                                  if (selectedRutan) {
                                    setTahti({
                                      ...tahti,
                                      nama_rutan: selectedRutan.nama_rutan,
                                      kota_rutan: selectedRutan.kota,
                                      alamat_rutan: selectedRutan.alamat_rutan,
                                    });
                                  }
                                }}
                                default="Pilih"
                                options={rutanFilter}
                                required
                                disabled={!isFormEditable}
                              />
                            </Col>
                            <Col sm="3">
                              <Input
                                label="Kota Rutan"
                                name="kota_rutan"
                                style={{ textTransform: "uppercase" }}
                                value={tahti.kota_rutan}
                                onChange={handleChange}
                                disabled={!isFormEditable}
                              />
                            </Col>
                            <Col sm="6">
                              <Input
                                as="textarea"
                                label="Alamat Rutan"
                                name="alamat_rutan"
                                style={{ textTransform: "uppercase" }}
                                value={tahti.alamat_rutan}
                                onChange={handleChange}
                                disabled={!isFormEditable}
                              />
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {tahti.asal_tahanan === "Lapas" ? (
                  <Accordion.Item eventKey="2">
                    <Accordion.Header className="custom-header">
                      Informasi Penahanan
                    </Accordion.Header>
                    <Accordion.Body className="py-2 px-0">
                      <Card className="card bg-white">
                        <Card.Body>
                          <Row className="g-2 mt-2">
                            <Col sm="3">
                              <Input
                                label="No. Register"
                                name="no_register_lapas"
                                value={tahti.no_register_lapas}
                                onChange={handleChange}
                                disabled={!isFormEditable}
                                required
                              />
                            </Col>
                            <Col sm="3">
                              <Input
                                label="No. Putusan"
                                name="no_putusan"
                                value={tahti.no_putusan}
                                onChange={handleChange}
                                disabled={!isFormEditable}
                                required
                              />
                            </Col>
                            <Col sm="6">
                              <Input
                                label="Perkara"
                                name="perkara"
                                value={tahti.perkara}
                                onChange={handleChange}
                                required
                                disabled={!isFormEditable}
                              />
                            </Col>
                            {/* <Col sm="3">
                              <Input
                                label="Tindak Pidana"
                                name="tindak_pidana"
                                value={tahti.tindak_pidana}
                                onChange={handleChange}
                                required
                                disabled={!isFormEditable}
                              />
                            </Col> */}
                          </Row>
                          <Row className="g-2 mt-2">
                            <Col sm="3">
                              <Input
                                required
                                label="Asal Satuan Kerja"
                                name="nama_satker"
                                value={tahti.nama_satker}
                                disabled
                              />
                            </Col>
                            <Col sm="3">
                              <Input
                                required
                                label="Penempatan Rutan"
                                name="nama_rutan"
                                style={{ textTransform: "uppercase" }}
                                value={tahti.nama_rutan}
                                onChange={handleChange}
                                disabled={!isFormEditable}
                              />
                            </Col>
                            <Col sm="3">
                              <Input
                                label="Kota Rutan"
                                name="kota_rutan"
                                style={{ textTransform: "uppercase" }}
                                value={tahti.kota_rutan}
                                onChange={handleChange}
                                disabled={!isFormEditable}
                              />
                            </Col>
                            <Col sm="3">
                              <Input
                                as="textarea"
                                label="Alamat Rutan"
                                name="alamat_rutan"
                                style={{ textTransform: "uppercase" }}
                                value={tahti.alamat_rutan}
                                onChange={handleChange}
                                disabled={!isFormEditable}
                              />
                            </Col>
                            <Col sm="3">
                              <Select
                                label="Tipe Tahanan"
                                name="tipe_tahanan"
                                onChange={handleChange}
                                value={tahti.tipe_tahanan}
                                default="Pilih"
                                options={[
                                  {
                                    value: "Dewasa",
                                    text: "Dewasa",
                                  },
                                  { value: "Anak", text: "Anak" },
                                ]}
                                disabled={!isFormEditable}
                              />
                            </Col>
                            <Col sm="3">
                              <DatePicker
                                label="Tanggal Masuk Tahanan"
                                name="awal_penahanan"
                                timeFormat="HH:mm:ss"
                                dateFormat="dd/MM/yyyy"
                                maxDate={new Date()}
                                selected={payloadDate.awal_penahanan}
                                onChange={(date) => {
                                  setPayloadDate({
                                    ...payloadDate,
                                    awal_penahanan: date,
                                  });
                                  handleDatePicker(date, "awal_penahanan");
                                }}
                                required
                                disabled={!isFormEditable}
                              />
                            </Col>
                            <Col sm="3">
                              <DatePicker
                                label="Tanggal Keluar Tahanan"
                                name="akhir_penahanan"
                                timeFormat="HH:mm:ss"
                                dateFormat="dd/MM/yyyy"
                                selected={payloadDate.akhir_penahanan}
                                onChange={(date) => {
                                  setPayloadDate({
                                    ...payloadDate,
                                    akhir_penahanan: date,
                                  });
                                  handleDatePicker(date, "akhir_penahanan");
                                }}
                                required
                                disabled={!isFormEditable}
                              />
                            </Col>
                            <Col sm="3">
                              <Input
                                type="file"
                                label="Lampiran"
                                name="lampiran"
                                // required
                                accepts={[".pdf"]}
                                disabled={!isFormEditable}
                                onChange={(e) => {
                                  const files = e.target.files[0];
                                  setTahti({
                                    ...tahti,
                                    lampiran: [files],
                                  });
                                }}
                              />
                              {detailData?.files?.lampiran &&
                                (() => {
                                  const { lampiran } = detailData.files;
                                  const { p: path, k: kategori } =
                                    trimUrlPath(lampiran);
                                  const file = trimFileUrl(lampiran);

                                  return (
                                    <Link
                                      target="_blank"
                                      to={`/arsip/view/${file}?kategori=${kategori}&p=${encodeToHex(
                                        path
                                      )}`}
                                      className="text-primary fs-6 fw-medium d-flex align-items-center gap-2"
                                    >
                                      <i className="ri-link"></i>
                                      File Yang Sudah Terupload
                                    </Link>
                                  );
                                })()}
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                ) : (
                  <Accordion.Item eventKey="3">
                    <Accordion.Header className="custom-header">
                      Informasi Penahanan
                    </Accordion.Header>
                    <Accordion.Body className="py-2 px-0">
                      <Card className="card bg-white">
                        <Card.Body>
                          <Row className="g-2 mt-2">
                            <Col sm="4">
                              <Select
                                label="Periode Penahanan"
                                name="nama_fase_penahanan"
                                onChange={handleChange}
                                value={tahti.nama_fase_penahanan}
                                default="Pilih"
                                options={[
                                  {
                                    value: "Penyidik",
                                    text: "Fase 1 : Penyidik (20 Hari)",
                                  },
                                  {
                                    value: "Jaksa",
                                    text: "Fase 2 : Jaksa (40 Hari)",
                                  },
                                  {
                                    value: "PN1",
                                    text: "Fase 3 : PN1 (30 Hari)",
                                  },
                                  {
                                    value: "PN2",
                                    text: "Fase 4 : PN2 (30 Hari)",
                                  },
                                ]}
                                required
                                disabled={!isFormEditable}
                              />
                            </Col>
                            <Col sm="4">
                              <Input
                                label="Fase Penahanan"
                                value={tahti.nama_fase_penahanan}
                                required
                                disabled
                              />
                            </Col>
                            <Col sm="2">
                              <Select
                                label="Tipe Tahanan"
                                name="tipe_tahanan"
                                onChange={handleChange}
                                value={tahti.tipe_tahanan}
                                default="Pilih"
                                options={[
                                  {
                                    value: "Dewasa",
                                    text: "Dewasa",
                                  },
                                  { value: "Anak", text: "Anak" },
                                ]}
                                disabled={!isFormEditable}
                              />
                            </Col>
                            <Col sm="2">
                              <Select
                                label="Kondisi Tahanan"
                                name="kondisi_tahanan"
                                onChange={handleChange}
                                value={tahti.kondisi_tahanan}
                                default="Pilih"
                                options={[
                                  {
                                    value: "Sehat",
                                    text: "Sehat",
                                  },
                                  { value: "Tidak Sehat", text: "Tidak Sehat" },
                                ]}
                                required
                                disabled={!isFormEditable}
                              />
                            </Col>
                            <Col sm={isLapas || isRegistrasi ? "4" : "3"}>
                              <DatePicker
                                label="Tanggal Penahanan (Sesuai Sprin Han)"
                                name="tanggal_penahanan"
                                timeFormat="HH:mm:ss"
                                dateFormat="dd/MM/yyyy"
                                maxDate={new Date()}
                                selected={payloadDate.tanggal_penahanan}
                                onChange={(date) => {
                                  setPayloadDate({
                                    ...payloadDate,
                                    tanggal_penahanan: date,
                                  });
                                  handleDatePicker(date, "tanggal_penahanan");
                                }}
                                required
                                disabled
                              />
                            </Col>
                            <Col sm={isLapas || isRegistrasi ? "4" : "3"}>
                              <DatePicker
                                label="Tanggal Masuk Tahanan"
                                name="awal_penahanan"
                                timeFormat="HH:mm:ss"
                                dateFormat="dd/MM/yyyy"
                                maxDate={new Date()}
                                selected={payloadDate.awal_penahanan}
                                onChange={(date) => {
                                  setPayloadDate({
                                    ...payloadDate,
                                    awal_penahanan: date,
                                  });
                                  handleDatePicker(date, "awal_penahanan");
                                }}
                                required
                                disabled={!isFormEditable}
                              />
                            </Col>
                            <Col sm={isLapas || isRegistrasi ? "4" : "3"}>
                              <DatePicker
                                label="Tanggal Terakhir Ditahan"
                                name="akhir_penahanan"
                                selected={payloadDate.akhir_penahanan}
                                onChange={(date) =>
                                  handleDatePicker(date, "akhir_penahanan")
                                }
                                timeFormat="HH:mm:ss"
                                dateFormat="dd/MM/yyyy"
                                required
                                disabled
                              />
                            </Col>
                            {!isLapas && !isRegistrasi && (
                              <Col sm="3">
                                <Input
                                  label="Sisa Masa Tahanan"
                                  value={sisaMasaTahanan + " Hari"}
                                  disabled
                                />
                              </Col>
                            )}
                          </Row>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                <Accordion.Item eventKey="4">
                  <Accordion.Header className="custom-header">
                    Informasi Tambahan
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row className="mt-2 g-2">
                          <Col sm="6">
                            <Input
                              as="textarea"
                              label="Keterangan"
                              name="informasi_tambahan"
                              value={tahti.informasi_tambahan}
                              onChange={handleChange}
                              disabled={!isFormEditable}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
          {/* <div className="d-flex justify-content-end align-items-center gap-3">
            <Button
              variant="warning"
              as={Link}
              to={isRegistrasi ? "/tahanan/registrasi/list" : "/tahanan/list"}
            >
              BATAL
            </Button>
            {!isEdit &&
              !mutateAdd.isLoading &&
              !mutateEdit.isLoading &&
              !mutateAddLapas.isLoading &&
              !mutateEditLapas.isLoading && (
                <Button
                  variant="secondary"
                  onClick={() => handleSubmit("DRAFT")}
                  disabled={!isFormEditable}
                >
                  SIMPAN DRAFT
                </Button>
              )}
            {isRegistrasi &&
              !mutateAdd.isLoading &&
              !mutateEdit.isLoading &&
              !mutateAddLapas.isLoading &&
              !mutateEditLapas.isLoading && (
                <Button
                  variant="primary"
                  onClick={() => handleSubmit("FINAL")}
                  disabled={!isFormEditable}
                >
                  Simpan
                </Button>
              )}
            {isRegistrasi &&
              (mutateAdd.isLoading ||
                mutateEdit.isLoading ||
                mutateAddLapas.isLoading ||
                mutateEditLapas.isLoading) && (
                <div className="d-flex justify-content-end align-items-center gap-3">
                  <Button
                    variant="primary"
                    className="d-flex align-items-center gap-2"
                    disabled
                  >
                    Sedang Menyimpan <Spinner variant="light" size="sm" />
                  </Button>
                </div>
              )}
          </div> */}
          <div className="d-flex justify-content-end align-items-center gap-3">
            {!mutateAdd.isLoading && !mutateEdit.isLoading && (
              <Link
                to={`/tahanan/${isRegistrasi ? "registrasi/list" : "list"}`}
              >
                <Button variant="warning">BATAL</Button>
              </Link>
            )}
            {/* {!mutateAdd.isLoading && !mutateEdit.isLoading && (
              <Button
                variant="secondary"
                onClick={() => handleSubmit("DRAFT")}
                disabled={!isFormEditable}
                hidden={!isFormEditable}
              >
                SIMPAN DRAFT
              </Button>
            )} */}
            {!mutateAdd.isLoading && !mutateEdit.isLoading && (
              <Button
                variant="primary"
                onClick={() => handleSubmit("FINAL")}
                disabled={!isFormEditable}
                hidden={!isFormEditable}
              >
                SIMPAN
              </Button>
            )}
            {(mutateAdd.isLoading || mutateEdit.isLoading) && (
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
                disabled
              >
                Sedang Menyimpan <Spinner variant="light" size="sm" />
              </Button>
            )}
          </div>
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
}

export default FormTahti;
