import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Accordion,
  Row,
  Spinner,
  Table,
  Form,
  Alert,
  Placeholder,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  DatePicker,
  Image,
  Input,
  Select,
} from "../../../../components/Components";
import { debounce } from "lodash";
import {
  useGetDetailTahti,
  useGetPengeluaranDetail,
  usePutPengeluaran,
  useSatkerTahtiList,
} from "../../../../utils/apis/query";
import moment from "moment";
import { useQueryClient } from "react-query";
import { encodeToHex } from "../../../../utils";

function FormEdit() {
  const [isEdit, setIsEdit] = useState(false);
  const [isFormEditAble, setIsFormEditAble] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [formReady, setFormReady] = useState(false);
  const [payloadCariTahanann, setPayloadCariTahanan] = useState({
    nama_tersangka: "",
  });
  const [namaTahanan, setNamaTahanan] = useState("");
  const [idTahanan, setIdTahanan] = useState("");
  const [penyidik, setPenyidik] = useState([]);

  const [payloadDate, setPayloadDate] = useState({
    tanggal_sprin_pengeluaran: "",
    tanggal_pengeluaran: "",
    awal_penahanan: "",
    akhir_penahanan: "",
  });

  const [payloadForm, setPayloadForm] = useState({
    arsip: [],
    lampiran: [],
    nama_tahanan: "",
    no_tahanan: "",
    no_sprin_pengeluaran: "",
    tanggal_sprin_pengeluaran: "",
    tanggal_pengeluaran: "",
    kategori_pengeluaran: "Pembantaran",
    awal_penahanan: "",
    akhir_penahanan: "",
    kondisi: "",
    status_dokumen: "",
    status_terakhir: "",
    alasan: "",
    jenis_pengalihan: "rutan",
    kota: "",
    provinsi: "",
    alamat_rumah: "",
    rutan_tujuan: "",
    jenis_sp3: "",
    jaminan_penangguhan: "",
    nama_rumah_sakit: "",
    lokasi_rumah_sakit: "", // info
    jenis_pelimpahan: "",
    keterangan: "",
    penyidik_ids: [],
  });
  const [payloadFormDataTahanan, setPayloadFormDataTahanan] = useState({
    no_tahanan: "",
    nama: "",
    tempat_lahir: "",
    tanggal_lahir: "",
    umur: "",
    kelamin: "",
    kewarganegaraan: "",
    asal_negara: "",
    agama: "",
    pekerjaan: "",
    alamat: "",
    foto_tahanan: "",
    foto_sidikjari: "",
    perkara: "",
    penempatan_rutan: "",
    lokasi_rutan: "",
    riwayat: [],
  });

  const {
    data: dataTahanan,
    isFetching: tahananFetching,
    isError: tahananError,
  } = useGetDetailTahti(idTahanan, { enabled: !!idTahanan });

  const {
    data: dataDetail,
    isFetching: isDataDetailFetching,
    isError: isDataDetailError,
    error: dataDetailError,
  } = useGetPengeluaranDetail(id);

  const { data: dataRutanList } = useSatkerTahtiList();

  const handleCheckbox = (data, isChecked) => {
    if (isChecked) {
      setPayloadForm({
        ...payloadForm,
        penyidik_ids: [...payloadForm.penyidik_ids, data.id_penyidik],
      });
    } else {
      setPayloadForm({
        ...payloadForm,
        penyidik_ids: payloadForm.penyidik_ids.filter(
          (selectedId) => selectedId !== data.id_penyidik
        ),
      });
    }
  };

  const handleChange = (e) => {
    setIsFormDirty(true);
    const { name, value } = e.target;
    setPayloadForm({ ...payloadForm, [name]: value });
  };

  const handleDebounce = useCallback(
    debounce((newPayload) => {
      setPayloadCariTahanan(newPayload);
    }, 500),
    []
  );

  const onSuccess = (response) => {
    setIsFormDirty(false);
    setTimeout(() => {
      navigate("/tahanan/pengeluaran/list");
    }, 2500);
  };
  const mutateSubmit = usePutPengeluaran(onSuccess);

  const handleSubmit = (status) => {
    payloadForm.jenis_pelimpahan =
      payloadForm.kategori_pengeluaran === "P21"
        ? payloadForm.jenis_pelimpahan
        : payloadForm.kategori_pengeluaran === "Bebas Demi Hukum"
        ? "Bebas Demi Hukum"
        : "";
    let formData = new FormData();
    let payload = payloadForm;
    payload.status_dokumen = status;
    if (
      payload.kategori_pengeluaran !== "Pembantaran" ||
      payload.kategori_pengeluaran !== "Rehabilitasi Narkoba"
    ) {
      payload = {
        ...payload,
        nama_rumah_sakit: "",
        lokasi_rumah_sakit: "",
      };
    }
    if (payload.kategori_pengeluaran !== "Penangguhan") {
      payload = {
        ...payload,
        jaminan_penangguhan: "",
      };
    }
    if (payload.kategori_pengeluaran !== "Pengalihan") {
      payload = {
        ...payload,
        jenis_pengalihan: "",
      };
    }
    if (payload.kategori_pengeluaran !== "SP3") {
      payload = {
        ...payload,
        jenis_sp3: "",
      };
    }
    if (payload.kategori_pengeluaran !== "Meninggal Dunia") {
      payload = {
        ...payload,
        jenis_sp3: "",
      };
    }
    if (
      payload.kategori_pengeluaran !== "P21" ||
      payload.kategori_pengeluaran !== "Bebas Demi Hukum"
    ) {
      payload = {
        ...payload,
        jenis_pelimpahan: "",
      };
    }
    if (payload.jaminan_penangguhan !== "rutan") {
      payload = {
        ...payload,
        jenis_srutan_tujuanp3: "",
      };
    }
    if (payload.jaminan_penangguhan !== "rumah") {
      payload = {
        ...payload,
        alamat_rumah: "",
        kota: "",
        provinsi: "",
      };
    }
    if (payload.jaminan_penangguhan !== "kota") {
      payload = {
        ...payload,
        alamat_rumah: "",
        kota: "",
        provinsi: "",
      };
    }
    Object.keys(payloadForm).forEach((key) => {
      if (
        typeof payloadForm[key] === "string" ||
        typeof payloadForm[key] === "number"
      ) {
        formData.append(key, payloadForm[key]);
      }
    });
    payloadForm.arsip.forEach((file, idx) => {
      formData.append(`arsip`, file);
    });
    payloadForm.lampiran.forEach((file, idx) => {
      formData.append(`lampiran`, file);
    });
    payloadForm.penyidik_ids.forEach((ids, idx) => {
      formData.append(`penyidik_ids`, ids);
    });

    mutateSubmit.mutate({ payload: formData, id: id });
  };

  useEffect(() => {
    handleDebounce({ ...payloadCariTahanann, nama_tersangka: namaTahanan });
  }, [namaTahanan, handleDebounce, payloadCariTahanann]);

  useEffect(() => {
    if (id) {
      setIsEdit(true);
      setIsFormEditAble(false);
    }
    if (dataDetail && !idTahanan) {
      const _ = dataDetail;
      setIdTahanan(_.id_tahanan);
    }
    if (dataTahanan && dataDetail) {
      const tahanan = dataTahanan;
      const detail = dataDetail;

      const _ = dataTahanan;
      setPayloadFormDataTahanan({
        no_tahanan: tahanan.no_tahanan,
        nama: tahanan.nama_tersangka,
        tempat_lahir: tahanan.tempat_lahir,
        tanggal_lahir: tahanan.tanggal_lahir,
        umur: tahanan.umur,
        kelamin: tahanan.jenis_kelamin,
        kewarganegaraan: tahanan.kebangsaan,
        asal_negara: tahanan.asal_negara,
        agama: tahanan.agama,
        perkara: tahanan.perkara,
        pekerjaan: tahanan.pekerjaan,
        alamat: tahanan.alamat,
        foto_tahanan: tahanan.rumus_wajah,
        foto_sidikjari: tahanan.rumus_sidikjari,
        penempatan_rutan: tahanan.nama_rutan,
        lokasi_rutan: tahanan.alamat_rutan,
        riwayat: tahanan.riwayat_pengeluaran,
      });
      setPayloadForm({
        ...payloadForm,
        nama_tahanan: detail.nama_tahanan,
        no_tahanan: detail.no_tahanan,
        no_sprin_pengeluaran: detail.no_sprin_pengeluaran,
        tanggal_sprin_pengeluaran: detail?.tanggal_sprin_pengeluaran,
        kondisi: detail.kondisi,
        alasan: detail.alasan,
        keterangan: detail.keterangan,
        status_dokumen: detail.status_dokumen,
        penyidik_ids: detail?.penyidik?.map((e) => e.id_penyidik) ?? [],

        tanggal_pengeluaran: detail.tanggal_pengeluaran,
        kategori_pengeluaran: detail.kategori_pengeluaran,
        awal_penahanan: detail.awal_penahanan,
        akhir_penahanan: detail.akhir_penahanan,
        jenis_pengalihan: detail.jenis_pengalihan,
        kota: detail.kota,
        provinsi: detail.provinsi,
        alamat_rumah: detail.alamat_rumah,
        rutan_tujuan: detail.rutan_tujuan,
        jenis_sp3: detail.jenis_sp3,
        jaminan_penangguhan: detail.jaminan_penangguhan,
        nama_rumah_sakit: detail.nama_rumah_sakit,
        lokasi_rumah_sakit: "", // info
        jenis_pelimpahan: detail.jenis_pelimpahan,
      });
      setPayloadDate({
        ...payloadDate,
        tanggal_sprin_pengeluaran: moment(
          detail?.tanggal_sprin_pengeluaran
        ).toDate(),
        tanggal_pengeluaran: moment(detail?.tanggal_pengeluaran).toDate(),
        awal_penahanan: moment(detail?.awal_penahanan).toDate(),
        akhir_penahanan: moment(detail?.akhir_penahanan).toDate(),
      });

      setPenyidik(_?.penyidik ?? []);
      setFormReady(true);
    }
  }, [dataTahanan, dataDetail]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormDirty]);
  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between m-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/tahanan">Tahanan</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/tahanan/pengeluaran/list">
                    List pengeluaran Tahanan
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active fw-bold"
                  aria-current="page"
                >
                  Form pengeluaran Tahanan
                </li>
              </ol>
            </div>
            {isEdit && (
              <Button
                className="d-flex align-items-center gap-2"
                variant="primary"
                size="normal"
                onClick={() => setIsFormEditAble(!isFormEditAble)}
              >
                <i className="ri-edit-2-line"></i>
                {isFormEditAble ? "Batalkan Edit" : "Edit Form"}
              </Button>
            )}
          </div>
        </Card.Body>
      </Card>
      <Card className="bg-white">
        <Card.Body>
          <Row className="g-2">
            <Col sm="12">
              {mutateSubmit.isError && (
                <Alert variant="danger">
                  <ul className="m-0">
                    {mutateSubmit?.error?.response?.data?.detail?.map(
                      (errEdit, idx) => (
                        <li key={idx}>
                          <b>{errEdit.loc[1]} : </b>
                          {errEdit.msg}
                        </li>
                      )
                    ) ?? (
                      <li>
                        <b>Error : </b>
                        {mutateSubmit?.error?.response?.data?.message ??
                          "Bermasalah"}
                      </li>
                    )}
                  </ul>
                </Alert>
              )}
              {mutateSubmit.isSuccess && (
                <Alert variant="success">
                  {mutateSubmit.data.data.message}
                </Alert>
              )}
            </Col>
            <Col sm="12">
              {!formReady && (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner variant="primary" size="sm" />
                </div>
              )}
              {formReady && (
                <Accordion
                  defaultActiveKey={["0", "1", "2", "3", "4", "5"]}
                  flush
                  alwaysOpen
                >
                  <Accordion.Item eventKey="2">
                    <Accordion.Header className="custom-header">
                      Informasi / Profil Tahanan
                    </Accordion.Header>
                    <Accordion.Body className="py-2 px-0">
                      <Card className="card bg-white">
                        <Card.Body>
                          <Row>
                            <Col sm="4" className="d-flex flex-column gap-3">
                              <div className="d-flex flex-column gap-2">
                                <span className="fs-6 fw-medium">
                                  Foto Tahanan
                                </span>
                                <Image
                                  src={payloadFormDataTahanan.foto_tahanan}
                                  height="250px"
                                  width="100%"
                                />
                              </div>
                              <div className="d-flex flex-column gap-2">
                                <span className="fs-6 fw-medium">
                                  Foto Sidik Jari
                                </span>
                                <Image
                                  src={payloadFormDataTahanan.foto_sidikjari}
                                  height="250px"
                                  width="100%"
                                />
                              </div>
                            </Col>
                            <Col sm="8">
                              <Row className="g-2">
                                <Col sm="6">
                                  <Input
                                    label="No. Tahanan"
                                    name="no_tahanan"
                                    defaultValue={
                                      payloadFormDataTahanan.no_tahanan
                                    }
                                    disabled
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    label="Nama"
                                    name="nama"
                                    defaultValue={payloadFormDataTahanan.nama}
                                    disabled
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    label="Tempat Lahir"
                                    defaultValue={
                                      payloadFormDataTahanan.tempat_lahir
                                    }
                                    name="tempat_lahir"
                                    disabled
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    label="Tanggal Lahir"
                                    name="tanggal_lahir"
                                    defaultValue={
                                      payloadFormDataTahanan.tanggal_lahir
                                    }
                                    disabled
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    type="number"
                                    label="Umur Saat Ini"
                                    name="umur"
                                    defaultValue={payloadFormDataTahanan.umur}
                                    disabled
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    label="Kelamin"
                                    name="jenis_kelamin"
                                    disabled
                                    defaultValue={
                                      payloadFormDataTahanan.kelamin
                                    }
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    label="Kewarganegaraan"
                                    name="kebangsaan"
                                    defaultValue={
                                      payloadFormDataTahanan.kewarganegaraan
                                    }
                                    disabled
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    label="Asal Negara"
                                    name="asal_negara"
                                    defaultValue={
                                      payloadFormDataTahanan.asal_negara
                                    }
                                    disabled
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    label="Agama"
                                    name="agama"
                                    defaultValue={payloadFormDataTahanan.agama}
                                    disabled
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    label="Pekerjaan"
                                    name="pekerjaan"
                                    defaultValue={
                                      payloadFormDataTahanan.pekerjaan
                                    }
                                    type="text"
                                    disabled
                                  />
                                </Col>
                                <Col sm="12">
                                  <Input
                                    as="textarea"
                                    label="Alamat"
                                    defaultValue={payloadFormDataTahanan.alamat}
                                    name="alamat"
                                    disabled
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header className="custom-header">
                      Informasi Dokumen Penahanan
                    </Accordion.Header>
                    <Accordion.Body className="py-2 px-0">
                      <Card className="card bg-white">
                        <Card.Body>
                          <Row className="g-2">
                            <Col sm="12">
                              <Row className="g-2 mt-1">
                                <Col sm="5">
                                  <Input
                                    label="Nomor Sprin pengeluaran Tahanan"
                                    name="no_sprin_pengeluaran"
                                    required
                                    onChange={handleChange}
                                    defaultValue={
                                      payloadForm.no_sprin_pengeluaran
                                    }
                                    disabled={!isFormEditAble}
                                  />
                                </Col>
                                <Col sm="3">
                                  <DatePicker
                                    label="Tanggal Sprin"
                                    name="tanggal_sprin_pengeluaran"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={new Date()}
                                    onChange={(date) => {
                                      setPayloadDate({
                                        ...payloadDate,
                                        tanggal_sprin_pengeluaran: date,
                                        tanggal_pengeluaran: date,
                                      });
                                      setPayloadForm({
                                        ...payloadForm,
                                        tanggal_sprin_pengeluaran:
                                          moment(date).format("YYYY-MM-DD"),
                                        tanggal_pengeluaran:
                                          moment(date).format("YYYY-MM-DD"),
                                      });
                                    }}
                                    selected={
                                      payloadDate.tanggal_sprin_pengeluaran
                                    }
                                    required
                                    disabled={!isFormEditAble}
                                  />
                                </Col>
                                <Col
                                  sm="4"
                                  className="d-flex flex-column gap-2"
                                >
                                  <Input
                                    label="Unggah Arsip"
                                    type="file"
                                    onChange={(e) => {
                                      const files = e.target.files[0];
                                      setPayloadForm({
                                        ...payloadForm,
                                        arsip: [files],
                                      });
                                    }}
                                    accepts={[".pdf"]}
                                    required
                                    disabled={!isFormEditAble}
                                  />
                                  {dataDetail?.pengeluaran_files[0]?.arsip && (
                                    <Link
                                      target="_blank"
                                      to={`/arsip/view/${
                                        dataDetail.pengeluaran_files[0].file
                                      }?kategori=${
                                        dataDetail.pengeluaran_files[0].kategori
                                      }&p=${encodeToHex(
                                        dataDetail.pengeluaran_files[0].path
                                      )}`}
                                      className="text-primary fs-6 fw-medium d-flex align-items-center gap-2"
                                    >
                                      <i className="ri-link"></i>
                                      File Yang Sudah Terupload
                                    </Link>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header className="custom-header">
                      Informasi Pengeluaran Tahanan
                    </Accordion.Header>
                    <Accordion.Body className="py-2 px-0">
                      <Card className="card bg-white">
                        <Card.Body>
                          <Row>
                            <Col sm="4">
                              <Select
                                label="Pilih Kategori Pengeluaran"
                                name="kategori_pengeluaran"
                                default="-"
                                options={[
                                  {
                                    text: "Pembantaran",
                                    value: "Pembantaran",
                                  },
                                  {
                                    text: "Penangguhan",
                                    value: "Penangguhan",
                                  },
                                  {
                                    text: "Pengalihan Jenis Tahanan",
                                    value: "Pengalihan",
                                  },
                                  {
                                    text: "SP3",
                                    value: "SP3",
                                  },
                                  {
                                    text: "Tahap 2",
                                    value: "P21",
                                  },
                                  {
                                    text: "Bebas Demi Hukum",
                                    value: "Bebas Demi Hukum",
                                  },
                                  {
                                    text: "Rehabilitasi Narkoba",
                                    value: "Rehabilitasi Narkoba",
                                  },
                                  // {
                                  //   text: "Meninggal Dunia",
                                  //   value: "Meninggal Dunia",
                                  // },
                                ]}
                                defaultValue={payloadForm.kategori_pengeluaran}
                                onChange={handleChange}
                                disabled={!isFormEditAble}
                              />
                            </Col>
                          </Row>
                          {(payloadForm.kategori_pengeluaran ===
                            "Pembantaran" ||
                            payloadForm.kategori_pengeluaran ===
                              "Rehabilitasi Narkoba") && (
                            <Row className="g-2 mt-2">
                              <Col sm="4">
                                <DatePicker
                                  label="Tanggal Pengeluaran"
                                  name="tanggal_pengeluaran"
                                  timeFormat="HH:mm:ss"
                                  dateFormat="dd/MM/yyyy"
                                  minDate={new Date()}
                                  onChange={(date) => {
                                    setPayloadDate({
                                      ...payloadDate,
                                      tanggal_pengeluaran: date,
                                    });
                                    setPayloadForm({
                                      ...payloadForm,
                                      tanggal_pengeluaran:
                                        moment(date).format("YYYY-MM-DD"),
                                    });
                                  }}
                                  selected={payloadDate.tanggal_pengeluaran}
                                  required
                                  disabled
                                />
                              </Col>
                              <Col sm="4">
                                <Select
                                  label="Kondisi Fisik Tahanan"
                                  name="kondisi"
                                  default="-"
                                  options={[
                                    {
                                      text: "Sakit",
                                      value: "Sakit",
                                    },
                                    {
                                      text: "Sehat",
                                      value: "Sehat",
                                    },
                                  ]}
                                  defaultValue={payloadForm.kondisi}
                                  onChange={handleChange}
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                              <Col sm="4">
                                <Input
                                  label={
                                    payloadForm.kategori_pengeluaran ===
                                    "Pembantaran"
                                      ? "Nama Rumah Sakit"
                                      : "Nama Tempat Rehabilitasi"
                                  }
                                  type="text"
                                  name="nama_rumah_sakit"
                                  defaultValue={payloadForm.nama_rumah_sakit}
                                  onChange={handleChange}
                                  required
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                              {/* <Col sm="4">
                                <Input
                                  as="textarea"
                                  name="lokasi_rumah_sakit"
                                  defaultValue={payloadForm.lokasi_rumah_sakit}
                                  onChange={handleChange}
                                  label={
                                    payloadForm.kategori_pengeluaran ===
                                    "Pembantaran"
                                      ? "Lokasi Rumah Sakit"
                                      : "Lokasi Tempat Rehabilitasi"
                                  }
                                  disabled={!isFormEditAble}
                                />
                              </Col> */}
                              <Col sm="4">
                                <Input
                                  as="textarea"
                                  name="alasan"
                                  defaultValue={payloadForm.alasan}
                                  onChange={handleChange}
                                  label={
                                    payloadForm.kategori_pengeluaran ===
                                    "Pembantaran"
                                      ? "Alasan Pembantaran (Masukkan jenis sakit)"
                                      : "Alasan Rehabilitasi"
                                  }
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                            </Row>
                          )}
                          {payloadForm.kategori_pengeluaran ===
                            "Penangguhan" && (
                            <Row className="g-2 mt-2">
                              <Col sm="4">
                                <DatePicker
                                  label="Tanggal Pengeluaran"
                                  name="tanggal_pengeluaran"
                                  timeFormat="HH:mm:ss"
                                  dateFormat="dd/MM/yyyy"
                                  onChange={(date) => {
                                    setPayloadDate({
                                      ...payloadDate,
                                      tanggal_pengeluaran: date,
                                    });
                                    setPayloadForm({
                                      ...payloadForm,
                                      tanggal_pengeluaran:
                                        moment(date).format("YYYY-MM-DD"),
                                    });
                                  }}
                                  selected={payloadDate.tanggal_pengeluaran}
                                  required
                                  disabled
                                />
                              </Col>
                              <Col sm="4">
                                <Select
                                  label="Kondisi Fisik Tahanan"
                                  name="kondisi"
                                  default="-"
                                  options={[
                                    {
                                      text: "Sakit",
                                      value: "Sakit",
                                    },
                                    {
                                      text: "Sehat",
                                      value: "Sehat",
                                    },
                                  ]}
                                  defaultValue={payloadForm.kondisi}
                                  onChange={handleChange}
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                              <Col sm="4">
                                <Select
                                  label="Jaminan Penangguhan"
                                  default="-"
                                  name="jaminan_penangguhan"
                                  options={[
                                    {
                                      text: "Uang",
                                      value: "uang",
                                    },
                                    {
                                      text: "Kerabat",
                                      value: "kerabat",
                                    },
                                  ]}
                                  defaultValue={payloadForm.jaminan_penangguhan}
                                  onChange={handleChange}
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                              <Col sm="4">
                                <Input
                                  as="textarea"
                                  name="alasan"
                                  defaultValue={payloadForm.alasan}
                                  onChange={handleChange}
                                  label="Alasan Penangguhan"
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                            </Row>
                          )}
                          {payloadForm.kategori_pengeluaran ===
                            "Pengalihan" && (
                            <Row className="g-2 mt-2">
                              <Col sm="4">
                                <Select
                                  label="Jenis Pengalihan"
                                  name="jenis_pengalihan"
                                  default="-"
                                  options={[
                                    {
                                      text: "Penahanan Kota",
                                      value: "kota",
                                    },
                                    {
                                      text: "Penahanan (Pindah) Rutan",
                                      value: "rutan",
                                    },
                                    {
                                      text: "Penahanan Rumah",
                                      value: "rumah",
                                    },
                                  ]}
                                  defaultValue={payloadForm.jenis_pengalihan}
                                  onChange={handleChange}
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                              <Col sm="4">
                                <Select
                                  label="Kondisi Fisik Tahanan"
                                  default="-"
                                  name="kondisi"
                                  options={[
                                    {
                                      text: "Sakit",
                                      value: "Sakit",
                                    },
                                    {
                                      text: "Sehat",
                                      value: "Sehat",
                                    },
                                  ]}
                                  defaultValue={payloadForm.kondisi}
                                  onChange={handleChange}
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                              <Col sm="4">
                                <DatePicker
                                  label="Tanggal Pengeluaran"
                                  name="tanggal_pengeluaran"
                                  timeFormat="HH:mm:ss"
                                  dateFormat="dd/MM/yyyy"
                                  onChange={(date) => {
                                    setPayloadDate({
                                      ...payloadDate,
                                      tanggal_pengeluaran: date,
                                    });
                                    setPayloadForm({
                                      ...payloadForm,
                                      tanggal_pengeluaran:
                                        moment(date).format("YYYY-MM-DD"),
                                    });
                                  }}
                                  selected={payloadDate.tanggal_pengeluaran}
                                  required
                                  disabled
                                />
                              </Col>
                              {payloadForm.jenis_pengalihan === "rutan" && (
                                <Col sm="12">
                                  <Row className="g-2">
                                    <Col sm="4">
                                      <Select
                                        label="Asal Rutan"
                                        default="-"
                                        options={dataRutanList.map((e) => ({
                                          text: e.nama_rutan,
                                          value: e.nama_rutan,
                                        }))}
                                        disabled
                                        defaultValue={
                                          payloadFormDataTahanan.penempatan_rutan
                                        }
                                      />
                                    </Col>
                                    <Col sm="4">
                                      <Select
                                        label="Rutan Tujuan"
                                        default="-"
                                        name="rutan_tujuan"
                                        options={dataRutanList.map((e) => ({
                                          text: e.nama_rutan,
                                          value: e.nama_rutan,
                                        }))}
                                        onChange={handleChange}
                                        defaultValue={payloadForm.rutan_tujuan}
                                        disabled={!isFormEditAble}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              )}
                              {payloadForm.jenis_pengalihan === "rumah" && (
                                <Col sm="12">
                                  <Row className="g-2">
                                    <Col sm="4">
                                      <Select
                                        label="Asal Rutan"
                                        default="-"
                                        options={dataRutanList.map((e) => ({
                                          text: e.nama_rutan,
                                          value: e.nama_rutan,
                                        }))}
                                        disabled
                                        defaultValue={
                                          payloadFormDataTahanan.penempatan_rutan
                                        }
                                      />
                                    </Col>
                                    <Col sm="8">
                                      <Row className="g-2">
                                        <Col sm="12">
                                          <Input
                                            as="textarea"
                                            label="Ke Alamat Rumah"
                                            name="alamat_rumah"
                                            defaultValue={
                                              payloadForm.alamat_rumah
                                            }
                                            onChange={handleChange}
                                            disabled={!isFormEditAble}
                                          />
                                        </Col>
                                        <Col sm="12">
                                          <Input
                                            label="Kota"
                                            name="kota"
                                            defaultValue={payloadForm.kota}
                                            onChange={handleChange}
                                            type="text"
                                            disabled={!isFormEditAble}
                                          />
                                        </Col>
                                        <Col sm="12">
                                          <Select
                                            label="Provinsi"
                                            default="-"
                                            name="provinsi"
                                            options={[
                                              {
                                                text: "Jawa Barat",
                                                value: "Jawa Barat",
                                              },
                                            ]}
                                            onChange={handleChange}
                                            defaultValue={payloadForm.provinsi}
                                            disabled={!isFormEditAble}
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Col>
                              )}
                              {payloadForm.jenis_pengalihan === "kota" && (
                                <Col sm="12">
                                  <Row className="g-2">
                                    <Col sm="12">
                                      <Input
                                        label="Nama Kota"
                                        name="kota"
                                        defaultValue={payloadForm.kota}
                                        onChange={handleChange}
                                        type="text"
                                        disabled={!isFormEditAble}
                                      />
                                    </Col>
                                    <Col sm="12">
                                      <Select
                                        label="Provinsi"
                                        default="-"
                                        name="provinnsi"
                                        options={[
                                          {
                                            text: "Jawa Barat",
                                            value: "Jawa Barat",
                                          },
                                        ]}
                                        onChange={handleChange}
                                        defaultValue={payloadForm.provinsi}
                                        disabled={!isFormEditAble}
                                      />
                                    </Col>
                                    <Col sm="12">
                                      <Input
                                        as="textarea"
                                        label="Alamat Rumah"
                                        name="alamat_rumah"
                                        handleChange={payloadForm.alamat_rumah}
                                        defaultValue={payloadForm.alamat_rumah}
                                        onChange={handleChange}
                                        disabled={!isFormEditAble}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              )}
                              <Col sm="12">
                                <Input
                                  as="textarea"
                                  label="Alasan Pengalihan"
                                  name="alasan"
                                  defaultValue={payloadForm.alasan}
                                  onChange={handleChange}
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                            </Row>
                          )}
                          {payloadForm.kategori_pengeluaran === "SP3" && (
                            <Row className="g-2 mt-2">
                              <Col sm="4">
                                <DatePicker
                                  label="Tanggal Pengeluaran"
                                  name="tanggal_pengeluaran"
                                  timeFormat="HH:mm:ss"
                                  dateFormat="dd/MM/yyyy"
                                  onChange={(date) => {
                                    setPayloadDate({
                                      ...payloadDate,
                                      tanggal_pengeluaran: date,
                                    });
                                    setPayloadForm({
                                      ...payloadForm,
                                      tanggal_pengeluaran:
                                        moment(date).format("YYYY-MM-DD"),
                                    });
                                  }}
                                  selected={payloadDate.tanggal_pengeluaran}
                                  required
                                  disabled
                                />
                              </Col>
                              <Col sm="4">
                                <Select
                                  label="Jenis Penghentian"
                                  default="-"
                                  name="jenis_sp3"
                                  options={[
                                    {
                                      text: "⁠Restorative Justice",
                                      value: "⁠Restorative Justice",
                                    },
                                    {
                                      text: "Tidak Cukup Bukti",
                                      value: "Tidak Cukup Bukti",
                                    },
                                    // {
                                    //   text: "Bukan Tindak Pidana",
                                    //   value: "Bukan Tindak Pidana",
                                    // },
                                    // {
                                    //   text: "Kadaluarsa",
                                    //   value: "Kadaluarsa",
                                    // },
                                    {
                                      text: "Meninggal Dunia",
                                      value: "Meninggal Dunia",
                                    },
                                    // {
                                    //   text: "Keadilan Restoratif",
                                    //   value: "Keadilan Restoratif",
                                    // },
                                    {
                                      text: "Diversi",
                                      value: "Diversi",
                                    },
                                    {
                                      text: "Putusan Pra Peradilan",
                                      value: "Putusan Pra Peradilan",
                                    },
                                  ]}
                                  defaultValue={payloadForm.jenis_sp3}
                                  onChange={handleChange}
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                              <Col sm="4">
                                <Select
                                  label="Kondisi Fisik Tahanan"
                                  default="-"
                                  options={[
                                    {
                                      text: "Sakit",
                                      value: "Sakit",
                                    },
                                    {
                                      text: "Sehat",
                                      value: "Sehat",
                                    },
                                  ]}
                                  defaultValue={payloadForm.kondisi}
                                  onChange={handleChange}
                                  disabled={!isFormEditAble}
                                />
                              </Col>

                              <Col sm="4">
                                <Input
                                  as="textarea"
                                  name="alasan"
                                  defaultValue={payloadForm.alasan}
                                  onChange={handleChange}
                                  label="Alasan Penghentian"
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                            </Row>
                          )}
                          {payloadForm.kategori_pengeluaran ===
                            "Meninggal Dunia" && (
                            <>
                              {payloadForm.jenis_sp3 !==
                                "Tersangka Meninggal Dunia" &&
                                setPayloadForm({
                                  ...payloadForm,
                                  jenis_sp3: "Tersangka Meninggal Dunia",
                                })}
                              <Row className="g-2 mt-2">
                                <Col sm="4">
                                  <DatePicker
                                    label="Tanggal Pengeluaran"
                                    name="tanggal_pengeluaran"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => {
                                      setPayloadDate({
                                        ...payloadDate,
                                        tanggal_pengeluaran: date,
                                      });
                                      setPayloadForm({
                                        ...payloadForm,
                                        tanggal_pengeluaran:
                                          moment(date).format("YYYY-MM-DD"),
                                      });
                                    }}
                                    selected={payloadDate.tanggal_pengeluaran}
                                    required
                                    disabled
                                  />
                                </Col>
                                <Col sm="4">
                                  <Input
                                    label="Jenis Penghentian"
                                    name="jenis_sp3"
                                    defaultValue={payloadForm.jenis_sp3}
                                    onChange={handleChange}
                                    disabled
                                  />
                                </Col>
                                <Col sm="4">
                                  <Select
                                    label="Kondisi Fisik Tahanan"
                                    default="-"
                                    name="kondisi"
                                    options={[
                                      {
                                        text: "Sakit",
                                        value: "Sakit",
                                      },
                                      {
                                        text: "Sehat",
                                        value: "Sehat",
                                      },
                                    ]}
                                    defaultValue={payloadForm.kondisi}
                                    onChange={handleChange}
                                    disabled={!isFormEditAble}
                                  />
                                </Col>

                                <Col sm="4">
                                  <Input
                                    as="textarea"
                                    name="alasan"
                                    defaultValue={payloadForm.alasan}
                                    onChange={handleChange}
                                    label="Alasan Penghentian"
                                    disabled={!isFormEditAble}
                                  />
                                </Col>
                              </Row>
                            </>
                          )}
                          {(payloadForm.kategori_pengeluaran === "P21" ||
                            payloadForm.kategori_pengeluaran ===
                              "Bebas Demi Hukum") && (
                            <Row className="g-2 mt-2">
                              <Col sm="4">
                                <DatePicker
                                  label="Tanggal Pengeluaran"
                                  name="tanggal_pengeluaran"
                                  timeFormat="HH:mm:ss"
                                  dateFormat="dd/MM/yyyy"
                                  onChange={(date) => {
                                    setPayloadDate({
                                      ...payloadDate,
                                      tanggal_pengeluaran: date,
                                    });
                                    setPayloadForm({
                                      ...payloadForm,
                                      tanggal_pengeluaran:
                                        moment(date).format("YYYY-MM-DD"),
                                    });
                                  }}
                                  selected={payloadDate.tanggal_pengeluaran}
                                  required
                                  disabled
                                />
                              </Col>
                              {payloadForm.kategori_pengeluaran === "P21" ? (
                                <Col sm="4">
                                  <Select
                                    label="Jenis Pelimpahan"
                                    default="-"
                                    name="jenis_pelimpahan"
                                    options={[
                                      {
                                        text: "Berkas Perkara Lengkap",
                                        value: "Berkas Perkara Lengkap",
                                      },
                                      {
                                        text: "Dakwaan Sudah Siap",
                                        value: "Dakwaan Sudah Siap",
                                      },
                                    ]}
                                    defaultValue={payloadForm.jenis_pelimpahan}
                                    onChange={handleChange}
                                    disabled={!isFormEditAble}
                                  />
                                </Col>
                              ) : (
                                <Col sm="4">
                                  <Input
                                    label="Jenis Pengeluaran"
                                    name="jenis_pelimpahan"
                                    value="Bebas Demi Hukum"
                                    onChange={handleChange}
                                    disabled
                                  />
                                </Col>
                              )}
                              <Col sm="4">
                                <Select
                                  label="Kondisi Fisik Tahanan"
                                  default="-"
                                  options={[
                                    {
                                      text: "Sakit",
                                      value: "Sakit",
                                    },
                                    {
                                      text: "Sehat",
                                      value: "Sehat",
                                    },
                                  ]}
                                  defaultValue={payloadForm.kondisi}
                                  onChange={handleChange}
                                  disabled={!isFormEditAble}
                                />
                              </Col>

                              <Col sm="4">
                                <Input
                                  as="textarea"
                                  name="alasan"
                                  defaultValue={payloadForm.alasan}
                                  onChange={handleChange}
                                  label={
                                    payloadForm.kategori_pengeluaran === "P21"
                                      ? "Alasan Pelimpahan"
                                      : "Alasan Pengeluaran"
                                  }
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                            </Row>
                          )}
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header className="custom-header">
                      Informasi Penahanan
                    </Accordion.Header>
                    <Accordion.Body className="py-2 px-0">
                      <Card className="card bg-white">
                        <Card.Body>
                          <Row className="g-2 mt-2">
                            <Col sm="4">
                              <Input
                                label="Perkara"
                                name="perkara"
                                defaultValue={payloadFormDataTahanan.perkara}
                                required
                                disabled
                              />
                            </Col>
                            <Col sm="4">
                              <Input
                                label="Penempatan Rutan"
                                defaultValue={
                                  payloadFormDataTahanan.penempatan_rutan
                                }
                                name="nama_rutan"
                                required
                                disabled
                              />
                            </Col>
                            <Col sm="4">
                              <Input
                                as="textarea"
                                label="Lokasi Rutan"
                                defaultValue={
                                  payloadFormDataTahanan.lokasi_rutan
                                }
                                name="alamat_rutan"
                                required
                                disabled
                              />
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header className="custom-header">
                      Informasi Penyidik
                    </Accordion.Header>
                    <Accordion.Body className="py-2 px-0">
                      <Card className="card bg-white">
                        <Card.Body>
                          <Row className="g-2">
                            <Col sm="12">
                              <Card className="card bg-white">
                                <Card.Body>
                                  <Row className="g-1 mb-2 d-flex align-items-center">
                                    <Col sm="12" md="5">
                                      <h6>Daftar Penyidik</h6>
                                    </Col>
                                    <Col sm="12" md="7">
                                      <Link className="d-flex align-items-center justify-content-end"></Link>
                                    </Col>
                                  </Row>
                                  <Table className="table dems-table">
                                    <thead>
                                      <tr>
                                        <th>No</th>
                                        <th>NRP</th>
                                        <th>Nama Penyidik</th>
                                        <th>Asal Satker</th>
                                        <th>Pangkat</th>
                                        <th>Jabatan</th>
                                        <th>Pilih</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {penyidik.map((data, index) => (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>{data.nrp}</td>
                                          <td>{data.nama_penyidik}</td>
                                          <td>{data.nama_satker}</td>
                                          <td>{data.pangkat}</td>
                                          <td>Penyidik</td>
                                          <td>
                                            <Form.Check
                                              type="checkbox"
                                              onChange={(e) => {
                                                handleCheckbox(
                                                  data,
                                                  e.target.checked
                                                );
                                              }}
                                              checked={payloadForm.penyidik_ids.includes(
                                                data.id_penyidik
                                              )}
                                              disabled={!isFormEditAble}
                                            />
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </Card.Body>
                              </Card>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header className="custom-header">
                      Informasi Tambahan
                    </Accordion.Header>
                    <Accordion.Body className="py-2 px-0">
                      <Card className="card bg-white">
                        <Card.Body>
                          <Row className="mt-2 g-2">
                            <Col sm="4" className="d-flex flex-column gap-2">
                              <Input
                                label="Lampiran Pendukung"
                                type="file"
                                onChange={(e) => {
                                  const files = e.target.files[0];
                                  setPayloadForm({
                                    ...payloadForm,
                                    lampiran: [files],
                                  });
                                }}
                                accepts={[".pdf"]}
                                disabled={!isFormEditAble}
                              />
                              {dataDetail?.pengeluaran_files[1]?.lampiran && (
                                <Link
                                  target="_blank"
                                  to={`/arsip/view/${
                                    dataDetail.pengeluaran_files[1].file
                                  }?kategori=${
                                    dataDetail.pengeluaran_files[1].kategori
                                  }&p=${encodeToHex(
                                    dataDetail.pengeluaran_files[1].path
                                  )}`}
                                  className="text-primary fs-6 fw-medium d-flex align-items-center gap-2"
                                >
                                  <i className="ri-link"></i>
                                  File Yang Sudah Terupload
                                </Link>
                              )}
                            </Col>
                            <Col sm="6">
                              <Input
                                as="textarea"
                                onChange={handleChange}
                                defaultValue={payloadForm.keterangan}
                                col="4"
                                name="keterangan"
                                label="Keterangan"
                                required
                                disabled={!isFormEditAble}
                              />
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
          <div className="d-flex justify-content-end align-items-center gap-3">
            {!mutateSubmit.isLoading && (
              <Button
                variant="warning"
                as={Link}
                to="/tahanan/perpanjangan/list"
              >
                BATAL
              </Button>
            )}
            {/* {!mutateSubmit.isLoading && (
              <Button
                variant="secondary"
                onClick={() => handleSubmit("DRAFT")}
                disabled={!isFormEditAble}
                hidden={!isFormEditAble}
              >
                SIMPAN DRAFT
              </Button>
            )} */}
            {!mutateSubmit.isLoading && (
              <Button
                variant="primary"
                onClick={() => handleSubmit("FINAL")}
                disabled={!isFormEditAble}
                hidden={!isFormEditAble}
              >
                SIMPAN
              </Button>
            )}
            {mutateSubmit.isLoading && (
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
                disabled
              >
                Sedang Menyimpan <Spinner variant="light" size="sm" />
              </Button>
            )}
          </div>
          {/* {!mutateSubmit.isLoading ? (
            <div className="d-flex justify-content-end align-items-center gap-3">
              <Link to="/tahanan/pengeluaran/list">
                <Button variant="warning">BATAL</Button>
              </Link>
              <Button
                variant="primary"
                onClick={() => handleSubmit("FINAL")}
                disabled={!isFormEditAble}
              >
                SIMPAN
              </Button>
            </div>
          ) : (
            <div className="d-flex justify-content-end align-items-center gap-3">
              <Link>
                <Button variant="warning" disabled>
                  BATAL
                </Button>
              </Link>
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
                disabled
              >
                Sedang Menyimpan <Spinner variant="light" size="sm" />
              </Button>
            </div>
          )} */}
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
}

export default FormEdit;
