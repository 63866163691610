import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Spinner,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const mockFetchList = () => {
  return new Promise((resolve) => {
    setTimeout(()=> {
      resolve([
        {
          id: 0,
          isActive: false,
          color: "primary",
          icon: "ri-folder-5-line",
          name: "Dashboard Concepts",
          isFolder: 1
        },
        {
          id: 1,
          isActive: false,
          color: "primary",
          icon: "ri-folder-5-line",
          name: "Video",
          isFolder: 1
        },
        {
          id: 2,
          isActive: false,
          color: "primary",
          icon: "ri-folder-5-line",
          name: "Music",
          isFolder: 1
        },
        {
          id: 3,
          isActive: false,
          color: "primary",
          icon: "ri-folder-5-line",
          name: "App",
          isFolder: 1
        },
        {
          id: 3,
          isActive: false,
          color: "primary",
          icon: "ri-folder-5-line",
          name: "Important folder",
          isFolder: 1
        },
        {
          id: 3,
          isActive: false,
          color: "primary",
          icon: "ri-folder-5-line",
          name: "Collection",
          isFolder: 1
        },
        {
          id: 4,
          isActive: false,
          color: "",
          icon: "ri-file-zip-line",
          name: "Backups091022.zip", 
          isFolder: 0
        },
        {
          id: 5,
          isActive: false,
          isFolder: 0,
          color: "orange",
          icon: "ri-file-ppt-line",
          name: "Presentation.ppt",
        },
        {
          color: "",
          id: 6,
          isActive: false,
          isFolder: 0,
          icon: "ri-file-zip-line",
          name: "Backups091022.zip",
          date: "Aug 10, 2023",
          size: "4.7MB"
        }, {
          color: "orange",
          id: 7,
          isActive: false,
          isFolder: 0,
          icon: "ri-file-ppt-line",
          name: "Presentation.ppt",
          date: "Aug 10, 2023",
          size: "11.21MB"
        }, {
          color: "success",
          id: 8,
          isActive: false,
          isFolder: 0,
          icon: "ri-file-excel-line",
          name: "Expenses091122.xls",
          date: "Sep 11, 2023",
          size: "7.38MB"
        }, {
          color: "",
          id: 9,
          isActive: false,
          isFolder: 0,
          icon: "ri-image-2-line",
          name: "IMG_101022.jpg",
          date: "Sep 10, 2023",
          size: "1.26MB"
        }, {
          color: "",
          id: 10,
          isActive: false,
          isFolder: 0,
          icon: "ri-image-2-line",
          name: "IMG_101122.jpg",
          date: "Sep 10, 2023",
          size: "1.83MB"
        }, {
          color: "danger",
          id: 11,
          isActive: false,
          isFolder: 0,
          icon: "ri-file-pdf-line",
          name: "Documents001.pdf",
          date: "Sep 05, 2023",
          size: "876.1KB"
        }, {
          color: "danger",
          id: 12,
          isActive: false,
          isFolder: 0,
          icon: "ri-file-pdf-line",
          name: "Geting_Started.pdf",
          date: "Aug 01, 2023",
          size: "112.4KB"
        }
      ])
    },1000)
  })
}
export default function FileManager() {
  const [directories, setDirectories] = useState([])
  const [dirPath, setDirPath] = useState([])
  const dirScrollRef = useRef(null)
  const navigate = useNavigate()
  const mockDirectoriesParent = {
      name: "Dashboard Concept",
      isLoading: true,
      directory: []
  }
  const mockPath = {
    pi: 0,
    ci: 0,
    isFolder: 1,
    name: 'Arsip'
  }

  useEffect(() => {
    document.body.classList.add("page-app");
    document.body.classList.add("sidebar-hide");
    // Initial state
    setDirectories([...directories, mockDirectoriesParent])
    mockFetchList().then((res) => {
      setDirectories((prev) => {
        return prev.map((v, i) => i === (prev.length - 1) ? ({...v, isLoading: false, directory: res}): (v))
      })
      setDirPath((prev) => [...prev, mockPath])
    })

    return () => {
      document.body.classList.remove("page-app");
      document.body.classList.remove("sidebar-hide");
    };
  }, []);

  useEffect(() => {
    if (dirScrollRef.current) {
      dirScrollRef.current.scrollLeft = dirScrollRef.current.scrollWidth;
    }
  }, [directories]);

  /**
   * Untuk generate new directory
   * 
   * @param Object pv - Parrent Value
   * @param Integer pi - Parrent Index
   * @param Object cv - Children Value
   * @param Integer ci - Children Index
   * 
   */
  const handleClickDirectory = async ({pv, pi}, {cv, ci}) => {
    let dirState = directories;
    let mockNewDir = {
      name: "Dashboard Concept",
      isLoading: true,
      directory: []
    }
    const mockPath = {
      pi: pi,
      ci: ci,
      isFolder : cv.isFolder,
      name: cv.name
    }

    if (!cv.isFolder) {
      navigate('/file_manager/view')
      return null
    }

    // Untuk highlight selected dir / dir active
    dirState.map((dir, idx) => {
      if (idx === pi) {
        dir.directory.map((dirSelected, idxDirSelected) => {
          if (idxDirSelected === ci) {
            dirSelected.isActive = true;
          } else {
            dirSelected.isActive = false
          }
          return dirSelected
        })
      }
      return dir
    })

    setDirectories([...dirState])

    // Untuk berpindah folder / mengubah path direktori
    // Lalu menambahkan isi array baru pada index terakhir sebagai init folder
    if (pi < (dirState.length - 1)) {
      setDirectories((prev) => [...prev.slice(0, pi + 1), mockNewDir])
      setDirPath((prev) => [...prev.slice(0, pi + 1), mockPath])
    } else {
      setDirPath((prev) => [...prev, mockPath])
      setDirectories((prev) => [...prev, mockNewDir])
    }

    // fetch isi folder selekted
    try {
      let mockList = await mockFetchList()
      // Mengubah field isLoading dan field directory[] pada index terakhir
      setDirectories((prev) => prev.map((v,i) => i === (prev.length - 1) ? ({...v, isLoading: false, directory: mockList}):({...v})))
    } catch (error) {
      
    }
  }

  const handleSearch = (e) => {
    const index = e.target.getAttribute('dir-index')
    
  }

  // toggle sidebar in mobile
  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            {
              dirPath.map((dir, didx) => (
                dir.isFolder ? (
                  <li 
                    className="breadcrumb-item"
                    key={didx}
                    onClick={() => handleClickDirectory({pv: null, pi: dir.pi}, {cv: dir, ci: dir.ci})}
                    >
                    <Link to="#">{dir.name}</Link>
                  </li>
                ) : (
                  <li className="breadcrumb-item active" key={didx}>
                    {dir.name}
                  </li>
                )
              ))
            }
          </ol>
        </div>
      </div>
      <div className="horizontal-scroll-container horizontal-directories" ref={dirScrollRef}>
        {
          !directories.length ? 
          (
            <div className="dir-column">
              <Card className="border-0 py-1 px-2">
                  <div className="directory-header">
                    <i className="ri-folder-shared-fill icon"></i>
                  </div>
              </Card>
              <div className="wrap-dir-column loading-dir">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            </div>
          ) :
          directories.map((pv, pi) => (
            pv.isLoading ? (
              <div className="dir-column" key={pi}>
                <Card className="border-0 py-1 px-2">
                    <div className="directory-header">
                      <i className="ri-folder-shared-fill icon"></i>
                    </div>
                </Card>
                <div className="wrap-dir-column loading-dir">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              </div>
            ) : (
              <div className="dir-column" key={pi}>
                <Card className="border-0 py-1 px-2">
                    <div className="directory-header">
                      <input type="text" placeholder="Search" dir-index={pi} onChange={(e) => handleSearch(e)}/>
                      <i className="ri-folder-shared-fill icon"></i>
                    </div>
                </Card>
                <div className="wrap-dir-column rounded-1">
                  {
                    pv.directory.map((cv, ci) => (
                      <React.Fragment key={ci}>
                        <Card 
                          className={`border-0 p-2 card-directory ${cv.isActive ? 'dir-active': ''}`}
                          onClick={() => handleClickDirectory({pv,pi}, {cv, ci})}
                        >
                          <div className="media">
                            <div className={"media-icon " + cv.color}><i className={cv.icon}></i></div>
                            <h6 className="file-name"><Link href="">{cv.name}</Link></h6>
                          </div>
                        </Card>
                      </React.Fragment>
                    ))
                  }
                </div>
              </div>
            )
          ))
        }
      </div>
    </React.Fragment>
  );
}
