const kesatuan = [
  { value: "BARESKRIM POLRI", text: "BARESKRIM" },
  { value: "ACEH", text: "ACEH" },
  { value: "SUMATERA UTARA", text: "SUMUT" },
  { value: "SUMATERA BARAT", text: "SUMBAR" },
  { value: "RIAU", text: "RIAU" },
  { value: "BENGKULU", text: "BENGKULU" },
  { value: "JAMBI", text: "JAMBI" },
  { value: "KEPULAUAN RIAU", text: "KEPRI" },
  { value: "BANGKA BELITUNG", text: "BABEL" },
  { value: "SUMATERA SELATAN", text: "SUMSEL" },
  { value: "LAMPUNG", text: "LAMPUNG" },
  { value: "POLDA METRO JAYA", text: "METRO JAYA" },
  { value: "BANTEN", text: "BANTEN" },
  { value: "JAWA BARAT", text: "JABAR" },
  { value: "JAWA TENGAH", text: "JATENG" },
  { value: "DI YOGYAKARTA", text: "DIY" },
  { value: "JAWA TIMUR", text: "JATIM" },
  { value: "BALI", text: "BALI" },
  { value: "NUSA TENGGARA BARAT", text: "NTB" },
  { value: "NUSA TENGGARA TIMUR", text: "NTT" },
  { value: "KALIMANTAN BARAT", text: "KALBAR" },
  { value: "KALIMANTAN SELATAN", text: "KALSEL" },
  { value: "KALIMANTAN TENGAH", text: "KALTENG" },
  { value: "KALIMANTAN TIMUR", text: "KALTIM" },
  { value: "KALIMANTAN UTARA", text: "KALTARA" },
  { value: "SULAWESI SELATAN", text: "SULSEL" },
  { value: "SULAWESI TENGGARA", text: "SULTRA" },
  { value: "SULAWESI TENGAH", text: "SULTENG" },
  { value: "SULAWESI UTARA", text: "SULUT" },
  { value: "SULAWESI BARAT", text: "SULBAR" },
  { value: "GORONTALO", text: "GORONTALO" },
  { value: "MALUKU", text: "MALUKU" },
  { value: "MALUKU UTARA", text: "MALUT" },
  { value: "PAPUA", text: "PAPUA" },
  { value: "PAPUA BARAT", text: "PAPUA BARAT" },
];

const weeks = [
  { value: "Minggu I", text: "Minggu 1" },
  { value: "Minggu II", text: "Minggu 2" },
  { value: "Minggu III", text: "Minggu 3" },
  { value: "Minggu IV", text: "Minggu 4" },
];

const triwulan = [
  { value: "Triwulan I", text: "Triwulan 1" },
  { value: "Triwulan II", text: "Triwulan 2" },
  { value: "Triwulan III", text: "Triwulan 3" },
  { value: "Triwulan IV", text: "Triwulan 4" },
];

const months = [
  { value: "Januari", text: "Januari" },
  { value: "Februari", text: "Februari" },
  { value: "Maret", text: "Maret" },
  { value: "April", text: "April" },
  { value: "Mei", text: "Mei" },
  { value: "Juni", text: "Juni" },
  { value: "Juli", text: "Juli" },
  { value: "Agustus", text: "Agustus" },
  { value: "September", text: "September" },
  { value: "Oktober", text: "Oktober" },
  { value: "November", text: "November" },
  { value: "Desember", text: "Desember" },
];

const currentYear = new Date().getFullYear();
const years = [];

for (let i = 0; i <= 10; i++) {
  years.push({ value: currentYear - i, text: currentYear - i });
}

const roles = [
  // General Roles
  { value: "Super Admin", text: "Super Admin" },
  { value: "Eksternal", text: "Eksternal" },
  { value: "Eksekutif POLRI", text: "Eksekutif POLRI" },

  // Roles Penyidik
  { value: "Admin PENYIDIK", text: "Admin Penyidik" },
  { value: "Eksekutif PENYIDIK", text: "Eksekutif Penyidik" },
  { value: "Atasan PENYIDIK", text: "Atasan Penyidik" },
  { value: "PENYIDIK", text: "Penyidik" },

  // Roles Tahti
  { value: "Admin TAHTI", text: "Admin TAHTI" },
  { value: "Eksekutif TAHTI", text: "Eksekutif TAHTI" },
  { value: "Umum TAHTI", text: "Umum TAHTI" },
  { value: "Petugas TAHTI", text: "Petugas TAHTI" },
  { value: "Petugas BB TAHTI", text: "Petugas BB TAHTI" },
];
const superadmin = [
  { value: "Super Admin", text: "Super Admin" },
  { value: "Eksternal", text: "Eksternal" },
  { value: "Admin Penyidik", text: "Admin Penyidik" },
  { value: "Admin TAHTI", text: "Admin TAHTI" },
];

const admintahti = [
  { value: "Eksekutif TAHTI", text: "Eksekutif TAHTI" },
  { value: "Umum TAHTI", text: "Umum TAHTI" },
  { value: "Petugas TAHTI", text: "Petugas TAHTI" },
  { value: "Petugas BB", text: "Petugas BB" },
];

const pangkat = [
  { value: "JENDERAL POLISI", text: "JENDERAL POLISI" },
  { value: "KOMJEN POL", text: "KOMJEN POL" },
  { value: "IRJEN POL", text: "IRJEN POL" },
  { value: "BRIGJEN", text: "BRIGJEN" },
  { value: "KOMBES POL", text: "KOMBES POL" },
  { value: "AKBP", text: "AKBP" },
  { value: "KOMPOL", text: "KOMPOL" },
  { value: "AKP", text: "AKP" },
  { value: "IPTU", text: "IPTU" },
  { value: "IPDA", text: "IPDA" },
  { value: "AIPTU", text: "AIPTU" },
  { value: "AIPDA", text: "AIPDA" },
  { value: "BRIPKA", text: "BRIPKA" },
  { value: "BRIGPOL", text: "BRIGPOL" },
  { value: "BRIPTU", text: "BRIPTU" },
  { value: "BRIPDA", text: "BRIPDA" },
  { value: "ABRIP", text: "ABRIP" },
  { value: "ABRIPTU", text: "ABRIPTU" },
  { value: "ABRIPDA", text: "ABRIPDA" },
  { value: "BHARAKA", text: "BHARAKA" },
  { value: "BHARATU", text: "BHARATU" },
  { value: "BHARADA", text: "BHARADA" },
];
const country = [
  { value: "Afganistan", text: "Afganistan" },
  { value: "Afrika Selatan", text: "Afrika Selatan" },
  { value: "Albania", text: "Albania" },
  { value: "Aljazair", text: "Aljazair" },
  { value: "Amerika Serikat", text: "Amerika Serikat" },
  { value: "Andorra", text: "Andorra" },
  { value: "Angola", text: "Angola" },
  { value: "Anguilla", text: "Anguilla" },
  { value: "Antigua dan Barbuda", text: "Antigua dan Barbuda" },
  { value: "Antillen Belanda", text: "Antillen Belanda" },
  { value: "Arab Saudi", text: "Arab Saudi" },
  { value: "Argentina", text: "Argentina" },
  { value: "Armenia", text: "Armenia" },
  { value: "Aruba", text: "Aruba" },
  { value: "Atol Johnston", text: "Atol Johnston" },
  { value: "Australia", text: "Australia" },
  { value: "Austria", text: "Austria" },
  { value: "Azerbaijan", text: "Azerbaijan" },
  { value: "Bahama", text: "Bahama" },
  { value: "Bahrain", text: "Bahrain" },
  { value: "Bangladesh", text: "Bangladesh" },
  { value: "Barbados", text: "Barbados" },
  { value: "Belanda", text: "Belanda" },
  { value: "Belarus", text: "Belarus" },
  { value: "Belgia", text: "Belgia" },
  { value: "Belize", text: "Belize" },
  { value: "Benin", text: "Benin" },
  { value: "Bermuda", text: "Bermuda" },
  { value: "Bhutan", text: "Bhutan" },
  { value: "Bolivia", text: "Bolivia" },
  { value: "Bosnia Herzegovina", text: "Bosnia Herzegovina" },
  { value: "Botswana", text: "Botswana" },
  { value: "Brasil", text: "Brasil" },
  { value: "Britania Raya", text: "Britania Raya" },
  { value: "Brunei", text: "Brunei" },
  { value: "Bulgaria", text: "Bulgaria" },
  { value: "Burkina Faso", text: "Burkina Faso" },
  { value: "Burundi", text: "Burundi" },
  { value: "Ceko", text: "Ceko" },
  { value: "Chad", text: "Chad" },
  { value: "Chili", text: "Chili" },
  { value: "Denmark", text: "Denmark" },
  { value: "Djibouti", text: "Djibouti" },
  { value: "Dominika", text: "Dominika" },
  { value: "Ekuador", text: "Ekuador" },
  { value: "El Salvador", text: "El Salvador" },
  { value: "Eritrea", text: "Eritrea" },
  { value: "Estonia", text: "Estonia" },
  { value: "Ethiopia", text: "Ethiopia" },
  { value: "Fiji", text: "Fiji" },
  { value: "Filipina", text: "Filipina" },
  { value: "Finlandia", text: "Finlandia" },
  { value: "Gabon", text: "Gabon" },
  { value: "Gambia", text: "Gambia" },
  { value: "Georgia", text: "Georgia" },
  { value: "Ghana", text: "Ghana" },
  { value: "Gibraltar", text: "Gibraltar" },
  { value: "Greenland", text: "Greenland" },
  { value: "Grenada", text: "Grenada" },
  { value: "Guadeloupe", text: "Guadeloupe" },
  { value: "Guam", text: "Guam" },
  { value: "Guatemala", text: "Guatemala" },
  { value: "Guernsey", text: "Guernsey" },
  { value: "Guinea", text: "Guinea" },
  { value: "Guinea Bissau", text: "Guinea Bissau" },
  { value: "Guinea Khatulistiwa", text: "Guinea Khatulistiwa" },
  { value: "Guyana", text: "Guyana" },
  { value: "Guyana Perancis", text: "Guyana Perancis" },
  { value: "Haiti", text: "Haiti" },
  { value: "Honduras", text: "Honduras" },
  { value: "Hong Kong", text: "Hong Kong" },
  { value: "Hongaria", text: "Hongaria" },
  { value: "India", text: "India" },
  { value: "Irak", text: "Irak" },
  { value: "Iran", text: "Iran" },
  { value: "Irlandia", text: "Irlandia" },
  { value: "Islandia", text: "Islandia" },
  { value: "Israel", text: "Israel" },
  { value: "Italia", text: "Italia" },
  { value: "Jalur Gaza", text: "Jalur Gaza" },
  { value: "Jamaika", text: "Jamaika" },
  { value: "Jepang", text: "Jepang" },
  { value: "Jerman", text: "Jerman" },
  { value: "Jersey", text: "Jersey" },
  { value: "Kaledonia Baru", text: "Kaledonia Baru" },
  { value: "Kamboja", text: "Kamboja" },
  { value: "Kamerun", text: "Kamerun" },
  { value: "Kanada", text: "Kanada" },
  { value: "Kazakhstan", text: "Kazakhstan" },
  { value: "Kenya", text: "Kenya" },
  { value: "Kepulauan Cayman", text: "Kepulauan Cayman" },
  { value: "Kepulauan Cocos", text: "Kepulauan Cocos" },
  { value: "Kepulauan Cook", text: "Kepulauan Cook" },
  { value: "Kepulauan Falkland", text: "Kepulauan Falkland" },
  { value: "Kepulauan Faroe", text: "Kepulauan Faroe" },
  { value: "Kepulauan Mariana Utara", text: "Kepulauan Mariana Utara" },
  { value: "Kepulauan Marshall", text: "Kepulauan Marshall" },
  { value: "Kepulauan Pitcairn", text: "Kepulauan Pitcairn" },
  { value: "Kepulauan Turks dan Caicos", text: "Kepulauan Turks dan Caicos" },
  { value: "Kepulauan Virgin", text: "Kepulauan Virgin" },
  { value: "Kepulauan Virgin Inggris", text: "Kepulauan Virgin Inggris" },
  { value: "Kirgizia", text: "Kirgizia" },
  { value: "Kiribati", text: "Kiribati" },
  { value: "Kolombia", text: "Kolombia" },
  { value: "Komoro", text: "Komoro" },
  { value: "Korea Selatan", text: "Korea Selatan" },
  { value: "Korea Utara", text: "Korea Utara" },
  { value: "Kosta Rika", text: "Kosta Rika" },
  { value: "Kroasia", text: "Kroasia" },
  { value: "Kuba", text: "Kuba" },
  { value: "Kuwait", text: "Kuwait" },
  { value: "Laos", text: "Laos" },
  { value: "Latvia", text: "Latvia" },
  { value: "Lebanon", text: "Lebanon" },
  { value: "Lesotho", text: "Lesotho" },
  { value: "Liberia", text: "Liberia" },
  { value: "Libya", text: "Libya" },
  { value: "Liechtenstein", text: "Liechtenstein" },
  { value: "Lithuania", text: "Lithuania" },
  { value: "Luxembourg", text: "Luxembourg" },
  { value: "Madagaskar", text: "Madagaskar" },
  { value: "Makau", text: "Makau" },
  { value: "Makedonia", text: "Makedonia" },
  { value: "Maladewa", text: "Maladewa" },
  { value: "Malawi", text: "Malawi" },
  { value: "Malaysia", text: "Malaysia" },
  { value: "Mali", text: "Mali" },
  { value: "Malta", text: "Malta" },
  { value: "Maroko", text: "Maroko" },
  { value: "Martinique", text: "Martinique" },
  { value: "Mauritania", text: "Mauritania" },
  { value: "Mauritius", text: "Mauritius" },
  { value: "Mayotte", text: "Mayotte" },
  { value: "Meksiko", text: "Meksiko" },
  { value: "Mesir", text: "Mesir" },
  { value: "Mikronesia", text: "Mikronesia" },
  { value: "Moldavia", text: "Moldavia" },
  { value: "Monako", text: "Monako" },
  { value: "Mongolia", text: "Mongolia" },
  { value: "Montserrat", text: "Montserrat" },
  { value: "Mozambik", text: "Mozambik" },
  { value: "Myanmar", text: "Myanmar" },
  { value: "Namibia", text: "Namibia" },
  { value: "Nauru", text: "Nauru" },
  { value: "Nepal", text: "Nepal" },
  { value: "Niger", text: "Niger" },
  { value: "Nigeria", text: "Nigeria" },
  { value: "Nikaragua", text: "Nikaragua" },
  { value: "Norwegia", text: "Norwegia" },
  { value: "Oman", text: "Oman" },
  { value: "Pakistan", text: "Pakistan" },
  { value: "Palau", text: "Palau" },
  { value: "Panama", text: "Panama" },
  { value: "Pantai Gading", text: "Pantai Gading" },
  { value: "Papua Nugini", text: "Papua Nugini" },
  { value: "Paraguay", text: "Paraguay" },
  { value: "Perancis (Metropolitan)", text: "Perancis (Metropolitan)" },
  { value: "Peru", text: "Peru" },
  { value: "Polandia", text: "Polandia" },
  { value: "Polinesia Perancis", text: "Polinesia Perancis" },
  { value: "Portugal", text: "Portugal" },
  { value: "Puerto Riko", text: "Puerto Riko" },
  { value: "Pulau Man", text: "Pulau Man" },
  { value: "Pulau Natal", text: "Pulau Natal" },
  { value: "Pulau Norfolk", text: "Pulau Norfolk" },
  { value: "Qatar", text: "Qatar" },
  { value: "Rep Afrika Tengah", text: "Rep Afrika Tengah" },
  { value: "Republik Cina (Taiwan)", text: "Republik Cina (Taiwan)" },
  { value: "Republik Demokratik Kongo", text: "Republik Demokratik Kongo" },
  { value: "Republik Dominika", text: "Republik Dominika" },
  { value: "Republik Kongo", text: "Republik Kongo" },
  { value: "Republik Rakyat Cina", text: "Republik Rakyat Cina" },
  { value: "Reunion", text: "Reunion" },
  { value: "Romania", text: "Romania" },
  { value: "Rusia", text: "Rusia" },
  { value: "Rwanda", text: "Rwanda" },
  { value: "Sahara Barat", text: "Sahara Barat" },
  { value: "Saint Helena", text: "Saint Helena" },
  { value: "Saint Kitts dan Nevis", text: "Saint Kitts dan Nevis" },
  { value: "Saint Lucia", text: "Saint Lucia" },
  { value: "Saint Pierre dan Miquelon", text: "Saint Pierre dan Miquelon" },
  {
    value: "Saint Vincent dan Grenadines",
    text: "Saint Vincent dan Grenadines",
  },
  { value: "Samoa", text: "Samoa" },
  { value: "Samoa Amerika", text: "Samoa Amerika" },
  { value: "San Marino", text: "San Marino" },
  { value: "Sao Tome dan Principe", text: "Sao Tome dan Principe" },
  { value: "Selandia Baru", text: "Selandia Baru" },
  { value: "Senegal", text: "Senegal" },
  { value: "Serbia dan Montenegro", text: "Serbia dan Montenegro" },
  { value: "Seychelles", text: "Seychelles" },
  { value: "Sierra Leone", text: "Sierra Leone" },
  { value: "Singapura", text: "Singapura" },
  { value: "Siprus", text: "Siprus" },
  { value: "Slovenia", text: "Slovenia" },
  { value: "Slowakia", text: "Slowakia" },
  { value: "Solomon", text: "Solomon" },
  { value: "Somalia", text: "Somalia" },
  { value: "Spanyol", text: "Spanyol" },
  { value: "Sri Lanka", text: "Sri Lanka" },
  { value: "Sudan", text: "Sudan" },
  { value: "Suriah", text: "Suriah" },
  { value: "Suriname", text: "Suriname" },
  { value: "Svalbard", text: "Svalbard" },
  { value: "Swaziland", text: "Swaziland" },
  { value: "Swedia", text: "Swedia" },
  { value: "Swiss", text: "Swiss" },
  { value: "Tajikistan", text: "Tajikistan" },
  { value: "Tanjung Verde", text: "Tanjung Verde" },
  { value: "Tanzania", text: "Tanzania" },
  { value: "Tepi Barat", text: "Tepi Barat" },
  { value: "Thailand", text: "Thailand" },
  { value: "Timor Leste", text: "Timor Leste" },
  { value: "Togo", text: "Togo" },
  { value: "Tokelau", text: "Tokelau" },
  { value: "Tonga", text: "Tonga" },
  { value: "Trinidad dan Tobago", text: "Trinidad dan Tobago" },
  { value: "Tunisia", text: "Tunisia" },
  { value: "Turki", text: "Turki" },
  { value: "Turkmenistan", text: "Turkmenistan" },
  { value: "Tuvalu", text: "Tuvalu" },
  { value: "Uganda", text: "Uganda" },
  { value: "Ukraina", text: "Ukraina" },
  { value: "Uni Emirat Arab", text: "Uni Emirat Arab" },
  { value: "Uni Eropa", text: "Uni Eropa" },
  { value: "Uruguay", text: "Uruguay" },
  { value: "Uzbekistan", text: "Uzbekistan" },
  { value: "Vanuatu", text: "Vanuatu" },
  { value: "Vatikan", text: "Vatikan" },
  { value: "Venezuela", text: "Venezuela" },
  { value: "Vietnam", text: "Vietnam" },
  { value: "Wallis dan Futuna", text: "Wallis dan Futuna" },
  { value: "Yaman", text: "Yaman" },
  { value: "Yordania", text: "Yordania" },
  { value: "Yunani", text: "Yunani" },
  { value: "Zambia", text: "Zambia" },
  { value: "Zimbabwe", text: "Zimbabwe" },
];

const status_tahanan = [
  {
    value: "Ditahan",
    text: "Ditahan",
  },
  {
    value: "Bon tahanan",
    text: "Bon tahanan",
  },
  {
    value: "Perpanjangan",
    text: "Perpanjangan",
  },
  {
    value: "Dibantarkan",
    text: "⁠⁠Pembantaran",
  },
  {
    value: "Pengalihan⁠",
    text: "⁠Pengalihan Jenis Tahanan",
  },
  {
    value: "⁠⁠Penangguhan",
    text: "⁠⁠Penangguhan",
  },
  {
    value: "SP3",
    text: "SP3",
  },
  {
    value: "Tahap 2",
    text: "Tahap 2",
  },
  {
    value: "Bebas Demi Hukum",
    text: "Bebas Demi Hukum",
  },
  {
    value: "⁠⁠Rehabilitasi Narkoba",
    text: "⁠⁠Rehabilitasi Narkoba",
  },
];

export const roleAksesMenu = [
  {
    role: "Super Admin",
    pages: [
      "TAHANAN",
      "BARANG BUKTI",
      "BERKAS",
      "DASHBOARD",
      "LAPORAN",
      "MANAJEMEN PENGGUNA",
      "PENGATURAN",
      "MASTER",
      "PROFIL",
      "NOTIFIKASI",
    ],
  },
  {
    role: "Eksekutif TAHTI",
    pages: [
      "TAHANAN",
      "BARANG BUKTI",
      "BERKAS",
      "DASHBOARD",
      "LAPORAN",
      "PROFIL",
      "NOTIFIKASI",
    ],
  },
  {
    role: "Admin TAHTI",
    pages: [
      "TAHANAN",
      "BARANG BUKTI",
      "BERKAS",
      "DASHBOARD",
      "LAPORAN",
      "MANAJEMEN PENGGUNA",
      "PROFIL",
      "NOTIFIKASI",
    ],
  },
  {
    role: "Umum TAHTI",
    pages: [
      "TAHANAN",
      "BARANG BUKTI",
      "BERKAS",
      "DASHBOARD",
      "LAPORAN",
      "PROFIL",
      "NOTIFIKASI",
    ],
  },
  {
    role: "Petugas TAHTI",
    pages: [
      "TAHANAN",
      "BERKAS",
      "DASHBOARD",
      "LAPORAN",
      "PROFIL",
      "NOTIFIKASI",
    ],
  },
  {
    role: "Petugas BB TAHTI",
    pages: ["BERKAS", "DASHBOARD", "LAPORAN", "PROFIL", "NOTIFIKASI"],
  },
  {
    role: "Eksternal",
    pages: ["BERKAS", "DASHBOARD", "PROFIL", "NOTIFIKASI"],
  },
  {
    role: "Eksekutif Penyyidik",
    pages: [
      "TAHANAN",
      "BARANG BUKTI",
      "BERKAS",
      "DASHBOARD",
      "LAPORAN",
      "PROFIL",
      "NOTIFIKASI",
    ],
  },
  {
    role: "Admin Penyidik",
    pages: [
      "TAHANAN",
      "BARANG BUKTI",
      "BERKAS",
      "DASHBOARD",
      "LAPORAN",
      "MANAJEMEN PENGGUNA",
      "PROFIL",
      "NOTIFIKASI",
    ],
  },
  {
    role: "Atasan Penyidik",
    pages: [
      "TAHANAN",
      "BARANG BUKTI",
      "BERKAS",
      "DASHBOARD",
      "LAPORAN",
      "PROFIL",
      "NOTIFIKASI",
    ],
  },
  {
    role: "Penyidik",
    pages: [
      "TAHANAN",
      "BARANG BUKTI",
      "BERKAS",
      "DASHBOARD",
      "LAPORAN",
      "PROFIL",
      "NOTIFIKASI",
    ],
  },
];

const direktorat = [
  { text: "Lapas", value: "Lapas" },
  { text: "Dittipidum", value: "Dittipidum" },
  { text: "Dittipidkor", value: "Dittipidkor" },
  { text: "Dittipidnarkoba", value: "Dittipidnarkoba" },
  { text: "Dittipidter", value: "Dittipidter" },
  { text: "Dittipidsiber", value: "Dittipidsiber" },
  { text: "Dittipideksus", value: "Dittipideksus" },
  { text: "Dittipid PPA-PPO", value: "Dittipid PPA-PPO" },
  { text: "OJK", value: "OJK" },
  { text: "Jaksa", value: "Jaksa" },
  { text: "BNN", value: "BNN" },
  { text: "PPNS", value: "PPNS" },
  { text: "KPK", value: "KPK" },
];

const jenisTindakPidana = [
  { label: "Pidana Umum", value: "Pidana Umum" },
  { label: "Pidana Khusus", value: "Pidana Khusus" },
  { label: "Pidana Korupsi", value: "Pidana Korupsi" },
  { label: "Pidana Narkoba", value: "Pidana Narkoba" },
  { label: "Pidana Tertentu", value: "Pidana Tertentu" },
  { label: "Pidana Siber", value: "Pidana Siber" },
  {
    label: "Pidana Eksus (Ekonomi Khusus)",
    value: "Pidana Eksus (Ekonomi Khusus)",
  },
];

const jenisTindakPidanaTRX = [
  { text: "Pidana Umum", value: "Pidana Umum" },
  { text: "Pidana Khusus", value: "Pidana Khusus" },
  { text: "Pidana Korupsi", value: "Pidana Korupsi" },
  { text: "Pidana Narkoba", value: "Pidana Narkoba" },
  { text: "Pidana Tertentu", value: "Pidana Tertentu" },
  { text: "Pidana Siber", value: "Pidana Siber" },
  {
    text: "Pidana Eksus (Ekonomi Khusus)",
    value: "Pidana Eksus (Ekonomi Khusus)",
  },
];

const methodList = [
  { text: "Dashboard Barang Bukti", value: "Dashboard Barang Bukti" },
  { text: "Dashboard Admindik", value: "Dashboard Admindik" },
  { text: "Dashboard Tahanan", value: "Dashboard Tahanan" },
  { text: "Tahanan/Bon Tahanan", value: "Tahanan/Bon Tahanan" },
  { text: "Tahanan/Pengeluaran Tahanan", value: "Tahanan/Pengeluaran Tahanan" },
  {
    text: "Tahanan/Perpanjangan Tahanan",
    value: "Tahanan/Perpanjangan Tahanan",
  },
  { text: "Tahanan/Daftar Tahanan", value: "Tahanan/Daftar Tahanan" },
  { text: "Tahanan/Register Tahanan", value: "Tahanan/Register Tahanan" },
  { text: "Barang Bukti/Penerimaan", value: "Barang Bukti/Penerimaan" },
  { text: "Barang Bukti/Pemusnahan", value: "Barang Bukti/Pemusnahan" },
  { text: "Barang Bukti/Penyerahan", value: "Barang Bukti/Penyerahan" },
  { text: "Barang Bukti/Pengeluaran", value: "Barang Bukti/Pengeluaran" },
  {
    text: "Barang Bukti/Daftar Barang Bukti",
    value: "Barang Bukti/Daftar Barang Bukti",
  },
  { text: "Barang Bukti/Penyimpanan", value: "Barang Bukti/Penyimpanan" },
  { text: "Barang Bukti/Pengembalian", value: "Barang Bukti/Pengembalian" },
  { text: "Laporan/Tahanan", value: "Laporan/Tahanan" },
  { text: "Laporan/Barang Bukti", value: "Laporan/Barang Bukti" },
];

export {
  kesatuan,
  months,
  years,
  weeks,
  triwulan,
  roles,
  pangkat,
  country,
  status_tahanan,
  direktorat,
  jenisTindakPidana,
  superadmin,
  admintahti,
  methodList,
  jenisTindakPidanaTRX,
};
