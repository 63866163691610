import { Buffer } from "buffer";
import CryptoJS from 'crypto-js';

const aesKey = process.env.REACT_APP_AES_SECRET

export const encryptAES = (txt) => {
  const enc = CryptoJS.AES.encrypt(JSON.stringify(txt), aesKey)
  return enc
};

export const decryptAES = (chtx) => {
  const bytes = CryptoJS.AES.decrypt(chtx.toString(), aesKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export function encodeToHex(str) {
    return str ? Buffer.from(str, 'utf8').toString('hex') : '';
}
  
export function decodeFromHex(hexStr) {
    return hexStr ? Buffer.from(hexStr, 'hex').toString('utf8') : '';
}

export function trimUrlPath(url) {
  // Create an anchor element to utilize the URL API
  const anchor = document.createElement('a');
  anchor.href = url;

  // Get the path from the URL
  let path = anchor.pathname;

  // Remove the file name at the end
  path = path.substring(0, path.lastIndexOf('/'));
  
  let baket = ''
  let kategori = ''
  if (url.split('/').includes('tahti-dev')) {
    baket = 'tahti-dev'
    kategori = 'tahti'
  }
  if (url.split('/').includes('tahti-prod')) {
    baket = 'tahti-prod'
    kategori = 'tahti'
  }
  if (url.split('/').includes('tahti-production')) {
    baket = 'tahti-production'
    kategori = 'tahti'
  }
  if (url.split('/').includes('polda-metro-jaya')) {
    baket = 'polda-metro-jaya'
    kategori = 'tahti'
  }

  // Find the index of "polda-metro-jaya"
  const index = path.indexOf(baket);

  if (index === -1) {
    return ''; // Return an empty string if "polda-metro-jaya" is not found
  }

  // Extract the part after "polda-metro-jaya/"
  const resultPath = path.substring(index + `${baket}/`.length);
  
  return {
    p: resultPath.replaceAll(/%20/g, ' '),
    k: 'tahti'
  }
}

export function trimFileUrl(url) {
  return url.split('/').pop()
}

export function removeEmptyFields(payload) {
  return Object.fromEntries(Object.entries(payload).filter(([key, value]) => value !== ""));
}

export const isNotEmptyObject = (obj) => {
  return obj && typeof obj === 'object' && !Array.isArray(obj) && Object.keys(obj).length > 0;
};

export function checkObjectFields(obj) {
  const values = Object.values(obj);
  const allEmpty = values.every(value => !value);
  if (allEmpty) {
    return null;
  }
  const allFilled = values.every(value => value);
  if (allFilled) {
    return true;
  }
  return false;
}

export const formatString = (str) => {
  return str
    .split('_')                   
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))  
    .join(' ');     
};

export const validateToPath = (path) => {
  const urlPattern = /^(https?:\/\/)/i; // Match full URLs starting with http:// or https://
  const allowedDomains = ['dems.co.id', 'portal.dems.co.id']; // Whitelisted domains

  // Characters or patterns that could be used for bypasses
  const bypassPatterns = [
    /\\/g,          // Backslashes ( \ )
    /%/g,           // Percent encoding ( % )
    /°/g,           // Degree symbol ( ° )
    /\/\/+/g,       // Protocol-relative URLs or double slashes ( // )
    /\\{2,}/g       // Double backslashes ( \\ )
  ];

  try {
    // Check for bypass patterns
    for (const pattern of bypassPatterns) {
      if (pattern.test(path)) {
        throw new Error('Potential bypass detected');
      }
    }

    if (urlPattern.test(path)) {
      const parsedUrl = new URL(path); // Parse the URL

      // Validate the domain is in the whitelist
      if (!allowedDomains.includes(parsedUrl.hostname)) {
        throw new Error('Invalid domain for redirect');
      }

      // Check for invalid URL schemes or bypass attempts
      if (parsedUrl.protocol !== 'https:' && parsedUrl.protocol !== 'http:') {
        throw new Error('Invalid URL scheme');
      }

      return parsedUrl.href; // Return valid, sanitized full URL
    }

    // Handle relative paths
    const sanitizedPath = path.startsWith('/') ? path.slice(1) : path;
    return sanitizedPath;

  } catch (error) {
    console.error('Path validation error:', error.message);
    return ''; // Return empty string or a safe fallback path
  }
};

export const formatStringConvert = (inputString) => {
  // Escape karakter yang berbahaya di HTML
  let formattedString = inputString
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");

  formattedString = formattedString
    .replace(/\n{2,}/g, "</p><p>") 
    .replace(/\n/g, "<br />"); 

  formattedString = formattedString.replace(/\t/g, "&emsp;");

  return `${formattedString}`; 
};

export function convertObjToQueryString(obj) {
  // Gunakan Object.entries untuk mendapatkan array key-value pair
  const queryString = Object.entries(obj)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');

  return queryString;
}

export function formatNumberIndo(number) {
  if (number >= 1e12) {
      return (number / 1e12).toFixed(1).replace('.', ',') + ' Triliun';
  } else if (number >= 1e9) {
      return (number / 1e9).toFixed(1).replace('.', ',') + ' Miliar';
  } else if (number >= 1e6) {
      return (number / 1e6).toFixed(1).replace('.', ',') + ' Juta';
  } else {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
}

export function getNumbersFromString(str) {
  const numbers = str.match(/-?\d+/g);
  return numbers ? numbers.map(Number) : [];
}

export function formatIDR(amount) {
  if (isNaN(amount)) {
    return "Invalid number";
  }
  const numberString = amount.toFixed(2);
  const [integerPart, decimalPart] = numberString.split(".");
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    "."
  );
  if (decimalPart === "00") {
    return `Rp ${formattedIntegerPart}`;
  }
  return `Rp ${formattedIntegerPart},${decimalPart}`;
}

export const formatNumberWithCommas = (value) => {
  if (!value) return "";
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};