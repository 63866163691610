import {
  Badge,
  Button,
  Card,
  Col,
  Row,
  Form,
  Table,
  Accordion,
  Spinner,
  Alert as AlertBS,
  Tabs,
  Tab,
} from "react-bootstrap";
import {
  DatePicker,
  Input,
  Select,
  Image,
  Select2,
} from "../../../../components/Components";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import {
  useGetDetailTahti,
  usePostTahti,
  usePostTahtiLapas,
  usePutTahti,
  useSatkerTahtiList,
  useGetDetailTahtiLapas,
  usePutTahtiLapas,
  useRutanList,
} from "../../../../utils/apis/query";
import { format, parse, addDays } from "date-fns";
import Alert from "../../../../libraries/Alert";
import State from "../../../../libraries/State";
import Files from "react-files";
import { country, jenisTindakPidanaTRX, pangkat } from "../../../../utils";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import logo_kop from "../../../../assets/img/logo_kopsurat.png";

function FormTahti() {
  const onSuccess = () => {
    setIsFormDirty(false);
    setTimeout(() => {
      navigate("/tahanan/registrasi/list");
    }, 1500);
  };
  const [today, setToday] = useState("");
  const location = useLocation();
  const isRegistrasi = location.pathname.includes("/registrasi");
  const isLapas = location.pathname.includes("/lapas");
  const state = State.getState();
  const userData = state.getItem("profileData", true);
  const [isEdit, setIsEdit] = useState(false);
  const [isFormEditable, setIsFormEditable] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const mutateAdd = usePostTahti(onSuccess);
  const mutateAddLapas = usePostTahtiLapas(onSuccess);
  const mutateEdit = usePutTahti(onSuccess);
  const mutateEditLapas = usePutTahtiLapas(onSuccess);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isAddingRow, setIsAddingRow] = useState(false);
  const [penyidikData, setPenyidikData] = useState([]);
  const [imagePreviewTahanan, setImagePreviewTahanan] = useState(null);
  const [imagePreviewSidikJari, setImagePreviewSidikJari] = useState(null);
  const [sisaMasaTahanan, setSisaMasaTahanan] = useState(0);
  const [activeTab, setActiveTab] = useState("tab1");

  const handleSelect = (key) => {
    setActiveTab(key);
  };
  const [penyidik, setPenyidik] = useState({
    nrp: "",
    nama_penyidik: "",
    nama_satker: "",
    pangkat: "",
    jabatan: "",
    no_hp: "",
  });
  const [payloadDate, setPayloadDate] = useState({
    tanggal_lahir: "",
    tanggal_penahanan: "",
    tanggal_kejadian: "",
    awal_penahanan: "",
    akhir_penahanan: "",
    tanggal_laporan: "",
    tanggal_sprin_penahanan: "",
    tanggal_dokkes: "",
    tanggal_laporan_lapas: "",
    tanggal_dokumen_ba: "",
    tanggal_ba_penahanan: "",
    tanggal_surat_keterangan_pusdokkes: "",
  });

  const [tahti, setTahti] = useState({
    no_kasus: "",
    no_sprin_penahanan: "",
    nama_satker: "",
    nama_tersangka: "",
    tempat_lahir: "",
    tanggal_lahir: "",
    umur: "",
    jenis_kelamin: "",
    agama: "",
    kebangsaan: "",
    asal_negara: "",
    pekerjaan: "",
    alamat: "",
    perkara: "",
    tanggal_penahanan: "",
    tanggal_kejadian: "",
    alamat_tkp: "",
    nama_rutan: "",
    alamat_rutan: "",
    kota_rutan: "",
    fase: "",
    awal_penahanan: "",
    akhir_penahanan: "",
    tanggal_surat: today,
    no_tahanan: "",
    fase_penahanan: "",
    pasal_pelanggaran: "",
    informasi_tambahan: "",
    no_ktp: "",
    no_passport: "",
    no_sim: "",
    golongan_darah: "",
    no_laporan: "",
    tanggal_laporan: "",
    tanggal_sprin_penahanan: "",
    no_dokkes: "",
    tanggal_dokkes: "",
    rumus_wajah: "",
    rumus_sidikjari: "",
    penyidik: "",
    asal_direktorat: "",
    asal_tahanan: "",
    kondisi_tahanan: "",
    status_dokumen: "",
    no_laporan_lapas: "",
    ba_peminjaman_lapas: "",
    tanggal_laporan_lapas: "",
    tanggal_dokumen_ba: "",
    arsip_laporan_lapas: null,
    arsip_berita_acara_lapas: null,
    lampiran: null,
    no_register_lapas: "",
    no_putusan: "",
    tindak_pidana: "",
    no_ba_penahanan: "",
    no_keterangan_pemeriksaan_tersangka_dari_pusdokkes: "",
    tanggal_ba_penahanan: "",
    tanggal_surat_keterangan_pusdokkes: "",
    arsip_ba_penahanan: null,
    arsip_pemeriksaan_tersangka_dari_pusdokkes: null,
    arsip_no_sprin_penahanan: null,
    arsip_nomor_lp: null,
    nama_fase_penahanan: "",
    tipe_tahanan: "",
  });

  const { data: masterListSatkers } = useSatkerTahtiList();
  const satkersFilter = masterListSatkers.map((e) => ({
    label: e.nama_satker,
    value: e.nama_satker,
  }));
  const { data: masterListRutan } = useRutanList({
    nama_satker: tahti.nama_satker,
  });
  const rutanFilter = masterListRutan.map((e) => ({
    text: e.nama_rutan,
    value: e.nama_rutan,
  }));

  const handleAddRow = () => {
    setIsAddingRow(true);
  };
  const closeRow = () => {
    setIsAddingRow(false);
  };

  const handleSaveRow = () => {
    setPenyidikData([...penyidikData, penyidik]);
    setPenyidik({
      nrp: "",
      nama_penyidik: "",
      nama_satker: "",
      pangkat: "",
      jabatan: "",
      no_hp: "",
    });
    setIsAddingRow(false);
  };

  const handleNewRowChange = (e) => {
    const { name, value } = e.target;
    setPenyidik({ ...penyidik, [name]: value });
  };

  const handleDelete = (index) => {
    const newPenyidikData = penyidikData.filter((_, idx) => idx !== index);
    setPenyidikData(newPenyidikData);
  };
  // ----------------------

  const nonLapas = useGetDetailTahti(id, { enabled: !!id });
  const asalLapas = useGetDetailTahtiLapas(id, { enabled: !!id });
  let hookResult = "";

  if (isLapas) {
    hookResult = asalLapas;
  } else {
    hookResult = nonLapas;
  }
  const {
    data: detailData,
    isFetching: detailIsFetching,
    isError: detailIsError,
    error: detailError,
  } = hookResult;

  const handleChange = (e) => {
    setIsFormDirty(true);
    let { name, value } = e.target;
    if (name === "nama_fase_penahanan") {
      let akhir_penahanan = null;
      let val = "";
      if (value === "Penyidik") {
        val = "20";
      } else if (value === "Jaksa") {
        val = "40";
      } else if (value === "PN1") {
        val = "30";
      } else if (value === "PN2") {
        val = "30";
      } else {
        val = "0";
      }
      if (tahti.tanggal_sprin_penahanan) {
        // const awal = parse(tahti.awal_penahanan, "yyyy-MM-dd", new Date());
        const awal = moment(tahti.tanggal_sprin_penahanan).format("YYYY-MM-DD");
        akhir_penahanan = addDays(awal, parseInt(val - 1));
      }

      setTahti({
        ...tahti,
        fase: val,
        nama_fase_penahanan: value,
        akhir_penahanan: akhir_penahanan
          ? moment(akhir_penahanan).format("YYYY-MM-DD")
          : "",
      });
      setPayloadDate({
        ...payloadDate,
        akhir_penahanan: akhir_penahanan
          ? moment(akhir_penahanan).toDate()
          : null,
      });
    } else if (
      // name === "nama_rutan" ||
      name === "kota_rutan" ||
      name === "alamat_rutan"
    ) {
      setTahti({
        ...tahti,
        [name]: value.toUpperCase(),
      });
    } else {
      setTahti({ ...tahti, [name]: value });
    }
  };

  const handleDatePicker = (date, name) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setTahti({ ...tahti, [name]: formattedDate });

    if (name === "tanggal_sprin_penahanan" && date) {
      setTahti((prevState) => ({
        ...prevState,
        tanggal_penahanan: formattedDate,
      }));
      setPayloadDate((prevState) => ({
        ...prevState,
        tanggal_penahanan: date,
      }));

      if (tahti.fase) {
        const awalDate = formattedDate;
        const akhirDate = addDays(awalDate, parseInt(tahti.fase - 1));
        setTahti((prevState) => ({
          ...prevState,
          akhir_penahanan: moment(akhirDate).format("YYYY-MM-DD"),
        }));
        setPayloadDate((prevState) => ({
          ...prevState,
          akhir_penahanan: akhirDate ? moment(akhirDate).toDate() : null,
        }));
      }
    }

    if (name === "tanggal_lahir") {
      const age = calculateAge(date);
      setTahti((prevState) => ({ ...prevState, umur: age }));
    }
  };
  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDifference = today.getMonth() - birth.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birth.getDate())
    ) {
      age--;
    }

    return age;
  };

  useEffect(() => {
    const getToday = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    setToday(getToday);
  }, []);

  useEffect(() => {
    if (userData) {
      setTahti({
        ...tahti,
        nama_satker: userData?.satker?.nama_satker,
        // nama_rutan: userData?.satker?.nama_rutan,
        // alamat_rutan: userData?.satker?.alamat_rutan,
        // kota_rutan: userData?.satker?.kota,
      });
    }
  }, [userData]);

  useEffect(() => {
    setIsEdit(false);
    setIsFormEditable(true);
    if (id) {
      setIsEdit(true);
      setIsFormEditable(false);
    }
  }, [id]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormDirty]);

  useEffect(() => {
    if (detailData) {
      setTahti({
        ...tahti,
        no_kasus: detailData.no_kasus,
        no_tahanan: detailData.no_tahanan,
        no_sprin_penahanan: detailData.no_sprin_penahanan,
        nama_satker: detailData.nama_satker,
        nama_tersangka: detailData.nama_tersangka,
        tempat_lahir: detailData.tempat_lahir,
        tanggal_lahir: detailData.tanggal_lahir,
        umur: detailData.umur,
        jenis_kelamin: detailData.jenis_kelamin,
        agama: detailData.agama,
        kebangsaan: detailData.kebangsaan,
        asal_negara: detailData.asal_negara,
        pekerjaan: detailData.pekerjaan,
        alamat: detailData.alamat,
        perkara: detailData.perkara,
        tanggal_penahanan: detailData.tanggal_penahanan,
        tanggal_kejadian: detailData.tanggal_kejadian,
        alamat_tkp: detailData.alamat_tkp,
        nama_rutan: detailData.nama_rutan
          ? detailData.nama_rutan
          : userData?.satker?.nama_rutan,
        alamat_rutan: detailData.alamat_rutan
          ? detailData.alamat_rutan
          : userData?.satker?.alamat_rutan,
        kota_rutan: detailData.kota_rutan
          ? detailData.kota_rutan
          : userData?.satker?.kota,
        fase_penahanan: detailData.fase_penahanan,
        fase: detailData.fase_penahanan?.split("/")[0],
        nama_fase_penahanan: detailData.nama_fase_penahanan,
        awal_penahanan: detailData.awal_penahanan,
        akhir_penahanan: detailData.akhir_penahanan,
        tanggal_surat: detailData.tanggal_surat,
        pasal_pelanggaran: detailData.pasal_pelanggaran,
        informasi_tambahan: detailData.informasi_tambahan,
        no_ktp: detailData.no_ktp,
        no_passport: detailData.no_passport,
        no_sim: detailData.no_sim,
        golongan_darah: detailData.golongan_darah,
        no_laporan: detailData.no_laporan,
        tanggal_laporan: detailData.tanggal_laporan,
        tanggal_sprin_penahanan: detailData.tanggal_sprin_penahanan,
        no_dokkes: detailData.no_dokkes,
        tanggal_dokkes: detailData.tanggal_dokkes,
        asal_direktorat: detailData.asal_direktorat,
        asal_tahanan: detailData.asal_tahanan,
        kondisi_tahanan: detailData.kondisi_tahanan,
        status_dokumen: detailData.status_dokumen,
        no_laporan_lapas: detailData.no_laporan_lapas,
        ba_peminjaman_lapas: detailData.ba_peminjaman_lapas,
        tanggal_laporan_lapas: detailData.tanggal_laporan_lapas,
        tanggal_dokumen_ba: detailData.tanggal_dokumen_ba,
        no_register_lapas: detailData.no_register_lapas,
        no_putusan: detailData.no_putusan,
        tindak_pidana: detailData.tindak_pidana,
        tanggal_ba_penahanan: detailData.tanggal_ba_penahanan,
        tanggal_surat_keterangan_pusdokkes:
          detailData.tanggal_surat_keterangan_pusdokkes,
        no_ba_penahanan: detailData.no_ba_penahanan,
        no_keterangan_pemeriksaan_tersangka_dari_pusdokkes:
          detailData.no_keterangan_pemeriksaan_tersangka_dari_pusdokkes,
        tipe_tahanan: detailData.tipe_tahanan,
      });
      setImagePreviewTahanan(detailData.rumus_wajah);
      setImagePreviewSidikJari(detailData.rumus_sidikjari);
      setPayloadDate({
        ...payloadDate,
        tanggal_lahir: detailData?.tanggal_lahir
          ? moment(detailData?.tanggal_lahir).toDate()
          : null,
        tanggal_penahanan: detailData?.tanggal_penahanan
          ? moment(detailData?.tanggal_penahanan).toDate()
          : null,
        tanggal_kejadian: detailData?.tanggal_kejadian
          ? moment(detailData?.tanggal_kejadian).toDate()
          : null,
        awal_penahanan: detailData?.awal_penahanan
          ? moment(detailData?.awal_penahanan).toDate()
          : null,
        akhir_penahanan: detailData?.akhir_penahanan
          ? moment(detailData?.akhir_penahanan).toDate()
          : null,
        tanggal_laporan: detailData?.tanggal_laporan
          ? moment(detailData?.tanggal_laporan).toDate()
          : null,
        tanggal_sprin_penahanan: detailData?.tanggal_sprin_penahanan
          ? moment(detailData?.tanggal_sprin_penahanan).toDate()
          : null,
        tanggal_dokkes: detailData?.tanggal_dokkes
          ? moment(detailData?.tanggal_dokkes).toDate()
          : null,
        tanggal_laporan_lapas: detailData?.tanggal_laporan_lapas
          ? moment(detailData?.tanggal_laporan_lapas).toDate()
          : null,
        tanggal_dokumen_ba: detailData?.tanggal_dokumen_ba
          ? moment(detailData?.tanggal_dokumen_ba).toDate()
          : null,
        tanggal_ba_penahanan: detailData?.tanggal_ba_penahanan
          ? moment(detailData?.tanggal_ba_penahanan).toDate()
          : null,
        tanggal_surat_keterangan_pusdokkes:
          detailData?.tanggal_surat_keterangan_pusdokkes
            ? moment(detailData?.tanggal_surat_keterangan_pusdokkes).toDate()
            : null,
      });
      setPenyidikData(detailData.penyidik ?? []);

      const startPenahanan = new Date(detailData.awal_penahanan);
      const endPenahanan = startPenahanan.setDate(
        startPenahanan.getDate() +
          parseInt(detailData.fase_penahanan?.split("/")[1])
      );
      const today = new Date();
      let masaTahanan = new Date(detailData.akhir_penahanan) - today;
      masaTahanan = Math.ceil(masaTahanan / (1000 * 60 * 60 * 24));
      setSisaMasaTahanan(masaTahanan);
    }
  }, [detailData]);

  useEffect(() => {
    return () => {
      setTahti({
        no_kasus: "",
        no_tahanan: "",
        no_sprin_penahanan: "",
        nama_satker: "",
        nama_tersangka: "",
        tempat_lahir: "",
        tanggal_lahir: "",
        umur: "",
        jenis_kelamin: "",
        agama: "",
        kebangsaan: "",
        asal_negara: "",
        pekerjaan: "",
        alamat: "",
        perkara: "",
        tanggal_penahanan: "",
        tanggal_kejadian: "",
        alamat_tkp: "",
        nama_rutan: "",
        fase_penahanan: "",
        fase: "",
        nama_fase_penahanan: "",
        awal_penahanan: "",
        akhir_penahanan: "",
        tanggal_surat: "",
        alamat_rutan: "",
        pasal_pelanggaran: "",
        informasi_tambahan: "",
        no_ktp: "",
        no_passport: "",
        no_sim: "",
        golongan_darah: "",
        no_laporan: "",
        tanggal_laporan: "",
        tanggal_sprin_penahanan: "",
        no_dokkes: "",
        tanggal_dokkes: "",
        rumus_wajah: "",
        rumus_sidikjari: "",
        penyidik: [],
        asal_direktorat: "",
        asal_tahanan: "",
        kondisi_tahanan: "",
        no_laporan_lapas: "",
        ba_peminjaman_lapas: "",
        tanggal_laporan_lapas: "",
        tanggal_dokumen_ba: "",
        lampiran: "",
        arsip_laporan_lapas: "",
        arsip_berita_acara_lapas: "",
        no_register_lapas: "",
        no_putusan: "",
        tindak_pidana: "",
        tanggal_ba_penahanan: "",
        tanggal_surat_keterangan_pusdokkes: "",
        no_ba_penahanan: "",
        no_keterangan_pemeriksaan_tersangka_dari_pusdokkes: "",
        arsip_ba_penahanan: "",
        arsip_pemeriksaan_tersangka_dari_pusdokkes: "",
        arsip_no_sprin_penahanan: "",
        arsip_nomor_lp: "",
        tipe_tahanan: "",
      });
    };
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  const handlePrintPimpinan = async () => {
    const input = document.getElementById("printable-content-pimpinan");

    // Tangkap elemen sebagai kanvas
    const canvas = await html2canvas(input, {
      useCORS: true,
      allowTaint: false,
      scale: 2, // Skala lebih tinggi untuk kualitas gambar yang lebih baik
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    const pdfWidth = pdf.internal.pageSize.getWidth(); // Lebar PDF
    const pdfHeight = pdf.internal.pageSize.getHeight(); // Tinggi PDF

    const padding = 10; // Padding untuk setiap sisi
    const contentWidth = pdfWidth - 2 * padding; // Lebar konten dalam PDF
    const canvasWidth = canvas.width; // Lebar kanvas
    const canvasHeight = canvas.height; // Tinggi kanvas

    const imgHeight = (canvasHeight * contentWidth) / canvasWidth; // Skala tinggi gambar sesuai PDF
    let heightLeft = imgHeight; // Tinggi yang tersisa untuk dipotong

    let position = padding; // Posisi awal untuk gambar pertama
    const marginTop = 10; // Margin atas untuk halaman selain pertama

    // Tambahkan gambar ke halaman PDF, potong jika melebihi tinggi halaman
    pdf.addImage(imgData, "PNG", padding, position, contentWidth, imgHeight);
    heightLeft -= pdfHeight - padding;

    while (heightLeft > 0) {
      position -= pdfHeight - marginTop - padding; // Geser posisi untuk halaman berikutnya dengan margin atas
      pdf.addPage(); // Tambahkan halaman baru
      pdf.addImage(imgData, "PNG", padding, position, contentWidth, imgHeight);
      heightLeft -= pdfHeight - padding;
    }

    pdf.save(tahti.no_tahanan + "_" + tahti.nama_tersangka + ".pdf");
  };

  const handlePrintInternal = async () => {
    const input = document.getElementById("printable-content-internal");

    // Tangkap elemen sebagai kanvas
    const canvas = await html2canvas(input, {
      useCORS: true,
      allowTaint: false,
      scale: 2, // Skala lebih tinggi untuk kualitas gambar yang lebih baik
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    const pdfWidth = pdf.internal.pageSize.getWidth(); // Lebar PDF
    const pdfHeight = pdf.internal.pageSize.getHeight(); // Tinggi PDF

    const padding = 10; // Padding untuk setiap sisi
    const contentWidth = pdfWidth - 2 * padding; // Lebar konten dalam PDF
    const canvasWidth = canvas.width; // Lebar kanvas
    const canvasHeight = canvas.height; // Tinggi kanvas

    const imgHeight = (canvasHeight * contentWidth) / canvasWidth; // Skala tinggi gambar sesuai PDF
    let heightLeft = imgHeight; // Tinggi yang tersisa untuk dipotong

    let position = padding; // Posisi awal untuk gambar pertama
    const marginTop = 10; // Margin atas untuk halaman selain pertama

    // Tambahkan gambar ke halaman PDF, potong jika melebihi tinggi halaman
    pdf.addImage(imgData, "PNG", padding, position, contentWidth, imgHeight);
    heightLeft -= pdfHeight - padding;

    while (heightLeft > 0) {
      position -= pdfHeight - marginTop - padding; // Geser posisi untuk halaman berikutnya dengan margin atas
      pdf.addPage(); // Tambahkan halaman baru
      pdf.addImage(imgData, "PNG", padding, position, contentWidth, imgHeight);
      heightLeft -= pdfHeight - padding;
    }

    pdf.save(tahti.no_tahanan + "_" + tahti.nama_tersangka + ".pdf");
  };
  const handlePrintEksternal = async () => {
    const input = document.getElementById("printable-content-eksternal");

    // Tangkap elemen sebagai kanvas
    const canvas = await html2canvas(input, {
      useCORS: true,
      allowTaint: false,
      scale: 2, // Skala lebih tinggi untuk kualitas gambar yang lebih baik
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    const pdfWidth = pdf.internal.pageSize.getWidth(); // Lebar PDF
    const pdfHeight = pdf.internal.pageSize.getHeight(); // Tinggi PDF

    const padding = 10; // Padding untuk setiap sisi
    const contentWidth = pdfWidth - 2 * padding; // Lebar konten dalam PDF
    const canvasWidth = canvas.width; // Lebar kanvas
    const canvasHeight = canvas.height; // Tinggi kanvas

    const imgHeight = (canvasHeight * contentWidth) / canvasWidth; // Skala tinggi gambar sesuai PDF
    let heightLeft = imgHeight; // Tinggi yang tersisa untuk dipotong

    let position = padding; // Posisi awal untuk gambar pertama
    const marginTop = 10; // Margin atas untuk halaman selain pertama

    // Tambahkan gambar ke halaman PDF, potong jika melebihi tinggi halaman
    pdf.addImage(imgData, "PNG", padding, position, contentWidth, imgHeight);
    heightLeft -= pdfHeight - padding;

    while (heightLeft > 0) {
      position -= pdfHeight - marginTop - padding; // Geser posisi untuk halaman berikutnya dengan margin atas
      pdf.addPage(); // Tambahkan halaman baru
      pdf.addImage(imgData, "PNG", padding, position, contentWidth, imgHeight);
      heightLeft -= pdfHeight - padding;
    }

    pdf.save(tahti.no_tahanan + "_" + tahti.nama_tersangka + ".pdf");
  };

  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between m-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/tahanan/registrasi/list">Tahanan</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/tahanan/registrasi/list">Tahanan List</Link>
                </li>
                <li
                  className="breadcrumb-item active fw-bold"
                  aria-current="page"
                >
                  Print Preview Tahanan
                </li>
              </ol>
            </div>
            {isEdit && isRegistrasi && (
              <Button
                className="d-flex align-items-center gap-2"
                variant="primary"
                size="normal"
                onClick={() => setIsFormEditable(!isFormEditable)}
              >
                <i className="ri-edit-2-line"></i>
                {isFormEditable ? "Batalkan Edit" : "Edit Form"}
              </Button>
            )}
          </div>
        </Card.Body>
      </Card>
      <Card className="bg-white">
        <Card.Body>
          <Row className="g-2">
            {
              <Col sm="12">
                {mutateAdd.isError && (
                  <AlertBS variant="danger">
                    <ul>
                      {mutateAdd?.error?.response?.data?.detail?.map(
                        (errEdit) => (
                          <li>
                            <b>{errEdit.loc[1]} </b>
                            {errEdit.msg}
                          </li>
                        )
                      ) ?? (
                        <li>
                          <b>Error </b>{" "}
                          {mutateAdd?.error?.response?.data?.value?.message ??
                            " Proses Submit Gagal"}
                        </li>
                      )}
                    </ul>
                  </AlertBS>
                )}
                {mutateAdd.isSuccess && (
                  <AlertBS variant="success">
                    {mutateAdd.data.data.message}
                  </AlertBS>
                )}
              </Col>
            }
            {
              <Col sm="12">
                {mutateEdit.isError && (
                  <AlertBS variant="danger">
                    <ul>
                      {mutateEdit?.error?.response?.data?.detail?.map(
                        (errEdit) => (
                          <li>
                            <b>{errEdit.loc[1]} </b>
                            {errEdit.msg}
                          </li>
                        )
                      ) ?? (
                        <li>
                          <b>Error </b>{" "}
                          {mutateEdit?.error?.response?.data?.value?.message ??
                            " Proses Submit Gagal"}
                        </li>
                      )}
                    </ul>
                  </AlertBS>
                )}
                {mutateEdit.isSuccess && (
                  <AlertBS variant="success">
                    {mutateEdit.data.data.message}
                  </AlertBS>
                )}
              </Col>
            }
            <Col sm="12">
              <Tabs
                activeKey={activeTab}
                onSelect={handleSelect}
                className="mb-3"
                justify
              >
                <Tab eventKey="tab1" title="Versi Pimpinan">
                  <div className="d-flex justify-content-end align-items-center">
                    <Button variant="danger" onClick={handlePrintPimpinan}>
                      Print to PDF
                    </Button>
                  </div>
                  <Accordion
                    defaultActiveKey={["0", "1", "2"]}
                    flush
                    alwaysOpen
                    id="printable-content-pimpinan"
                  >
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <img
                        src={logo_kop}
                        alt="Logo Kop"
                        style={{ width: "150px" }}
                      />
                      <h3>KEPOLISIAN REPUBLIK INDONESIA</h3>
                      <h3>{tahti.nama_satker}</h3>
                    </div>
                    <hr />
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="custom-header">
                        Biodata Tahanan
                      </Accordion.Header>
                      <Accordion.Body className="py-2 px-0">
                        <Card className="card bg-white">
                          <Card.Body>
                            <Row>
                              <Col sm="8">
                                <Row className="g-2 mt-2">
                                  <Col sm="4">
                                    <label style={{ color: "blue" }}>NIK</label>
                                  </Col>
                                  <Col sm="8">
                                    <label>{tahti.no_tahanan}</label>
                                  </Col>
                                  <Col sm="4">
                                    <label style={{ color: "blue" }}>
                                      Nama Lengkap
                                    </label>
                                  </Col>
                                  <Col sm="8">
                                    <label>{tahti.nama_tersangka}</label>
                                  </Col>
                                  <Col sm="4">
                                    <label style={{ color: "blue" }}>
                                      Tempat, Tanggal lahir
                                    </label>
                                  </Col>
                                  <Col sm="8">
                                    <label>
                                      {tahti.tempat_lahir || "-"},{" "}
                                      {tahti.tanggal_lahir
                                        ? moment(tahti.tanggal_lahir).format(
                                            "DD/MM/YYYY"
                                          )
                                        : "-"}
                                    </label>
                                  </Col>
                                  <Col sm="4">
                                    <label style={{ color: "blue" }}>
                                      Jenis Kelamin
                                    </label>
                                  </Col>
                                  <Col sm="8">
                                    <label>{tahti.jenis_kelamin}</label>
                                  </Col>
                                  <Col sm="4">
                                    <label style={{ color: "blue" }}>
                                      Pekerjaan
                                    </label>
                                  </Col>
                                  <Col sm="8">
                                    <label>{tahti.pekerjaan || "-"}</label>
                                  </Col>
                                  <Col sm="4">
                                    <label style={{ color: "blue" }}>
                                      Agama
                                    </label>
                                  </Col>
                                  <Col sm="8">
                                    <label>{tahti.agama || "-"}</label>
                                  </Col>
                                  <Col sm="4">
                                    <label style={{ color: "blue" }}>
                                      Kewarganegaraan
                                    </label>
                                  </Col>
                                  <Col sm="8">
                                    <label>{tahti.kebangsaan || "-"}</label>
                                  </Col>
                                  <Col sm="4">
                                    <label style={{ color: "blue" }}>
                                      Asal Negara
                                    </label>
                                  </Col>
                                  <Col sm="8">
                                    <label>{tahti.asal_negara || "-"}</label>
                                  </Col>
                                  <Col sm="4">
                                    <label style={{ color: "blue" }}>
                                      Alamat
                                    </label>
                                  </Col>
                                  <Col sm="8">
                                    <label>{tahti.alamat || "-"}</label>
                                  </Col>
                                </Row>
                              </Col>
                              <Col
                                sm="4"
                                className="d-flex flex-column gap-3 mt-2"
                              >
                                <div className="d-flex flex-column gap-2">
                                  <span className="fs-6 fw-medium">
                                    Foto Tahanan
                                  </span>
                                  <Image
                                    src={imagePreviewTahanan}
                                    height="250px"
                                    width="100%"
                                  />
                                </div>
                                <div className="d-flex flex-column gap-2">
                                  <span className="fs-6 fw-medium">
                                    Foto Sidik Jari
                                  </span>
                                  <Image
                                    src={imagePreviewSidikJari}
                                    height="250px"
                                    width="100%"
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header className="custom-header">
                        Informasi Penyidikan
                      </Accordion.Header>
                      <Accordion.Body className="py-2 px-0">
                        <Card className="card bg-white">
                          <Card.Body>
                            <Row className="g-2">
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  Instansi Gakkum
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>{tahti.asal_tahanan}</label>
                              </Col>
                            </Row>
                            <Row className="mt-2">
                              <Col sm="12">
                                <Card className="card bg-white">
                                  <Card.Body>
                                    <Row className="g-1 mb-2 d-flex align-items-center">
                                      <Col sm="12" md="5">
                                        <h6>Daftar Penyidik</h6>
                                      </Col>
                                    </Row>
                                    <Table className="table dems-table">
                                      <thead>
                                        <tr>
                                          <th>No</th>
                                          <th>NRP</th>
                                          <th>Nama Penyidik</th>
                                          <th>Asal Satker</th>
                                          <th>Pangkat</th>
                                          <th>Jabatan</th>
                                          <th>No. HP</th>
                                          <th>&nbsp;</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {penyidikData.length
                                          ? penyidikData.map((data, index) => (
                                              <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{data.nrp}</td>
                                                <td>{data.nama_penyidik}</td>
                                                <td>{data.nama_satker}</td>
                                                <td>{data.pangkat}</td>
                                                <td>{data.jabatan}</td>
                                                <td>{data.no_hp}</td>
                                                <td>
                                                  <div className="d-flex align-items-center justify-content-end gap-2">
                                                    <Badge
                                                      onClick={() =>
                                                        handleDelete(index)
                                                      }
                                                      className="btn bg-danger"
                                                      as="button"
                                                      disabled={!isFormEditable}
                                                    >
                                                      <i className="ri-delete-bin-line"></i>
                                                    </Badge>
                                                  </div>
                                                </td>
                                              </tr>
                                            ))
                                          : !penyidikData.length && (
                                              <tr>
                                                <td
                                                  colSpan="4"
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  <span>
                                                    Tidak ada data penyidik!
                                                  </span>
                                                </td>
                                              </tr>
                                            )}
                                        {isAddingRow && (
                                          <tr>
                                            <td></td>
                                            <td>
                                              <Input
                                                type="text"
                                                name="nrp"
                                                value={penyidik.nrp}
                                                onChange={handleNewRowChange}
                                                placeholder="NRP"
                                              />
                                            </td>
                                            <td>
                                              <Input
                                                type="text"
                                                name="nama_penyidik"
                                                value={penyidik.nama_penyidik}
                                                onChange={handleNewRowChange}
                                                placeholder="Nama Penyidik"
                                              />
                                            </td>
                                            <td style={{ width: "200px" }}>
                                              <Select2
                                                name="nama_satker"
                                                defaultValue={"-"}
                                                placeholder={"Satker"}
                                                options={satkersFilter}
                                                value={penyidik.nama_satker}
                                                onChange={(selectedOption) => {
                                                  setPenyidik({
                                                    ...penyidik,
                                                    nama_satker:
                                                      selectedOption.value,
                                                  });
                                                }}
                                                required
                                              />
                                              {/* <Select
                                                default="Pilih Satker"
                                                name="nama_satker"
                                                value={penyidik.nama_satker}
                                                onChange={handleNewRowChange}
                                                options={satkersFilter}
                                              /> */}
                                            </td>
                                            <td>
                                              <Select
                                                default="Pilih Pangkat"
                                                name="pangkat"
                                                value={penyidik.pangkat}
                                                onChange={handleNewRowChange}
                                                options={pangkat}
                                              />
                                            </td>
                                            <td>
                                              <Input
                                                type="text"
                                                name="jabatan"
                                                value={penyidik.jabatan}
                                                onChange={handleNewRowChange}
                                                placeholder="Jabatan"
                                              />
                                            </td>
                                            <td>
                                              <Input
                                                type="text"
                                                name="no_hp"
                                                value={penyidik.no_hp}
                                                onChange={handleNewRowChange}
                                                placeholder="NO. HP"
                                              />
                                            </td>
                                            <td className="d-flex gap-1">
                                              <Button
                                                className="btn bg-danger"
                                                onClick={closeRow}
                                              >
                                                <i class="ri-close-large-line"></i>
                                              </Button>
                                              <Button
                                                className="btn bg-success"
                                                onClick={handleSaveRow}
                                              >
                                                <i class="ri-check-line"></i>
                                              </Button>
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </Table>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>
                            <Row className="g-2 mt-2">
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  Asal Satker
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>{tahti.nama_satker || "-"}</label>
                              </Col>
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  Tindak Pidana
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>{tahti.tindak_pidana || "-"}</label>
                              </Col>
                              <Col sm="4">
                                <label style={{ color: "blue" }}>Perkara</label>
                              </Col>
                              <Col sm="8">
                                <label>{tahti.perkara || "-"}</label>
                              </Col>
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  Pasal Pelanggaran
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>{tahti.pasal_pelanggaran || "-"}</label>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header className="custom-header">
                        Informasi Penahanan
                      </Accordion.Header>
                      <Accordion.Body className="py-2 px-0">
                        <Card className="card bg-white">
                          <Card.Body>
                            <Row className="g-2 mt-2">
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  No. LP, Tanggal
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>
                                  {tahti.no_laporan || "-"},{" "}
                                  {tahti.tanggal_laporan
                                    ? moment(tahti.tanggal_laporan).format(
                                        "DD/MM/YYYY"
                                      )
                                    : "-"}
                                </label>
                              </Col>
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  No. Sprin Han, Tanggal
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>
                                  {tahti.no_sprin_penahanan || "-"},{" "}
                                  {tahti.tanggal_sprin_penahanan
                                    ? moment(
                                        tahti.tanggal_sprin_penahanan
                                      ).format("DD/MM/YYYY")
                                    : "-"}
                                </label>
                              </Col>
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  Fase Penahanan Saat Ini
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>
                                  {tahti.nama_fase_penahanan === "Penyidik"
                                    ? "Fase 1 : Penyidik (20 Hari)"
                                    : tahti.nama_fase_penahanan === "Jaksa"
                                    ? "Fase 2 : Jaksa (40 Hari)"
                                    : tahti.nama_fase_penahanan === "PN1"
                                    ? "Fase 3 : PN1 (30 Hari)"
                                    : tahti.nama_fase_penahanan === "PN2"
                                    ? "Fase 4 : PN2 (30 Hari)"
                                    : "-"}
                                </label>
                              </Col>
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  Mulai Penahanan
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>
                                  {tahti.tanggal_penahanan
                                    ? moment(tahti.tanggal_penahanan).format(
                                        "DD/MM/YYYY"
                                      )
                                    : "-"}
                                </label>
                              </Col>

                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  Akhir Penahanan
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>
                                  {tahti.akhir_penahanan
                                    ? moment(tahti.akhir_penahanan).format(
                                        "DD/MM/YYYY"
                                      )
                                    : "-"}
                                </label>
                              </Col>
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  Lokasi Penahanan
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>{tahti.nama_rutan}</label>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Tab>
                <Tab eventKey="tab2" title="Versi Internal">
                  <div className="d-flex justify-content-end align-items-center">
                    <Button variant="danger" onClick={handlePrintInternal}>
                      Print to PDF
                    </Button>
                  </div>
                  <Accordion
                    defaultActiveKey={["0", "1", "2"]}
                    flush
                    alwaysOpen
                    id="printable-content-internal"
                  >
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <img
                        src={logo_kop}
                        alt="Logo Kop"
                        style={{ width: "150px" }}
                      />
                      <h3>KEPOLISIAN REPUBLIK INDONESIA</h3>
                      <h3>{tahti.nama_satker}</h3>
                    </div>
                    <hr />
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="custom-header">
                        Biodata Tahanan
                      </Accordion.Header>
                      <Accordion.Body className="py-2 px-0">
                        <Card className="card bg-white">
                          <Card.Body>
                            <Row>
                              <Col sm="8">
                                <Row className="g-2 mt-2">
                                  <Col sm="4">
                                    <label style={{ color: "blue" }}>NIK</label>
                                  </Col>
                                  <Col sm="8">
                                    <label>{tahti.no_tahanan}</label>
                                  </Col>
                                  <Col sm="4">
                                    <label style={{ color: "blue" }}>
                                      Nama Lengkap
                                    </label>
                                  </Col>
                                  <Col sm="8">
                                    <label>{tahti.nama_tersangka}</label>
                                  </Col>
                                  <Col sm="4">
                                    <label style={{ color: "blue" }}>
                                      Tempat, Tanggal lahir
                                    </label>
                                  </Col>
                                  <Col sm="8">
                                    <label>
                                      {tahti.tempat_lahir || "-"},{" "}
                                      {moment(tahti.tanggal_lahir).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </label>
                                  </Col>
                                  <Col sm="4">
                                    <label style={{ color: "blue" }}>
                                      Jenis Kelamin
                                    </label>
                                  </Col>
                                  <Col sm="8">
                                    <label>{tahti.jenis_kelamin}</label>
                                  </Col>
                                  <Col sm="4">
                                    <label style={{ color: "blue" }}>
                                      Pekerjaan
                                    </label>
                                  </Col>
                                  <Col sm="8">
                                    <label>{tahti.pekerjaan || "-"}</label>
                                  </Col>
                                  <Col sm="4">
                                    <label style={{ color: "blue" }}>
                                      Agama
                                    </label>
                                  </Col>
                                  <Col sm="8">
                                    <label>{tahti.agama || "-"}</label>
                                  </Col>
                                  <Col sm="4">
                                    <label style={{ color: "blue" }}>
                                      Kewarganegaraan
                                    </label>
                                  </Col>
                                  <Col sm="8">
                                    <label>{tahti.kebangsaan || "-"}</label>
                                  </Col>
                                  <Col sm="4">
                                    <label style={{ color: "blue" }}>
                                      Asal Negara
                                    </label>
                                  </Col>
                                  <Col sm="8">
                                    <label>{tahti.asal_negara || "-"}</label>
                                  </Col>
                                  <Col sm="4">
                                    <label style={{ color: "blue" }}>
                                      Alamat
                                    </label>
                                  </Col>
                                  <Col sm="8">
                                    <label>{tahti.alamat || "-"}</label>
                                  </Col>
                                  <Col sm="4">
                                    <label style={{ color: "blue" }}>
                                      Golongan Darah
                                    </label>
                                  </Col>
                                  <Col sm="8">
                                    <label>{tahti.golongan_darah || "-"}</label>
                                  </Col>
                                  <Col sm="4">
                                    <label style={{ color: "blue" }}>
                                      No. BPJS
                                    </label>
                                  </Col>
                                  <Col sm="8">
                                    <label>{tahti.no_dokkes || "-"}</label>
                                  </Col>
                                  <Col sm="4">
                                    <label style={{ color: "blue" }}>
                                      Satker BPJS
                                    </label>
                                  </Col>
                                  <Col sm="8">
                                    <label>-</label>
                                  </Col>
                                </Row>
                              </Col>
                              <Col
                                sm="4"
                                className="d-flex flex-column gap-3 mt-2"
                              >
                                <div className="d-flex flex-column gap-2">
                                  <span className="fs-6 fw-medium">
                                    Foto Tahanan
                                  </span>
                                  <Image
                                    src={imagePreviewTahanan}
                                    height="250px"
                                    width="100%"
                                  />
                                </div>
                                <div className="d-flex flex-column gap-2">
                                  <span className="fs-6 fw-medium">
                                    Foto Sidik Jari
                                  </span>
                                  <Image
                                    src={imagePreviewSidikJari}
                                    height="250px"
                                    width="100%"
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header className="custom-header">
                        Informasi Penyidikan
                      </Accordion.Header>
                      <Accordion.Body className="py-2 px-0">
                        <Card className="card bg-white">
                          <Card.Body>
                            <Row className="g-2">
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  Instansi Gakkum
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>{tahti.asal_tahanan}</label>
                              </Col>
                            </Row>
                            <Row className="mt-2">
                              <Col sm="12">
                                <Card className="card bg-white">
                                  <Card.Body>
                                    <Row className="g-1 mb-2 d-flex align-items-center">
                                      <Col sm="12" md="5">
                                        <h6>Daftar Penyidik</h6>
                                      </Col>
                                    </Row>
                                    <Table className="table dems-table">
                                      <thead>
                                        <tr>
                                          <th>No</th>
                                          <th>NRP</th>
                                          <th>Nama Penyidik</th>
                                          <th>Asal Satker</th>
                                          <th>Pangkat</th>
                                          <th>Jabatan</th>
                                          <th>No. HP</th>
                                          <th>&nbsp;</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {penyidikData.length
                                          ? penyidikData.map((data, index) => (
                                              <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{data.nrp}</td>
                                                <td>{data.nama_penyidik}</td>
                                                <td>{data.nama_satker}</td>
                                                <td>{data.pangkat}</td>
                                                <td>{data.jabatan}</td>
                                                <td>{data.no_hp}</td>
                                                <td>
                                                  <div className="d-flex align-items-center justify-content-end gap-2">
                                                    <Badge
                                                      onClick={() =>
                                                        handleDelete(index)
                                                      }
                                                      className="btn bg-danger"
                                                      as="button"
                                                      disabled={!isFormEditable}
                                                    >
                                                      <i className="ri-delete-bin-line"></i>
                                                    </Badge>
                                                  </div>
                                                </td>
                                              </tr>
                                            ))
                                          : !penyidikData.length && (
                                              <tr>
                                                <td
                                                  colSpan="4"
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  <span>
                                                    Tidak ada data penyidik!
                                                  </span>
                                                </td>
                                              </tr>
                                            )}
                                        {isAddingRow && (
                                          <tr>
                                            <td></td>
                                            <td>
                                              <Input
                                                type="text"
                                                name="nrp"
                                                value={penyidik.nrp}
                                                onChange={handleNewRowChange}
                                                placeholder="NRP"
                                              />
                                            </td>
                                            <td>
                                              <Input
                                                type="text"
                                                name="nama_penyidik"
                                                value={penyidik.nama_penyidik}
                                                onChange={handleNewRowChange}
                                                placeholder="Nama Penyidik"
                                              />
                                            </td>
                                            <td style={{ width: "200px" }}>
                                              <Select2
                                                name="nama_satker"
                                                defaultValue={"-"}
                                                placeholder={"Satker"}
                                                options={satkersFilter}
                                                value={penyidik.nama_satker}
                                                onChange={(selectedOption) => {
                                                  setPenyidik({
                                                    ...penyidik,
                                                    nama_satker:
                                                      selectedOption.value,
                                                  });
                                                }}
                                                required
                                              />
                                              {/* <Select
                                                default="Pilih Satker"
                                                name="nama_satker"
                                                value={penyidik.nama_satker}
                                                onChange={handleNewRowChange}
                                                options={satkersFilter}
                                              /> */}
                                            </td>
                                            <td>
                                              <Select
                                                default="Pilih Pangkat"
                                                name="pangkat"
                                                value={penyidik.pangkat}
                                                onChange={handleNewRowChange}
                                                options={pangkat}
                                              />
                                            </td>
                                            <td>
                                              <Input
                                                type="text"
                                                name="jabatan"
                                                value={penyidik.jabatan}
                                                onChange={handleNewRowChange}
                                                placeholder="Jabatan"
                                              />
                                            </td>
                                            <td>
                                              <Input
                                                type="text"
                                                name="no_hp"
                                                value={penyidik.no_hp}
                                                onChange={handleNewRowChange}
                                                placeholder="NO. HP"
                                              />
                                            </td>
                                            <td className="d-flex gap-1">
                                              <Button
                                                className="btn bg-danger"
                                                onClick={closeRow}
                                              >
                                                <i class="ri-close-large-line"></i>
                                              </Button>
                                              <Button
                                                className="btn bg-success"
                                                onClick={handleSaveRow}
                                              >
                                                <i class="ri-check-line"></i>
                                              </Button>
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </Table>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>
                            <Row className="g-2 mt-2">
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  Asal Satker
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>{tahti.nama_satker || "-"}</label>
                              </Col>
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  Tanggal Kejadian
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>
                                  {tahti.tanggal_kejadian
                                    ? moment(tahti.tanggal_kejadian).format(
                                        "DD/MM/YYYY"
                                      )
                                    : "-"}
                                </label>
                              </Col>
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  No. Kasus
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>{tahti.no_kasus || "-"}</label>
                              </Col>
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  Tindak Pidana
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>{tahti.tindak_pidana || "-"}</label>
                              </Col>
                              <Col sm="4">
                                <label style={{ color: "blue" }}>Perkara</label>
                              </Col>
                              <Col sm="8">
                                <label>{tahti.perkara || "-"}</label>
                              </Col>
                              <Col sm="4">
                                <label style={{ color: "blue" }}>TKP</label>
                              </Col>
                              <Col sm="8">
                                <label>{tahti.alamat_tkp || "-"}</label>
                              </Col>
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  Pasal Pelanggaran
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>{tahti.pasal_pelanggaran || "-"}</label>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header className="custom-header">
                        Informasi Penahanan
                      </Accordion.Header>
                      <Accordion.Body className="py-2 px-0">
                        <Card className="card bg-white">
                          <Card.Body>
                            <Row className="g-2 mt-2">
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  No. LP, Tanggal
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>
                                  {tahti.no_laporan || "-"},{" "}
                                  {moment(tahti.tanggal_laporan).format(
                                    "DD/MM/YYYY"
                                  )}
                                </label>
                              </Col>
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  No. Sprin Han, Tanggal
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>
                                  {tahti.no_sprin_penahanan || "-"},{" "}
                                  {moment(tahti.tanggal_sprin_penahanan).format(
                                    "DD/MM/YYYY"
                                  )}
                                </label>
                              </Col>
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  Fase Penahanan Saat Ini
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>
                                  {tahti.nama_fase_penahanan === "Penyidik"
                                    ? "Fase 1 : Penyidik (20 Hari)"
                                    : tahti.nama_fase_penahanan === "Jaksa"
                                    ? "Fase 2 : Jaksa (40 Hari)"
                                    : tahti.nama_fase_penahanan === "PN1"
                                    ? "Fase 3 : PN1 (30 Hari)"
                                    : tahti.nama_fase_penahanan === "PN2"
                                    ? "Fase 4 : PN2 (30 Hari)"
                                    : "-"}
                                </label>
                              </Col>
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  Mulai Penahanan
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>
                                  {moment(tahti.tanggal_penahanan).format(
                                    "DD/MM/YYYY"
                                  )}
                                </label>
                              </Col>

                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  Akhir Penahanan
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>
                                  {moment(tahti.akhir_penahanan).format(
                                    "DD/MM/YYYY"
                                  )}
                                </label>
                              </Col>
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  Lokasi Penahanan
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>{tahti.nama_rutan}</label>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Tab>
                <Tab eventKey="tab3" title="Versi Eksternal">
                  <div className="d-flex justify-content-end align-items-center">
                    <Button variant="danger" onClick={handlePrintEksternal}>
                      Print to PDF
                    </Button>
                  </div>
                  <Accordion
                    defaultActiveKey={["0", "1", "2"]}
                    flush
                    alwaysOpen
                    id="printable-content-eksternal"
                  >
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <img
                        src={logo_kop}
                        alt="Logo Kop"
                        style={{ width: "150px" }}
                      />
                      <h3>KEPOLISIAN REPUBLIK INDONESIA</h3>
                      <h3>{tahti.nama_satker}</h3>
                    </div>
                    <hr />
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="custom-header">
                        Biodata Tahanan
                      </Accordion.Header>
                      <Accordion.Body className="py-2 px-0">
                        <Card className="card bg-white">
                          <Card.Body>
                            <Row>
                              <Col sm="8">
                                <Row className="g-2 mt-2">
                                  <Col sm="4">
                                    <label style={{ color: "blue" }}>
                                      Nama Lengkap
                                    </label>
                                  </Col>
                                  <Col sm="8">
                                    <label>{tahti.nama_tersangka}</label>
                                  </Col>
                                  <Col sm="4">
                                    <label style={{ color: "blue" }}>
                                      Tempat, Tanggal lahir
                                    </label>
                                  </Col>
                                  <Col sm="8">
                                    <label>
                                      {tahti.tempat_lahir || "-"},{" "}
                                      {tahti.tanggal_lahir
                                        ? moment(tahti.tanggal_lahir).format(
                                            "DD/MM/YYYY"
                                          )
                                        : "-"}
                                    </label>
                                  </Col>
                                  <Col sm="4">
                                    <label style={{ color: "blue" }}>
                                      Jenis Kelamin
                                    </label>
                                  </Col>
                                  <Col sm="8">
                                    <label>{tahti.jenis_kelamin}</label>
                                  </Col>
                                  <Col sm="4">
                                    <label style={{ color: "blue" }}>
                                      Pekerjaan
                                    </label>
                                  </Col>
                                  <Col sm="8">
                                    <label>{tahti.pekerjaan || "-"}</label>
                                  </Col>
                                </Row>
                              </Col>
                              <Col
                                sm="4"
                                className="d-flex flex-column gap-3 mt-2"
                              >
                                <div className="d-flex flex-column gap-2">
                                  <span className="fs-6 fw-medium">
                                    Foto Tahanan
                                  </span>
                                  <Image
                                    src={imagePreviewTahanan}
                                    height="250px"
                                    width="100%"
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header className="custom-header">
                        Informasi Penyidikan
                      </Accordion.Header>
                      <Accordion.Body className="py-2 px-0">
                        <Card className="card bg-white">
                          <Card.Body>
                            <Row className="g-2 mt-2">
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  Asal Satker
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>{tahti.nama_satker || "-"}</label>
                              </Col>
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  Tindak Pidana
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>{tahti.tindak_pidana || "-"}</label>
                              </Col>
                              <Col sm="4">
                                <label style={{ color: "blue" }}>Perkara</label>
                              </Col>
                              <Col sm="8">
                                <label>{tahti.perkara || "-"}</label>
                              </Col>
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  Pasal Pelanggaran
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>{tahti.pasal_pelanggaran || "-"}</label>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header className="custom-header">
                        Informasi Penahanan
                      </Accordion.Header>
                      <Accordion.Body className="py-2 px-0">
                        <Card className="card bg-white">
                          <Card.Body>
                            <Row className="g-2 mt-2">
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  Fase Penahanan Saat Ini
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>
                                  {tahti.nama_fase_penahanan === "Penyidik"
                                    ? "Fase 1 : Penyidik (20 Hari)"
                                    : tahti.nama_fase_penahanan === "Jaksa"
                                    ? "Fase 2 : Jaksa (40 Hari)"
                                    : tahti.nama_fase_penahanan === "PN1"
                                    ? "Fase 3 : PN1 (30 Hari)"
                                    : tahti.nama_fase_penahanan === "PN2"
                                    ? "Fase 4 : PN2 (30 Hari)"
                                    : "-"}
                                </label>
                              </Col>
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  Mulai Penahanan
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>
                                  {tahti.tanggal_penahanan
                                    ? moment(tahti.tanggal_penahanan).format(
                                        "DD/MM/YYYY"
                                      )
                                    : "-"}
                                </label>
                              </Col>

                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  Akhir Penahanan
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>
                                  {tahti.akhir_penahanan
                                    ? moment(tahti.akhir_penahanan).format(
                                        "DD/MM/YYYY"
                                      )
                                    : "-"}
                                </label>
                              </Col>
                              <Col sm="4">
                                <label style={{ color: "blue" }}>
                                  Lokasi Penahanan
                                </label>
                              </Col>
                              <Col sm="8">
                                <label>{tahti.nama_rutan}</label>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
          <div className="d-flex justify-content-end align-items-center">
            <Button variant="warning" onClick={handleBack}>
              BATAL
            </Button>
          </div>
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
}

export default FormTahti;
