import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Spinner,
  Badge,
  Form,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  Input,
} from "../../../components/Components";

function FormMenu() {
  const navigate = useNavigate();
  const [listFungsi, setListFungsi] = useState([
                {name: 'Dashboard Tahanan - View'},
                {name: 'Dashboard Barang Bukti - View'},
                {name: 'Dashboard Eksternal - View'},
                {name: 'Dashboard Berkas - View'},
                {name: 'Dashboard APM - View'},
  ])
  const [selected, setSelected] = useState(
    {
        role: '',
        menu: '',
        fungsi: ''
    }
  )
  
  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between m-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/pengaturan/role/list">Pengaturan</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/pengaturan/role/list">
                    List Role Akses
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active fw-bold"
                  aria-current="page"
                >
                  Konfigurasi Role Akses
                </li>
              </ol>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="bg-white">
        <Card.Body>
          <Row className="g-2">
            {/* <Col sm="12">
              {mutateSubmit.isError && (
                <Alert variant="danger">
                  <ul className="m-0">
                    {mutateSubmit?.error?.response?.data?.detail?.map(
                      (errEdit, idx) => (
                        <li key={idx}>
                          <b>{errEdit.loc[1]} : </b>
                          {errEdit.msg}
                        </li>
                      )
                    ) ?? (
                      <li>
                        <b>Error : </b>
                        {mutateSubmit?.error?.response?.data?.message ??
                          "Bermasalah"}
                      </li>
                    )}
                  </ul>
                </Alert>
              )}
              {mutateSubmit.isSuccess && (
                <Alert variant="success">
                  {mutateSubmit.data.data.message}
                </Alert>
              )}
            </Col> */}
            {
              false && (
                <Col>
                  <div className="w-100 d-flex justify-content-center">
                    <Spinner variant="primary" size="sm" />
                  </div>
                </Col>
              )
            }
            {
              false && (
                <Col>
                  Error
                </Col>
              )
            }
            {
            //   !isDataListError && !isDataListFetching && (
            true && (
                  <Col sm="12">
                    <Row className="g-2">
                        <Col sm="3">
                          <Card className="bg-white w-100">
                              <Card.Header className="bg-white">
                                  <Card.Title className="m-0 p-0">Role</Card.Title>
                              </Card.Header>
                              <Card.Body className="p-2">
                                <div className="d-flex flex-column gap-2">
                                    <div className="d-flex align-items-center justify-content-between gap-1">
                                        <Input 
                                            placeholder="Tambah Baru"
                                            style={{height: 30}}
                                            className="w-100"
                                        ></Input>
                                        <Badge 
                                        style={{height: 27}}
                                        className="btn bg-secondary" 
                                        as="button"
                                        >
                                            <i className="ri-add-line"></i>
                                        </Badge>
                                    </div>
                                    {
                                        [
                                            {name: 'Eksekutif TAHTI'},
                                            {name: 'Petugas TAHTI'},
                                            {name: 'Admin TAHTI'},
                                            {name: 'Umum TAHTI'},
                                            {name: 'Super Admin'},
                                        ].map((k, idx) => (
                                            <Card 
                                              className={
                                                `p-1
                                                ${k.name === selected.role ? 'card-success text-light': ''}
                                                `
                                              } 
                                              key={idx}
                                            >
                                                <div 
                                                  className="d-flex align-items-center justify-content-between"
                                                  style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                    setSelected({...selected, role: k.name, menu: '', fungsi: ''})
                                                  }}
                                                >
                                                    <Card.Text className="p-0 m-0">{k?.name ?? '-'}</Card.Text>
                                                    {
                                                      k.name === selected.role &&
                                                      (
                                                        <i className="ri-arrow-right-line"></i>
                                                      )
                                                    }
                                                </div>
                                            </Card>
                                          ))
                                    }

                                  </div>
                              </Card.Body>
                          </Card>
                        </Col>
                        <Col sm="4">
                          <Card className="bg-white w-100">
                              <Card.Header className="bg-white">
                                  <Card.Title className="m-0 p-0">Akses Menu</Card.Title>
                              </Card.Header>
                              <Card.Body className="p-2">
                                <div className="d-flex flex-column gap-2">
                                    <div className="d-flex align-items-center justify-content-between gap-1">
                                        <Input 
                                            placeholder="Tambah Baru"
                                            style={{height: 30}}
                                            width="w-100"
                                        ></Input>
                                        <Badge 
                                        style={{height: 27}}
                                        className="btn bg-secondary" 
                                        as="button"
                                        >
                                            <i className="ri-add-line"></i>
                                        </Badge>
                                    </div>
                                    {
                                        [
                                            {name: 'Dashboard'},
                                            {name: 'Laporan'},
                                            {name: 'Tahanan'},
                                            {name: 'Barang Bukti'},
                                            {name: 'Berkas'},
                                            {name: 'Pencarian Barang'},
                                            {name: 'Berkas Dibagikan'},
                                            {name: 'Pengaturan Konfigurasi'},
                                            
                                        ].map((k, idx) => (
                                            <Card 
                                              className={
                                                `p-1
                                                ${k.name === selected.menu ? 'card-success text-light': ''}
                                                `
                                              } 
                                              key={idx}
                                            >
                                                <div 
                                                  className="d-flex align-items-center justify-content-between"
                                                  style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                    
                                                    
                                                    setSelected({...selected,  menu: k.name,})
                                                  }}
                                                >
                                                    <Card.Text className="p-0 m-0">{k?.name ?? '-'}</Card.Text>
                                                    {
                                                      k.name === selected.menu &&
                                                      (
                                                        <i className="ri-arrow-right-line"></i>
                                                      )
                                                    }
                                                </div>
                                            </Card>
                                          ))
                                    }

                                  </div>
                              </Card.Body>
                          </Card>
                        </Col>
                        <Col sm="5">
                          <Card className="bg-white w-100">
                              <Card.Header className="bg-white">
                                  <Card.Title className="m-0 p-0">Akses Fungsi</Card.Title>
                              </Card.Header>
                              <Card.Body className="p-2">
                                <div className="d-flex flex-column gap-2">
                                    <div className="d-flex align-items-center justify-content-between gap-1">
                                        <Input 
                                            placeholder="Tambah Baru"
                                            style={{height: 30}}
                                            width="w-100"
                                        ></Input>
                                        <Badge 
                                        style={{height: 27}}
                                        className="btn bg-secondary" 
                                        as="button"
                                        >
                                            <i className="ri-add-line"></i>
                                        </Badge>
                                    </div>
                                    {
                                        listFungsi.map((k, idx) => (
                                            <Card 
                                              className={
                                                `p-1
                                                ${false ? 'card-success text-light': ''}
                                                `
                                              } 
                                              key={idx}
                                            >
                                                <div 
                                                  className="d-flex align-items-center justify-content-between"
                                                  style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                  }}
                                                >
                                                    <Card.Text className="p-0 m-0">{k?.name ?? '-'}</Card.Text>
                                                    <Form.Check
                                                        type="checkbox"
                                                        defaultChecked
                                                    />
                                                </div>
                                            </Card>
                                          ))
                                    }

                                  </div>
                              </Card.Body>
                          </Card>
                        </Col>
                    </Row>
                  </Col>
                )
            }
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
            <div className="d-flex justify-content-end align-items-center gap-3">
              <Button
                variant="warning"
                as={Link}
              >
                BATAL
              </Button>
              <Button variant="primary">
                APPLY / SIMPAN
              </Button>
            </div>
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
}

export default FormMenu;
 