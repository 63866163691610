import PerfectScrollbar from "react-perfect-scrollbar"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Link, NavLink } from "react-router-dom"
import { labelMenuGroup } from "../data/Menu"
import State from "../../libraries/State"
import { isNotEmptyObject } from "../../utils"
import { useGetSidebarMenuByRole } from "../../utils/apis/query"

export const Sidebar = () => {
    const scrollBarRef = useRef(null);
    const state = State.getState()
    const profile = useMemo(() => state?.getItem('profileData', true) ?? {}, [state])
    const [isReady, setIsReady] = useState(false)
    const [menuTransformed, setMenuTransformed] = useState([])
    
    const {
        data: sidebarMenuByRole
    } = useGetSidebarMenuByRole()

    useEffect(() => {
        let menu = sidebarMenuByRole?.menu ?? null
        if (!!menu && isNotEmptyObject(profile)) {
            // GPT SOLUTION
            const transformData = (data) => {
                return {
                    menu: data?.menu?.map((group) => ({
                      group: group.group,
                      menu: group.menu.length
                        ? group.menu.map((menuItem) => ({
                            menu_parent: menuItem.nama_menu || null,
                            icon: menuItem.icon || null,
                            nomor_menu: menuItem.nomor_menu || 1,
                            path: menuItem.jenis === "Non Label" ? menuItem.path : null,
                            menu_action: menuItem.jenis === "Label" ? null : menuItem.menu_action,
                            menu_child: menuItem.jenis === "Non Label" ? [] : menuItem.menu_child.map((child) => ({
                              nama_child: child.nama_menu,
                              nomor_menu: child.nomor_menu || 1,
                              path: child.path || null,
                              menu_action: child.jenis === "Non Label" ? child.menu_action : null
                            }))
                          }))
                        : group.menu_child.length // Jika tidak ada `menu`, periksa `menu_child`
                        ? [
                            {
                              menu_parent: group.menu_parent || null,
                              nomor_menu: group.nomor_menu || 1,
                              icon: group.icon || null,
                              path: null, // Label tidak memiliki path
                              menu_action: null, // Label tidak memiliki menu_action
                              menu_child: group.menu_child.map((child) => ({
                                nama_child: child.nama_menu,
                                nomor_menu: child.nomor_menu || 1,
                                path: child.path,
                                menu_action: child.menu_action // Jika Non Label, ambil `menu_action`
                              }))
                            }
                          ]
                        : []
                    })) ?? []
                };
              };

            // Fungsi untuk menggabungkan grup yang memiliki nama sama
            const mergeGroups = (data) => {
                const result = [];
                data.menu.forEach((item) => {
                    const existingGroup = result.find((group) => group.group === item.group);
                
                    if (existingGroup) {
                        // Jika grup sudah ada, gabungkan menu-nya
                        existingGroup.menu = [...existingGroup.menu, ...item.menu];
                    } else {
                        // Jika belum ada, tambahkan grup baru
                        result.push(item);
                    }
                });
            
                return result;
            };

            // Lakukan transformasi dan gabungkan grup
            const transformedData = transformData(sidebarMenuByRole);
            const mergedData = mergeGroups(transformedData);
            setMenuTransformed((prev) => ([...mergedData]))
            
            state.setItem("menuRole", mergedData, true)
            
            setIsReady(true)
        }
    }, [profile, sidebarMenuByRole])

    const handleUpdateSize = () => {
      if (scrollBarRef.current) {
        scrollBarRef.current.updateScroll();
      }
    };
  
    return (
      <div className="sidebar">
        <div className="sidebar-header">
          <Link className="sidebar-logo">SIMPATI</Link>
          <small className="fs-sm text-secondary">
            SISTEM MANAJEMEN TERPUSAT<br />TAHANAN DAN BARANG BUKTI
          </small>
        </div>
        <PerfectScrollbar
          className="sidebar-body"
          ref={scrollBarRef}
        >
         {
            isReady &&  <SidebarMenu profile={profile} menuTransformed={menuTransformed} onUpdateSize={handleUpdateSize} />
         }
        </PerfectScrollbar>
      </div>
    );
  };

const SidebarMenu = ({profile, menuTransformed}) => {
    const state = State.getState()
    const toggleMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest(".nav-group");
        parent.classList.toggle("show");
    };
    
    const toggleSubMenu = (e) => {
        e.preventDefault();
    
        let parent = e.target.closest(".nav-item");
        let node = parent.parentNode.firstChild;
    
        while (node) {
          if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
            node.classList.remove("show");
          node = node.nextElementSibling || node.nextSibling;
        }
    
        parent.classList.toggle("show");
    };
    
    return (
        <React.Fragment> 
            {
            menuTransformed.map((labelMenu, idxLabel) => (
                <div className="nav-group show" key={idxLabel}>
                    <div className="nav-label" onClick={toggleMenu}>
                        {labelMenu.group}
                    </div>
                    <ul className="nav nav-sidebar">
                        {
                            labelMenu.menu.sort((a, b) => a.nomor_menu - b.nomor_menu).map((menu, key) => (
                                <li key={key} className="nav-item">
                                    {
                                        !menu.menu_child.length ?
                                        (
                                            <NavLink to={menu.path} className="nav-link">
                                                <i className={menu.icon}></i> <span>{menu.menu_parent}</span>
                                            </NavLink>
                                        ) :
                                        (
                                            <div onClick={toggleSubMenu} className="nav-link has-sub">
                                                <i className={menu.icon}></i> <span>{menu.menu_parent}</span>
                                            </div>
                                        )
                                    }
                                    {
                                        menu?.menu_child && 
                                        (
                                            <nav className="nav nav-sub">
                                                {
                                                    menu.menu_child.sort((a, b) => a.nomor_menu - b.nomor_menu).map((sm, keySm) => (
                                                        <NavLink to={sm.path} className="nav-sub-link" key={keySm}>
                                                            {sm.nama_child}
                                                        </NavLink>
                                                    ))
                                                }
                                            </nav>
                                        )
                                    }
                                </li>
                            ))
                        }
                    </ul>
                </div>
            ))
            }
        </React.Fragment>
    )
}