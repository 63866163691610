import React, { useCallback, useEffect, useState } from 'react'
import { Badge, Button, Card, Col, Accordion, Row, Spinner, Table, Form, Modal, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { DatePicker, Input, Select } from '../../../../components/Components'
import { useGetDetailTahti, useGetTahtiList, usePostPenangguhan } from '../../../../utils/apis/query'
import { debounce } from 'lodash'
import moment from 'moment'


function FormMenu() {
  const [modalShow, setModalShow] = useState(true)
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [payloadCariTahanann, setPayloadCariTahanan] = useState({nama_tersangka: ''})
  const [namaTahanan, setNamaTahanan] = useState('')
  const [idTahanan, setIdTahanan] = useState('')
  const [showForm, setShowForm] = useState(false)

  const [penyidik, setPenyidik] = useState([])
  const [payloadDate, setPayloadDate] = useState(
    {
      tanggal_sprin_penangguhan: '',
      tanggal_permin_penangguhan: '',
      awal_penangguhan: '',
      akhir_penangguhan: '',
    }
  )
  const [payloadForm, setPayloadForm] = useState(
    {
      arsip: [],
      lampiran: [],
      nama_tahanan: '',
      no_tahanan: '',
      no_sprin_penangguhan: '',
      tanggal_sprin_penangguhan: '',
      no_permin_penangguhan: '',
      tanggal_permin_penangguhan: '',
      awal_penangguhan: '',
      akhir_penangguhan: '',
      kondisi: '',
      alasan: '',
      keterangan: '',
      penyidik_ids: []
    }
  )
  const [payloadFormDataTahanan, setPayloadFormDataTahanan] = useState(
    {
      no_tahanan: '',
      nama: '',
      tempat_lahir: '',
      tanggal_lahir: '',
      umur: '',
      kelamin: '',
      kewarganegaraan: '',
      asal_negara: '',
      agama: '',
      pekerjaan: '',
      alamat: '',
      foto_tahanan: '',
      foto_sidikjari: '',
      perkara: '',
      penempatan_rutan: '',
      lokasi_rutan: ''
    }
  )

  const 
  {
    data: dataTahanan,
    isFetching: tahananFetching,
    isError: tahananError
  } =useGetDetailTahti(idTahanan, {enabled: !!idTahanan})

  const 
  {
    data: dataTahananList,
    isFetching: modalFetching,
  } = useGetTahtiList(payloadCariTahanann, {enabled: !!namaTahanan})

  const handleDebounce = useCallback(
    debounce((newPayload) => {
      setPayloadCariTahanan(newPayload);
    }, 500),
    []
  );

  const handleChange = (e) => {
    setIsFormDirty(true)
    const { name, value } = e.target
    setPayloadForm({...payloadForm, [name]: value})
  }

  const handleCheckbox = (data, isChecked) => {
    if (isChecked) {
      setPayloadForm({...payloadForm, penyidik_ids: [...payloadForm.penyidik_ids, data.id_penyidik]})
    } else {
      setPayloadForm({...payloadForm, penyidik_ids: payloadForm.penyidik_ids.filter(selectedId => selectedId !== data.id_penyidik)})
    }
  }
  const onSuccess = (response) => {
    
  }

  const mutateSubmit = usePostPenangguhan(onSuccess)

  const handleSubmit = () => {
    let formData = new FormData()

    // Object.keys(payloadForm).forEach(key => {
    //   formData.append(key, payloadForm[key]);
    // })

    Object.keys(payloadForm).forEach(key => {
      if (typeof payloadForm[key] === 'string' || typeof payloadForm[key] === 'number') {
        formData.append(key, payloadForm[key]);
      }
    })
    payloadForm.arsip.forEach((file, idx) => {
      formData.append(`arsip`, file);
    })
    payloadForm.lampiran.forEach((file, idx) => {
      formData.append(`lampiran`, file);
    })
    payloadForm.penyidik_ids.forEach((ids, idx) => {
      formData.append(`penyidik_ids`, ids);
    })

    mutateSubmit.mutate(formData)
  }

  useEffect(() => {
    if (dataTahanan) {
      const _ = dataTahanan
      setPayloadFormDataTahanan(
        {
          no_tahanan: _.no_tahanan,
          nama: _.nama_tersangka,
          tempat_lahir: _.tempat_lahir,
          tanggal_lahir: _.tanggal_lahir,
          umur: _.umur,
          kelamin: _.jenis_kelamin,
          kewarganegaraan: _.kebangsaan,
          asal_negara: _.asal_negara,
          agama: _.agama,
          perkara: _.perkara,
          pekerjaan: _.pekerjaan,
          alamat: _.alamat,
          foto_tahanan: _.rumus_wajah,
          foto_sidikjari: _.rumus_sidikjari,
          penempatan_rutan: _.nama_rutan,
          lokasi_rutan: _.alamat_rutan
        }
      )
      setPayloadForm(
        {
          ...payloadForm,
          kondisi: _.ko,
          no_tahanan: _.no_tahanan,
          nama_tahanan: _.nama_tersangka
        }
      )
      setPenyidik(_?.penyidik ?? [])
    }
  }, [dataTahanan])

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        const message = 'You have unsaved changes. Are you sure you want to leave?';
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isFormDirty]);

  useEffect(() => {
    handleDebounce({ ...payloadCariTahanann, nama_tersangka:  namaTahanan});
  }, [namaTahanan, handleDebounce, payloadCariTahanann]);
  return (
    <React.Fragment>
        <Card className='bg-white mb-2'>
            <Card.Body className='p-2'>
              <div className="d-flex align-items-center justify-content-between m-0">
                  <div>
                      <ol className="breadcrumb fs-sm mb-0">
                          <li className="breadcrumb-item">
                          <Link to="/tahanan">Tahanan</Link>
                          </li>
                          <li className="breadcrumb-item">
                          <Link to="/tahanan/penangguhan/list">List Penangguhan Tahanan</Link>
                          </li>
                          <li className="breadcrumb-item active fw-bold" aria-current="page">
                          Form Penangguhan Tahanan
                          </li>
                      </ol>
                  </div>
              </div>
            </Card.Body>
        </Card>
        <Card className='bg-white'>
          <Card.Body>
            <Row className="g-2">
              <Col sm="12">
                {
                  (tahananFetching && !tahananError) && 
                  (
                    <div className="d-flex justify-content-center">
                      <Spinner variant='primary' size='small' />
                    </div>
                  )
                }
                {
                  (!tahananFetching && tahananError) && 
                  (
                    <div className="d-flex justify-content-center">
                      <span className="text-danger text-center">
                        <b>Oops Error</b> please refresh the page / re-login
                      </span>
                    </div>
                  )
                }
                {
                  mutateSubmit.isError && (
                    <Alert variant='danger'>
                      <ul className='m-0'>
                        {
                          mutateSubmit?.error?.response?.data?.detail
                          ?.map((errEdit) => (
                            <li><b>{errEdit.loc[1]} : </b>{errEdit.msg}</li>
                          )) ?? (
                            <li><b>Error : </b>{ mutateSubmit?.error?.response?.data?.message ?? 'Bermasalah' }</li>
                          )
                        }
                      </ul>
                    </Alert>
                  )
                }
                {
                  mutateSubmit.isSuccess && (
                    <Alert variant='success'>
                      {mutateSubmit.data.data.message}
                    </Alert>
                  )
                }
              </Col>
              <Col sm="12">
                <Accordion defaultActiveKey="0" flush alwaysOpen>
                    {
                      (showForm && !tahananFetching && !tahananError) &&
                      (
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            Informasi / Profil Tahanan
                          </Accordion.Header>
                          <Accordion.Body className="py-2 px-0">
                            <Card className="card bg-white">
                              <Card.Body>
                                <Row className="g-2">
                                  <Col sm="4">
                                    <Input
                                      label="No. Tahanan"
                                      name="no_tahanan"
                                      defaultValue={payloadFormDataTahanan.no_tahanan}
                                      disabled
                                    />
                                  </Col>
                                  <Col sm="4">
                                    <Input
                                      label="Nama"
                                      name="nama"
                                      defaultValue={payloadFormDataTahanan.nama}
                                      disabled
                                    />
                                  </Col>
                                  <Col sm="2">
                                    <Input
                                      label="Tempat Lahir"
                                      defaultValue={payloadFormDataTahanan.tempat_lahir}
                                      name="tempat_lahir"
                                      disabled
                                    />
                                  </Col>
                                  <Col sm="2">
                                    <Input
                                      label="Tanggal Lahir"
                                      name="tanggal_lahir"
                                      defaultValue={payloadFormDataTahanan.tanggal_lahir}
                                      disabled
                                    />
                                  </Col>
                                  <Col sm="2">
                                    <Input
                                      type="number"
                                      label="Umur Saat Ini"
                                      name="umur"
                                      defaultValue={payloadFormDataTahanan.umur}
                                      disabled
                                    />
                                  </Col>
                                  <Col sm="2">
                                    <Input
                                      label="Kelamin"
                                      name="jenis_kelamin"
                                      disabled
                                      defaultValue={payloadFormDataTahanan.kelamin}
                                    />
                                  </Col>
                                  <Col sm="2">
                                    <Input
                                      label="Kewarganegaraan"
                                      name="kebangsaan"
                                      defaultValue={payloadFormDataTahanan.kewarganegaraan}
                                      disabled
                                    />
                                  </Col>
                                  <Col sm="2">
                                    <Input
                                      label="Asal Negara"
                                      name="asal_negara"
                                      defaultValue={payloadFormDataTahanan.asal_negara}
                                      disabled
                                    />
                                  </Col>
                                  <Col sm="2">
                                    <Input
                                      label="Agama"
                                      name="agama"
                                      defaultValue={payloadFormDataTahanan.agama}
                                      disabled
                                    />
                                  </Col>
                                  <Col sm="2">
                                    <Input
                                      label="Pekerjaan"
                                      name="pekerjaan"
                                      defaultValue={payloadFormDataTahanan.pekerjaan}
                                      type="text"
                                      disabled
                                    />
                                  </Col>
                                  <Col sm="6">
                                    <Input
                                      as="textarea"
                                      label="Alamat"
                                      defaultValue={payloadFormDataTahanan.alamat}
                                      name="alamat"
                                      disabled
                                    />
                                  </Col>
                                  <Col sm="3">
                                    <Card className="card bg-white">
                                      <Card.Body>
                                      <div className="d-flex flex-column gap-2">
                                        <span className="fs-6 fw-medium">Foto Tahanan</span>
                                        <img 
                                          src={payloadFormDataTahanan.foto_tahanan} 
                                          style={{height: 165, minHeight: 165, maxHeight: 165, objectFit: 'cover', cursor: 'pointer'}}
                                          alt="" 
                                          onError={(e) => {
                                            e.target.src = '/noimage.jpg'
                                          }}
                                        />
                                      </div>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                  <Col sm="3">
                                  <Card className="card bg-white">
                                      <Card.Body>
                                      <div className="d-flex flex-column gap-2">
                                        <span className="fs-6 fw-medium">Foto Sidik Jari</span>
                                        <img 
                                          src={payloadFormDataTahanan.foto_sidikjari} 
                                          style={{height: 165, minHeight: 165, maxHeight: 165, objectFit: 'cover', cursor: 'pointer'}}
                                          alt="" 
                                          onError={(e) => {
                                            e.target.src = '/noimage.jpg'
                                          }}
                                        />
                                      </div>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                    }
                    {
                      (showForm && !tahananFetching && !tahananError) &&
                      (
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>Informasi Penahanan</Accordion.Header>
                          <Accordion.Body className="py-2 px-0">
                            <Card className="card bg-white">
                              <Card.Body>
                                <Row className="g-2 mt-2">
                                  <Col sm="4">
                                    <Input
                                      label="Perkara"
                                      name="perkara"
                                      defaultValue={payloadFormDataTahanan.perkara}
                                      required
                                      disabled
                                    />
                                  </Col>
                                  <Col sm="4">
                                    <Input
                                      label="Penempatan Rutan"
                                      defaultValue={payloadFormDataTahanan.penempatan_rutan}
                                      name="nama_rutan"
                                      required
                                      disabled
                                    />
                                  </Col>
                                  <Col sm="4">
                                    <Input
                                      as="textarea"
                                      label="Lokasi Rutan"
                                      defaultValue={payloadFormDataTahanan.lokasi_rutan}
                                      name="alamat_rutan"
                                      required
                                      disabled
                                    />
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                    }
                    {
                      (showForm && !tahananFetching && !tahananError) &&
                      (
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>Informasi Pembuat Pinjaman Bon Penahanan</Accordion.Header>
                          <Accordion.Body className='py-2 px-0'>
                            <Card className='card bg-white'>
                              <Card.Body>
                                <Row className='g-2'>
                                    <Col sm="12">
                                      <Card className="card bg-white">
                                        <Card.Body>
                                          <Row className='g-1 mb-2 d-flex align-items-center'>
                                              <Col sm="12" md="5">
                                                <h6>Daftar Penyidik</h6>
                                              </Col>
                                              <Col sm="12" md="7">
                                                  <Link className="d-flex align-items-center justify-content-end">
                                                    
                                                  </Link>
                                              </Col>
                                          </Row>
                                            <Table className="table dems-table">
                                              <thead>
                                              <tr>    
                                                  <th>No</th>
                                                  <th>NRP</th>
                                                  <th>Nama Penyidik</th>
                                                  <th>Asal Satker</th>
                                                  <th>Pangkat</th>
                                                  <th>Jabatan</th>
                                                  <th>Pilih</th>
                                              </tr>
                                              </thead>
                                              <tbody>
                                              {
                                                penyidik.map((data, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {index + 1}
                                                        </td>
                                                        <td>
                                                            {data.nrp}
                                                        </td>
                                                        <td>
                                                            {data.nama_penyidik}
                                                        </td>
                                                        <td>
                                                            {data.nama_satker}
                                                        </td>
                                                        <td>
                                                            {data.pangkat}
                                                        </td>
                                                        <td>
                                                            Penyidik
                                                        </td>
                                                        <td>
                                                            <Form.Check
                                                              type='checkbox'
                                                              onChange={(e) => {
                                                                handleCheckbox(data, e.target.checked)
                                                              }}  
                                                             />
                                                        </td>
                                                    </tr>
                                                ))
                                              }
                                              </tbody>
                                          </Table>
                                        </Card.Body>
                                      </Card>
                                    </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                    }
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Informasi Dokumen Penahanan</Accordion.Header>
                      <Accordion.Body className='py-2 px-0'>
                        <Card className='card bg-white'>
                          <Card.Body>
                            <Row className='g-2'>
                                <Col sm="12">
                                    <Row className='g-2 mt-1'>
                                      <Col sm="5">
                                        <Input 
                                            label="Nomor Sprin Penangguhan Tahanan"
                                            name="no_sprin_penangguhan"
                                            required
                                            onChange={handleChange}
                                            defaultValue={payloadForm.no_sprin_penangguhan}
                                        />
                                      </Col>
                                      <Col sm="3">
                                        <DatePicker 
                                            label="Tanggal"
                                            name="tanggal_sprin_penangguhan"
                                            timeFormat="HH:mm:ss"
                                            dateFormat="dd/MM/yyyy"
                                            onChange={(date) => {
                                              setPayloadDate({...payloadDate, tanggal_sprin_penangguhan: date})
                                              setPayloadForm({...payloadForm, tanggal_sprin_penangguhan: moment(date).format('YYYY-MM-DD')})
                                            }}
                                            selected={payloadDate.tanggal_sprin_penangguhan}
                                            required
                                        />
                                      </Col>
                                      <Col sm="4">
                                        <Input 
                                            label="Unggah Arsip"
                                            type="file"
                                            onChange={(e) => {
                                              const files = e.target.files[0]
                                              setPayloadForm({...payloadForm, arsip: [...payloadForm.arsip, files]})
                                            }}
                                            required
                                          />
                                      </Col>
                                      <Col sm="5">
                                        <Input 
                                            label="Nomor Permin Penangguhan Tahanan"
                                            name="no_permin_penangguhan"
                                            required
                                            onChange={handleChange}
                                            defaultValue={payloadForm.no_permin_penangguhan}
                                        />
                                      </Col>
                                      <Col sm="3">
                                        <DatePicker 
                                            label="Tanggal"
                                            name="tanggal_permin_penangguhan"
                                            timeFormat="HH:mm:ss"
                                            dateFormat="dd/MM/yyyy"
                                            onChange={(date) => {
                                              setPayloadDate({...payloadDate, tanggal_permin_penangguhan: date})
                                              setPayloadForm({...payloadForm, tanggal_permin_penangguhan: moment(date).format('YYYY-MM-DD')})
                                            }}
                                            selected={payloadDate.tanggal_permin_penangguhan}
                                            required
                                        />
                                      </Col>
                                      <Col sm="4">
                                        <Input 
                                          label="Unggah Arsip"
                                          type="file"
                                          onChange={(e) => {
                                            const files = e.target.files[0]
                                            setPayloadForm({...payloadForm, arsip: [...payloadForm.arsip, files]})
                                          }}
                                          required
                                        />
                                      </Col>
                                    </Row>
                                </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                    <Accordion.Header>Informasi Penangguhan Tahanan</Accordion.Header>
                      <Accordion.Body className='py-2 px-0'>
                        <Card className='card bg-white'>
                          <Card.Body>
                            <Row className='g-2 mt-2'>
                              <Col sm="4">
                                <Input 
                                  label="Kondisi Fisik Tahanan"
                                  type="text"
                                  name="kondisi"
                                  onChange={handleChange}
                                  defaultValue={payloadForm.kondisi} 
                                  required
                                />
                              </Col>
                              <Col sm="4">
                                <DatePicker 
                                    label="Tgl Mulai Penangguhan"
                                    name="awal_penangguhan"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => {
                                      setPayloadDate({...payloadDate, awal_penangguhan: date})
                                      setPayloadForm({...payloadForm, awal_penangguhan: moment(date).format('YYYY-MM-DD')})
                                    }}
                                    selected={payloadDate.awal_penangguhan}
                                    required
                                />
                              </Col>
                              <Col sm="4">
                                <DatePicker 
                                    label="Tgl Selesai Penangguhan"
                                    name="akhir_penangguhan"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => {
                                      setPayloadDate({...payloadDate, akhir_penangguhan: date})
                                      setPayloadForm({...payloadForm, akhir_penangguhan: moment(date).format('YYYY-MM-DD')})
                                    }}
                                    selected={payloadDate.akhir_penangguhan}
                                    required
                                />
                              </Col>
                              <Col sm="5">
                                <Input 
                                  as="textarea"
                                  name="alasan"
                                  onChange={handleChange}
                                  defaultValue={payloadForm.alasan}
                                  label="Alasan Penangguhan"
                                  required
                                />
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>Informasi Tambahan</Accordion.Header>
                      <Accordion.Body className='py-2 px-0'>
                          <Card className='card bg-white'>
                            <Card.Body>
                              <Row className='mt-2 g-2'>
                                <Col sm="4">
                                  <Input
                                    label="Lampiran Pendukung"
                                    type="file"
                                    onChange={(e) => {
                                      const files = e.target.files[0]
                                      setPayloadForm({...payloadForm, lampiran: [...payloadForm.lampiran, files]})
                                    }}
                                    required
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input 
                                    as="textarea"
                                    onChange={handleChange}
                                    defaultValue={payloadForm.keterangan}
                                    col="4"
                                    name="keterangan"
                                    label="Keterangan"
                                    required
                                  />
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                      </Accordion.Body>
                   </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className='bg-white'>
          <div className="d-flex justify-content-end align-items-center gap-3">
            <Link to="/tahanan/penangguhan/list"><Button variant='warning' >BATAL</Button></Link>
            {
              (!mutateSubmit.isLoading) || (!mutateSubmit.isLoading) ? (
                <Button variant='primary' onClick={handleSubmit}>SIMPAN</Button>
              ) : (
                <Button variant='primary' className='d-flex align-items-center gap-2' disabled>Sedang Menyimpan <Spinner variant='light' size='sm' /></Button>
              )
            }
          </div>
          </Card.Footer>
        </Card>
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size='lg'
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Cari Tahanan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="g-4">
              <Col sm="12">
                <Input 
                  label="Nama Tahanan"
                  onChange={(e) => {
                    setNamaTahanan(e.target.value)
                  }}
                ></Input>
              </Col>
              <Col sm="12">
                <Table className="table dems-table">
                    <thead>
                      <tr>    
                          <th>No</th>
                          <th>Nama Tahanan</th>
                          <th>Asal Satker</th>
                          <th>Perkara</th>
                          <th>Lokasi</th>
                          <th>Pilih</th>
                      </tr>
                    </thead>
                    <tbody>
                    {modalFetching && (
                        <tr>
                          <td colSpan="6" style={{ textAlign: "center" }}>
                            <Spinner animation="border" variant="primary" size='small'/>
                          </td>
                        </tr>
                    )}
                    {
                      !modalFetching && (
                        dataTahananList.map((data, index) => (
                            <tr key={index}>
                                <td>
                                    {index + 1}
                                </td>
                                <td>
                                    {data.nama_tersangka}
                                </td>
                                <td>
                                    {data.nama_satker}
                                </td>
                                <td>
                                    {data.perkara}
                                </td>
                                <td>
                                  {data.nama_rutan}
                                </td>
                                <td>
                                    <Form.Check 
                                      type='checkbox'
                                      onClick={() => {
                                        setIdTahanan(data.id)
                                        setShowForm(true)
                                        setModalShow(false)
                                      }}
                                    />
                                </td>
                            </tr>
                        ))
                      )
                    }
                    </tbody>
                </Table>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
    </React.Fragment>
  )
}

export default FormMenu