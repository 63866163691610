import axios from "axios";
import State from "./State";

const state = State.getState();
const mainHost = process.env.REACT_APP_API_HOST_NAME;

const instanceDems = axios.create({
    baseURL: mainHost,
});

instanceDems.interceptors.request.use(
    (config) => {
        const configClone = {...config}
        const token = state.getItem('accessToken', true)?.token?.access_token ?? null;
        const ip = state.getItem('ipUser', true) || null
        if (token) configClone.headers.Authorization = `Bearer ${token}`
        if (!token) configClone.headers.Authorization = 'Bearer'
        if (ip) configClone.headers['ip-address'] = `${ip}`
        return configClone;
    },
    (error) => {
        console.error('Error in request:', error);
        return Promise.reject(error);
    }
);

instanceDems.interceptors.response.use(
    (response) => response,
    async (error) => {
        const statusCode = error.response?.status; 
        const message = error.response?.data?.message ?? null; 
        const pagePath = window?.location?.pathname ?? '/'
        
        if (statusCode === 440 || statusCode === 401) { 
            window.location.href = '/session-expired?from=' + (pagePath);
            state.flush(true);
        }

        if (statusCode === 403) { 
            window.location.href = '/login';
        }

        return Promise.reject(error);
    }
);
// Fungsi GET menggunakan Axios instance
export async function get(endpoint, options) {
    let endPointAddTrail = endpoint.endsWith('/') ? endpoint.slice(0, -1) : endpoint;

    try {
        const response = await instanceDems.get(endPointAddTrail, options);
        return response;
    } catch (error) {
        console.error('GET request error:', error);
        throw error;
    }
}

// Fungsi POST menggunakan Axios instance
export async function post(endpoint, payload) {
    let endPointAddTrail = endpoint.endsWith('/') ? endpoint.slice(0, -1) : endpoint;
    try {
        const response = await instanceDems.post(endPointAddTrail, payload);
        return response;
    } catch (error) {
        console.error('POST request error:', error);
        throw error;
    }
}

// Fungsi PUT menggunakan Axios instance
export async function put(endpoint, payload) {
    let endPointAddTrail = endpoint.endsWith('/') ? endpoint.slice(0, -1) : endpoint;
    try {
        const response = await instanceDems.put(endPointAddTrail, payload);
        return response;
    } catch (error) {
        console.error('PUT request error:', error);
        throw error;
    }
}

// Fungsi DELETE menggunakan Axios instance
export async function del(endpoint) {
    let endPointAddTrail = endpoint.endsWith('/') ? endpoint.slice(0, -1) : endpoint;
    try {
        const response = await instanceDems.delete(endPointAddTrail);
        return response;
    } catch (error) {
        console.error('DELETE request error:', error);
        throw error;
    }
}

export { axios };