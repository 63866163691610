import React, { useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Spinner,
  Badge,
  Form,
} from "react-bootstrap";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Input,
  MyPagination,
  Select,
  Select2,
  DatePicker,
} from "../../../components/Components";
import {
  useGetLaporanTahanan,
  useSatkerTahtiList,
  useGetPoldaPolres,
} from "../../../utils/apis/query";
import { debounce, filter } from "lodash";
import Alert from "../../../libraries/Alert";
import { status_tahanan } from "../../../utils/dummy/variable";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import logo_kop from "../../../assets/img/logo_kopsurat.png";
import moment from "moment";
import State from "../../../libraries/State";

function List() {
  const location = useLocation();
  const path = location.pathname;
  const state = State.getState();
  const profileData = state.getItem("profileData", true);
  const satker = profileData?.satker?.nama_satker ?? "";
  const isBareskrim = /bareskrim/gi.test(satker);
  const [namaSatkerPoldaFilter, setNamaSatkerPoldaFilter] = useState("");
  const [namaSatkerPolresFilter, setNamaSatkerPolresFilter] = useState("");
  const [namaSatkerPolresPayloadFilter, setNamaSatkerPolresPayloadFilter] =
    useState("");
  const [rekapDate, setRekapDate] = useState("");
  const [filters, setFilters] = useState({
    tipe_laporan: "",
  });
  const [payloadDebounced, setPayloadDebounced] = useState({
    tipe_laporan: "",
  });
  const [formDate, setFormDate] = useState({
    rekap_date: "",
  });

  const { data: dataSatker } = useGetPoldaPolres(
    {
      polda: namaSatkerPoldaFilter,
      polres:
        namaSatkerPolresFilter === "satker_sendiri"
          ? ""
          : namaSatkerPolresFilter,
    }
    // {
    //   enabled: !!namaSatkerPoldaFilter || !!namaSatkerPolresFilter,
    // }
  );

  const optionPolda = [
    { label: "SEMUA POLDA", value: "" },
    ...dataSatker.polda.map((o) => ({ label: o.value, value: o.value })),
  ];
  const optionPolres = [
    { label: "SEMUA POLRES", value: "" },
    { label: "SATKER SENDIRI", value: "satker_sendiri" },
    ...dataSatker.polres.map((o) => ({ label: o.value, value: o.value })),
  ];
  const {
    data: dataList,
    pagination,
    isFetching: dataFetching,
    isError: dataIsError,
    error: dataError,
    refetch: refetchDataList,
  } = useGetLaporanTahanan(payloadDebounced);

  const handleChange = (e) => {
    if (e) {
      const tgl = moment(e).format("YYYY-MM-DD");

      setRekapDate(tgl);
      // setPayloadDebounced({
      //   ...payloadDebounced,
      //   rekap_date: tglAwal,
      //   end_date: tglAkhir,
      // });
    }
  };

  const formatToTitleCase = useCallback((path) => {
    const lastPath = path.substring(path.lastIndexOf("/") + 1);

    const value = lastPath.replace(/-/g, " ");

    // const titleCaseString = spacedString
    //   .split(" ")
    //   .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    //   .join(" ");

    setFilters({
      tipe_laporan:
        value === "daftar tahanan atensi menonjol"
          ? "daftar tahanan atensi/menonjol"
          : value,
    });
    setPayloadDebounced({
      tipe_laporan: "",
    });
  }, []);

  useEffect(() => {
    formatToTitleCase(path);
  }, [formatToTitleCase, path]);

  useEffect(() => {
    if (profileData) {
      moment.locale();
      const satker = profileData?.satker?.nama_satker ?? "";
      const isPolres = /polres/gi.test(satker);
      const isBareskrim = /bareskrim/gi.test(satker);
      if (isBareskrim) {
        setNamaSatkerPoldaFilter(satker);
        setNamaSatkerPolresFilter("semua_polres");
      } else {
        setNamaSatkerPoldaFilter(!isPolres ? satker : "");
        setNamaSatkerPolresFilter(isPolres ? satker : "");
      }
    }
  }, [profileData]);
  useEffect(() => {
    if (profileData) {
      moment.locale();
      const satker = profileData?.satker?.nama_satker ?? "";
      const isPolres = /polres/gi.test(satker);
      const isBareskrim = /bareskrim/gi.test(satker);

      if (isBareskrim) {
        if (namaSatkerPolresPayloadFilter === "") {
          setNamaSatkerPolresPayloadFilter(
            dataSatker.polres.map((o) => o.value).join(",")
          );
        } else {
          setNamaSatkerPolresPayloadFilter(namaSatkerPolresPayloadFilter);
        }
      } else {
        if (!isPolres && namaSatkerPolresPayloadFilter === "") {
          setNamaSatkerPolresPayloadFilter(
            dataSatker.polres.map((o) => o.value).join(",")
          );
        }
        if (isPolres && namaSatkerPolresPayloadFilter === "") {
          setNamaSatkerPolresPayloadFilter(satker);
        }
      }
    }
  }, [profileData, dataSatker, namaSatkerPolresPayloadFilter, refetchDataList]);

  useEffect(() => {
    if (namaSatkerPolresPayloadFilter) {
      refetchDataList();
    }
  }, [namaSatkerPolresPayloadFilter, refetchDataList]);

  useEffect(() => {
    setTimeout(() => {
      if (namaSatkerPoldaFilter !== null || !!namaSatkerPoldaFilter) {
        refetchDataList();
      }
    }, 2000);
  }, []);

  const generate = (e) => {
    setPayloadDebounced({
      ...payloadDebounced,
      tipe_laporan: filters.tipe_laporan,
      rekap_date: rekapDate,
      nama_satker_polda: namaSatkerPoldaFilter,
      nama_satker_polres:
        namaSatkerPolresPayloadFilter === "satker_sendiri"
          ? ""
          : namaSatkerPolresPayloadFilter,
    });
  };

  const exportToExcel = async () => {
    if (!payloadDebounced.tipe_laporan) {
      alert("Mohon generate laporan terlebih dahulu");
      return;
    }
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    const title = payloadDebounced.tipe_laporan;
    const tableColumn = fieldTable[
      payloadDebounced.tipe_laporan.replace(/[ /]/g, "_")
    ].map((data) => data.text);

    worksheet.mergeCells(
      `A1:${String.fromCharCode(65 + tableColumn.length - 1)}1`
    );
    worksheet.getCell("A1").value = title;
    worksheet.getCell("A1").alignment = { horizontal: "center" };
    worksheet.getCell("A1").font = { bold: true, size: 20 };

    worksheet.mergeCells(
      `A2:${String.fromCharCode(65 + tableColumn.length - 1)}2`
    );
    const today = new Date();
    const formattedDate = today.toLocaleDateString("id-ID", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    worksheet.getCell("A2").value = formattedDate;
    worksheet.getCell("A2").alignment = { horizontal: "center" };
    worksheet.getCell("A2").font = { bold: true, size: 12 };

    worksheet.getCell("A3").value =
      "Satker : " +
      (payloadDebounced.satker ? payloadDebounced.satker : "Semua Satker");
    worksheet.getCell("A3").alignment = { horizontal: "left" };
    worksheet.getCell("A3").font = { bold: true, size: 12 };

    const headerRow = worksheet.addRow(tableColumn);

    headerRow.eachCell((cell) => {
      cell.alignment = { horizontal: "center" };
      cell.font = { bold: true };
    });

    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFA500" },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    dataList.forEach((data) => {
      const row = fieldTable[
        payloadDebounced.tipe_laporan.replace(/[ /]/g, "_")
      ].map((field) =>
        field.value === "penyidik"
          ? data[field.value]
              .map((penyidikItem) => penyidikItem.nama_penyidik)
              .join(", ")
          : data[field.value]
      );

      const newRow = worksheet.addRow(row);

      newRow.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    });

    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const cellValue = cell.value ? cell.value.toString() : "";
        maxLength = Math.max(maxLength, cellValue.length);
      });
      column.width = maxLength + 2;
    });

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${payloadDebounced.tipe_laporan}.xlsx`);
  };

  const exportPDF = () => {
    if (!payloadDebounced.tipe_laporan) {
      alert("Mohon generate laporan terlebih dahulu");
      return;
    }

    const doc = new jsPDF("landscape");
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const text = payloadDebounced.tipe_laporan;

    // Logo
    const imgWidth = 30;
    const imgHeight = 30;
    const imgX = (pageWidth - imgWidth) / 2;
    const imgY = 10;

    doc.addImage(logo_kop, "PNG", imgX, imgY, imgWidth, imgHeight);

    //judul laporan
    doc.setFontSize(14);
    doc.text(text, pageWidth / 2, imgY + imgHeight + 10, { align: "center" });
    const titleWidth = doc.getTextWidth(text);
    doc.line(
      (pageWidth - titleWidth) / 2,
      imgY + imgHeight + 12,
      (pageWidth + titleWidth) / 2,
      imgY + imgHeight + 12
    );

    const today = new Date();
    const dateWithOutDay = today.toLocaleDateString();
    const formattedDate = today.toLocaleDateString("id-ID", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    doc.setFontSize(12);
    doc.text(` ${formattedDate}`, pageWidth / 2, imgY + imgHeight + 17, {
      align: "center",
    });

    //  satker
    const satkerText = `Satker: ${payloadDebounced.satker || "Semua Satker"}`;
    doc.text(satkerText, 15, imgY + imgHeight + 25);

    // kolom tabel
    const tableColumn = [];
    fieldTable[payloadDebounced.tipe_laporan.replace(/[ /]/g, "_")].map(
      (data) => tableColumn.push(data.text)
    );

    // baris tabel
    const tableRows = dataList.map((data) =>
      fieldTable[payloadDebounced.tipe_laporan.replace(/[ /]/g, "_")].map(
        (field) =>
          field.value === "penyidik"
            ? data[field.value]
                .map((penyidikItem) => penyidikItem.nama_penyidik)
                .join(", ")
            : data[field.value]
      )
    );

    // Render tabel
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: imgY + imgHeight + 30,
      theme: "grid",
      styles: { fontSize: 10 },
      headStyles: { fillColor: [255, 165, 0] },
    });

    // Deteksi akhir halaman terakhir dan tambahkan tanggal di kanan bawah
    const numberOfPages = doc.internal.getNumberOfPages();
    doc.setPage(numberOfPages);

    // Set tanggal dan space untuk tanda tangan di halaman terakhir, kanan bawah
    doc.text(`Jakarta, ${dateWithOutDay}`, pageWidth - 45, pageHeight - 40);
    doc.line(pageWidth - 50, pageHeight - 18, pageWidth - 10, pageHeight - 18);

    doc.save(payloadDebounced.tipe_laporan + ".pdf");
  };

  const option = [
    {
      value: "Rekap Tahanan POLRI",
      text: "Rekap Tahanan POLRI",
    },
    {
      value: "Rekap Tahanan Rutan yang lebih dari 60 Hari",
      text: "Daftar Tahanan Rutan Lebih Dari 60 Hari",
    },
    {
      value: "Rekap Tahanan Rutan yang kurang dari 60 Hari",
      text: "Daftar Tahanan Rutan Kurang Dari 60 Hari",
    },
    {
      value: "Daftar Tahanan Direktorat Narkoba yang lebih dari 60 Hari",
      text: "Daftar Tahanan Direktorat Narkoba Lebih Dari 60 Hari",
    },
    {
      value: "Daftar Tahanan Direktorat Narkoba yang kurang dari 60 Hari",
      text: "Daftar Tahanan Direktorat Narkoba Kurang Dari 60 Hari",
    },
    {
      value: "Daftar Tahanan Titipan Kejaksaan Pada Rutan",
      text: "Daftar Tahanan Titipan Kejaksaan Pada Rutan",
    },
    {
      value: "Daftar Tahanan Atensi/Menonjol",
      text: "Daftar Tahanan Atensi/Menonjol",
    },
    {
      value: "Daftar Tahanan Yang Menderita Sakit",
      text: "Daftar Tahanan Menderita Sakit",
    },
    {
      value: "Daftar Tahanan Perempuan",
      text: "Daftar Tahanan Perempuan",
    },
    {
      value: "Daftar Tahanan Kriminal Umum",
      text: "Daftar Tahanan Kriminal Hukum",
    },
    {
      value: "Daftar Tahanan Ditangguhkan",
      text: "Daftar Tahanan Ditangguhkan",
    },
  ];
  const fieldTable = {
    rekap_tahanan_polri: [
      {
        value: "nama_rutan",
        text: "Rutan",
      },
      {
        value: "sisa",
        text: "Sisa",
      },
      {
        value: "tahanan_masuk",
        text: "Tahanan Masuk",
      },
      {
        value: "tahanan_keluar",
        text: "Tahanan Keluar",
      },
      {
        value: "jumlah",
        text: "Jumlah",
      },
      {
        value: "tahanan_dewasa_laki",
        text: "Laki-laki Dewasa",
      },
      {
        value: "tahanan_dewasa_perempuan",
        text: "Perempuan Dewasa",
      },
      {
        value: "tahanan_anak_laki",
        text: "Laki-laki Anak",
      },
      {
        value: "tahanan_anak_perempuan",
        text: "Perempuan Anak",
      },
      {
        value: "tahanan_polri",
        text: "Polri",
      },
      {
        value: "tahanan_lapas",
        text: "Lapas",
      },
    ],
    rekap_tahanan_rutan_yang_lebih_dari_60_hari: [
      {
        value: "nama_tersangka",
        text: "Nama Tersangka/Tahanan",
      },
      {
        value: "pasal_pelanggaran",
        text: "Pasal",
      },
      {
        value: "penyidik",
        text: "Penyidik",
      },
      {
        value: "asal_direktorat",
        text: "Asal Direktorat",
      },
      {
        value: "lama_penahanan",
        text: "Lama Penahanan",
      },
      {
        value: "keterangan",
        text: "Keterangan",
      },
    ],
    rekap_tahanan_rutan_yang_kurang_dari_60_hari: [
      {
        value: "nama_tersangka",
        text: "Nama Tersangka/Tahanan",
      },
      {
        value: "pasal_pelanggaran",
        text: "Pasal",
      },
      {
        value: "penyidik",
        text: "Penyidik",
      },
      {
        value: "asal_direktorat",
        text: "Asal Direktorat",
      },
      {
        value: "lama_penahanan",
        text: "Lama Penahanan",
      },
      {
        value: "keterangan",
        text: "Keterangan",
      },
    ],
    daftar_tahanan_direktorat_narkoba_yang_lebih_dari_60_hari: [
      {
        value: "nama_tersangka",
        text: "Nama Tersangka/Tahanan",
      },
      {
        value: "pasal_pelanggaran",
        text: "Pasal",
      },
      {
        value: "penyidik",
        text: "Penyidik",
      },
      {
        value: "asal_direktorat",
        text: "Asal Direktorat",
      },
      {
        value: "lama_penahanan",
        text: "Lama Penahanan",
      },
      {
        value: "keterangan",
        text: "Keterangan",
      },
    ],
    daftar_tahanan_direktorat_narkoba_yang_kurang_dari_60_hari: [
      {
        value: "nama_tersangka",
        text: "Nama Tersangka/Tahanan",
      },
      {
        value: "pasal_pelanggaran",
        text: "Pasal",
      },
      {
        value: "penyidik",
        text: "Penyidik",
      },
      {
        value: "asal_direktorat",
        text: "Asal Direktorat",
      },
      {
        value: "lama_penahanan",
        text: "Lama Penahanan",
      },
      {
        value: "keterangan",
        text: "Keterangan",
      },
    ],
    daftar_tahanan_titipan_kejaksaan_pada_rutan: [
      {
        value: "nama_tersangka",
        text: "Nama Tersangka/Tahanan",
      },
      {
        value: "pasal_pelanggaran",
        text: "Pasal",
      },
      {
        value: "penyidik",
        text: "Penyidik",
      },
      {
        value: "asal_direktorat",
        text: "Asal Direktorat",
      },
      {
        value: "lama_penahanan",
        text: "Lama Penahanan",
      },
      {
        value: "keterangan",
        text: "Keterangan",
      },
    ],
    daftar_tahanan_atensi_menonjol: [
      {
        value: "nama_tersangka",
        text: "Nama Tersangka/Tahanan",
      },
      {
        value: "pasal_pelanggaran",
        text: "Pasal",
      },
      {
        value: "penyidik",
        text: "Penyidik",
      },
      {
        value: "asal_direktorat",
        text: "Asal Direktorat",
      },
      {
        value: "lama_penahanan",
        text: "Lama Penahanan",
      },
      {
        value: "keterangan",
        text: "Keterangan",
      },
    ],
    daftar_tahanan_yang_menderita_sakit: [
      {
        value: "nama_tersangka",
        text: "Nama Tersangka/Tahanan",
      },
      {
        value: "pasal_pelanggaran",
        text: "Pasal",
      },
      {
        value: "penyidik",
        text: "Penyidik",
      },
      {
        value: "asal_direktorat",
        text: "Asal Direktorat",
      },
      {
        value: "penyakit",
        text: "Penyakit Yang Diderita",
      },
      {
        value: "lama_penahanan",
        text: "Masa Penahanan",
      },
      {
        value: "rumah_sakit",
        text: "Tempat Rawat",
      },
      {
        value: "keterangan",
        text: "Keterangan",
      },
    ],
    daftar_tahanan_perempuan: [
      {
        value: "nama_tersangka",
        text: "Nama Tersangka/Tahanan",
      },
      {
        value: "pasal_pelanggaran",
        text: "Pasal",
      },
      {
        value: "kasus",
        text: "Kasus",
      },
      {
        value: "penyidik",
        text: "Penyidik",
      },
      {
        value: "asal_direktorat",
        text: "Asal Direktorat",
      },
      {
        value: "lama_penahanan",
        text: "Lama Penahanan",
      },
      {
        value: "keterangan",
        text: "Keterangan",
      },
    ],
    daftar_tahanan_kriminal_umum: [
      {
        value: "nama_tersangka",
        text: "Nama Tersangka/Tahanan",
      },
      {
        value: "pasal_pelanggaran",
        text: "Pasal",
      },
      {
        value: "kasus",
        text: "Kasus",
      },
      {
        value: "penyidik",
        text: "Penyidik",
      },
      {
        value: "asal_direktorat",
        text: "Asal Direktorat",
      },
      {
        value: "lama_penahanan",
        text: "Lama Penahanan",
      },
      {
        value: "keterangan",
        text: "Keterangan",
      },
    ],
    daftar_tahanan_ditangguhkan: [
      {
        value: "nama_tersangka",
        text: "Nama Tersangka/Tahanan",
      },
      {
        value: "pasal_pelanggaran",
        text: "Pasal",
      },
      {
        value: "kasus",
        text: "Kasus",
      },
      {
        value: "penyidik",
        text: "Penyidik",
      },
      {
        value: "asal_direktorat",
        text: "Asal Direktorat",
      },
      {
        value: "no_sprin_penangguhan",
        text: "No. Sprin Penahanan",
      },
      {
        value: "tanggal_keluar",
        text: "Tanggal Keluar",
      },
    ],
  };

  const handleDebounce = useCallback(
    debounce(({ field, val }) => {
      setPayloadDebounced({ ...payloadDebounced, [field]: val });
    }, 500),
    []
  );
  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item">
              <Link to="/laporan/tahanan/rekap-tahanan-polri">Laporan</Link>
            </li>
            <li className="breadcrumb-item active fw-bold" aria-current="page">
              Laporan {filters.tipe_laporan}
            </li>
          </ol>
        </div>
      </div>
      <Row>
        <Col xl="12">
          <Card className="card bg-white">
            <Card.Body>
              <Row className="g-1 mb-2">
                <Col>
                  <Card className="card bg-white">
                    <Card.Body className="bg-white d-flex flex-column gap-2">
                      <Row className="g-2">
                        {/* <Col>
                          <Select
                            default="Pilih Tipe Laporan"
                            name="tipe_laporan"
                            options={option}
                            onChange={(e) => {
                              setFilters({
                                ...filters,
                                tipe_laporan: e.target.value,
                              });
                              setRekapDate();
                            }}
                          />
                        </Col> */}
                        <Col>
                          <Select2
                            defaultValue={"Semua Kesatuan"}
                            options={optionPolda}
                            value={namaSatkerPoldaFilter}
                            name="nama_satker_polda"
                            placeholder="Semua Kesatuan"
                            required
                            isClearable
                            isSearchable
                            disabled={!isBareskrim}
                            onChange={(e) => {
                              setNamaSatkerPoldaFilter(e.value);
                            }}
                          />
                        </Col>
                        <Col>
                          <Select2
                            defaultValue={"Semua Kesatuan"}
                            options={optionPolres}
                            value={namaSatkerPolresFilter}
                            name="nama_satker_polres"
                            placeholder="Semua Polres"
                            required
                            isClearable
                            isSearchable
                            onChange={(e) => {
                              setNamaSatkerPolresFilter(e.value);
                              setNamaSatkerPolresPayloadFilter(e.value);
                            }}
                          />
                        </Col>
                        {filters.tipe_laporan === "rekap tahanan polri" && (
                          <Col>
                            <DatePicker
                              placeholderText="Pilih Tanggal"
                              selected={formDate.rekap_date}
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => {
                                handleChange(date);
                                setFormDate({
                                  ...formDate,
                                  rekap_date: date,
                                });
                              }}
                            />
                          </Col>
                        )}
                        <Col className="d-grid">
                          <Button
                            className="btn btn-primary"
                            onClick={generate}
                          >
                            Generate
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="g-2">
                {payloadDebounced.tipe_laporan && (
                  <Col sm="12">
                    <Card className="card bg-white">
                      <Card.Body className="bg-white flex-column d-flex gap-2 overflow-scroll">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex flex-column gap-2">
                            <div className="fs-4 fw-bold">
                              Laporan {payloadDebounced.tipe_laporan}
                            </div>
                          </div>
                          <div className="d-flex align-items-end gap-2">
                            <Button
                              className="btn-danger btn"
                              onClick={() => exportPDF("pengelolaan")}
                            >
                              Export PDF
                            </Button>
                            <Button
                              className="btn-success btn"
                              onClick={() => exportToExcel("pengelolaan")}
                            >
                              Export XLSX
                            </Button>
                          </div>
                        </div>
                        <Table className="table dems-table">
                          <thead>
                            <tr>
                              <th>No</th>
                              {fieldTable[
                                payloadDebounced.tipe_laporan.replace(
                                  /[ /]/g,
                                  "_"
                                )
                              ].map((data, index) => (
                                <th>{data.text}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {dataFetching && (
                              <tr>
                                <td colSpan="4" style={{ textAlign: "center" }}>
                                  <Spinner
                                    animation="border"
                                    variant="primary"
                                  />
                                </td>
                              </tr>
                            )}
                            {dataIsError &&
                              (dataError?.response?.data?.detail?.map(
                                (err, idx) => (
                                  <tr key={idx}>
                                    <td
                                      colSpan="4"
                                      style={{ textAlign: "center" }}
                                      className="text-danger"
                                    >
                                      <span>
                                        <b>{err.loc[1]} </b>
                                        {err.msg}
                                      </span>
                                    </td>
                                  </tr>
                                )
                              ) ?? (
                                <tr>
                                  <td
                                    colSpan="4"
                                    style={{ textAlign: "center" }}
                                    className="text-danger"
                                  >
                                    <span>
                                      <b>Opps Error </b>Reload page or please
                                      wait until we fix this issue
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            {dataList.length && !dataFetching && !dataIsError
                              ? dataList.map((data, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    {fieldTable[
                                      payloadDebounced.tipe_laporan.replace(
                                        /[ /]/g,
                                        "_"
                                      )
                                    ].map((field, idx) => (
                                      <td key={idx}>
                                        {field.value === "penyidik"
                                          ? data[field.value]
                                              .map(
                                                (penyidikItem) =>
                                                  penyidikItem.nama_penyidik
                                              )
                                              .join(", ")
                                          : data[field.value] || "-"}
                                      </td>
                                    ))}
                                  </tr>
                                ))
                              : !dataFetching &&
                                !dataList.length &&
                                !dataIsError && (
                                  <tr>
                                    <td
                                      colSpan="4"
                                      style={{ textAlign: "center" }}
                                    >
                                      <span>Tidak Ada Data !</span>
                                    </td>
                                  </tr>
                                )}
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  </Col>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default List;
