import React, {useEffect, useRef, useState} from 'react'
import { VectorMap } from "@react-jvectormap/core";
import { idnMerc } from "@react-jvectormap/indonesia";
import State from '../../../libraries/State';
import { Placeholder, Spinner } from 'react-bootstrap';

function Map({markers, dataMapMarker, mapRender}) {
  const state = State.getState()
  const [markerMap, setMarkerMap] = useState([])
  const [mapRendered, setMapRendered] = useState(false)
  const profile = state?.getItem('profileData', true)
  const regStyle = {
    initial: {
      fill: '#CD1126', // Warna default wilayah,
      "fill-opacity": 1,
      stroke: "white", // Warna border
      "stroke-width": 0.2, // Ketebalan border
      "stroke-opacity": 1,
    },
    hover: {
      fill: '#ffff', // Warna saat wilayah di-hover
      stroke: 'red',
      "stroke-width": 1
    },
    selected: {
      fill: '#ffff', // Warna saat wilayah dipilih
    },
  }
  const mapOptions = {
    zoomOnScroll: true, // Optional: Enable zoom on scroll
    zoomButtons: true, // Optional: Enable zoom in/out buttons
    initialZoom: 2 // Adjust this value to set default zoom level
  };

  const handleMarkerClick = (e, i) => {
    // 
  }


  useEffect(() => {
    if (markers && !!dataMapMarker && profile) {
      let nama_satker = profile?.satker?.nama_satker ?? null
      markers.map((marker) => {
        marker.status = 'satker'
        if (marker.name === nama_satker) marker.status = 'activeSatker'
        return marker
      })
      setMarkerMap(markers)
      
    }
  }, [markers, mapRender, dataMapMarker, profile]);

  return (
    <React.Fragment>
        <div style={{height: 400}}>
            {
            !!mapRender ? (
                markers.length ?
                (
                  <VectorMap 
                    map={idnMerc} 
                    backgroundColor="#fff" 
                    regionStyle={regStyle} 
                    markers={markerMap}
                    options={mapOptions}
                    zoomOnScroll={false}
                    regionsSelectable={true} 
                    regionsSelectableOne={true}
                    onRegionTipShow={(e, el, code) => {
                      el.html(el.html());
                    }}
                    onMarkerTipShow={(e, label, code) => {
                      const marker = markerMap[code]; 
                      const additionalInfo = dataMapMarker[marker.name];

                      if (additionalInfo) {
                        let infoText = '';
                        Object.entries(additionalInfo).forEach(([key, value]) => {
                          infoText += `❯ \t${key}: ${value}<br>`;
                        });

                        label.html(`
                          <div class="custome-tooltip-map">
                          <b>${marker.name}</b><br>
                          <b>${marker.tanggal_rekap}<br></b><br>
                          ${infoText}  
                          </div>
                        `);
                      }
                    }}
                    onMarkerClick={handleMarkerClick}
                    series={
                      {
                        markers: [
                          {
                            attribute: 'image',
                            scale: {
                              'satker': '/pinYellow.ico',
                              'activeSatker': '/loc.ico',
                            },
                            values: markerMap.reduce(function(p, c, i){ p[i] = c.status; return p }, {}),
                          }
                        ]
                      }
                    }
                  />
                ) : 
                (
                  <span>Tidak ada data pada map</span>
                )
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <Placeholder
                  as="p"
                  animation="wave"
                  className="m-0 w-100 d-flex flex-column gap-2"
                >
                  <Placeholder sm="12" style={{height: 400}}/>
                </Placeholder>
              </div>
              // <VectorMap 
              //   map={idnMerc} 
              //   backgroundColor="#fff" 
              //   regionStyle={regStyle} 
              //   markers={markerMap}
              //   options={mapOptions}
              //   zoomOnScroll={false}
              //   regionsSelectable={true} 
              //   regionsSelectableOne={true}
              // />
            )
            }
        </div>
    </React.Fragment>
  )
}

export default Map