import React, { useCallback, useEffect, useState } from "react";
import { Alert, Badge, Button, Card, Col, Modal, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  IconPicker,
  Input,
  Select,
  Select2,
} from "../../../components/Components";
import {
  useGetDetailMasterRutan,
  usePostMasterRutan,
  usePutMasterRutan,
  useSatkerTahtiList,
} from "../../../utils/apis/query";
import { setPlatform } from "@tensorflow/tfjs";

function Form() {
  const [isEdit, setIsEdit] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [payload, setPayload] = useState({
    nama_satker: "",
    nama_rutan: "",
    alamat_rutan: "",
    kota: "",
    provinsi: "",
    kapasitas: "",
    keterangan: "",
  });
  const onSuccess = () => {
    setTimeout(() => {
      navigate("/master/rutan");
    }, 1500);
  };

  const mutateAdd = usePostMasterRutan(onSuccess);
  const mutateEdit = usePutMasterRutan(onSuccess);
  const {
    data: detailData,
    isFetching: detailIsFetching,
    isError: detailIsError,
    error: detailError,
  } = useGetDetailMasterRutan(id, { enabled: !!id });

  const { data: masterListSatkers } = useSatkerTahtiList();
  const satkersFilter = masterListSatkers.map((e) => ({
    label: e.nama_satker,
    value: e.nama_satker,
  }));

  const handleChange = (e) => {
    let { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };
  const handleSubmit = () => {
    let formData = new FormData();
    Object.keys(payload).forEach((key) => {
      if (
        typeof payload[key] === "string" ||
        typeof payload[key] === "number"
      ) {
        formData.append(key, payload[key]);
      }
    });
    if (isEdit) {
      mutateEdit.mutate({ id, formData });
    } else {
      mutateAdd.mutate(formData);
    }
  };

  useEffect(() => {
    setIsEdit(false);
    if (id) {
      setIsEdit(true);
    }
  }, [id]);

  useEffect(() => {
    if (detailData) {
      setPayload({
        ...payload,
        nama_satker: detailData.nama_satker,
        nama_rutan: detailData.nama_rutan,
        alamat_rutan: detailData.alamat_rutan,
        kota: detailData.kota,
        provinsi: detailData.provinsi,
        kapasitas: detailData.kapasitas,
        keterangan: detailData.keterangan,
      });
    }
  }, [detailData]);

  useEffect(() => {
    return () => {
      setPayload({
        nama_satker: "",
        nama_rutan: "",
        alamat_rutan: "",
        kota: "",
        provinsi: "",
        kapasitas: "",
        keterangan: "",
      });
    };
  }, []);
  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between m-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/master/rutan">Rutan</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/master/rutan">List Rutan</Link>
                </li>
                <li
                  className="breadcrumb-item active fw-bold"
                  aria-current="page"
                >
                  Form {isEdit ? "Edit" : "Add"} Rutan
                </li>
              </ol>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="bg-white">
        <Card.Body>
          <Row className="g-2">
            <Col sm="12">
              {mutateAdd.isError && (
                <Alert variant="danger">
                  <ul>
                    {mutateAdd?.error?.response?.data?.detail?.map(
                      (errEdit) => (
                        <li>
                          <b>{errEdit.loc[1]} </b>
                          {errEdit.msg}
                        </li>
                      )
                    ) ?? (
                      <li>
                        <b>Error</b>Sesuatu Bermasalah
                      </li>
                    )}
                  </ul>
                </Alert>
              )}
              {mutateAdd.isSuccess && (
                <Alert variant="success">{mutateAdd.data.data.message}</Alert>
              )}
            </Col>
            <Col sm="12">
              {mutateEdit.isError && (
                <Alert variant="danger">
                  <ul>
                    {mutateEdit?.error?.response?.data?.detail?.map(
                      (errEdit) => (
                        <li>
                          <b>{errEdit.loc[1]} </b>
                          {errEdit.msg}
                        </li>
                      )
                    ) ?? (
                      <li>
                        <b>Error</b>Sesuatu Bermasalah
                      </li>
                    )}
                  </ul>
                </Alert>
              )}
              {mutateEdit.isSuccess && (
                <Alert variant="success">{mutateEdit.data.data.message}</Alert>
              )}
            </Col>
            <Col sm="4">
              <Select2
                default="-"
                label="Satker"
                name="nama_satker"
                options={satkersFilter}
                value={payload.nama_satker}
                onChange={(e) => {
                  setPayload({
                    ...payload,
                    nama_satker: e.value,
                  });
                }}
                required
              />
            </Col>
            <Col sm="4">
              <Input
                value={payload.nama_rutan}
                name="nama_rutan"
                onChange={handleChange}
                label="Nama Rutan"
                required
              />
            </Col>
            <Col sm="4">
              <Input
                type="number"
                value={payload.kapasitas}
                name="kapasitas"
                onChange={handleChange}
                label="Kapasitas"
                required
              />
            </Col>
            <Col sm="4">
              <Input
                value={payload.kota}
                name="kota"
                onChange={handleChange}
                label="Kota Rutan"
              />
            </Col>
            <Col sm="4">
              <Input
                value={payload.provinsi}
                name="provinsi"
                onChange={handleChange}
                label="provinsi Rutan"
                required
              />
            </Col>
            <Col sm="4">
              <Input
                as="textarea"
                value={payload.alamat_rutan}
                name="alamat_rutan"
                onChange={handleChange}
                label="Alamat Rutan"
                required
              />
            </Col>
            <Col sm="4">
              <Input
                value={payload.keterangan}
                name="keterangan"
                onChange={handleChange}
                label="Keterangan"
                required
              />
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
          <div className="d-flex justify-content-end align-items-center">
            {!mutateAdd.isLoading || !mutateEdit.isLoading ? (
              <Button variant="primary" onClick={handleSubmit}>
                {isEdit ? "Save" : "Submit"}
              </Button>
            ) : (
              <Button variant="primary" disabled>
                Loading
              </Button>
            )}
          </div>
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
}

export default Form;
