import React, { useEffect, useState, useCallback } from "react";
import { Row, Col, Tabs, Tab, Card, Button, Spinner } from "react-bootstrap";
import { ChartDashBarbuk, ChartPie, DashboardMap } from "../module";
import * as am5 from "@amcharts/amcharts5";
import { Input, MyPagination, Select, Select2 } from "../../../components/Components";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import {
  useSatkerTahtiList,
  useDashboardBarbuk,
  useGetDashboardBarbukMap,
  useDashboardBarbukList,
  useGetPoldaPolres,
} from "../../../utils/apis/query";
import { formatIDR, months, years } from "../../../utils";
import moment from "moment/moment";
import State from "../../../libraries/State";

function Index() {
  const state = State.getState();
  const profileData = state.getItem("profileData", true);
  const [activeSort, setActiveSort] = useState('nama')
  const [namaSatkerPoldaFilter, setNamaSatkerPoldaFilter] = useState('')
  const [namaSatkerPolresFilter, setNamaSatkerPolresFilter] = useState('')
  const [namaSatkerPolresPayloadFilter, setNamaSatkerPolresPayloadFilter] = useState('')
  const [namaSatkerPoldaPayloadFilter, setNamaSatkerPoldaPayloadFilter] = useState("");
  const [bulanFilter, setBulanFilter] = useState('')
  const [tahunFilter, setTahunFilter] = useState('')

  // Map
  const [markersMap, setMarkerMap] = useState([]);
  const [dataMapMarker, setDataMapMarker] = useState(null);
  const [mapRendered, setMapRendered] = useState(false);

  // Chart
  const [fetchedOnce, setFetchedOnce] = useState(true);
  const [dataChartBarbuk, setDataChartBarbuk] = useState([])
  const [golonganBarbuk, setGolonganBarbuk] = useState([])
  const [barangBuktiUang, setBarangBuktiUang] = useState([])
  const [barangBuktiLogamMulia, setBarangBuktiLogamMulia] = useState([])
  const [barangBuktiTanah, setBarangBuktiTanah] = useState([])
  const [barangBuktiGedungDanBangunan, setBarangBuktiGedungDanBangunan] = useState([])
  const [barangBuktiPeralatanMesin, setBarangBuktiPeralatanMesin] = useState([])
  const [barangBuktiBernilaiEkonomis, setBarangBuktiBernilaiEkonomis] = useState([])

  const satker = profileData?.satker?.nama_satker ?? ''
  const isPolres = /polres/gi.test(satker)
  const isPolda = /polda/gi.test(satker)
  const isBareskrim = /bareskrim/gi.test(satker)
  const {
    data: dataSatker
  } = useGetPoldaPolres(
    {
      polda: namaSatkerPoldaFilter,
      polres: namaSatkerPolresFilter === 'satker_sendiri' ? '' : namaSatkerPolresFilter,
    },
    {
      enabled: !!namaSatkerPoldaPayloadFilter || !!namaSatkerPolresFilter
    }
  )

  const optionPolda = [{label: 'SEMUA POLDA', value: 'semua_polda'},...dataSatker.polda.map((o) => ({label: o.value, value: o.value}))]
  const optionPolres= [{label: 'SEMUA', value: 'semua'}, {label: 'SATKER SENDIRI', value: 'satker_sendiri'},...dataSatker.polres.map((o) => ({label: o.value, value: o.value}))]


  const {
    data: dataBarbuk,
    isFetching: isDataBarbukFethcing,
    isError: isDataBarbukError,
    error: dataBarbukError,
    refetch: refetchDataBarbuk
  } = useDashboardBarbuk(
    {
      nama_satker_polda: namaSatkerPoldaPayloadFilter,
      nama_satker_polres: namaSatkerPolresPayloadFilter,
      bulan: bulanFilter,
      tahun: tahunFilter
    },
    {
      enabled: (!!namaSatkerPoldaPayloadFilter || !!namaSatkerPolresPayloadFilter) && !!fetchedOnce
    }
  )

  const {
    data: dataBarbukList,
    isFetching: isDataBarbukListFethcing,
    isError: isDataBarbukListError,
    error: dataBarbukListError,
    refetch: refetchDataBarbukList
  } = useDashboardBarbukList(
    {
      nama_satker_polda: namaSatkerPoldaPayloadFilter,
      nama_satker_polres: namaSatkerPolresPayloadFilter,
      bulan: bulanFilter,
      tahun: tahunFilter
    },
    {
      enabled: (!!namaSatkerPoldaPayloadFilter || !!namaSatkerPolresPayloadFilter) && !!fetchedOnce
    }
  )

  const {
    data: dataBarbukMap,
    isFetching: isDataBarbukMapFethcing,
    isError: isDataBarbukMapError,
    error: dataBarbukMapError,
    refetch: refetchDataBarbukMap
  } = useGetDashboardBarbukMap(
    {
      nama_satker_polda: namaSatkerPoldaPayloadFilter,
      nama_satker_polres: namaSatkerPolresPayloadFilter,
      bulan: bulanFilter,
      tahun: tahunFilter
    },
    {
      enabled: (!!namaSatkerPoldaPayloadFilter || !!namaSatkerPolresPayloadFilter) && !!fetchedOnce
    }
  )

  // SCOPE MAP
  useEffect(() => {
    if (dataBarbukMap.length) {
      setMapRendered(false)

      const markers = dataBarbukMap.map(item => ({
        latLng: item.latLng, // Mengambil latLng dari response
        name: item.nama_satker, // Mengambil nama_satker dari response
        tanggal_rekap: item.tanggal_rekap
      }));
      const dataMarkers = dataBarbukMap.reduce((acc, item) => {
        acc[item.nama_satker] = {
         "Barang Bukti Uang": `${formatIDR(item.barbuk_uang)}`,
        "Barang Bukti Logam Mulia": `${item.barbuk_logam_mulia} Gram`,
        "Barang Bukti Lahan": `${item.barbuk_lahan} Ha`,
        "Barang Bukti Gedung & Bangunan": `${item.barbuk_gedung_dan_bangunan} Unit`,
        "Barang Bukti Peralatan Mesin": `${item?.barbuk_peralatan_mesin ?? 0} Unit`
        };
        return acc;
      }, {});
    
      setMarkerMap(markers);
      setDataMapMarker(dataMarkers);
    }
  }, [dataBarbukMap])

  useEffect(() => {
    if (markersMap && dataMapMarker) {
      setMapRendered(true);
    }
  }, [markersMap, dataMapMarker])

  // SCOPE CHART
  useEffect(() => {
    if (dataBarbukList) {
      function convertData(responseArray) {
        const result = {};
      
        responseArray.forEach(item => {
          const { jenis, sub_jenis, total_registrasi_barbuk } = item;
      
          if (!result[jenis]) {
            result[jenis] = { jenis };
          }
      
          result[jenis][sub_jenis] = total_registrasi_barbuk;
        });
      
        return Object.values(result);
      }

      setDataChartBarbuk(convertData(dataBarbukList))
      
    }
  }, [dataBarbukList])

  useEffect(() => {
    
    if (dataBarbuk) {
      const dataBarbukSummary = dataBarbuk?.summary_barbuk?.reduce((acc, obj, index) => {
        // Untuk object pertama, salin semua field non-number
        if (index === 0) {
          Object.keys(obj).forEach(key => {
            if (typeof obj[key] !== 'number') {
              acc[key] = obj[key];
            }
          });
        }
        
        // Tambahkan semua field number
        Object.keys(obj).forEach(key => {
          if (typeof obj[key] === 'number') {
            acc[key] = (acc[key] || 0) + obj[key];
          }
        });
        
        return acc;
      }, {}) ?? []

      const 
      {
        uang,
        logam_mulia,
        lahan,
        gedung,
        alat_mesin,
        surat_berharga,
        golongan
      } = dataBarbukSummary
      

      setBarangBuktiUang(
        [
          {
            category: "Tersimpan di Brankas",
            type: "Rupiah",
            value: uang?.barbuk_uang_tersimpan_di_brankas ?? 0,
            color: am5.color("#63a8b8"),
          },
          {
            category: "Tersimpan di Bank",
            type: "Rupiah",
            value: uang?.barbuk_uang_tersimpan_di_bank ?? 0,
            color: am5.color("#01b5b4"),
          },
        ]
      )
      setBarangBuktiLogamMulia(
        [
          {
            category: "Emas",
            type: "kg",
            value: logam_mulia?.barbuk_logammulia_emas ?? 0,
            color: am5.color("#b1dede"),
          },
          {
            category: "Perak",
            type: "kg",
            value: logam_mulia?.barbuk_logammulia_perak ?? 0,
            color: am5.color("#a49bca"),
          },
          {
            category: "Perunggu",
            type: "kg",
            value: logam_mulia?.barbuk_logammulia_perunggu ?? 0,
            color: am5.color("#1a84c8"),
          },
          {
            category: "Emas Putih",
            type: "kg",
            value: logam_mulia?.barbuk_logammulia_emasputih ?? 0,
            color: am5.color("#355798"),
          },
          {
            category: "Tembaga",
            type: "kg",
            value: logam_mulia?.barbuk_logammulia_tembaga ?? 0,
            color: am5.color("#63a8b8"),
          },
          {
            category: "Permata",
            type: "Kg",
            value: logam_mulia?.barbuk_logammulia_permata ?? 0,
            color: am5.color("#01b5b4"),
          },
        ]
      )
      setBarangBuktiTanah(
        [
          {
            category: "Lahan Perkebunan",
            type: "ha",
            value: lahan?.barbuk_lahan_perkebunan ?? 0,
            color: am5.color("#b94795"),
          },
          {
            category: "Lahan Pertanian/Sawah",
            type: "ha",
            value: lahan?.barbuk_lahan_sawah ?? 0,
            color: am5.color("#f16c9b"),
          },
          {
            category: "Lahan Kosong",
            type: "ha",
            value: lahan?.barbuk_lahan_lahankosong ?? 0,
            color: am5.color("#ef4f5b"),
          },
        ]
      )

      setBarangBuktiGedungDanBangunan(
        [
          {
            category: "Rumah",
            type: "unit",
            value: gedung?.barbuk_gedung_rumah ?? 0,
            color: am5.color("#355798"),
          },
          {
            category: "Ruko",
            type: "unit",
            value: gedung?.barbuk_gedung_ruko ?? 0,
            color: am5.color("#1a84c8"),
          },
          {
            category: "Apartemen",
            type: "unit",
            value: gedung?.barbuk_gedung_apartemen ?? 0,
            color: am5.color("#a49bca"),
          },
          {
            category: "Hotel",
            type: "unit",
            value: gedung?.barbuk_gedung_hotel ?? 0,
            color: am5.color("#b1dede"),
          },
          {
            category: "Lainnya",
            type: "unit",
            value: gedung?.barbuk_gedung_lainnya ?? 0,
            color: am5.color("#01b5b4"),
          },
        ]
      )
      setGolonganBarbuk(
        [
          {
            category: "Bergerak",
            type: "Bergerak",
            value: golongan?.barbuk_bergerak_total ?? 0,
            color: am5.color("#b1dede"),
          },
          {
            category: "Tidak Bergerak",
            type: "Tidak Bergerak",
            value: golongan?.barbuk_tidakbergerak_total ?? 0,
            color: am5.color("#01b5b4"),
          },
        ]
      )
      setBarangBuktiPeralatanMesin(
        [
          {
            category: "Kendaraan Bermotor",
            type: "Unit",
            value: alat_mesin?.barbuk_alatmesin_bermotor?? 0,
            color: am5.color("#b1dede"),
          },
          {
            category: "Kendaraan Tidak Bermotor",
            type: "Unit",
            value: alat_mesin?.barbuk_alatmesin_tidakbermotor ?? 0,
            color: am5.color("#1a84c8"),
          },
          {
            category: "Alat Berat",
            type: "Unit",
            value: alat_mesin?.barbuk_alatmesin_alatberat ?? 0,
            color: am5.color("#a49bca"),
          },
          {
            category: "Mesin",
            type: "Unit",
            value: alat_mesin?.barbuk_alatmesin_mesin ?? 0,
            color: am5.color("#01b5b4"),
          },
          {
            category: "Kapal/Pesawat",
            type: "Unit",
            value: alat_mesin?.barbuk_alatmesin_kapalpesawat ?? 0,
            color: am5.color("#63a8b8"),
          },
          {
            category: "Furniture",
            type: "Unit",
            value: alat_mesin?.barbuk_alatmesin_furniture ?? 0,
            color: am5.color("#355798"),
          },
          {
            category: "Peralatan Elektronik",
            type: "Unit",
            value: alat_mesin?.barbuk_alatmesin_elektronik ?? 0,
            color: am5.color("#f16c9b"),
          },
        ]
      )
      setBarangBuktiBernilaiEkonomis(
        [
          {
            category: "Uang",
            type: "Rupiah",
            value: uang?.barbuk_uang_total ?? 0,
            color: am5.color("#355798"),
          },
          {
            category: "Dokumen/Surat Berharga",
            type: "Rupiah",
            value: surat_berharga?.barbuk_suratberharga_total ?? 0,
            color: am5.color("#63a8b8"),
          },
          {
            category: "Logam Mulia",
            type: "Rupiah",
            value: logam_mulia?.barbuk_logammulia_total ?? 0,
            color: am5.color("#01b5b4"),
          },
          {
            category: "Tanah",
            type: "Rupiah",
            value: lahan?.barbuk_lahan_total ?? 0,
            color: am5.color("#a49bca"),
          },
          {
            category: "Gedung & Bangunan",
            type: "Rupiah",
            value: gedung?.barbuk_gedung_total ?? 0,
            color: am5.color("#1a84c8"),
          },
          {
            category: "Peralatan Mesin",
            type: "Rupiah",
            value: alat_mesin?.barbuk_alatmesin_total ?? 0,
            color: am5.color("#b1dede"),
          },
        ]
      )
      
    }
  }, [dataBarbuk])

  //  Yok Bisa Yok Debug Kode Di Bawah Ini
  useEffect(() => {
    if (profileData) {
      moment.locale();
      const satker = profileData?.satker?.nama_satker ?? "";
      const username = profileData?.user?.username ?? "";
      const isPolres = /polres/gi.test(satker);
      const isBareskrim = /bareskrim/gi.test(satker);
      if (isBareskrim) {
        if (['kabareskrim', 'kapusiknas'].includes(username)) {
          setNamaSatkerPoldaFilter('semua_polda');
          setNamaSatkerPoldaPayloadFilter('semua_polda');
        } else {
          setNamaSatkerPoldaFilter(satker);
          setNamaSatkerPoldaPayloadFilter(satker);
        }
        setNamaSatkerPolresFilter("satker_sendiri");
        setBulanFilter(moment().format("MMMM"));
        setTahunFilter(moment().format("YYYY"));
      } else {
        if (isPolres) {
          let namaPolda = profileData?.profile?.nama_satker_parent_polres ?? "";
          setNamaSatkerPoldaFilter(namaPolda);
          setNamaSatkerPoldaPayloadFilter(namaPolda);

          setBulanFilter(moment().format("MMMM"));
          setTahunFilter(moment().format("YYYY"));
        } else {
          setNamaSatkerPoldaFilter(satker);
          setNamaSatkerPoldaPayloadFilter(satker);
          setNamaSatkerPolresFilter("satker_sendiri");
          setBulanFilter(moment().format("MMMM"));
          setTahunFilter(moment().format("YYYY"));
        }
      }
    }
  }, [profileData]);

  useEffect(() => {
    if (profileData) {
      const satker = profileData?.satker?.nama_satker ?? "";
      const isPolres = /polres/gi.test(satker);
      const isBareskrim = /bareskrim/gi.test(satker);
      const isfilterPoldaAll = namaSatkerPoldaFilter === 'semua_polda'

      if (isBareskrim || isfilterPoldaAll) {
        const filterBareskrim = /bareskrim/gi.test(namaSatkerPoldaFilter);
        if (!namaSatkerPolresFilter) setNamaSatkerPolresPayloadFilter('satker_sendiri')
        if (isfilterPoldaAll && namaSatkerPolresPayloadFilter === 'satker_sendiri') {
          setNamaSatkerPoldaPayloadFilter(dataSatker.polda.map((o) => o.value).join(","))
        }
        if (isfilterPoldaAll && namaSatkerPolresPayloadFilter === 'semua') {
          setNamaSatkerPoldaPayloadFilter('semua_polda')
        } 
        if (namaSatkerPolresFilter === 'semua') {
          let payload = filterBareskrim  ? '' : dataSatker.polres.map((o) => o.value).join(",");
          setNamaSatkerPolresPayloadFilter(payload);
        }
        
      } else {
        if (isPolres) {
          let namaPolres = profileData?.profile?.nama_satker_tahti ?? "";
          setNamaSatkerPolresFilter(namaPolres);
          setNamaSatkerPolresPayloadFilter(namaPolres);
        } else {
          if (!namaSatkerPolresFilter) setNamaSatkerPolresPayloadFilter('satker_sendiri')
          if (namaSatkerPolresFilter === 'semua') {
            setNamaSatkerPolresPayloadFilter(
              dataSatker.polres.map((o) => o.value).join(",")
            )
          }
        }
      }
    }
  }, [profileData, dataSatker, namaSatkerPolresFilter, namaSatkerPoldaFilter])

  useEffect(() => {
    if (isDataBarbukFethcing && isDataBarbukListFethcing && isDataBarbukMapFethcing) {
      if (fetchedOnce) setFetchedOnce(false)
    }
    
  }, [
    isDataBarbukFethcing,
    isDataBarbukListFethcing,
    isDataBarbukMapFethcing,
    fetchedOnce
  ])

  return (
    <React.Fragment>
      <div className="mb-3">
        <h4 className="main-title mb-0">Dashboard Barang Bukti</h4>
      </div>
      <Row>
        <Col sm="12">
          <Card className="card border-0 shadow bg-white">
            <Card.Body>
              <Row className="g-2">
                <Col sm="12">
                  <Card.Text
                    as="label"
                    style={{ paddingLeft: 0 }}
                    className="fs-5 fw-bold mb-2"
                  >
                    Jumlah Barang Bukti Bareskrim Polri Dan Polda Jajaran
                    Bulan {bulanFilter} Tahun {tahunFilter}
                  </Card.Text>
                  <Row style={{ paddingLeft: 0 }}>
                    <Col sm="3">
                      <Select2
                        defaultValue={"Semua Kesatuan"}
                        options={optionPolda}
                        value={namaSatkerPoldaFilter}
                        name="nama_satker_polda"
                        placeholder="Semua Kesatuan"
                        required
                        isClearable
                        isSearchable
                        disabled={!isBareskrim || isPolres}
                        onChange={(e) => {
                          setNamaSatkerPoldaFilter(e.value)
                          setNamaSatkerPoldaPayloadFilter(e.value)
                          setNamaSatkerPolresFilter((prev) => ('satker_sendiri'))
                          setNamaSatkerPolresPayloadFilter((prev) => ('satker_sendiri'))
                        }}
                      />
                    </Col>
                    <Col sm="3">
                      <Select2
                        defaultValue={"Semua Kesatuan"}
                        options={optionPolres}
                        value={namaSatkerPolresFilter}
                        name="nama_satker_polres"
                        placeholder="Semua"
                        required
                        isClearable
                        isSearchable
                        disabled={isPolres}
                        onChange={(e) => {
                          setNamaSatkerPolresFilter(e.value)
                          setNamaSatkerPolresPayloadFilter(e.value)
                        }}
                      />
                    </Col>
                    <Col sm="2">
                      <Select
                        default="Select Bulan"
                        value={bulanFilter}
                        name="bulan"
                        options={months}
                        onChange={(e) => {
                          const val = e.target.value
                          setBulanFilter(val)
                        }}
                      />
                    </Col>
                    <Col sm="2">
                      <Select
                        default="Select Tahun"
                        value={tahunFilter}
                        name="tahun"
                        options={years}
                        onChange={(e) => {
                          const val = e.target.value
                          setTahunFilter(val)
                        }}
                      />
                    </Col>
                    <Col sm="2">
                      <Button
                        variant="danger"
                        size="small"
                        onClick={() => {
                          refetchDataBarbuk()
                          refetchDataBarbukList()
                          refetchDataBarbukMap()
                        }}
                      >
                        Filter
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col sm="12">
                  <div className="w-100">
                    <Card className="card bg-white">
                      <Card.Body>
                        <DashboardMap
                          mapRender={mapRendered}
                          markers={markersMap}
                          dataMapMarker={dataMapMarker}
                        />
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2 g-2">
                <Col sm="6">
                  <ChartPie
                    title="Barang Bukti Uang"
                    data={barangBuktiUang}
                  />
                </Col>
                <Col sm="6">
                  <ChartPie
                    title="Barang Bukti Logam Mulia"
                    data={barangBuktiLogamMulia}
                  />
                </Col>
                <Col sm="6">
                  <ChartPie
                    title="Barang Bukti Tanah"
                    data={barangBuktiTanah}
                  />
                </Col>
                <Col sm="6">
                  <ChartPie
                    title="Barang Bukti Gedung Dan Bangunan"
                    data={barangBuktiGedungDanBangunan}
                  />
                </Col>
                <Col sm="6">
                  <ChartPie
                    title="Barang Bukti Peralatan Mesin"
                    data={barangBuktiPeralatanMesin}
                  />
                </Col>
                <Col sm="6">
                  <ChartPie
                    title="Golongan Barbuk"
                    data={golonganBarbuk}
                  />
                </Col>
                {
                  dataChartBarbuk.length ? 
                  (
                    <Col sm="12">
                        <ChartDashBarbuk 
                          title="Daftar Barang Bukti per Jenis"
                          dataChart={dataChartBarbuk}
                        />
                    </Col>
                  ) :
                  (
                    <Card className="card-one">
                      <Card.Body className="d-flex flex-col justify-content-center items-center bg-secosndary p-5">
                      <span className="text-danger fw-bold">
                              Tidak ada data Barang Bukti pada bulan {bulanFilter}
                          </span>
                      </Card.Body>
                    </Card>
                  )
                }
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Index;
