import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Accordion,
  Row,
  Spinner,
  Table,
  Form,
  Alert,
  Modal,
  Ratio,
} from "react-bootstrap";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  DatePicker,
  Input,
  Select,
  Image,
  MyPagination,
  Select2,
} from "../../../../components/Components";
import {
  useGetDetailPenerimaanBarbuk,
  useGetTahtiList,
  useGetDetailTahti,
  usePostPenerimaanBarbuk,
  usePutPenerimaanBarbuk,
  useGetMasterBarbukList,
  usePenyidikList,
  useGetPenyidikList,
  useSatkerTahtiList,
  useGetLokasiBarbukListNew,
} from "../../../../utils/apis/query";
import moment from "moment";
import { debounce } from "lodash";
import State from "../../../../libraries/State";
import Files from "react-files";
import "../../../../../src/assets/css/custom.css";
import {
  encodeToHex,
  formatNumberWithCommas,
  formatString,
  pangkat,
  trimFileUrl,
  trimUrlPath,
} from "../../../../utils";
import { default as AlertModal } from "../../../../libraries/Alert";
import { oneHot } from "@tensorflow/tfjs";
import { jenisTindakPidanaTRX } from "../../../../utils";

function FormPenerimaanBarangBukti() {
  const onSuccess = () => {
    setTimeout(() => {
      navigate("/barbuk/penerimaan/list");
    }, 1500);
  };
  const state = State.getState();
  const userData = state.getItem("profileData", true);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [penyidikParam, setPenyidikParam] = useState({
    page: 1,
    size: 5,
    nama_penyidik: "",
  });
  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const [four, setFour] = useState(false);
  const [five, setFive] = useState(false);
  const [six, setSix] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [rincianBarbukModal, setRincianBarbukModal] = useState(false);
  // const [rincianBarbuk, setRincianBarbuk] = useState(null);
  const [rincianBarbuk, setRincianBarbuk] = useState({
    tipe: "",
    jenis: "",
    sub_jenis: "",
    unit: null,
    keterangan: "",
    no: null,
    no_label: "",
    baik: "",
    jumlah_penerimaan: 0,
    tidak_baik: "",
    photos: [],
    lokasi: "",
    alamat: "",
    keterangan_penyimpanan: "",
  });
  const [daftarBarbuk, setDaftarBarbuk] = useState([]);
  const [indexRincianBarbuk, setIndexRincianBarbuk] = useState(null);
  const [barbukModalShow, setBarbukModalShow] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [formErrorMesssage, setFormErrorMesssage] = useState([]);
  const [payloadCariTahanann, setPayloadCariTahanan] = useState({
    search: "",
  });
  const [namaTahanan, setNamaTahanan] = useState("");
  const [idTahanan, setIdTahanan] = useState("");
  const mutateAdd = usePostPenerimaanBarbuk(onSuccess);
  const mutateEdit = usePutPenerimaanBarbuk(onSuccess);
  const [penyidik, setPenyidik] = useState([]);
  const [isFormEditAble, setIsFormEditAble] = useState(true);
  const [isAddingRow, setIsAddingRow] = useState(false);
  const [isAddingRowPenyidik, setIsAddingRowPenyidik] = useState(false);
  const [saksiData, setSaksiData] = useState([]);
  const [penyidikData, setPenyidikData] = useState([]);
  const [penyidikPayload, setPenyidikPayload] = useState({
    nrp: "",
    nama_penyidik: "",
    nama_satker: "",
    pangkat: "",
    jabatan: "",
    no_hp: "",
  });
  const [barbukData, setBarbukData] = useState([]);
  const [saksi, setSaksi] = useState({
    nama: "",
    pekerjaan: "",
    alamat: "",
    keterangan: "",
    no_hp: "",
  });
  const [payloadDate, setPayloadDate] = useState({
    tanggal_penerimaan: "",
    tanggal_dokumen_no_laporan_polisi: "",
    tanggal_dokumen_no_ba_penerimaan_barbuk: "",
    tanggal_dokumen_no_surat_perintah_penyitaan: "",
    tanggal_dokumen_no_ba_penyitaan: "",
    tanggal_dokumen_no_surat_izin_penyitaan_bb_dari_ketua_pn: "",
    tanggal_dokumen_no_surat_penyitaan_dari_ketua_pn: "",
  });
  const [payloadForm, setPayloadForm] = useState({
    no_dokumen: "",
    lokasi_penerimaan: "",
    petugas_penerima: "",
    tanggal_penerimaan: "",
    uraian: "",
    no_tahanan: "",
    nama_tahanan: "",
    saksi_barbuk: "",
    barang_bukti: [],
    penyidik: [],
    no_laporan_polisi: "",
    tanggal_dokumen_no_laporan_polisi: "",
    arsip_nomor_laporan_polisi: null,
    no_ba_penerimaan_barbuk: "",
    tanggal_dokumen_no_ba_penerimaan_barbuk: "",
    arsip_nomor_ba_penerimaan_barbuk: null,
    no_surat_perintah_penyitaan: "",
    tanggal_dokumen_no_surat_perintah_penyitaan: "",
    arsip_nomor_surat_perintah_penyitaan: null,
    no_ba_penyitaan: "",
    tanggal_dokumen_no_ba_penyitaan: "",
    arsip_ba_penyitaan: null,
    no_surat_izin_penyitaan_bb_dari_ketua_pn: "",
    tanggal_dokumen_no_surat_izin_penyitaan_bb_dari_ketua_pn: "",
    arsip_nomor_surat_izin_penyitaan_bb: null,
    no_surat_penyitaan_dari_ketua_pn: "",
    tanggal_dokumen_no_surat_penyitaan_dari_ketua_pn: "",
    arsip_nomor_surat_penetapan_penyitaan: null,
    photos: [],
    status_dokumen: "",
    jenis_tindak_pidana: "",
    perkara: "",
  });
  const [payloadFormDataTahanan, setPayloadFormDataTahanan] = useState({
    no_tahanan: "",
    nama_tahanan: "",
    tempat_lahir: "",
    tanggal_lahir: "",
    umur: "",
    jenis_kelamin: "",
    agama: "",
    kebangsaan: "",
    asal_negara: "",
    pekerjaan: "",
    alamat: "",
    perkara: "",
    nama_rutan: "",
    alamat_rutan: "",
    rumus_wajah: "",
    rumus_sidikjari: "",
  });

  const {
    data: dataTahanan,
    isFetching: tahananFetching,
    isError: tahananError,
  } = useGetDetailTahti(idTahanan, { enabled: !!idTahanan });

  const {
    data: dataPenerimaanBarbuk,
    isFetching: penerimaanBarbukIsFetching,
    isError: penerimaanBarbukIsError,
    error: penerimaanBarbukError,
  } = useGetDetailPenerimaanBarbuk(id, { enabled: !!id });

  const { data: dataTahananList, isFetching: modalFetching } = useGetTahtiList(
    payloadCariTahanann,
    { enabled: !!namaTahanan }
  );

  const { data: masterListSatkers } = useSatkerTahtiList();
  const satkersFilter = masterListSatkers.map((e) => ({
    label: e.nama_satker,
    value: e.nama_satker,
  }));

  const {
    data: listPenyidik,
    pagination: paginationPenyidik,
    isFetching: isListPenyidikFetching,
  } = useGetPenyidikList(penyidikParam);
  const { data: lokasiPenyimpananList } = useGetLokasiBarbukListNew();
  const lokasiOptions = lokasiPenyimpananList.map((e) => ({
    label: e.lokasi,
    value: e.lokasi,
  }));
  const handleDebounce = useCallback(
    debounce((newPayload) => {
      setPayloadCariTahanan(newPayload);
    }, 500),
    []
  );

  const handleChange = (e) => {
    setIsFormDirty(true);
    const { name, value } = e.target;
    setPayloadForm({ ...payloadForm, [name]: value });
  };
  const addRincianBarbuk = () => {
    const inputs = document.querySelectorAll("#addBarbukForm");
    let allValid = true;
    let errorMsg = [];
    errorMsg = [];

    inputs.forEach((input) => {
      let name = formatString(input?.name ?? "-");
      if (input.type === "number" && parseInt(input.value) < 0) {
        errorMsg.push(name);
        allValid = false;
      }

      if (!input.reportValidity()) {
        allValid = false;
        errorMsg.push(name);
        input.reportValidity();
      }
    });
    if (!rincianBarbuk.photos.length) errorMsg.push("File Upload");
    if (allValid && !errorMsg.length) {
      if (indexRincianBarbuk !== null) {
        setDaftarBarbuk(
          daftarBarbuk.map((_, idx) => {
            if (indexRincianBarbuk === idx) {
              return rincianBarbuk;
            }
            return _;
          })
        );
        setRincianBarbukModal(false);
      } else {
        setDaftarBarbuk([...daftarBarbuk, rincianBarbuk]);
        setRincianBarbukModal(false);
      }
    } else {
      AlertModal.showMessage(
        "Periksa Mandatori Input",
        "warning",
        `${errorMsg.toString()}`
      );
    }
  };

  const handleChangeBarbuk = (e) => {
    const { name, value } = e.target;
    setRincianBarbuk((prevState) => {
      const updatedBarbuk = {
        ...prevState,
        [name]: value,
      };

      const jumlahPenerimaan =
        Number(updatedBarbuk.baik || 0) + Number(updatedBarbuk.tidak_baik || 0);

      return {
        ...updatedBarbuk,
        jumlah_penerimaan: jumlahPenerimaan,
      };
    });
  };

  // SAKSI
  const handleAddRow = () => {
    setIsAddingRow(true);
  };
  const closeRow = () => {
    setIsAddingRow(false);
  };

  const handleSaveRow = () => {
    const inputs = document.querySelectorAll("#addSaksiRow");
    let allValid = true;
    inputs.forEach((input) => {
      if (input.type === "number" && input.value.length > 13) {
        input.setCustomValidity(
          "Panjang nomor tidak boleh lebih dari 13 digit."
        );
        input.reportValidity();
        allValid = false;
      } else {
        input.setCustomValidity("");
      }

      if (!input.reportValidity()) {
        allValid = false;
      }
    });

    if (allValid) {
      setSaksiData([...saksiData, saksi]);
      setSaksi({
        nama: "",
        pekerjaan: "",
        alamat: "",
        keterangan: "",
        no_hp: "",
      });
      setIsAddingRow(false);
    }
  };
  const handleSaveRowPenyidik = () => {
    const inputs = document.querySelectorAll("#addPenyidikRow");
    let allValid = true;
    inputs.forEach((input) => {
      if (input.type === "number" && input.value.length > 13) {
        input.setCustomValidity(
          "Panjang nomor tidak boleh lebih dari 13 digit."
        );
        input.reportValidity();
        allValid = false;
      } else {
        input.setCustomValidity("");
      }

      if (!input.reportValidity()) {
        allValid = false;
      }
    });

    if (allValid) {
      setPenyidikData([...penyidikData, penyidikPayload]);
      setPenyidikPayload({
        nama: "",
        pekerjaan: "",
        alamat: "",
        keterangan: "",
        no_hp: "",
      });
      setIsAddingRowPenyidik(false);
    }
  };

  const handleNewRowChange = (e) => {
    const { name, value } = e.target;
    setSaksi({ ...saksi, [name]: value });
  };
  const handleChangeRowPenyidik = (e) => {
    const { name, value } = e.target;
    setPenyidikPayload({ ...penyidikPayload, [name]: value });
  };

  const handleDelete = (index) => {
    const newSaksiData = saksiData.filter((_, idx) => idx !== index);
    setSaksiData(newSaksiData);
  };
  // --------------------------

  // BARANG BUKTI
  const [searchBarbuk, setSearchBarbuk] = useState("");
  const [payloadDebounced, setPayloadDebounced] = useState({
    search: "",
    page: 1,
    size: 10,
  });
  const {
    data: masterBarbuk,
    pagination,
    isFetching: barbukFetching,
    isError: barbukIsError,
    error: barbukError,
  } = useGetMasterBarbukList(payloadDebounced);

  const handleDebounceBarbuk = useCallback(
    debounce((newPayload) => {
      setPayloadDebounced(newPayload);
    }, 300),
    []
  );

  const handlePaginationBarbuk = (page) =>
    setPayloadDebounced({ ...payloadDebounced, page: page });

  useEffect(() => {
    handleDebounceBarbuk({ ...payloadDebounced, search: searchBarbuk });
  }, [searchBarbuk, handleDebounceBarbuk, payloadDebounced]);

  useEffect(() => {
    if (!rincianBarbukModal) {
      setIndexRincianBarbuk(null);
      setRincianBarbuk({
        tipe: "",
        jenis: "",
        sub_jenis: "",
        unit: null,
        keterangan: "",
        no: null,
        no_label: "",
        baik: "",
        jumlah_penerimaan: 0,
        tidak_baik: "",
        photos: [],
        lokasi: "",
        alamat: "",
        keterangan_penyimpanan: "",
      });
    }
  }, [rincianBarbukModal]);

  // yang semanagt dong yyang semnagat dong

  const handleSubmit = (status) => {
    setFormErrorMesssage([]);
    const inputs = document.querySelectorAll("input");
    let allValid = true;
    let errFormMsg = [];

    inputs.forEach((input) => {
      if (input.id !== "addSaksiRow") {
        if (!input.reportValidity()) {
          if (input.type === "file") {
            errFormMsg.push("File Input Unggah Arsip / Lampiran");
          } else {
            errFormMsg.push(
              (input?.name ?? "") || "Input Mandatori / Kondisional"
            );
          }
          allValid = false;
        }
      }
    });
    setFormErrorMesssage(errFormMsg);

    if (allValid || status === "DRAFT") {
      setFormErrorMesssage([]);
      payloadForm.status_dokumen = status;
      payloadForm.saksi_barbuk = JSON.stringify(saksiData);
      payloadForm.barang_bukti = JSON.stringify(
        daftarBarbuk.map((_, idx) => {
          _.photos.map((photo) => {
            if (photo.foto_url) {
              photo.photo = trimFileUrl(photo?.foto_url ?? "-");
              delete photo.foto_url;
            }
            delete photo.tag;
            return photo;
          });
          return _;
        })
      );
      payloadForm.penyidik = JSON.stringify(penyidikData);

      let formDataPayload = new FormData();
      Object.keys(payloadForm).forEach((key) => {
        if (
          typeof payloadForm[key] === "string" ||
          typeof payloadForm[key] === "number"
        ) {
          formDataPayload.append(key, payloadForm[key]);
        }
      });
      payloadForm.remove_photo_ids?.forEach((id, idx) => {
        formDataPayload.append(`remove_photo_ids`, id);
      });
      payloadForm.arsip_nomor_laporan_polisi?.forEach((file, idx) => {
        formDataPayload.append(`arsip_nomor_laporan_polisi`, file);
      });
      payloadForm.arsip_nomor_ba_penerimaan_barbuk?.forEach((file, idx) => {
        formDataPayload.append(`arsip_nomor_ba_penerimaan_barbuk`, file);
      });
      payloadForm.arsip_nomor_surat_perintah_penyitaan?.forEach((file, idx) => {
        formDataPayload.append(`arsip_nomor_surat_perintah_penyitaan`, file);
      });
      payloadForm.arsip_ba_penyitaan?.forEach((file, idx) => {
        formDataPayload.append(`arsip_ba_penyitaan`, file);
      });
      payloadForm.arsip_nomor_surat_izin_penyitaan_bb?.forEach((file, idx) => {
        formDataPayload.append(`arsip_nomor_surat_izin_penyitaan_bb`, file);
      });
      payloadForm.arsip_nomor_surat_penetapan_penyitaan?.forEach(
        (file, idx) => {
          formDataPayload.append(`arsip_nomor_surat_penetapan_penyitaan`, file);
        }
      );
      payloadForm.photos?.forEach((ids, idx) => {
        formDataPayload.append(`photos`, ids);
      });

      if (isEdit) {
        mutateEdit.mutate({ id, formData: formDataPayload });
      } else {
        mutateAdd.mutate(formDataPayload);
      }
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (userData) {
      setPayloadForm({
        ...payloadForm,
        petugas_penerima: userData?.profile?.nama_lengkap,
        lokasi_penerimaan: userData?.satker?.nama_satker,
      });
    }
  }, [userData]);

  useEffect(() => {
    if (id) {
      setIsEdit(true);
      setIsFormEditAble(false);
    }
    if (dataTahanan) {
      const _ = dataTahanan;
      setPayloadFormDataTahanan({
        no_tahanan: _.no_tahanan,
        nama_tahanan: _.nama_tersangka,
        tempat_lahir: _.tempat_lahir,
        tanggal_lahir: _.tanggal_lahir,
        umur: _.umur,
        jenis_kelamin: _.jenis_kelamin,
        agama: _.agama,
        kebangsaan: _.kebangsaan,
        asal_negara: _.asal_negara,
        pekerjaan: _.pekerjaan,
        alamat: _.alamat,
        perkara: _.perkara,
        nama_rutan: _.nama_rutan,
        alamat_rutan: _.alamat_rutan,
        rumus_wajah: _.rumus_wajah,
        rumus_sidikjari: _.rumus_sidikjari,
      });
      setPayloadForm({
        ...payloadForm,
        nama_tahanan: _.nama_tersangka,
        no_tahanan: _.no_tahanan,
      });
      setPenyidik(_?.penyidik ?? []);
    }
    if (dataPenerimaanBarbuk) {
      setPayloadForm({
        ...payloadForm,
        no_dokumen: dataPenerimaanBarbuk.no_dokumen,
        tanggal_dokumen: dataPenerimaanBarbuk.tanggal_dokumen,
        lokasi_penerimaan: dataPenerimaanBarbuk.lokasi_penerimaan,
        petugas_penerima: dataPenerimaanBarbuk.petugas_penerima,
        tanggal_penerimaan: dataPenerimaanBarbuk.tanggal_penerimaan,
        tanggal_dokumen_no_laporan_polisi:
          dataPenerimaanBarbuk.tanggal_dokumen_no_laporan_polisi,
        tanggal_dokumen_no_ba_penerimaan_barbuk:
          dataPenerimaanBarbuk.tanggal_dokumen_no_ba_penerimaan_barbuk,
        tanggal_dokumen_no_surat_perintah_penyitaan:
          dataPenerimaanBarbuk.tanggal_dokumen_no_surat_perintah_penyitaan,
        tanggal_dokumen_no_ba_penyitaan:
          dataPenerimaanBarbuk.tanggal_dokumen_no_ba_penyitaan,
        tanggal_dokumen_no_surat_izin_penyitaan_bb_dari_ketua_pn:
          dataPenerimaanBarbuk.tanggal_dokumen_no_surat_izin_penyitaan_bb_dari_ketua_pn,
        tanggal_dokumen_no_surat_penyitaan_dari_ketua_pn:
          dataPenerimaanBarbuk.tanggal_dokumen_no_surat_penyitaan_dari_ketua_pn,
        no_laporan_polisi: dataPenerimaanBarbuk.no_laporan_polisi,
        no_ba_penerimaan_barbuk: dataPenerimaanBarbuk.no_ba_penerimaan_barbuk,
        no_surat_perintah_penyitaan:
          dataPenerimaanBarbuk.no_surat_perintah_penyitaan,
        no_ba_penyitaan: dataPenerimaanBarbuk.no_ba_penyitaan,
        no_surat_izin_penyitaan_bb_dari_ketua_pn:
          dataPenerimaanBarbuk.no_surat_izin_penyitaan_bb_dari_ketua_pn,
        no_surat_penyitaan_dari_ketua_pn:
          dataPenerimaanBarbuk.no_surat_penyitaan_dari_ketua_pn,
        uraian: dataPenerimaanBarbuk.uraian,
        saksi_barbuk: dataPenerimaanBarbuk.saksi_barbuk,
        nama_tahanan: dataPenerimaanBarbuk.nama_tahanan,
        no_tahanan: dataPenerimaanBarbuk.no_tahanan,
        jenis_tindak_pidana: dataPenerimaanBarbuk.tindak_pidana,
        perkara: dataPenerimaanBarbuk.perkara,
      });
      setDaftarBarbuk(() => {
        let bb = dataPenerimaanBarbuk.barang_bukti;
        bb.map((b) => {
          b.photos.map((p) => {
            delete p.created_date;
            // delete p.id_foto
            return p;
          });
          b.no_label = b.no_label_barbuk;
          delete b.barbuk_id;
          delete b.created_date;
          delete b.created_by;
          delete b.id;
          delete b.id_transaksi_barang_bukti;
          delete b.updated_by;
          delete b.updated_date;
          return b;
        });
        return bb;
      });
      setPenyidikData(
        dataPenerimaanBarbuk?.penyidik?.map((p, i) => {
          delete p.id_penyidik;
          return p;
        }) ?? []
      );
      setPayloadDate({
        ...payloadDate,
        tanggal_penerimaan: moment(
          dataPenerimaanBarbuk?.tanggal_penerimaan
        ).toDate(),
        tanggal_dokumen_no_laporan_polisi:
          dataPenerimaanBarbuk?.tanggal_dokumen_no_laporan_polisi
            ? moment(
                dataPenerimaanBarbuk?.tanggal_dokumen_no_laporan_polisi
              ).toDate()
            : null,
        tanggal_dokumen_no_ba_penerimaan_barbuk:
          dataPenerimaanBarbuk?.tanggal_dokumen_no_ba_penerimaan_barbuk
            ? moment(
                dataPenerimaanBarbuk?.tanggal_dokumen_no_ba_penerimaan_barbuk
              ).toDate()
            : null,
        tanggal_dokumen_no_surat_perintah_penyitaan:
          dataPenerimaanBarbuk?.tanggal_dokumen_no_surat_perintah_penyitaan
            ? moment(
                dataPenerimaanBarbuk?.tanggal_dokumen_no_surat_perintah_penyitaan
              ).toDate()
            : null,
        tanggal_dokumen_no_ba_penyitaan:
          dataPenerimaanBarbuk?.tanggal_dokumen_no_ba_penyitaan
            ? moment(
                dataPenerimaanBarbuk?.tanggal_dokumen_no_ba_penyitaan
              ).toDate()
            : null,
        tanggal_dokumen_no_surat_izin_penyitaan_bb_dari_ketua_pn:
          dataPenerimaanBarbuk?.tanggal_dokumen_no_surat_izin_penyitaan_bb_dari_ketua_pn
            ? moment(
                dataPenerimaanBarbuk?.tanggal_dokumen_no_surat_izin_penyitaan_bb_dari_ketua_pn
              ).toDate()
            : null,
        tanggal_dokumen_no_surat_penyitaan_dari_ketua_pn:
          dataPenerimaanBarbuk?.tanggal_dokumen_no_surat_penyitaan_dari_ketua_pn
            ? moment(
                dataPenerimaanBarbuk?.tanggal_dokumen_no_surat_penyitaan_dari_ketua_pn
              ).toDate()
            : null,
      });
      // if (dataPenerimaanBarbuk.penerimaan_barbuk_files) {
      //   // const arsipArray = dataPenerimaanBarbuk.penerimaan_barbuk_files
      //   //   .filter((file) => file.arsip)
      //   //   .map((file) => file.arsip);

      //   const lampiranArray = dataPenerimaanBarbuk.penerimaan_barbuk_files
      //     .filter((file) => file.lampiran)
      //     .map((file) => file.lampiran);

      //   setPayloadForm((prevBarbuk) => ({
      //     ...prevBarbuk,
      //     // arsip: arsipArray,
      //     lampiran: lampiranArray,
      //   }));
      // }
      setIdTahanan(dataPenerimaanBarbuk.id_tahanan);
      setSaksiData(
        dataPenerimaanBarbuk?.saksi?.map((s, i) => {
          delete s.id_saksi;
          return s;
        }) ?? []
      );
      setBarbukData(dataPenerimaanBarbuk.barang_bukti ?? []);

      if (dataPenerimaanBarbuk.status_dokumen === "FINAL") {
      }
    }
  }, [dataTahanan, dataPenerimaanBarbuk]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormDirty]);

  useEffect(() => {
    handleDebounce({ ...payloadCariTahanann, search: namaTahanan });
  }, [namaTahanan, handleDebounce, payloadCariTahanann]);

  useEffect(() => {
    if (payloadForm) {
      const one =
        payloadForm.no_laporan_polisi &&
        payloadForm.tanggal_dokumen_no_laporan_polisi &&
        (isEdit
          ? true
          : (payloadForm?.arsip_nomor_laporan_polisi?.length ?? 0) > 0);
      setOne(one);
      const two =
        payloadForm.no_ba_penerimaan_barbuk &&
        payloadForm.tanggal_dokumen_no_ba_penerimaan_barbuk &&
        (isEdit
          ? true
          : (payloadForm?.arsip_nomor_ba_penerimaan_barbuk?.length ?? 0) > 0);
      setTwo(two);
      const three =
        payloadForm.no_surat_perintah_penyitaan &&
        payloadForm.tanggal_dokumen_no_surat_perintah_penyitaan &&
        (isEdit
          ? true
          : (payloadForm?.arsip_nomor_surat_perintah_penyitaan?.length ?? 0) >
            0);
      setThree(three);
      const four =
        payloadForm.no_ba_penyitaan &&
        payloadForm.tanggal_dokumen_no_ba_penyitaan &&
        (isEdit ? true : (payloadForm?.arsip_ba_penyitaan?.length ?? 0) > 0);
      setFour(four);
      const five =
        payloadForm.no_surat_izin_penyitaan_bb_dari_ketua_pn &&
        payloadForm.tanggal_dokumen_no_surat_izin_penyitaan_bb_dari_ketua_pn &&
        (isEdit
          ? true
          : (payloadForm?.arsip_nomor_surat_izin_penyitaan_bb?.length ?? 0) >
            0);
      setFive(five);
      const six =
        payloadForm.no_surat_penyitaan_dari_ketua_pn &&
        payloadForm.tanggal_dokumen_no_surat_penyitaan_dari_ketua_pn &&
        (isEdit
          ? true
          : (payloadForm?.arsip_nomor_surat_penetapan_penyitaan?.length ?? 0) >
            0);
      setSix(six);
    }
  }, [payloadForm]);
  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between m-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/barbuk/penerimaan/list">Barang Bukti</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/barbuk/penerimaan/list">
                    Form Penerimaan Barang Bukti
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active fw-bold"
                  aria-current="page"
                >
                  {isEdit
                    ? "Detail Penerimaan Barang Bukti"
                    : "Form Penerimaan Barang Bukti"}
                </li>
              </ol>
            </div>
            {isEdit && (
              <Button
                className="d-flex align-items-center gap-2"
                variant="primary"
                size="normal"
                onClick={() => setIsFormEditAble(!isFormEditAble)}
              >
                <i className="ri-edit-2-line"></i>
                {isFormEditAble ? "Batalkan Edit" : "Edit Form"}
              </Button>
            )}
          </div>
        </Card.Body>
      </Card>
      <Card className="bg-white">
        <Card.Body>
          <Row className="g-2">
            {
              <Col sm="12">
                {tahananFetching && !tahananError && (
                  <div className="d-flex justify-content-center">
                    <Spinner variant="primary" size="small" />
                  </div>
                )}
                {!tahananFetching && tahananError && (
                  <div className="d-flex justify-content-center">
                    <span className="text-danger text-center">
                      <b>Oops Error</b> please refresh the page / re-login
                    </span>
                  </div>
                )}
                {mutateAdd.isError && (
                  <Alert variant="danger">
                    <ul>
                      {mutateAdd?.error?.response?.data?.detail?.map(
                        (errEdit, idx) => (
                          <li key={idx}>
                            <b>{errEdit.loc[1]} </b>
                            {errEdit.msg}
                          </li>
                        )
                      ) ?? (
                        <li>
                          <b>Error </b>{" "}
                          {mutateAdd?.error?.response?.data?.message ??
                            " Proses Submit Gagal"}
                        </li>
                      )}
                    </ul>
                  </Alert>
                )}
                {formErrorMesssage.length > 0 && (
                  <Alert variant="danger">
                    <ul>
                      {formErrorMesssage.map((err, idx) => (
                        <li key={idx}>
                          <b>Periksa Form {"=>"} </b>
                          {err}
                        </li>
                      ))}
                    </ul>
                  </Alert>
                )}
                {mutateAdd.isSuccess && (
                  <Alert variant="success">{mutateAdd.data.data.message}</Alert>
                )}
              </Col>
            }
            {
              <Col sm="12">
                {mutateEdit.isError && (
                  <Alert variant="danger">
                    <ul>
                      {mutateEdit?.error?.response?.data?.detail?.map(
                        (errEdit, idx) => (
                          <li key={idx}>
                            <b>{errEdit.loc[1]} </b>
                            {errEdit.msg}
                          </li>
                        )
                      ) ?? (
                        <li>
                          <b>Error </b>{" "}
                          {mutateEdit?.error?.response?.data?.message ??
                            " Proses Edit Gagal"}
                        </li>
                      )}
                    </ul>
                  </Alert>
                )}
                {mutateEdit.isSuccess && (
                  <Alert variant="success">
                    {mutateEdit.data.data.message}
                  </Alert>
                )}
              </Col>
            }
            <Col sm="12">
              <Accordion
                defaultActiveKey={["0", "1", "2", "3", "4", "5"]}
                flush
                alwaysOpen
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="custom-header">
                    Informasi Dokumen Barang Bukti
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row className="g-2">
                          <Col sm="12">
                            <Row className="g-2 mt-1">
                              <Col
                                xs="auto"
                                className="d-flex align-items-center me-3"
                              >
                                1
                              </Col>
                              <Col sm="5">
                                <Input
                                  label="Nomor Laporan Polisi"
                                  name="no_laporan_polisi"
                                  value={payloadForm.no_laporan_polisi}
                                  onChange={handleChange}
                                  required
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                              <Col sm="2">
                                <DatePicker
                                  label="Tanggal Dokumen"
                                  name="tanggal_dokumen_no_laporan_polisi"
                                  timeFormat="HH:mm:ss"
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                  onChange={(date) => {
                                    setPayloadDate({
                                      ...payloadDate,
                                      tanggal_dokumen_no_laporan_polisi: date,
                                    });
                                    setPayloadForm({
                                      ...payloadForm,
                                      tanggal_dokumen_no_laporan_polisi:
                                        moment(date).format("YYYY-MM-DD"),
                                    });
                                  }}
                                  selected={
                                    payloadDate.tanggal_dokumen_no_laporan_polisi
                                  }
                                  required
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                              <Col sm="4" className="">
                                <Input
                                  label="Unggah Arsip"
                                  disabled={!isFormEditAble}
                                  type="file"
                                  onChange={(e) => {
                                    const files = e.target.files[0];
                                    setPayloadForm({
                                      ...payloadForm,
                                      arsip_nomor_laporan_polisi: [files],
                                    });
                                  }}
                                  accepts={[".pdf"]}
                                  required={!isEdit}
                                />
                                {dataPenerimaanBarbuk
                                  ?.penerimaan_barbuk_files[0]
                                  ?.arsip_nomor_laporan_polisi ? (
                                  (() => {
                                    const { arsip_nomor_laporan_polisi } =
                                      dataPenerimaanBarbuk
                                        .penerimaan_barbuk_files[0];
                                    const { p: path, k: kategori } =
                                      trimUrlPath(arsip_nomor_laporan_polisi);
                                    const file = trimFileUrl(
                                      arsip_nomor_laporan_polisi
                                    );

                                    return (
                                      <Link
                                        target="_blank"
                                        to={`/arsip/view/${file}?kategori=${kategori}&p=${encodeToHex(
                                          path
                                        )}`}
                                        className="text-primary fs-6 fw-medium d-flex align-items-center gap-2"
                                      >
                                        <i className="ri-link"></i>
                                        File Yang Sudah Terupload
                                      </Link>
                                    );
                                  })()
                                ) : (
                                  <Col className="mt-4"> </Col>
                                )}
                              </Col>
                              <Col
                                xs="auto"
                                className="d-flex align-items-center ms-3"
                              >
                                {one ? (
                                  <span
                                    style={{
                                      color: "green",
                                      fontSize: "1.5rem",
                                    }}
                                  >
                                    <i className="ri-check-line"></i>
                                  </span>
                                ) : (
                                  <span
                                    style={{ color: "red", fontSize: "1.5rem" }}
                                  >
                                    <i className="ri-close-large-line"></i>
                                  </span>
                                )}
                              </Col>
                            </Row>
                            <Row className="g-2 mt-1">
                              <Col
                                xs="auto"
                                className="d-flex align-items-center me-3"
                              >
                                2
                              </Col>
                              <Col sm="5">
                                <Input
                                  label="Nomor BA Penerimaan Barang Bukti"
                                  name="no_ba_penerimaan_barbuk"
                                  value={payloadForm.no_ba_penerimaan_barbuk}
                                  onChange={handleChange}
                                  required
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                              <Col sm="2">
                                <DatePicker
                                  label="Tanggal Dokumen"
                                  name="tanggal_dokumen_no_ba_penerimaan_barbuk"
                                  timeFormat="HH:mm:ss"
                                  maxDate={new Date()}
                                  dateFormat="dd/MM/yyyy"
                                  onChange={(date) => {
                                    setPayloadDate({
                                      ...payloadDate,
                                      tanggal_dokumen_no_ba_penerimaan_barbuk:
                                        date,
                                    });
                                    setPayloadForm({
                                      ...payloadForm,
                                      tanggal_dokumen_no_ba_penerimaan_barbuk:
                                        moment(date).format("YYYY-MM-DD"),
                                    });
                                  }}
                                  selected={
                                    payloadDate.tanggal_dokumen_no_ba_penerimaan_barbuk
                                  }
                                  required
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                              <Col sm="4" className="">
                                <Input
                                  label="Unggah Arsip"
                                  disabled={!isFormEditAble}
                                  type="file"
                                  onChange={(e) => {
                                    const files = e.target.files[0];
                                    setPayloadForm({
                                      ...payloadForm,
                                      arsip_nomor_ba_penerimaan_barbuk: [files],
                                    });
                                  }}
                                  accepts={[".pdf"]}
                                  required={!isEdit}
                                />
                                {dataPenerimaanBarbuk
                                  ?.penerimaan_barbuk_files[0]
                                  ?.arsip_nomor_ba_penerimaan_barbuk ? (
                                  (() => {
                                    const { arsip_nomor_ba_penerimaan_barbuk } =
                                      dataPenerimaanBarbuk
                                        .penerimaan_barbuk_files[0];
                                    const { p: path, k: kategori } =
                                      trimUrlPath(
                                        arsip_nomor_ba_penerimaan_barbuk
                                      );
                                    const file = trimFileUrl(
                                      arsip_nomor_ba_penerimaan_barbuk
                                    );

                                    return (
                                      <Link
                                        target="_blank"
                                        to={`/arsip/view/${file}?kategori=${kategori}&p=${encodeToHex(
                                          path
                                        )}`}
                                        className="text-primary fs-6 fw-medium d-flex align-items-center gap-2"
                                      >
                                        <i className="ri-link"></i>
                                        File Yang Sudah Terupload
                                      </Link>
                                    );
                                  })()
                                ) : (
                                  <Col className="mt-4"> </Col>
                                )}
                              </Col>
                              <Col
                                xs="auto"
                                className="d-flex align-items-center ms-3"
                              >
                                {two ? (
                                  <span
                                    style={{
                                      color: "green",
                                      fontSize: "1.5rem",
                                    }}
                                  >
                                    <i className="ri-check-line"></i>
                                  </span>
                                ) : (
                                  <span
                                    style={{ color: "red", fontSize: "1.5rem" }}
                                  >
                                    <i className="ri-close-large-line"></i>
                                  </span>
                                )}
                              </Col>
                            </Row>
                            <Row className="g-2 mt-1">
                              <Col
                                xs="auto"
                                className="d-flex align-items-center me-3"
                              >
                                3
                              </Col>
                              <Col sm="5">
                                <Input
                                  label="Nomor Surat Perintah Penyitaan"
                                  name="no_surat_perintah_penyitaan"
                                  value={
                                    payloadForm.no_surat_perintah_penyitaan
                                  }
                                  onChange={handleChange}
                                  required
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                              <Col sm="2">
                                <DatePicker
                                  label="Tanggal Dokumen"
                                  name="tanggal_dokumen_no_surat_perintah_penyitaan"
                                  timeFormat="HH:mm:ss"
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                  onChange={(date) => {
                                    setPayloadDate({
                                      ...payloadDate,
                                      tanggal_dokumen_no_surat_perintah_penyitaan:
                                        date,
                                    });
                                    setPayloadForm({
                                      ...payloadForm,
                                      tanggal_dokumen_no_surat_perintah_penyitaan:
                                        moment(date).format("YYYY-MM-DD"),
                                    });
                                  }}
                                  selected={
                                    payloadDate.tanggal_dokumen_no_surat_perintah_penyitaan
                                  }
                                  required
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                              <Col sm="4" className="">
                                <Input
                                  label="Unggah Arsip"
                                  disabled={!isFormEditAble}
                                  type="file"
                                  onChange={(e) => {
                                    const files = e.target.files[0];
                                    setPayloadForm({
                                      ...payloadForm,
                                      arsip_nomor_surat_perintah_penyitaan: [
                                        files,
                                      ],
                                    });
                                  }}
                                  accepts={[".pdf"]}
                                  required={!isEdit}
                                />
                                {dataPenerimaanBarbuk
                                  ?.penerimaan_barbuk_files[0]
                                  ?.arsip_nomor_surat_perintah_penyitaan ? (
                                  (() => {
                                    const {
                                      arsip_nomor_surat_perintah_penyitaan,
                                    } =
                                      dataPenerimaanBarbuk
                                        .penerimaan_barbuk_files[0];
                                    const { p: path, k: kategori } =
                                      trimUrlPath(
                                        arsip_nomor_surat_perintah_penyitaan
                                      );
                                    const file = trimFileUrl(
                                      arsip_nomor_surat_perintah_penyitaan
                                    );

                                    return (
                                      <Link
                                        target="_blank"
                                        to={`/arsip/view/${file}?kategori=${kategori}&p=${encodeToHex(
                                          path
                                        )}`}
                                        className="text-primary fs-6 fw-medium d-flex align-items-center gap-2"
                                      >
                                        <i className="ri-link"></i>
                                        File Yang Sudah Terupload
                                      </Link>
                                    );
                                  })()
                                ) : (
                                  <Col className="mt-4"> </Col>
                                )}
                              </Col>
                              <Col
                                xs="auto"
                                className="d-flex align-items-center ms-3"
                              >
                                {three ? (
                                  <span
                                    style={{
                                      color: "green",
                                      fontSize: "1.5rem",
                                    }}
                                  >
                                    <i className="ri-check-line"></i>
                                  </span>
                                ) : (
                                  <span
                                    style={{ color: "red", fontSize: "1.5rem" }}
                                  >
                                    <i className="ri-close-large-line"></i>
                                  </span>
                                )}
                              </Col>
                            </Row>
                            <Row className="g-2 mt-1">
                              <Col
                                xs="auto"
                                className="d-flex align-items-center me-3"
                              >
                                4
                              </Col>
                              <Col sm="5">
                                <Input
                                  label="Nomor BA Penyitaan"
                                  name="no_ba_penyitaan"
                                  value={payloadForm.no_ba_penyitaan}
                                  onChange={handleChange}
                                  required
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                              <Col sm="2">
                                <DatePicker
                                  label="Tanggal Dokumen"
                                  name="tanggal_dokumen_no_ba_penyitaan"
                                  timeFormat="HH:mm:ss"
                                  maxDate={new Date()}
                                  dateFormat="dd/MM/yyyy"
                                  onChange={(date) => {
                                    setPayloadDate({
                                      ...payloadDate,
                                      tanggal_dokumen_no_ba_penyitaan: date,
                                    });
                                    setPayloadForm({
                                      ...payloadForm,
                                      tanggal_dokumen_no_ba_penyitaan:
                                        moment(date).format("YYYY-MM-DD"),
                                    });
                                  }}
                                  selected={
                                    payloadDate.tanggal_dokumen_no_ba_penyitaan
                                  }
                                  required
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                              <Col sm="4" className="">
                                <Input
                                  label="Unggah Arsip"
                                  disabled={!isFormEditAble}
                                  type="file"
                                  onChange={(e) => {
                                    const files = e.target.files[0];
                                    setPayloadForm({
                                      ...payloadForm,
                                      arsip_ba_penyitaan: [files],
                                    });
                                  }}
                                  accepts={[".pdf"]}
                                  required={!isEdit}
                                />
                                {dataPenerimaanBarbuk
                                  ?.penerimaan_barbuk_files[0]
                                  ?.arsip_ba_penyitaan ? (
                                  (() => {
                                    const { arsip_ba_penyitaan } =
                                      dataPenerimaanBarbuk
                                        .penerimaan_barbuk_files[0];
                                    const { p: path, k: kategori } =
                                      trimUrlPath(arsip_ba_penyitaan);
                                    const file =
                                      trimFileUrl(arsip_ba_penyitaan);

                                    return (
                                      <Link
                                        target="_blank"
                                        to={`/arsip/view/${file}?kategori=${kategori}&p=${encodeToHex(
                                          path
                                        )}`}
                                        className="text-primary fs-6 fw-medium d-flex align-items-center gap-2"
                                      >
                                        <i className="ri-link"></i>
                                        File Yang Sudah Terupload
                                      </Link>
                                    );
                                  })()
                                ) : (
                                  <Col className="mt-4"> </Col>
                                )}
                              </Col>
                              <Col
                                xs="auto"
                                className="d-flex align-items-center ms-3"
                              >
                                {four ? (
                                  <span
                                    style={{
                                      color: "green",
                                      fontSize: "1.5rem",
                                    }}
                                  >
                                    <i className="ri-check-line"></i>
                                  </span>
                                ) : (
                                  <span
                                    style={{ color: "red", fontSize: "1.5rem" }}
                                  >
                                    <i className="ri-close-large-line"></i>
                                  </span>
                                )}
                              </Col>
                            </Row>
                            <Row className="g-2 mt-1">
                              <Col
                                xs="auto"
                                className="d-flex align-items-center me-3"
                              >
                                5
                              </Col>
                              <Col sm="5">
                                <Input
                                  label="Nomor Surat Izin/Persetujuan Penyitaan BB dari Ketua PN"
                                  name="no_surat_izin_penyitaan_bb_dari_ketua_pn"
                                  value={
                                    payloadForm.no_surat_izin_penyitaan_bb_dari_ketua_pn
                                  }
                                  onChange={handleChange}
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                              <Col sm="2">
                                <DatePicker
                                  label="Tanggal Dokumen"
                                  name="tanggal_dokumen_no_surat_izin_penyitaan_bb_dari_ketua_pn"
                                  timeFormat="HH:mm:ss"
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                  onChange={(date) => {
                                    setPayloadDate({
                                      ...payloadDate,
                                      tanggal_dokumen_no_surat_izin_penyitaan_bb_dari_ketua_pn:
                                        date,
                                    });
                                    setPayloadForm({
                                      ...payloadForm,
                                      tanggal_dokumen_no_surat_izin_penyitaan_bb_dari_ketua_pn:
                                        moment(date).format("YYYY-MM-DD"),
                                    });
                                  }}
                                  selected={
                                    payloadDate.tanggal_dokumen_no_surat_izin_penyitaan_bb_dari_ketua_pn
                                  }
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                              <Col sm="4" className="">
                                <Input
                                  label="Unggah Arsip"
                                  disabled={!isFormEditAble}
                                  type="file"
                                  onChange={(e) => {
                                    const files = e.target.files[0];
                                    setPayloadForm({
                                      ...payloadForm,
                                      arsip_nomor_surat_izin_penyitaan_bb: [
                                        files,
                                      ],
                                    });
                                  }}
                                  accepts={[".pdf"]}
                                />
                                {dataPenerimaanBarbuk
                                  ?.penerimaan_barbuk_files[0]
                                  ?.arsip_nomor_surat_izin_penyitaan_bb ? (
                                  (() => {
                                    const {
                                      arsip_nomor_surat_izin_penyitaan_bb,
                                    } =
                                      dataPenerimaanBarbuk
                                        .penerimaan_barbuk_files[0];
                                    const { p: path, k: kategori } =
                                      trimUrlPath(
                                        arsip_nomor_surat_izin_penyitaan_bb
                                      );
                                    const file = trimFileUrl(
                                      arsip_nomor_surat_izin_penyitaan_bb
                                    );

                                    return (
                                      <Link
                                        target="_blank"
                                        to={`/arsip/view/${file}?kategori=${kategori}&p=${encodeToHex(
                                          path
                                        )}`}
                                        className="text-primary fs-6 fw-medium d-flex align-items-center gap-2"
                                      >
                                        <i className="ri-link"></i>
                                        File Yang Sudah Terupload
                                      </Link>
                                    );
                                  })()
                                ) : (
                                  <Col className="mt-4"> </Col>
                                )}
                              </Col>
                              <Col
                                xs="auto"
                                className="d-flex align-items-center ms-3"
                              >
                                {five ? (
                                  <span
                                    style={{
                                      color: "green",
                                      fontSize: "1.5rem",
                                    }}
                                  >
                                    <i className="ri-check-line"></i>
                                  </span>
                                ) : (
                                  <span
                                    style={{ color: "red", fontSize: "1.5rem" }}
                                  >
                                    <i className="ri-close-large-line"></i>
                                  </span>
                                )}
                              </Col>
                            </Row>
                            <Row className="g-2 mt-1">
                              <Col
                                xs="auto"
                                className="d-flex align-items-center me-3"
                              >
                                6
                              </Col>
                              <Col sm="5">
                                <Input
                                  label="Nomor Surat Penetapan Penyitaan dari Ketua PN"
                                  name="no_surat_penyitaan_dari_ketua_pn"
                                  value={
                                    payloadForm.no_surat_penyitaan_dari_ketua_pn
                                  }
                                  onChange={handleChange}
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                              <Col sm="2">
                                <DatePicker
                                  label="Tanggal Dokumen"
                                  name="tanggal_dokumen_no_surat_penyitaan_dari_ketua_pn"
                                  timeFormat="HH:mm:ss"
                                  maxDate={new Date()}
                                  dateFormat="dd/MM/yyyy"
                                  onChange={(date) => {
                                    setPayloadDate({
                                      ...payloadDate,
                                      tanggal_dokumen_no_surat_penyitaan_dari_ketua_pn:
                                        date,
                                    });
                                    setPayloadForm({
                                      ...payloadForm,
                                      tanggal_dokumen_no_surat_penyitaan_dari_ketua_pn:
                                        moment(date).format("YYYY-MM-DD"),
                                    });
                                  }}
                                  selected={
                                    payloadDate.tanggal_dokumen_no_surat_penyitaan_dari_ketua_pn
                                  }
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                              <Col sm="4" className="">
                                <Input
                                  label="Unggah Arsip"
                                  disabled={!isFormEditAble}
                                  type="file"
                                  onChange={(e) => {
                                    const files = e.target.files[0];
                                    setPayloadForm({
                                      ...payloadForm,
                                      arsip_nomor_surat_penetapan_penyitaan: [
                                        files,
                                      ],
                                    });
                                  }}
                                  accepts={[".pdf"]}
                                />
                                {dataPenerimaanBarbuk
                                  ?.penerimaan_barbuk_files[0]
                                  ?.arsip_nomor_surat_penetapan_penyitaan ? (
                                  (() => {
                                    const {
                                      arsip_nomor_surat_penetapan_penyitaan,
                                    } =
                                      dataPenerimaanBarbuk
                                        .penerimaan_barbuk_files[0];
                                    const { p: path, k: kategori } =
                                      trimUrlPath(
                                        arsip_nomor_surat_penetapan_penyitaan
                                      );
                                    const file = trimFileUrl(
                                      arsip_nomor_surat_penetapan_penyitaan
                                    );

                                    return (
                                      <Link
                                        target="_blank"
                                        to={`/arsip/view/${file}?kategori=${kategori}&p=${encodeToHex(
                                          path
                                        )}`}
                                        className="text-primary fs-6 fw-medium d-flex align-items-center gap-2"
                                      >
                                        <i className="ri-link"></i>
                                        File Yang Sudah Terupload
                                      </Link>
                                    );
                                  })()
                                ) : (
                                  <Col className="mt-4"> </Col>
                                )}
                              </Col>
                              <Col
                                xs="auto"
                                className="d-flex align-items-center ms-3"
                              >
                                {six ? (
                                  <span
                                    style={{
                                      color: "green",
                                      fontSize: "1.5rem",
                                    }}
                                  >
                                    <i className="ri-check-line"></i>
                                  </span>
                                ) : (
                                  <span
                                    style={{ color: "red", fontSize: "1.5rem" }}
                                  >
                                    <i className="ri-close-large-line"></i>
                                  </span>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="custom-header">
                    Informasi Penerimaan Barang Bukti
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row className="g-2 mt-2">
                          <Col sm="6">
                            <Input
                              label="Perkara"
                              name="perkara"
                              value={payloadForm.perkara}
                              onChange={handleChange}
                              required
                              disabled={!isFormEditAble}
                            />
                          </Col>
                          <Col sm="6">
                            <Select
                              label="Tindak Pidana"
                              name="jenis_tindak_pidana"
                              value={payloadForm.jenis_tindak_pidana}
                              onChange={handleChange}
                              required
                              disabled={!isFormEditAble}
                              default="-"
                              options={jenisTindakPidanaTRX}
                            />
                          </Col>
                          <Col sm="4">
                            <Input
                              label="Petugas TAHTI (Terima)"
                              name="petugas_penerima"
                              value={payloadForm.petugas_penerima}
                              required
                              disabled
                            />
                          </Col>
                          <Col sm="4">
                            <Input
                              label="Lokasi Penerimaan"
                              name="lokasi_penerimaan"
                              value={payloadForm.lokasi_penerimaan}
                              required
                              disabled
                            />
                          </Col>
                          <Col sm="4">
                            <DatePicker
                              label="Tanggal Penerimaan"
                              name="tanggal_penerimaan"
                              maxDate={new Date()}
                              timeFormat="HH:mm:ss"
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => {
                                setPayloadDate({
                                  ...payloadDate,
                                  tanggal_penerimaan: date,
                                });
                                setPayloadForm({
                                  ...payloadForm,
                                  tanggal_penerimaan:
                                    moment(date).format("YYYY-MM-DD"),
                                });
                              }}
                              selected={payloadDate.tanggal_penerimaan}
                              required
                              disabled={!isFormEditAble}
                            />
                          </Col>
                          <Col sm="12">
                            <Card className="card bg-white">
                              <Card.Body>
                                <Row className="g-1 mb-2 d-flex align-items-center">
                                  <Col sm="12" md="5">
                                    <h6>Saksi Penerimaan Barang Bukti</h6>
                                  </Col>
                                  <Col sm="12" md="7">
                                    <Link className="d-flex align-items-center justify-content-end">
                                      <Button
                                        className="btn btn-primary"
                                        onClick={handleAddRow}
                                        disabled={!isFormEditAble}
                                      >
                                        Tambah Saksi
                                      </Button>
                                    </Link>
                                  </Col>
                                </Row>
                                <Table className="table dems-table">
                                  <thead>
                                    <tr>
                                      <th>No</th>
                                      <th>Nama</th>
                                      <th>Pekerjaan</th>
                                      <th>Alamat</th>
                                      <th>Keterangan</th>
                                      <th>No. HP</th>
                                      <th>Aksi</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {saksiData.length
                                      ? saksiData.map((data, index) => (
                                          <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td style={{ maxWidth: 230 }}>
                                              {data.nama}
                                            </td>
                                            <td style={{ maxWidth: 230 }}>
                                              {data.pekerjaan}
                                            </td>
                                            <td style={{ maxWidth: 230 }}>
                                              {data.alamat}
                                            </td>
                                            <td style={{ maxWidth: 230 }}>
                                              {data.keterangan}
                                            </td>
                                            <td>{data.no_hp}</td>
                                            <td>
                                              <div className="d-flex align-items-center justify-content-end gap-2">
                                                <Badge
                                                  onClick={() =>
                                                    handleDelete(index)
                                                  }
                                                  disabled={!isFormEditAble}
                                                  className="btn bg-danger"
                                                  as="button"
                                                >
                                                  <i className="ri-delete-bin-line"></i>
                                                </Badge>
                                              </div>
                                            </td>
                                          </tr>
                                        ))
                                      : !saksiData.length && (
                                          <tr>
                                            <td
                                              colSpan="7"
                                              style={{ textAlign: "center" }}
                                            >
                                              <span>Tidak ada data saksi!</span>
                                            </td>
                                          </tr>
                                        )}
                                    {isAddingRow && (
                                      <tr>
                                        <td>{(saksiData?.length ?? 0) + 1}</td>
                                        <td>
                                          <Input
                                            type="text"
                                            maxLength="100"
                                            name="nama"
                                            required={true}
                                            id="addSaksiRow"
                                            value={saksi.nama}
                                            onChange={handleNewRowChange}
                                            placeholder="Nama"
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            type="text"
                                            name="pekerjaan"
                                            maxLength="100"
                                            required={true}
                                            id="addSaksiRow"
                                            value={saksi.pekerjaan}
                                            onChange={handleNewRowChange}
                                            placeholder="Pekerjaan"
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            type="text"
                                            required={true}
                                            name="alamat"
                                            maxLength="200"
                                            id="addSaksiRow"
                                            value={saksi.alamat}
                                            onChange={handleNewRowChange}
                                            placeholder="Alamat"
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            type="text"
                                            id="addSaksiRow"
                                            required={true}
                                            name="keterangan"
                                            value={saksi.keterangan}
                                            onChange={handleNewRowChange}
                                            placeholder="Keterangan"
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            type="number"
                                            id="addSaksiRow"
                                            required={true}
                                            name="no_hp"
                                            value={saksi.no_hp}
                                            onChange={handleNewRowChange}
                                            placeholder="No. HP"
                                          />
                                        </td>
                                        <td className="d-flex gap-1">
                                          <Button
                                            className="btn bg-danger"
                                            onClick={closeRow}
                                          >
                                            <i className="ri-close-large-line"></i>
                                          </Button>
                                          <Button
                                            className="btn bg-success"
                                            onClick={handleSaveRow}
                                          >
                                            <i className="ri-check-line"></i>
                                          </Button>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </Table>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header className="custom-header">
                    Informasi Tambahan
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row className="mt-2 g-2">
                          <Col sm="8">
                            <Input
                              as="textarea"
                              col="4"
                              label="Keterangan"
                              name="uraian"
                              value={payloadForm.uraian}
                              onChange={handleChange}
                              disabled={!isFormEditAble}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                  <Accordion.Header className="custom-header">
                    Informasi Penyidik yang Menyerahkan
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row className="g-2">
                          <Col sm="12">
                            <Card className="card bg-white">
                              <Card.Body>
                                <Row className="g-1 mb-2 d-flex align-items-center">
                                  <Col sm="12" md="5">
                                    <h6 className="mb-0">Daftar Penyidik</h6>
                                  </Col>
                                  <Col sm="12" md="7">
                                    <Link className="d-flex align-items-center justify-content-end">
                                      {/* <Input
                                        placeholder="Cari Penyidik"
                                        onChange={(e) => {
                                          setPenyidikParam({...penyidikParam, nama_penyidik: e?.target?.value ?? ''})
                                        }}
                                      ></Input> */}
                                      <Button
                                        className="btn btn-primary"
                                        onClick={() =>
                                          setIsAddingRowPenyidik(true)
                                        }
                                        disabled={!isFormEditAble}
                                      >
                                        Tambah Penyidik
                                      </Button>
                                    </Link>
                                  </Col>
                                </Row>
                                <Table className="table dems-table">
                                  <thead>
                                    <tr>
                                      <th>No</th>
                                      <th>NRP</th>
                                      <th>Nama Penyidik</th>
                                      <th>Asal Satker</th>
                                      <th>Pangkat</th>
                                      <th>Jabatan</th>
                                      <th>No Hp</th>
                                      <th>&nbsp;</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {!!penyidikData.length &&
                                      penyidikData.map((data, idx) => (
                                        <tr key={idx}>
                                          <td>{idx + 1}</td>
                                          <td>{data.nrp}</td>
                                          <td>{data.nama_penyidik}</td>
                                          <td>{data.nama_satker}</td>
                                          <td>{data.pangkat}</td>
                                          <td>{data.jabatan}</td>
                                          <td>{data.no_hp}</td>
                                          <td>
                                            <div className="d-flex align-items-center justify-content-end gap-2">
                                              <Badge
                                                onClick={() =>
                                                  setPenyidikData(
                                                    penyidikData.filter(
                                                      (_, index) =>
                                                        index !== idx
                                                    )
                                                  )
                                                }
                                                className="btn bg-danger"
                                                as="button"
                                                disabled={!isFormEditAble}
                                              >
                                                <i className="ri-delete-bin-line"></i>
                                              </Badge>
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                    {isAddingRowPenyidik && (
                                      <tr>
                                        <td>
                                          {(penyidikData?.length ?? 0) + 1}
                                        </td>
                                        <td>
                                          <Input
                                            type="text"
                                            name="nrp"
                                            required={true}
                                            id="addPenyidikRow"
                                            value={penyidikPayload.nrp}
                                            onChange={handleChangeRowPenyidik}
                                            placeholder="NRP"
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            type="text"
                                            name="nama_penyidik"
                                            required={true}
                                            id="addPenyidikRow"
                                            value={
                                              penyidikPayload.nama_penyidik
                                            }
                                            onChange={handleChangeRowPenyidik}
                                            placeholder="Nama Penyidik"
                                          />
                                        </td>
                                        <td style={{ width: "200px" }}>
                                          <Select2
                                            id="addPenyidikRow"
                                            name="nama_satker"
                                            defaultValue={"-"}
                                            placeholder={"Satker"}
                                            options={satkersFilter}
                                            value={penyidikPayload.nama_satker}
                                            onChange={(selectedOption) => {
                                              setPenyidikPayload({
                                                ...penyidikPayload,
                                                nama_satker:
                                                  selectedOption.value,
                                              });
                                            }}
                                            required
                                          />
                                          {/* <Select
                                            default="Pilih Satker"
                                            name="nama_satker"
                                            id="addPenyidikRow"
                                            required={true}
                                            value={penyidikPayload.nama_satker}
                                            onChange={handleChangeRowPenyidik}
                                            options={satkersFilter}
                                          /> */}
                                        </td>
                                        <td style={{ width: "170px" }}>
                                          <Select
                                            default="Pangkat"
                                            name="pangkat"
                                            id="addPenyidikRow"
                                            required={true}
                                            value={penyidikPayload.pangkat}
                                            onChange={handleChangeRowPenyidik}
                                            options={pangkat}
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            type="text"
                                            name="jabatan"
                                            required={true}
                                            id="addPenyidikRow"
                                            value={penyidikPayload.jabatan}
                                            onChange={handleChangeRowPenyidik}
                                            placeholder="Jabatan"
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            type="number"
                                            name="no_hp"
                                            required={true}
                                            id="addPenyidikRow"
                                            value={penyidikPayload.no_hp}
                                            onChange={handleChangeRowPenyidik}
                                            placeholder="No. HP"
                                          />
                                        </td>
                                        <td className="d-flex gap-1">
                                          <Button
                                            className="btn bg-danger"
                                            onClick={() =>
                                              setIsAddingRowPenyidik(false)
                                            }
                                          >
                                            <i className="ri-close-large-line"></i>
                                          </Button>
                                          <Button
                                            className="btn bg-success"
                                            onClick={handleSaveRowPenyidik}
                                          >
                                            <i className="ri-check-line"></i>
                                          </Button>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </Table>
                                {/* <Table className="table dems-table">
                                  <thead>
                                    <tr>
                                      <th>No</th>
                                      <th>NRP</th>
                                      <th>Nama Penyidik</th>
                                      <th>Asal Satker</th>
                                      <th>Pangkat</th>
                                      <th>Jabatan</th>
                                      <th>&nbsp;</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {isListPenyidikFetching && (
                                      <tr>
                                        <td colSpan="7" style={{ textAlign: "center" }}>
                                          <Spinner animation="border" variant="primary" />
                                        </td>
                                      </tr>
                                    )}
                                    {
                                      (listPenyidik.length && !isListPenyidikFetching) && (
                                        listPenyidik.map((data, index) => (
                                          <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{data.nrp}</td>
                                            <td>{data.nama_penyidik}</td>
                                            <td>{data.nama_satker}</td>
                                            <td>{data.pangkat}</td>
                                            <td>{data.jabatan}</td>
                                            <td>
                                              <Form.Check
                                                type="checkbox"
                                                checked={payloadForm.penyidik_ids.some(
                                                  (item) =>
                                                    item === data.id
                                                )}
                                                onChange={(e) => {
                                                  handleCheckbox(
                                                    "penyidik",
                                                    data,
                                                    e.target.checked
                                                  );
                                                }}
                                              />
                                            </td>
                                          </tr>
                                        ))
                                      )
                                    }
                                  </tbody>
                                </Table>
                                  <MyPagination
                                    currentPage={penyidikParam.page}
                                    totalPages={paginationPenyidik.total_pages}
                                    onPageChange={(page) => {
                                      setPenyidikParam({...penyidikParam, page: page})
                                    }}
                                  /> */}
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header className="custom-header">
                    Informasi Rincian Barang Bukti
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row className="g-2 mt-2">
                          <Col sm="12">
                            <Card className="card bg-white">
                              <Card.Body>
                                <Row className="g-1 mb-2 d-flex align-items-center">
                                  <Col sm="12" md="5">
                                    <h6>Daftar Barang Bukti</h6>
                                  </Col>
                                  <Col sm="12" md="7">
                                    <Link className="d-flex align-items-center justify-content-end">
                                      <Button
                                        className="btn btn-primary"
                                        onClick={() => setBarbukModalShow(true)}
                                        disabled={!isFormEditAble}
                                      >
                                        TAMBAH
                                      </Button>
                                    </Link>
                                  </Col>
                                </Row>
                                <Table responsive className="table dems-table">
                                  <thead>
                                    <tr>
                                      <th>No</th>
                                      <th>Jenis</th>
                                      <th>Sub Jenis</th>
                                      <th>Tipe</th>
                                      <th>Unit</th>
                                      <th>Keterangan</th>
                                      <th>File</th>
                                      <th>Aksi</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {daftarBarbuk?.map((data, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{data.jenis || "-"}</td>
                                        <td>{data.sub_jenis || "-"}</td>
                                        <td>{data.tipe || "-"}</td>
                                        <td>{data.unit || "-"}</td>
                                        <td>{data.keterangan || "-"}</td>
                                        <td>{data.photos.length + " File"}</td>
                                        <td>
                                          <div className="d-flex align-items-center justify-content-end gap-2">
                                            <Badge
                                              onClick={() => {
                                                setIndexRincianBarbuk(index);
                                                setRincianBarbukModal(true);
                                                setRincianBarbuk(data);
                                              }}
                                              className="btn bg-primary"
                                              as="button"
                                            >
                                              {isFormEditAble ? (
                                                <i className="ri-edit-2-line"></i>
                                              ) : (
                                                <i className="ri-eye-line"></i>
                                              )}
                                            </Badge>
                                            <Badge
                                              onClick={() => {
                                                setDaftarBarbuk(
                                                  daftarBarbuk.filter(
                                                    (_, idx) => idx !== index
                                                  )
                                                );
                                              }}
                                              disabled={!isFormEditAble}
                                              className="btn bg-danger"
                                              as="button"
                                            >
                                              <i className="ri-delete-bin-line"></i>
                                            </Badge>
                                          </div>
                                        </td>
                                      </tr>
                                    )) ?? ""}
                                  </tbody>
                                </Table>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
          <div className="d-flex justify-content-end align-items-center gap-3">
            {!mutateAdd.isLoading && !mutateEdit.isLoading && (
              <Button variant="warning" as={Link} to="/barbuk/penerimaan/list">
                BATAL
              </Button>
            )}
            {/* {!mutateAdd.isLoading && !mutateEdit.isLoading && (
              <Button
                variant="secondary"
                onClick={() => handleSubmit("DRAFT")}
                disabled={!isFormEditAble}
                hidden={!isFormEditAble}
              >
                SIMPAN DRAFT
              </Button>
            )} */}
            {!mutateAdd.isLoading && !mutateEdit.isLoading && (
              <Button
                variant="primary"
                onClick={() => handleSubmit("FINAL")}
                disabled={!isFormEditAble}
                hidden={!isFormEditAble}
              >
                SIMPAN
              </Button>
            )}
            {(mutateAdd.isLoading || mutateEdit.isLoading) && (
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
                disabled
              >
                Sedang Menyimpan <Spinner variant="light" size="sm" />
              </Button>
            )}
          </div>
        </Card.Footer>
      </Card>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title>Cari Tahanan</Modal.Title>
          <Button
            className="btn btn-danger"
            onClick={() => setModalShow(false)}
          >
            <i className="ri-close-large-fill"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-4">
            <Col sm="12">
              <Input
                label="Nama Tahanan"
                onChange={(e) => {
                  setNamaTahanan(e.target.value);
                }}
              ></Input>
            </Col>
            <Col sm="12">
              <Table className="table dems-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Nama Tahanan</th>
                    <th>Asal Satker</th>
                    <th>Perkara</th>
                    <th>Lokasi</th>
                    <th>Pilih</th>
                  </tr>
                </thead>
                <tbody>
                  {modalFetching && (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        <Spinner
                          animation="border"
                          variant="primary"
                          size="small"
                        />
                      </td>
                    </tr>
                  )}
                  {!modalFetching &&
                    dataTahananList.map((data, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{data.nama_tersangka}</td>
                        <td>{data.nama_satker}</td>
                        <td>{data.perkara}</td>
                        <td>{data.nama_rutan}</td>
                        <td>
                          <Form.Check
                            type="checkbox"
                            onClick={() => {
                              setIdTahanan(data.id);
                              setModalShow(false);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={barbukModalShow}
        onHide={() => setBarbukModalShow(false)}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title>Cari Barang Bukti</Modal.Title>
          <Button
            className="btn btn-danger"
            onClick={() => {
              setBarbukModalShow(false);
              setSearchBarbuk("");
            }}
          >
            <i className="ri-close-large-fill"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-1 mb-2">
            <Col sm="12" md="5">
              <Input
                type="text"
                placeholder="Search"
                onChange={(e) => {
                  setSearchBarbuk(e.target.value);
                }}
              />
            </Col>
            <Col sm="12" md="7"></Col>
          </Row>
          <Row className="g-4">
            <Col sm="12">
              <Table className="table dems-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Jenis</th>
                    <th>Sub Jenis</th>
                    <th>Tipe</th>
                    <th>Unit</th>
                    <th>Pilih</th>
                  </tr>
                </thead>
                <tbody>
                  {masterBarbuk.length && !barbukFetching && !barbukIsError
                    ? masterBarbuk.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.jenis}</td>
                          <td>{data.sub_jenis}</td>
                          <td>{data.tipe}</td>
                          <td>{data.unit}</td>
                          <td>
                            <Button
                              variant="primary"
                              size="small"
                              onClick={() => {
                                setBarbukModalShow(false);
                                setRincianBarbukModal(true);
                                setRincianBarbuk({
                                  ...rincianBarbuk,
                                  jenis: data.jenis,
                                  sub_jenis: data.sub_jenis,
                                  tipe: data.tipe,
                                  unit: data.unit,
                                  keterangan: data.keterangan || "",
                                });
                              }}
                            >
                              Pilih
                            </Button>
                          </td>
                        </tr>
                      ))
                    : !barbukFetching &&
                      !masterBarbuk.length &&
                      !barbukIsError && (
                        <tr>
                          <td colSpan="7" style={{ textAlign: "center" }}>
                            <span>Data yang dicari tidak ditemukan</span>
                          </td>
                        </tr>
                      )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <MyPagination
            currentPage={payloadDebounced.page}
            totalPages={pagination.total_pages}
            onPageChange={handlePaginationBarbuk}
          />
        </Modal.Footer>
      </Modal>

      <Modal
        show={rincianBarbukModal}
        onHide={() => setRincianBarbukModal(false)}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title>Rincian Barang Bukti</Modal.Title>
          <Button
            className="btn btn-danger"
            onClick={() => setRincianBarbukModal(false)}
          >
            <i className="ri-close-large-fill"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-2">
            <Col sm="5">
              <Input
                disabled={!isFormEditAble}
                type="text"
                name="no_label"
                required={true}
                id="addBarbukForm"
                defaultValue={rincianBarbuk.no_label}
                onChange={handleChangeBarbuk}
                placeholder="No Label"
                label="No Label"
              />
            </Col>
            <Col sm="12" />
            <Col sm="4">
              <Input
                disabled={!isFormEditAble}
                type="number"
                name="baik"
                required={true}
                id="addBarbukForm"
                defaultValue={rincianBarbuk.baik}
                onChange={handleChangeBarbuk}
                placeholder="Kondisi Baik"
                label="Kondisi Baik"
                {...(rincianBarbuk.baik > 1000 && {
                  infoCaption: formatNumberWithCommas(
                    rincianBarbuk.baik.toString()
                  ),
                })}
              />
            </Col>
            <Col sm="4">
              <Input
                disabled={!isFormEditAble}
                type="number"
                name="tidak_baik"
                required={true}
                id="addBarbukForm"
                onChange={handleChangeBarbuk}
                defaultValue={rincianBarbuk.tidak_baik}
                placeholder="Kondisi Tidak Baik"
                label="Kondisi Tidak Baik"
                {...(rincianBarbuk.tidak_baik > 1000 && {
                  infoCaption: formatNumberWithCommas(
                    rincianBarbuk.tidak_baik.toString()
                  ),
                })}
              />
            </Col>
            <Col sm="2">
              <Input
                type="number"
                name="jumlah_penerimaan"
                required={true}
                disabled
                id="addBarbukForm"
                value={rincianBarbuk.jumlah_penerimaan}
                placeholder="Jumlah"
                label="Jumlah"
                {...(rincianBarbuk.jumlah_penerimaan > 1000 && {
                  infoCaption: formatNumberWithCommas(
                    rincianBarbuk.jumlah_penerimaan.toString()
                  ) + " " + rincianBarbuk?.unit ?? "-",
                })}
              />
            </Col>
            {
              rincianBarbuk.jumlah_penerimaan < 1000 &&
              (
                <Col>
                    <div 
                      className="fs-md fw-bold text-primary"
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        paddingTop: 25
                      }}
                    >
                      {rincianBarbuk?.unit ?? ''}
                    </div>
                </Col>
              )
            }
            <Col sm="6">
              <Input
                disabled={!isFormEditAble}
                as="textarea"
                label="Keterangan"
                onChange={handleChangeBarbuk}
                value={rincianBarbuk.keterangan}
                name="keterangan"
              />
            </Col>
            <Col sm="12">
              <Card className="bg-white">
                <Card.Body>
                  <Row className="g-2">
                    <Col sm="12">
                      <h6>Pilih Penyimpanan</h6>
                    </Col>
                    <Col sm="12">
                      {/* <Select
                        disabled={!isFormEditAble}
                        label="Lokasi"
                        onChange={handleChangeBarbuk}
                        value={rincianBarbuk.lokasi}
                        id="addBarbukForm"
                        required
                        name="lokasi"
                        default="-"
                        options={lokasiOptions}
                      /> */}
                      <Select2
                        options={lokasiOptions}
                        value={rincianBarbuk.lokasi}
                        required
                        isClearable
                        isSearchable
                        disabled={!isFormEditAble}
                        onChange={(e) => {
                          const selectedLokasi = lokasiPenyimpananList.find(
                            (lokasi) => lokasi.lokasi === e.value
                          );

                          if (selectedLokasi) {
                            setRincianBarbuk({
                              ...rincianBarbuk,
                              lokasi: selectedLokasi.lokasi,
                              alamat: selectedLokasi.alamat,
                              keterangan_penyimpanan: selectedLokasi.keterangan,
                            });
                          }
                        }}
                      />
                    </Col>
                    <Col sm="6">
                      <Input
                        disabled={!isFormEditAble}
                        as="textarea"
                        label="Alamat"
                        id="addBarbukForm"
                        onChange={handleChangeBarbuk}
                        value={rincianBarbuk.alamat}
                        name="alamat"
                      />
                    </Col>
                    <Col sm="6">
                      <Input
                        disabled={!isFormEditAble}
                        as="textarea"
                        id="addBarbukForm"
                        label="Catatan"
                        onChange={handleChangeBarbuk}
                        value={rincianBarbuk.keterangan_penyimpanan}
                        name="keterangan_penyimpanan"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            {true && (
              <Col sm="12">
                <Files
                  required
                  className="dems-files-dropzone"
                  onChange={(files) => {
                    setRincianBarbuk({
                      ...rincianBarbuk,
                      photos: [
                        ...rincianBarbuk.photos,
                        ...files.map((file) => ({
                          photo: file.name,
                          tag: [],
                          keterangan: "",
                        })),
                      ],
                    });
                    setPayloadForm({
                      ...payloadForm,
                      photos: [...payloadForm.photos, ...files],
                    });
                  }}
                  onError={(e) => {
                    alert(e?.message ?? "File Error");
                  }}
                  accepts={["image/*", ".pdf", "audio/*", "video/*"]}
                  multiple
                  maxFileSize={10000000}
                  minFileSize={0}
                  clickable={isFormEditAble}
                >
                  {rincianBarbuk.photos.map((photo, idx) => (
                    <div key={idx} className="dems-file-upload">
                      {isFormEditAble && (
                        <Badge
                          bg="danger"
                          as="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (photo.foto_url) {
                              setPayloadForm({
                                ...payloadForm,
                                remove_photo_ids: [
                                  ...(payloadForm?.remove_photo_ids ?? []),
                                  photo.id_foto,
                                ],
                              });
                              setRincianBarbuk({
                                ...rincianBarbuk,
                                photos: [
                                  ...rincianBarbuk.photos.filter(
                                    (f) => f.foto_url !== photo.foto_url
                                  ),
                                ],
                              });
                            } else {
                              setPayloadForm({
                                ...payloadForm,
                                photos: [
                                  ...payloadForm.photos.filter(
                                    (f) => f.name !== photo.photo
                                  ),
                                ],
                              });
                              setRincianBarbuk({
                                ...rincianBarbuk,
                                photos: [
                                  ...rincianBarbuk.photos.filter(
                                    (f) => f.photo !== photo.photo
                                  ),
                                ],
                              });
                            }
                          }}
                        >
                          <i className="ri-close-large-line"></i>
                        </Badge>
                      )}
                      {photo?.foto_url &&
                        (() => {
                          const { foto_url } = photo;
                          const { p: path, k: kategori } =
                            trimUrlPath(foto_url);
                          const file = trimFileUrl(foto_url);
                          return (
                            <Link
                              onClick={(e) => e.stopPropagation()}
                              to={`/arsip/view/${file}?kategori=${kategori}&p=${encodeToHex(
                                path
                              )}`}
                              target="_blank"
                              className="text-decoration-underline"
                            >
                              {trimFileUrl(photo.foto_url) || "file"}
                            </Link>
                          );
                        })()}
                      {photo?.photo && photo.photo}
                    </div>
                  ))}
                  {isFormEditAble && (
                    <div className="box-dems-click-upload">
                      <i className="ri-file-add-line"></i>
                    </div>
                  )}
                </Files>
              </Col>
            )}
            {/* {
              (!payloadForm.photos.length) &&
              (
                <Row className="g-2 mt-2">
                  <Col sm="12">
                    <h5>File Barang Bukti</h5>
                  </Col>
                  <Col sm="12">
                    {
                      !!rincianBarbuk.photos.length && (
                        rincianBarbuk.photos.map((photo, idx) => {
                          const { foto_url } = photo
                          const { p: path, k: kategori } =
                            trimUrlPath(foto_url);
                          const file = trimFileUrl(
                            foto_url
                          );
                          return (
                            <div key={idx} className="list-file-uploaded">
                              <Link 
                                target="_blank" to={`/arsip/view/${file}?kategori=${kategori}&p=${encodeToHex(path)}`}
                                target="_blank"
                                className="link-file"
                              >
                                <i className="ri-external-link-line"></i>
                                {file}
                              </Link>
                            </div>
                          )
                        })
                      )
                    }
                  </Col>
                </Row>
              )
            } */}
          </Row>
        </Modal.Body>
        {isFormEditAble && (
          <Modal.Footer>
            <Button
              variant="primary"
              size="medium"
              onClick={() => {
                addRincianBarbuk();
              }}
            >
              Simpan
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </React.Fragment>
  );
}

export default FormPenerimaanBarangBukti;
